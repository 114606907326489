import { uniqueId } from "lodash";
import { EnvironmentItemType, RoomItemType, SpaceItemType } from "../types";

export const generateNewSpaceItem = (): SpaceItemType => ({
  uuid: uniqueId("space-item-"),
  type: "space",
  name: "New space",
  sq: null,
  rooms: [],
  description: "",
  files: [],
  files_generated: [],
  environment: generateNewEnvironmentItem(),
});

export const generateNewRoomItem = (
  _roomItem: Partial<RoomItemType> = {}
): RoomItemType => ({
  uuid: uniqueId("space-item-"),
  type: "room",
  name: _roomItem.name || "New room",
  sq: _roomItem.sq || null,
  description: _roomItem.description || "",
  files: _roomItem.files || [],
  files_generated: _roomItem.files_generated || [],
});

export const generateNewEnvironmentItem = (): EnvironmentItemType => ({
  uuid: uniqueId("environment-item-"),
  type: "environment",
  name: "Space Environment",
  description: "",
  files: [],
  files_generated: [],
});
