/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef } from "react";
import { FiberMeshProps, useScene } from "react-babylonjs";
import { Color3 } from "@babylonjs/core";

export const ModelFallback = ({ ...rest }: FiberMeshProps) => {
  // access Babylon Scene

  const scene = useScene();

  // access refs to Babylon objects in scene like DOM nodes

  const boxRef = useRef<any>(null);

  // there is also a built-in hook called useBeforeRender that does will do this:

  useEffect(() => {
    if (boxRef.current !== null && scene) {
      const onBeforeRender = () => {
        const deltaTimeInMillis = scene.getEngine().getDeltaTime();
        boxRef.current.rotation.y +=
          (20 / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
      };

      scene.registerBeforeRender(onBeforeRender);

      return () => {
        scene.unregisterBeforeRender(onBeforeRender);
      };
    }
  }, [scene]);
  return (
    <box
      {...rest}
      name="loading-ballback"
      ref={boxRef}
      size={0.5}
      disposeInstanceOnUnmount
    >
      <standardMaterial
        name="cylinder-mat"
        diffuseColor={Color3.Green()}
        specularColor={Color3.Black()}
        alpha={0.5}
      />
    </box>
  );
};
