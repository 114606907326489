import React, { Component } from "react";
import { Divider, Typography, Col, Row, Spin, Empty } from "antd";
import { connect } from "react-redux";
import "./productDetails.scss";
import { Content } from "antd/lib/layout/layout";
import { getParamsFromPathname } from "../../../../utils/params";
import EasyEdit from "react-easy-edit";
import InlineTextInput from "./InlineInputs/TextInput";
import PriceDisplay from "./InlineInputs/PriceDisplay";
import TextDisplay from "./InlineInputs/TextDisplay";
import DimensionsInput from "./InlineInputs/DimensionsInput";
import DimensionDisplay from "./InlineInputs/DimensionsDisplay";
import TextAreaInput from "./InlineInputs/TextAreaInput";
import TextAreaDisplay from "./InlineInputs/TextAreaDisplay";
import URLDisplay from "./InlineInputs/URLDisplay";
import AudioInput from "./InlineInputs/AudioInput";
import AudioDisplay from "./InlineInputs/AudioDisplay";
import ThumbnailDisplay from "./InlineInputs/ThumbnailDisplay";
import ThumbnailInput from "./InlineInputs/ThumbnailInput";
import { patchVariant } from "store/actions/variant";
import PriceInput from "./InlineInputs/PriceInput";
import _ from "lodash";

const { Title } = Typography;

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { modalTitle: "product", imageVisable: false };
  }

  saveString = (key, value) => {
    switch (key) {
      case "dimensions":
        this.props.patchVariant(this.props.variantData.id, value);
        break;
      default:
        this.props.patchVariant(
          this.props.variantData.id,
          { [key]: value },
          true
        );
        break;
    }
  };

  handleSavePrice = (values) => {
    // price,
    // price_alternate,
    // price_ca
    _.forEach(values, (value, key) => {
      if (!values[key]) {
        values[key] = "";
      }
    });
    this.props.patchVariant(this.props.variantData.id, values);
  };

  // eslint-disable-next-line
  cancel = () => {};

  render() {
    return this.props.loading ? (
      <Content className="productDetail-container">
        <Spin>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Spin>
      </Content>
    ) : (
      <div className="productDetail-container">
        <Content className="product-description">
          <Row justify="center">
            <Title className="title" level={2}>
              Preview / Edit Product Variant Details{" "}
            </Title>
          </Row>
          <Row>
            <Col span={10}>
              <section>
                <EasyEdit
                  value={this.props.variantData.name || "N/A"}
                  type="text"
                  onSave={(value) => this.saveString("name", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<InlineTextInput />}
                  displayComponent={
                    <TextDisplay bold showEditAfter prefix={"Product Name: "} />
                  }
                  onValidate={(value) => {
                    return value && value !== "";
                  }}
                  validationMessage={"can not be empty"}
                />
              </section>
              <Divider />
              <section>
                <div className="productPrice">
                  <EasyEdit
                    value={{
                      price: this.props.variantData.price,
                      price_alternate: this.props.variantData.price_alternate,
                      price_ca: this.props.variantData.price_ca,
                    }}
                    type="text"
                    onSave={(values) => this.handleSavePrice(values)}
                    onCancel={this.cancel}
                    saveButtonLabel="save"
                    cancelButtonLabel="cancel"
                    editComponent={<PriceInput />}
                    displayComponent={<PriceDisplay />}
                    onValidate={(value) => {
                      return value;
                    }}
                    validationMessage={"must be a whole number"}
                  />
                </div>
              </section>
              <Divider />
              <section>
                <EasyEdit
                  value={{
                    depth_en: this.props.variantData.depth_en,
                    width_en: this.props.variantData.width_en,
                    height_en: this.props.variantData.height_en,
                    diameter_en: this.props.variantData.diameter_en,
                  }}
                  type="text"
                  onSave={(value) => this.saveString("dimensions", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<DimensionsInput />}
                  displayComponent={<DimensionDisplay />}
                  // onValidate={value => {return value && value !== ""}}
                  validationMessage={"invalid"}
                />
              </section>
              <Divider />
              <section>
                <EasyEdit
                  value={this.props.variantData.description}
                  type="text"
                  onSave={(value) => this.saveString("description", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<TextAreaInput />}
                  displayComponent={<TextAreaDisplay />}
                  // onValidate={value => {return value && value !== ""}}
                  validationMessage={"invalid"}
                />
              </section>
            </Col>
            <Col span={2} />
            <Col span={10}>
              <section>
                <EasyEdit
                  value={
                    this.props.itemData.thumbnail_url !== ""
                      ? this.props.itemData.thumbnail_url
                      : false
                  }
                  type="text"
                  onSave={(value) => this.saveString("thumbnail_url", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<AudioInput />}
                  displayComponent={<AudioDisplay />}
                  validationMessage={"can not be empty"}
                />
              </section>
              <Divider />
              <section>
                <EasyEdit
                  value={this.props.variantData.product_url || "N/A"}
                  type="text"
                  onSave={(value) => this.saveString("product_url", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<InlineTextInput />}
                  displayComponent={<URLDisplay />}
                  onValidate={(value) => {
                    return value && value !== "";
                  }}
                  validationMessage={"can not be empty"}
                />
              </section>
              <Divider />
              <section>
                <EasyEdit
                  value={{
                    url: this.props.variantData.new_thumbnail_url || "",
                    assetId: this.props.variantData.thumbnail_id,
                    variantId: this.props.variantData.id,
                  }}
                  type="text"
                  onCancel={this.cancel}
                  onSave={() => {}}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<ThumbnailInput />}
                  displayComponent={<ThumbnailDisplay />}
                  validationMessage={"can not be empty"}
                />
              </section>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = ({ items, variants }) => {
  let {
    orderIdInUrl,
    companyIdInUrl,
    itemCollectionIdInUrl,
    versionIdInUrl,
    variantIdInUrl,
    itemIdInUrl,
  } = getParamsFromPathname(window.location.pathname);

  let loading =
    !companyIdInUrl ||
    !orderIdInUrl ||
    !itemCollectionIdInUrl ||
    !versionIdInUrl ||
    !variantIdInUrl ||
    !items[itemIdInUrl] ||
    !variants[variantIdInUrl];

  if (!loading) {
    return {
      loading,
      variantData: variants[variantIdInUrl],
      itemData: items[itemIdInUrl],
    };
  } else {
    return {
      loading,
    };
  }
};

export default connect(mapStateToProps, {
  patchVariant,
})(ProductDetails);
