import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { Row, message, Card } from "antd";
import { Form, Input, Button } from "antd";
import { connect } from "react-redux";
import {
  validateContainNumber,
  validateLowerCase,
  validateMinimumLength,
  validateSpecialCharacter,
  validateUpperCase,
  validateMatch,
} from "utils";
import AmplifyService from "services/AmplifyService";
import logo from "assets/images/loginLogo.png";
import { setToken } from "store/actions/auth";

class AccountSettings extends Component {
  loginFormRef = React.createRef();

  state = {
    submitLoading: false,
  };

  onFinish = (values) => {
    this.setState({ submitLoading: true });
    AmplifyService.userChangePassword(values.oldPassword, values.newPassword)
      .then(() => {
        message.success("Reset password success! Signing out...");
        AmplifyService.userSignout({ global: true })
          .then(() => {
            this.props.setToken();
          })
          .catch((err) => console.warn(err))
          .finally(() => this.setState({ submitLoading: false }));
      })
      .catch((err) => {
        message.error(err);
        this.setState({ submitLoading: false });
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    return (
      <div className={"screen-container"}>
        <Row className="content_row" justify="center" align="middle">
          <Card className="login_card">
            <Row className="content_row" justify="center" align="middle">
              <img className="login_logo_img" alt="bitreel" src={logo} />
            </Row>
            <Row justify="center" align="middle">
              <h1>Reset Your Password</h1>
            </Row>
            <Form
              name="basic"
              // labelCol={{ span: 14, offset: 5 }}
              // wrapperCol={{ span: 14, offset: 5 }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              autoComplete="off"
              layout="vertical"
              ref={this.loginFormRef}
            >
              <Form.Item
                label="Old password"
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    validator: (_, value) => validateLowerCase(value),
                  },
                  {
                    validator: (_, value) => validateUpperCase(value),
                  },
                  {
                    validator: (_, value) => validateSpecialCharacter(value),
                  },
                  {
                    validator: (_, value) => validateContainNumber(value),
                  },
                  {
                    validator: (_, value) => validateMinimumLength(value),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="New password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    validator: (_, value) => validateLowerCase(value),
                  },
                  {
                    validator: (_, value) => validateUpperCase(value),
                  },
                  {
                    validator: (_, value) => validateSpecialCharacter(value),
                  },
                  {
                    validator: (_, value) => validateContainNumber(value),
                  },
                  {
                    validator: (_, value) => validateMinimumLength(value),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item label="Confirm new password" required>
                <Form.Item
                  name="confirmPassword"
                  noStyle
                  dependencies={["newPassword"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        return validateMatch(
                          getFieldValue("newPassword"),
                          value
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  loading={this.state.submitLoading}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }) => {
  return { profile };
};

export default connect(mapStateToProps, {
  setToken,
})(withRouter(AccountSettings));
