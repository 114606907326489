import React, { useMemo } from "react";
import { Image, Upload, UploadFile, message } from "antd";
import { FilesDropZone, FilesDropZoneProps } from "./FilesDropZone";
import { VideoPlayerPreview } from "./VideoPlayer";

export type TypeLimit = {
  maxSize?: number;
};
interface MediaDropZoneProps extends FilesDropZoneProps {
  value?: UploadFile<any>[];
  validateFileTypes?: { [key: string]: TypeLimit };
}

export const MediaDropZone = ({
  value = [],
  validateFileTypes,
  ...rest
}: MediaDropZoneProps) => {
  const filesByType = useMemo(() => {
    const result: {
      imageFiles: UploadFile<any>[];
      videoFiles: UploadFile<any>[];
    } = {
      imageFiles: [],
      videoFiles: [],
    };
    value.forEach((item) => {
      if (
        item.name.endsWith(".mp4") ||
        (item.type && item.type.startsWith("video"))
      ) {
        result.videoFiles.push(item);
      } else {
        result.imageFiles.push(item);
      }
    });
    return result;
  }, [value]);
  return (
    <div className="media-drop-zone">
      <div className="media-group">
        {!!filesByType.imageFiles.length && (
          <Image.PreviewGroup>
            {filesByType.imageFiles.map(({ url, uid }) => (
              <Image key={`image-${uid}`} width={178} height={178} src={url} />
            ))}
          </Image.PreviewGroup>
        )}
        {!!filesByType.videoFiles.length &&
          filesByType.videoFiles.map((videoItem) => (
            <VideoPlayerPreview
              value={videoItem}
              key={`video-${videoItem.uid}`}
            />
          ))}
      </div>
      <FilesDropZone
        {...rest}
        onChange={(args) => {
          if (rest.onChange) rest.onChange(args);
        }}
        beforeUpload={(file) => {
          if (validateFileTypes && validateFileTypes[file.type]) {
            const { maxSize } = validateFileTypes[file.type];
            if (maxSize && maxSize < file.size) {
              message.error("File size exceeded!");
              return Upload.LIST_IGNORE;
            }
          }
          return false;
        }}
        fileList={value}
      />
    </div>
  );
};
