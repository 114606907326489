import axios from "axios";
import { BACKEND_URL, GET_METHOD, POST_METHOD } from "sharedConstants";
import ApiService from "../ApiService";

export const createNewAsset = async function (type, filename) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/static_assets`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      type,
      name: filename,
    },
  });
  return response;
};

export const getAssetInfo = async function (assetId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/static_assets/${assetId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export const uploadAsset = async (
  assetId,
  file,
  createAsset = false,
  assetType = null,
  fileName = null
) => {
  let assetResponse;
  if (!assetId && createAsset) {
    assetResponse = await ApiService.createNewAsset(assetType, fileName);
  } else if (assetId) {
    assetResponse = await ApiService.getAssetInfo(assetId);
  } else {
    console.warn("Asset create failed");
    return false;
  }

  try {
    const { url, fields } = assetResponse.data.post_url;
    const uploadFormData = new FormData();
    Object.keys(fields).forEach((key) => {
      uploadFormData.append(key, fields[key]);
    });
    uploadFormData.append("file", file);
    await ApiService.postToS3(url, uploadFormData);
    return assetResponse.data.id;
  } catch (error) {
    return false;
  }
};
