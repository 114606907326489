import React, { useCallback } from "react";
import { notification } from "antd";
import { CollectionType } from "../types";
import { useUIContext } from "../contexts/UIContext";
import { patchCollection } from "../utils/api";
import { useLayoutsContext } from "../contexts/LayoutsContext";

export const useCollection = () => {
  const { setCollection, setSelectedKey } = useLayoutsContext();
  const { taskWrapper } = useUIContext();

  const updateCollection = useCallback(
    async (collection: Pick<CollectionType, "uuid" | "apiItemId" | "name">) => {
      await patchCollection(collection.apiItemId, { name: collection.name });
      setCollection((prevCollection) => ({ ...prevCollection, ...collection }));
      notification.success({
        message: "Collection successfully updated!",
      });
    },
    [setCollection]
  );

  return {
    updateCollection: taskWrapper(updateCollection),
  };
};
