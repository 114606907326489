import React, { useMemo, useState } from "react";
import { Button, Form, Input, UploadFile } from "antd";
import { useParts } from "../hooks";
import { LayoutType, PartType, SelectedItem } from "./../types";
import { useUIContext } from "../contexts/UIContext";
import { FilesDropZone } from "components/Media";
import { imageExtensions } from "sharedConstants";
import ApiService from "services/ApiService";
import FormItemLabel from "antd/es/form/FormItemLabel";

interface PartViewProps {
  selectedItem: SelectedItem;
}

export const PartView = ({ selectedItem }: PartViewProps) => {
  const [refreshKey, setRefreshKey] = useState(0);
  const { updatePart } = useParts();
  const { isDirty, setIsDirty } = useUIContext();
  const part = selectedItem.item as PartType;

  // const isImage = (filename: string) => {
  //   const filenameSplit = filename.split(".");
  //   return imageExtensions.includes(filenameSplit[filenameSplit.length - 1]);
  // };

  // const layout = selectedItem.item as LayoutType;
  // const fileList = useMemo<UploadFile[]>(
  //   () =>
  //     layout.files.reference_file.map((file: any) => ({
  //       name: `${file.file_name}`,
  //       uid: file.id,
  //       thumbUrl: isImage(file.file_name) ? file.url : undefined,
  //       url: file.url,
  //     })) || [],
  //   [layout.files.reference_file]
  // );

  const onFinish = async (values: any) => {
    await updatePart({
      uuid: part.uuid,
      apiId: part.apiId,
      name: values.name,
      priceMod: values.priceMod,
    });
    setIsDirty(false);
  };

  return (
    <div className="part-view">
      <Form
        name="part-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: part.name,
          priceMod: part.priceMod || 0,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Price Mod"
          name="priceMod"
          rules={[{ required: true, message: "Please input the price mod!" }]}
        >
          <Input addonBefore="$" type="number" />
        </Form.Item>

        {/* <Form.Item
          label="Part Thumbnail"
          name="referenceFiles"
          valuePropName="fileList"
        > */}
        <p>Thumbnail</p>
        <div>
          <img
            src={
              part.external_url +
              "/thumbnail_compressed/256.png?rerender=" +
              Date.now()
            }
          />
        </div>
        <>
          <FilesDropZone
            beforeUpload={async (fileData) => {
              const uploadFormData = new FormData();
              Object.keys(part.thumbnail_post_url.fields).forEach((key) => {
                uploadFormData.append(key, part.thumbnail_post_url.fields[key]);
              });
              uploadFormData.append("file", fileData);

              // @ts-ignore
              const s3UploadResponse = await ApiService.postToS3(
                part.thumbnail_post_url?.url,
                uploadFormData
              );

              setTimeout(() => {
                setRefreshKey(refreshKey + 1);
              }, 10000);
              return false;
            }}
            fileList={[]}
            listType="picture"
            showUploadList={{ showRemoveIcon: false }}
          />
        </>
        {/* </Form.Item> */}

        <Form.Item>
          <div className="commit-buttons">
            <Button disabled={!isDirty} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              htmlType="reset"
              onClick={() => {
                setIsDirty(false);
              }}
            >
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
