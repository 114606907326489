import { Auth } from "aws-amplify";
import ApiService from "./ApiService";

class AmplifyService {
  userChangePassword = async (oldPass, newPass) => {
    return await Auth.currentAuthenticatedUser().then((user) => {
      return Auth.changePassword(user, oldPass, newPass);
    });
  };

  forgotPassword = async (email, code, newPass) => {
    return await Auth.forgotPasswordSubmit(email, code, newPass);
  };

  sendVerificationCode = async (email) => {
    return await Auth.forgotPassword(email);
  };

  userSignUp = async (username, password) => {
    const user = await Auth.signUp({
      username,
      password,
      attributes: { email: username, name: username },
    });
    // await ApiService.postUserCredential(username, user.userSub);
    return user;
  };

  userSignIn = async (username, password) => {
    const user = await Auth.signIn(username, password);
    return user;
  };

  userSignout = async () => {
    const user = await Auth.signOut();
    return user;
  };
}

export default new AmplifyService();
