import React, { Component } from "react";
import {
  Divider,
  Typography,
  Col,
  Row,
  Spin,
  Empty,
  Upload,
  message,
} from "antd";
import { connect } from "react-redux";
import "./productDetails.scss";
import { Content } from "antd/lib/layout/layout";
import { getParamsFromPathname } from "../../../../utils/params";
import EasyEdit from "react-easy-edit";
import InlineTextInput from "./InlineInputs/TextInput";
import PriceDisplay from "./InlineInputs/PriceDisplay";
import TextDisplay from "./InlineInputs/TextDisplay";
import DimensionsInput from "./InlineInputs/DimensionsInput";
import DimensionDisplay from "./InlineInputs/DimensionsDisplay";
import TextAreaInput from "./InlineInputs/TextAreaInput";
import TextAreaDisplay from "./InlineInputs/TextAreaDisplay";
import URLDisplay from "./InlineInputs/URLDisplay";
import AudioInput from "./InlineInputs/AudioInput";
import AudioDisplay from "./InlineInputs/AudioDisplay";
import ThumbnailDisplay from "./InlineInputs/ThumbnailDisplay";
import ThumbnailInput from "./InlineInputs/ThumbnailInput";
import { patchVariant } from "store/actions/variant";
import PriceInput from "./InlineInputs/PriceInput";
import _ from "lodash";
import { uploadAsset } from "../../../../services/assets";

const { Title } = Typography;

class NewThumbnail extends Component {
  state = {};
  render() {
    return (
      <Upload
        showUploadList={false}
        beforeUpload={async (file) => {
          var fr = new FileReader();
          fr.onload = () => {
            this.props.setThumbPreview(fr.result, file);
            this.props.setParentValue(fr.result);
          };
          fr.readAsDataURL(file);
          return false;
        }}
      >
        Click here to upload new thumbnail.
      </Upload>
    );
  }
}

class ProductDetailsCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: "product",
      imageVisable: false,
      productDetails: {},
    };
  }

  saveString = (key, value) => {
    switch (key) {
      case "dimensions":
        this.setState(
          {
            productDetails: {
              ...this.state.productDetails,
              ...value,
            },
          },
          () => {
            this.props.setVariantInfoFxn(this.state.productDetails);
          }
        );
        break;
      default:
        this.setState(
          {
            productDetails: {
              ...this.state.productDetails,
              [key]: value,
            },
          },
          () => {
            this.props.setVariantInfoFxn(this.state.productDetails);
          }
        );
        break;
    }
  };

  handleSavePrice = (values) => {
    this.setState(
      {
        productDetails: {
          ...this.state.productDetails,
          ...values,
        },
      },
      () => {
        this.props.setVariantInfoFxn(this.state.productDetails);
      }
    );
  };

  setThumbPreview = (preview, file) => {
    this.setState(
      {
        thumbPreview: preview,
        productDetails: {
          ...this.state.productDetails,
          thumbnail: file,
        },
      },
      () => this.props.setVariantInfoFxn(this.state.productDetails)
    );
  };

  render() {
    return this.props.loading ? (
      <Content className="productDetail-container">
        <Spin>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Spin>
      </Content>
    ) : (
      <div className="productDetail-container">
        <Content className="product-description">
          <Row justify="center">
            <Title className="title" level={2}>
              Create Variant Advanced{" "}
            </Title>
          </Row>
          <Row>
            <Col span={10}>
              <section>
                <h3 className="section-title">Product Name</h3>
                <EasyEdit
                  value={this.state.productDetails.name}
                  type="text"
                  onSave={(value) => this.saveString("name", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<InlineTextInput />}
                  displayComponent={<TextDisplay bold showEditAfter />}
                  onValidate={(value) => {
                    return value && value !== "";
                  }}
                  validationMessage={"can not be empty"}
                />
              </section>
              <Divider />
              <section>
                <div className="productPrice">
                  <EasyEdit
                    value={{
                      price: this.state.productDetails.price,
                      price_alternate:
                        this.state.productDetails.price_alternate,
                      price_ca: this.state.productDetails.price_ca,
                    }}
                    type="text"
                    onSave={(values) => this.handleSavePrice(values)}
                    onCancel={this.cancel}
                    saveButtonLabel="save"
                    cancelButtonLabel="cancel"
                    editComponent={<PriceInput />}
                    displayComponent={<PriceDisplay />}
                    onValidate={(value) => {
                      return value;
                    }}
                    validationMessage={"must be a whole number"}
                  />
                </div>
              </section>
              <Divider />
              <section>
                <EasyEdit
                  value={{
                    depth_en: this.state.productDetails.depth_en,
                    width_en: this.state.productDetails.width_en,
                    height_en: this.state.productDetails.height_en,
                    diameter_en: this.state.productDetails.diameter_en,
                  }}
                  type="text"
                  onSave={(value) => this.saveString("dimensions", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<DimensionsInput />}
                  displayComponent={<DimensionDisplay />}
                  // onValidate={value => {return value && value !== ""}}
                  validationMessage={"invalid"}
                />
              </section>
            </Col>
            <Col span={2} />
            <Col span={10}>
              <section>
                <EasyEdit
                  value={this.state.productDetails.product_url || "N/A"}
                  type="text"
                  onSave={(value) => this.saveString("product_url", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<InlineTextInput />}
                  displayComponent={<URLDisplay />}
                  onValidate={(value) => {
                    return value && value !== "";
                  }}
                  validationMessage={"can not be empty"}
                />
              </section>
              <Divider />
              <section>
                <h3 className="section-title">Thumbnail</h3>
                <EasyEdit
                  value={this.state.thumbPreview}
                  type="text"
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={
                    <NewThumbnail
                      setThumbPreview={this.setThumbPreview}
                      setVariantInfoFxn={this.props.setVariantInfoFxn}
                    />
                  }
                  displayComponent={
                    <div
                      className="new-thumbnail-preview"
                      style={{
                        backgroundImage: `url('${this.state.thumbPreview}')`,
                      }}
                    ></div>
                  }
                  validationMessage={"can not be empty"}
                />
              </section>
              <Divider />
              <section>
                <h3 className="section-title">Description</h3>
                <EasyEdit
                  value={this.props.variantData.description}
                  type="text"
                  onSave={(value) => this.saveString("description", value)}
                  onCancel={this.cancel}
                  saveButtonLabel="save"
                  cancelButtonLabel="cancel"
                  editComponent={<TextAreaInput />}
                  displayComponent={<TextAreaDisplay />}
                  // onValidate={value => {return value && value !== ""}}
                  validationMessage={"invalid"}
                />
              </section>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = ({ items, variants }, { setVariantInfoFxn }) => {
  let {
    orderIdInUrl,
    companyIdInUrl,
    itemCollectionIdInUrl,
    versionIdInUrl,
    variantIdInUrl,
    itemIdInUrl,
  } = getParamsFromPathname(window.location.pathname);

  // let loading = !companyIdInUrl || !orderIdInUrl || !itemCollectionIdInUrl
  // || !versionIdInUrl || !variantIdInUrl || !items[itemIdInUrl] || !variants[variantIdInUrl]
  let loading = false;

  if (!loading) {
    return {
      loading,
      variantData: {},
      itemData: {},
      setVariantInfoFxn,
    };
  } else {
    return {
      loading,
      setVariantInfoFxn,
    };
  }
};

export default connect(mapStateToProps, {
  patchVariant,
})(ProductDetailsCreate);
