import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Model, useScene } from "react-babylonjs";
import _, { round } from "lodash";
import { BabylonFallbackLoading } from "./babylonFallbackLoading";
import { Vector3 } from "@babylonjs/core";

interface BabylonModelProps {
  rootUrl: string;
  sceneFilename: string;
  sceneName: string;
  children?: React.ReactElement | React.ReactElement[] | null;
  onModelLoading?: (value: boolean) => void;
  showroomShellOffset: {
    x: number | null;
    y: number | null;
    z: number | null;
  };
}

export const BabylonModel = ({
  rootUrl,
  sceneFilename,
  sceneName,
  children,
  showroomShellOffset,
  onModelLoading = () => {},
}: BabylonModelProps) => {
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [isLoadedModel, setIsLoadedModel] = useState<boolean>(false);
  const setLoadingProgressThrottle = useMemo(
    () => _.throttle((value: number) => setLoadingProgress(value), 500),
    []
  );

  useEffect(() => {
    onModelLoading(true);
  }, []);

  // useEffect(() => {
  //   if (scene) {
  //     scene.debugLayer.show();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <>
      <Suspense
        key={sceneFilename}
        fallback={<BabylonFallbackLoading progress={loadingProgress} />}
      >
        <Model
          name={sceneName}
          rootUrl={rootUrl}
          sceneFilename={sceneFilename}
          disposeInstanceOnUnmount
          onLoadProgress={(event) => {
            setLoadingProgressThrottle(
              round((event.loaded / event.total) * 100, 0)
            );
          }}
          onModelError={(model) => {
            console.log(model);
          }}
          onModelLoaded={(model) => {
            setIsLoadedModel(true);
            onModelLoading(false);
          }}
          pluginExtension=".glb"
          position={
            showroomShellOffset.x
              ? new Vector3(
                  showroomShellOffset.x || 0,
                  showroomShellOffset.y || 0,
                  showroomShellOffset.z || 0
                )
              : undefined
          }
        />
        {isLoadedModel && children ? children : null}
      </Suspense>
    </>
  );
};
