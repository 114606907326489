import React, { useCallback } from "react";
import { notification } from "antd";
import { patchPartGroup, postPartGroup, deletePartGroup } from "../utils/api";
import { useLayoutsContext } from "../contexts/LayoutsContext";
import { LayoutType, PartGroupType } from "../types";
import { useUIContext } from "../contexts/UIContext";

export const usePartGroups = () => {
  const { setSelectedKey, updateItemByUUID, getItemByUUID } =
    useLayoutsContext();
  const { taskWrapper } = useUIContext();

  const addPartGroup = useCallback(
    async (
      { name, optional }: { name: string; optional?: boolean },
      { layout }: { layout: LayoutType }
    ) => {
      if (layout) {
        const partGroup = await postPartGroup(
          name,
          layout.apiVersionId,
          optional
        );
        if (partGroup) {
          updateItemByUUID(layout.uuid, (_layout) => {
            (_layout as LayoutType).partGroups.push(partGroup);
            return _layout;
          });
          setSelectedKey(partGroup.uuid);
          notification.success({
            message: "Part Group successfully created!",
          });
        }
      }
    },
    [setSelectedKey, updateItemByUUID]
  );

  const updatePartGroup = useCallback(
    async (
      partGroup: Pick<PartGroupType, "uuid" | "apiId" | "name" | "optional">
    ) => {
      await patchPartGroup(partGroup);
      updateItemByUUID(partGroup.uuid, (_partGroup) => ({
        ..._partGroup,
        ...partGroup,
      }));
      notification.success({
        message: "Part Group successfully updated!",
      });
    },
    [updateItemByUUID]
  );

  const clonePartGroup = useCallback(
    async ({ partGroup }: { partGroup: PartGroupType }) => {
      const partGroupItem = getItemByUUID(partGroup.uuid);
      if (partGroupItem) {
        const layout = partGroupItem.data.layout as LayoutType;
        const clonedPartGroup = await addPartGroup(
          { name: `${partGroup.name} (Clone)`, optional: partGroup.optional },
          { layout }
        );
        return clonedPartGroup;
      }
    },
    [addPartGroup, getItemByUUID]
  );

  const removePartGroup = useCallback(
    async ({
      layout,
      partGroup,
    }: {
      layout: LayoutType;
      partGroup: PartGroupType;
    }) => {
      console.log(
        "Starting removePartGroup function with partgroup: ",
        layout,
        partGroup
      );
      if (layout && partGroup) {
        // @ts-ignore
        const partGroupApiResponse = await deletePartGroup(partGroup.apiId);
        console.log(
          "Response from deletePartGroup API call: ",
          partGroupApiResponse
        );
        if (partGroupApiResponse) {
          updateItemByUUID(layout.uuid, (_layout) => {
            console.log(
              "Inside updateItemByUUID callback with _layout: ",
              _layout
            );
            (_layout as LayoutType).partGroups = (
              _layout as LayoutType
            ).partGroups.filter((pg) => partGroup.uuid !== pg.uuid);
            console.log("Updated _layout after removing partGroup: ", _layout);
            return _layout;
          });
          setSelectedKey(layout.uuid);
          notification.success({
            message: "Part Group successfully deleted!",
          });
        }
      }
    },
    [setSelectedKey, updateItemByUUID]
  );

  return {
    addPartGroup: taskWrapper(addPartGroup),
    updatePartGroup: taskWrapper(updatePartGroup),
    clonePartGroup: taskWrapper(clonePartGroup),
    removePartGroup: taskWrapper(removePartGroup),
  };
};
