/* eslint-disable react/no-unknown-property */
import React from "react";
import { Color3, Vector3 } from "@babylonjs/core";

export const StickMesh = () => {
  return (
    <cylinder
      name="stick-base"
      diameter={0.2}
      height={0.2}
      position={new Vector3(0, 1, 0)}
      isPickable={false}
    >
      <standardMaterial
        name="stick-mat-head"
        diffuseColor={Color3.Green()}
        specularColor={Color3.Black()}
      />

      <cylinder
        name="stick"
        height={1}
        diameter={0.2}
        isPickable={false}
        position={new Vector3(0, 0.6, 0)}
      >
        <standardMaterial
          name="stick-mat-body"
          diffuseColor={Color3.Yellow()}
          specularColor={Color3.Black()}
        />
      </cylinder>
    </cylinder>
  );
};
