import axios from "axios";
import { notification } from "antd";
import {
  BACKEND_URL,
  POST_METHOD,
  GET_METHOD,
  PATCH_METHOD,
  DELETE_METHOD,
} from "sharedConstants";
import _ from "lodash";
import { setToken } from "store/actions/auth";
import { store } from "store";

import apiFunctions from "./";

//this is was causing a redirect back to /login
//because the token given in new login is wrong
axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (!err.config.skipValidation) {
      if (err.response.status === 404) {
        notification.open({
          message: "Not Found",
          description: err.response.data.message,
        });
      } else if (err.response.status === 400) {
        notification.error({
          message: "Bad Request",
          description: err.response.data.message,
        });
      } else if (err.response.status === 401) {
        notification.error({
          message: "Authentication Needed",
          description: `${err.response.data.message}. Try logging in again.`,
        });
        localStorage.clear();
        store.dispatch(setToken(null));
      } else if (err.response.status === 403) {
        notification.error({
          message: "Bad Authentication",
          description: `${err.response.data.message}. Try logging in again.`,
        });
        localStorage.clear();
        store.dispatch(setToken(null));
      } else if (err.response.status === 409) {
        notification.error({
          message: "Conflict",
          description: err.response.data.message,
        });
      } else {
        console.log(err);
        throw err;
      }
    } else {
      throw err;
    }
  }
);

class ApiService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.token = null;
    _.forEach(apiFunctions, (collection) => {
      _.forEach(collection, (fxn, key) => {
        this[key] = fxn.bind(this);
      });
    });
  }

  setToken = (token) => {
    this.token = token;
  };

  postRefresh = async (token, refresh) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/refresh`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: { refresh },
    });
    return response;
  };

  getMe = async (token) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/me`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  getShowroom = async (showroomUUID) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/showrooms/${showroomUUID}?exclude_shell_variants=true`,
    });
    // console.log(response);
    return response;
  };

  patchThumbnail = async (token, thumbnailId, data) => {
    const response = await axios({
      method: PATCH_METHOD,
      url: `${BACKEND_URL}/item_thumbnails/${thumbnailId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data,
    });
    return response;
  };

  postThumbnail = async (token, data) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/item_thumbnails`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data,
    });
    return response;
  };

  postUser = async (token, user) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/users`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: { email: user.email },
    });
    return response;
  };

  getUsers = async (token) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/users`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  getItemStatuses = async (token) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/item_statuses`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  getCompanies = async (token) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/companies`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  getCompany = async (token, companyId) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/companies/${companyId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  patchCompany = async (token, companyId, companyData) => {
    const response = await axios({
      method: PATCH_METHOD,
      url: `${BACKEND_URL}/companies/${companyId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: companyData,
    });
    return response;
  };

  getItemCopy = async (token, itemId) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/items/${itemId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  patchItem = async (token, itemId, itemData) => {
    const response = await axios({
      method: PATCH_METHOD,
      url: `${BACKEND_URL}/items/${itemId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: itemData,
    });
    return response;
  };
  patchMaterialName = async (id, name, token) => {
    // console.log("response", name, id);
    const response = await axios({
      method: PATCH_METHOD,
      url: `${BACKEND_URL}/materials/${id}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: name,
    });

    return response;
  };

  patchItemVersion = async (token, itemVersionId, itemVersionData) => {
    const response = await axios({
      method: PATCH_METHOD,
      url: `${BACKEND_URL}/item_versions/${itemVersionId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: itemVersionData,
    });
    return response;
  };

  postCompany = async (token, companyData) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/companies`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: companyData,
    });
    return response;
  };

  postOrder = async (token, orderData) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/orders`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: orderData,
    });
    return response;
  };

  postProject = async (token, projectData) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/projects`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: projectData,
    });
    return response;
  };

  postItemVersion = async (token, versionData) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/item_versions`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: versionData,
    });
    return response;
  };

  postItemNote = async (token, noteData) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/item_notes`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: noteData,
    });
    return response;
  };

  postItem = async (token, itemData) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/items`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: itemData,
    });
    return response;
  };

  deleteItem = async (token, itemId) => {
    const response = await axios({
      method: DELETE_METHOD,
      url: `${BACKEND_URL}/items/${itemId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };
  deleteMaterials = async (materialId, categoryId, token) => {
    // try {
    // First API call to delete material_category_associations
    // console.log("materialId, categoryId", materialId, categoryId);
    try {
      // First API call to delete material_category_associations
      const responseOne = await axios({
        method: DELETE_METHOD,
        url: `${BACKEND_URL}/material_category_associations/${materialId}/${categoryId}`,
        headers: { Authorization: `Bearer ${token || this.token}` },
      });

      // If the first API call succeeds, proceed with the second API call
      if (responseOne.status === 204 || responseOne.status === 200) {
        // Second API call to delete materials
        const responseTwo = await axios({
          method: DELETE_METHOD,
          url: `${BACKEND_URL}/materials/${materialId}`,
          headers: { Authorization: `Bearer ${token || this.token}` },
        });
        window.location.reload();
        // Return both responses
        return { responseOne, responseTwo };
      } else {
        // Handle unsuccessful response from the first API call
        throw new Error("Failed to delete material_category_associations");
      }
    } catch (error) {
      // Handle any errors occurred during API calls
      console.error("Error deleting material:", error);
      throw error; // Re-throw the error to be handled by the caller
    }
  };

  postUserCompany = async (token, userId, companyId) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/user_companies/${userId}/${companyId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  deleteUserCompany = async (token, userId, companyId) => {
    const response = await axios({
      method: DELETE_METHOD,
      url: `${BACKEND_URL}/user_companies/${userId}/${companyId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  postFileLocker = async (token, itemId, fileLockerData) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/file_locker/${itemId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: fileLockerData,
    });
    return response;
  };

  getFileLocker = async (token, itemId) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/file_locker/${itemId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  deleteFileLocker = async (token, itemId, fileLockerData) => {
    const response = await axios({
      method: DELETE_METHOD,
      url: `${BACKEND_URL}/file_locker/${itemId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: fileLockerData,
    });
    return response;
  };

  postAnnotation = async (token, annotationData) => {
    const response = await axios({
      method: POST_METHOD,
      url: `${BACKEND_URL}/annotations`,
      headers: { Authorization: `Bearer ${token || this.token}` },
      data: annotationData,
    });
    return response;
  };

  getVariants = async (token, itemId) => {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/variants/${itemId}`,
      headers: { Authorization: `Bearer ${token || this.token}` },
    });
    return response;
  };

  getItemCollection = async function (id) {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/item_collections/${id}`,
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response;
  };

  getVersion = async function (id) {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/item_versions/${id}`,
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response;
  };
  getPartGroupCopy = async function (id) {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/part_groups/${id}`,
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response;
  };
  getPartCopy = async function (id) {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/parts/${id}`,
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response;
  };
  getMaterialCopy = async function (id) {
    const response = await axios({
      method: GET_METHOD,
      url: `${BACKEND_URL}/materials/${id}`,
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response;
  };
}
export default new ApiService();
