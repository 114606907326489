import React, { Component } from "react";

class PriceInput extends Component {
  state = {};

  handleChangeValue = (key, value) => {
    let newValues = {
      ...this.props.value,
      [key]: value,
    };
    this.props.setParentValue(newValues);
  };

  render() {
    return (
      <div className="product-description-display">
        <h3 className="section-title">Price</h3>
        <div>
          <label>
            <span>Price</span>
            <input
              type="text"
              value={this.props.value.price}
              onChange={(e) => this.handleChangeValue("price", e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            <span>Alt Price</span>
            <input
              type="text"
              value={this.props.value.price_alternate}
              onChange={(e) =>
                this.handleChangeValue("price_alternate", e.target.value)
              }
            />
          </label>
        </div>
        <div>
          <label>
            <span>CA Price</span>
            <input
              type="text"
              value={this.props.value.price_ca}
              onChange={(e) =>
                this.handleChangeValue("price_ca", e.target.value)
              }
            />
          </label>
        </div>
      </div>
    );
  }
}

export default PriceInput;
