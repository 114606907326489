import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Showroom } from "services/types";
import { useShowroomContext } from "../contexts/ShowroomContext";
import { useSpaceContext } from "../contexts/SpaceContext";
import { useFetchShells } from "../hooks";
import { useAppSelector } from "hooks";

export const ShowroomLayout = () => {
  const { companyId, showroomId, spaceId, spaceVersionId } = useParams();
  const { setShowroomMode, setShowroom } = useShowroomContext();
  const { setSpace, setIsLoadingSpace, setSpaceVersionId } = useSpaceContext();
  const { shells, isLoadingAllShells } = useFetchShells();
  const showrooms: any[] = useAppSelector((state: any) =>
    state.showrooms.showroomsInfo.filter(
      (showroom: Showroom) => `${showroom.company_id}` === `${companyId}`
    )
  );
  const isLoadingAllShowrooms: boolean = useAppSelector(
    (state: any) => state.showrooms.isLoadingAllShowrooms
  );
  // Update showroom context when {showroomId} in url changes
  useEffect(() => {
    if (showroomId) {
      const showroom = showrooms?.find(({ id }) => `${id}` === showroomId);
      setShowroom(showroom || null);
    } else {
      setShowroom(null);
    }
  }, [setShowroom, showroomId, showrooms]);

  // Update showroom loading
  useEffect(() => {
    setShowroomMode(isLoadingAllShowrooms ? "LOADING" : "IDLE");
  }, [isLoadingAllShowrooms, setShowroomMode]);

  // Update space context when {spaceId} in url changes
  useEffect(() => {
    if (spaceId) {
      const space = shells?.find(({ id }) => `${id}` === spaceId);
      setSpace(space || null);
    } else {
      setSpace(null);
    }
  }, [setSpace, shells, spaceId]);

  // Update space context when {spaceVersionId} in url changes
  useEffect(() => {
    setSpaceVersionId(spaceVersionId || null);
  }, [setSpaceVersionId, spaceVersionId]);

  // Update space loading
  useEffect(() => {
    setIsLoadingSpace(isLoadingAllShells);
  }, [isLoadingAllShells, setIsLoadingSpace]);

  return (
    <div className="showroom-layout-wrapper">
      <Outlet />
    </div>
  );
};
