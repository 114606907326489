import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  IPointerEvent,
  KeyboardEventTypes,
  KeyboardInfo,
  Nullable,
  Observer,
  PickingInfo,
  Vector3,
} from "@babylonjs/core";
import { Button, MenuProps, Segmented } from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  AimOutlined,
  SyncOutlined,
  DragOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import _, { uniqueId } from "lodash";
import {
  ImageMultipleUploaderModal,
  // ImageUploaderModal,
} from "components/Media";
import { useEngineContext } from "modules/showrooms/contexts/EngineContext";
import { useCommentContext } from "modules/showrooms/contexts/CommentContext";
import { Annotation, OnAnnotationClickType } from "./annotation";
import { BabylonEngine } from "./babylonEngine";
import { BabylonCamera2D } from "./babylonCamera2D";
import { BabylonCamera3D } from "./babylonCamera3D";
import { ViewModeType, CameraActionsType, ToolsType } from "./types";
import { ContextMenu } from "components/ContextMenu";
import { AnnotationType, ShowroomGizmoType } from "../../types";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useShowroomContext } from "modules/showrooms/contexts/ShowroomContext";
import { useCommentsApi } from "modules/showrooms/hooks";
import { useParams } from "react-router-dom";

interface BabylonViewProps {
  url: string;
  onLeftClick?: (
    evt: IPointerEvent,
    pickResult: PickingInfo,
    isLongPress: boolean
  ) => boolean | void | Promise<boolean | void>;
  onMiddleClick?: (
    evt: IPointerEvent,
    pickResult: PickingInfo,
    isLongPress: boolean
  ) => boolean | void | Promise<boolean | void>;
  onRightClick?: (
    evt: IPointerEvent,
    pickResult: PickingInfo,
    isLongPress: boolean
  ) =>
    | boolean
    | void
    | ((options: ItemType[]) => ItemType[])
    | Promise<boolean | void | ((options: ItemType[]) => ItemType[])>;
  onLongPressClick?: (evt: IPointerEvent, pickResult: PickingInfo) => void;
  onPointerMove?: (evt: IPointerEvent, pickResult: PickingInfo) => void;
  children?: React.ReactNode;
  onAddCommentImages?: (commentId: string, images: string[]) => void;
}

export const BabylonView = ({
  url,
  onLeftClick = () => true,
  onMiddleClick = () => true,
  onRightClick = () => true,
  onLongPressClick = () => {},
  onPointerMove,
  children = null,
  onAddCommentImages = () => {},
}: BabylonViewProps) => {
  const {
    isFocusedEngine,
    hotspotMode,
    setHotspotMode,
    setIsLoadingEngine,
    showHotspots,
    setShowHotspots,
  } = useEngineContext();
  const {
    comment,
    selectedAnnotationId,
    setSelectedAnnotationId,
    // selectedAnnotation,
    upsetAnnotation,
    updateComment,
    removeAnnotation,
  } = useCommentContext();
  const { showroomMode, showroomGizmoMode, setShowroomGizmoMode } =
    useShowroomContext();
  const cameraRef3D = useRef<CameraActionsType | null>(null);
  const cameraRef2D = useRef<CameraActionsType | null>(null);
  const [viewMode, setViewMode] = useState<ViewModeType>("3D");
  const [isImageUploaderModalOpen, setIsImageUploaderModalOpen] =
    useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<MenuProps["items"]>([]);
  const { showroom } = useShowroomContext();
  const { spaceId, spaceVersionId } = useParams();

  const showroomShell = useMemo(() => {
    if (spaceId && spaceVersionId) {
      return showroom?.shells[spaceId].shell_versions[spaceVersionId]
        .showroom_shell;
    } else {
      return null;
    }
  }, [showroom?.shells, spaceId, spaceVersionId]);

  const annotationsArray = useMemo(
    () => _.values(comment.annotations),
    [comment.annotations]
  );

  /*************** Actions ***************/
  const addAnnotationAction = useCallback(
    (pointer: Vector3) => {
      const { x, y, z } = pointer;
      const id = uniqueId("annotation-");
      setSelectedAnnotationId(id);
      upsetAnnotation({
        id,
        type: "simple",
        x,
        y,
        z,
      });
    },
    [setSelectedAnnotationId, upsetAnnotation]
  );
  const removeAnnotationAction = useCallback(
    (annotation: AnnotationType) => {
      if (annotation.id === selectedAnnotationId) {
        setSelectedAnnotationId(null);
      }
      removeAnnotation(annotation.id);
    },
    [removeAnnotation, selectedAnnotationId, setSelectedAnnotationId]
  );
  const removeAnnotationImage = useCallback(
    (annotation: AnnotationType) => {
      upsetAnnotation(_.omit(annotation, "imageUrl"));
    },
    [upsetAnnotation]
  );

  const onToolsModeChange = useCallback(
    (value: ShowroomGizmoType) => {
      setShowroomGizmoMode(value);
    },
    [setShowroomGizmoMode]
  );
  const zoomInAction = useCallback(() => {
    if (viewMode === "3D") {
      if (cameraRef3D.current?.zoomIn) cameraRef3D.current?.zoomIn();
    } else {
      if (cameraRef2D.current?.zoomIn) cameraRef2D.current?.zoomIn();
    }
  }, [viewMode]);
  const zoomOutAction = useCallback(() => {
    if (viewMode === "3D") {
      if (cameraRef3D.current?.zoomOut) cameraRef3D.current?.zoomOut();
    } else {
      if (cameraRef2D.current?.zoomOut) cameraRef2D.current?.zoomOut();
    }
  }, [viewMode]);
  const zoomResetAction = useCallback(() => {
    if (viewMode === "3D") {
      if (cameraRef3D.current?.zoomReset) cameraRef3D.current?.zoomReset();
    } else {
      if (cameraRef2D.current?.zoomReset) cameraRef2D.current?.zoomReset();
    }
  }, [viewMode]);
  /***************************************/

  /*************** Handlers **************/
  const onLeftClickHandler = useCallback(
    async (
      evt: IPointerEvent,
      pickResult: PickingInfo,
      isLongPress: boolean
    ) => {
      const continueEvent = await onLeftClick(evt, pickResult, isLongPress);
      if (continueEvent === false) return;
      if (hotspotMode === "add" && pickResult?.pickedPoint) {
        addAnnotationAction(pickResult.pickedPoint);
        setHotspotMode(null);
      }
    },
    [addAnnotationAction, hotspotMode, onLeftClick, setHotspotMode]
  );
  const onMiddleClickHandler = useCallback(
    async (
      evt: IPointerEvent,
      pickResult: PickingInfo,
      isLongPress: boolean
    ) => {
      const continueEvent = await onMiddleClick(evt, pickResult, isLongPress);
      if (continueEvent === false) return;
    },
    [onMiddleClick]
  );
  const onRightClickHandler = useCallback(
    async (
      evt: IPointerEvent,
      pickResult: PickingInfo,
      isLongPress: boolean
    ) => {
      const continueEvent = await onRightClick(evt, pickResult, isLongPress);
      if (continueEvent === false) return;
      const options: ItemType[] = [];
      if (showHotspots) {
        options.push({
          key: "add-annotation",
          label: <span>Add annotation</span>,
          disabled: !pickResult.pickedPoint,
          onClick: () => {
            if (pickResult.pickedPoint)
              addAnnotationAction(pickResult.pickedPoint);
          },
        });
      }
      options.push({
        key: `${showHotspots ? "hide" : "show"}-annotation`,
        label: <span>{showHotspots ? "Hide" : "Show"} annotations</span>,
        disabled: !pickResult.pickedPoint,
        onClick: () => {
          setShowHotspots((value) => !value);
        },
      });
      if (typeof continueEvent === "function") {
        const newOptions = continueEvent(options);
        setMenuItems(newOptions);
      } else {
        setMenuItems(options);
      }
      setOpenMenu(true);
    },
    [addAnnotationAction, onRightClick, setShowHotspots, showHotspots]
  );
  // const onImageUploadAnnotationImageHandler = useCallback(
  //   (imageUrl: string) => {
  //     if (selectedAnnotation) {
  //       upsetAnnotation({
  //         ...selectedAnnotation,
  //         imageUrl,
  //       });
  //     }
  //   },
  //   [selectedAnnotation, upsetAnnotation]
  // );
  const onAnnotationClickHandler = useCallback(
    ({ annotation, evt, pickResult }: OnAnnotationClickType) => {
      // Select annotation
      if (annotation.id !== selectedAnnotationId) {
        setSelectedAnnotationId(annotation.id);
      }
      if (pickResult.buttonIndex === 2) {
        const items = [
          {
            key: "remove-annotation",
            label: <span>Remove annotation</span>,
            onClick: () => {
              removeAnnotationAction(annotation);
            },
          },
          {
            key: "view-upload-comment-images",
            label: <span>View / Upload Images</span>,
            onClick: () => {
              setIsImageUploaderModalOpen(true);
            },
          },
        ];
        // if (annotation.imageUrl) {
        //   items.push({
        //     key: "view-annotation-image",
        //     label: <span>View image</span>,
        //     onClick: () => {
        //       setIsImageUploaderModalOpen(true);
        //     },
        //   });
        //   items.push({
        //     key: "remove-annotation-image",
        //     label: <span>Remove image</span>,
        //     onClick: () => {
        //       removeAnnotationImage(annotation);
        //     },
        //   });
        // } else {
        //   items.push({
        //     key: "add-annotation-image",
        //     label: <span>Add image</span>,
        //     onClick: () => {
        //       setIsImageUploaderModalOpen(true);
        //     },
        //   });
        // }
        setMenuItems(items);
        setOpenMenu(true);
      }
    },
    [removeAnnotationAction, selectedAnnotationId, setSelectedAnnotationId]
  );

  const onAnnotationDeleteHandler = useCallback(
    ({ annotation }: OnAnnotationClickType) => {
      if (annotation.id === selectedAnnotationId) {
        setSelectedAnnotationId(null);
      }
      removeAnnotation(annotation.id);
    },
    [removeAnnotation, selectedAnnotationId, setSelectedAnnotationId]
  );
  /***************************************/

  /*************** Options ***************/
  const viewModeOptions = useMemo(
    () => [
      { label: "2D VIEW", value: "2D" },
      { label: "3D VIEW", value: "3D" },
    ],
    []
  );
  /***************************************/

  const HandSvg = () => (
    <svg
      fill="#000000"
      height="14px"
      width="14px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 485 485"
    >
      <g>
        <path
          d="M382.5,69.429c-7.441,0-14.5,1.646-20.852,4.573c-4.309-23.218-24.7-40.859-49.148-40.859
		c-7.68,0-14.958,1.744-21.467,4.852C285.641,16.205,265.932,0,242.5,0c-23.432,0-43.141,16.206-48.533,37.995
		c-6.508-3.107-13.787-4.852-21.467-4.852c-27.57,0-50,22.43-50,50v122.222c-6.129-2.686-12.891-4.187-20-4.187
		c-27.57,0-50,22.43-50,50V354c0,72.233,58.766,131,131,131h118c72.233,0,131-58.767,131-131V119.429
		C432.5,91.858,410.07,69.429,382.5,69.429z M402.5,354c0,55.691-45.309,101-101,101h-118c-55.691,0-101-45.309-101-101V251.178
		c0-11.028,8.972-20,20-20s20,8.972,20,20v80h30V83.143c0-11.028,8.972-20,20-20s20,8.972,20,20v158.035h30V50
		c0-11.028,8.972-20,20-20c11.028,0,20,8.972,20,20v191.178h30V83.143c0-11.028,8.972-20,20-20s20,8.972,20,20v158.035h30v-121.75
		c0-11.028,8.972-20,20-20s20,8.972,20,20V354z"
        />
      </g>
    </svg>
  );

  useEffect(() => {
    if (!isFocusedEngine) {
      setOpenMenu(false);
    }
  }, [isFocusedEngine]);

  useEffect(() => {
    setIsLoadingEngine(true);
  }, [setIsLoadingEngine]);

  return (
    <>
      <ContextMenu
        open={openMenu}
        onClose={() => {
          setOpenMenu(false);
        }}
        onChange={(value) => {
          if (!value) {
            setMenuItems([]);
          }
        }}
        menu={{
          items: menuItems,
          selectedKeys: [],
        }}
      >
        <BabylonEngine
          showroomShellOffset={{
            x: showroomShell?.offset_x ? -showroomShell.offset_x : null,
            y: showroomShell?.offset_y || null,
            z: showroomShell?.offset_z || null,
          }}
          url={url}
          onLeftClick={onLeftClickHandler}
          onMiddleClick={onMiddleClickHandler}
          onRightClick={onRightClickHandler}
          onLongPressClick={onLongPressClick}
          onPointerMove={onPointerMove}
          onModelLoading={(value) => {
            setIsLoadingEngine(value);
          }}
          camera={
            viewMode === "3D" ? (
              <BabylonCamera3D cameraRef={cameraRef3D} />
            ) : (
              <BabylonCamera2D cameraRef={cameraRef2D} />
            )
          }
        >
          <>
            {showHotspots
              ? annotationsArray.map((annotation) => (
                  <Annotation
                    isActive={annotation.id === selectedAnnotationId}
                    key={annotation.id}
                    annotation={annotation}
                    onClick={onAnnotationClickHandler}
                    onDelete={onAnnotationDeleteHandler}
                  />
                ))
              : null}
            {children}
          </>
        </BabylonEngine>
      </ContextMenu>
      <div className="controls-view-mode">
        <Segmented
          options={viewModeOptions}
          value={viewMode}
          onChange={(value) => {
            setViewMode(value as ViewModeType);
          }}
        />
      </div>
      {showroomMode === "GIZMO_MENU" && (
        <div className="controls-view-tools">
          <Button
            className={`controls-view-tools-button ${
              showroomGizmoMode === "POSITION" ? "selected" : ""
            }`}
            onClick={() => {
              onToolsModeChange("POSITION");
            }}
          >
            <DragOutlined />
          </Button>
          <Button
            className={`controls-view-tools-button ${
              showroomGizmoMode === "ROTATION" ? "selected" : ""
            }`}
            onClick={() => {
              onToolsModeChange("ROTATION");
            }}
          >
            <SyncOutlined />
          </Button>
          <Button
            className={`controls-view-tools-button ${
              showroomGizmoMode === "SELECT" ? "selected" : ""
            }`}
            onClick={() => {
              onToolsModeChange("SELECT");
            }}
          >
            <Icon component={HandSvg} />
          </Button>
          <Button
            className={`controls-view-tools-button ${
              showroomGizmoMode === "FOCUS" ? "selected" : ""
            }`}
            onClick={() => {
              onToolsModeChange("FOCUS");
            }}
          >
            <AimOutlined />
          </Button>
        </div>
      )}
      <div className="controls-view-action">
        <Button onClick={zoomInAction}>
          <PlusOutlined />
        </Button>
        <Button onClick={zoomOutAction}>
          <MinusOutlined />
        </Button>
        <Button onClick={zoomResetAction}>
          <AimOutlined />
        </Button>
      </div>
      {/* {isImageUploaderModalOpen && (
        <ImageUploaderModal
          isOpen={isImageUploaderModalOpen}
          onClose={() => {
            setIsImageUploaderModalOpen(false);
          }}
          image={selectedAnnotation?.imageUrl || ""}
          onSubmit={(image) => {
            onImageUploadAnnotationImageHandler(image);
            setIsImageUploaderModalOpen(false);
          }}
        />
      )} */}
      {isImageUploaderModalOpen && (
        <ImageMultipleUploaderModal
          isOpen={isImageUploaderModalOpen}
          onClose={() => {
            setIsImageUploaderModalOpen(false);
          }}
          images={comment.images.map(({ url }) => url)}
          onSubmit={(images) => {
            onAddCommentImages(comment.id, images);
            setIsImageUploaderModalOpen(false);
          }}
        />
      )}
    </>
  );
};
