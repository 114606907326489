import React, { useMemo, useState } from "react";
import { Button, Checkbox, Form, Input, UploadFile } from "antd";
import { useUIContext } from "../contexts/UIContext";
import { usePartGroups } from "../hooks";
import { PartGroupModelType, SelectedItem } from "./../types";
import { FilesDropZone } from "components/Media";
import ApiService from "services/ApiService";

interface PartGroupViewProps {
  selectedItem: SelectedItem;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const PartGroupView = ({
  selectedItem,
  children,
}: PartGroupViewProps) => {
  const { updatePartGroup } = usePartGroups();
  const { setIsDirty } = useUIContext();
  const [refreshKey, setRefreshKey] = useState(0);
  const partGroup = selectedItem.item as PartGroupModelType;

  const onFinish = async (values: any) => {
    await updatePartGroup({
      _uuid: partGroup._uuid,
      id: partGroup.id,
      name: values.name,
      optional: values.optional,
      custom_request_message: values.custom_request_message,
    });
    setIsDirty(false);
  };

  return (
    <div className="part-group-view">
      <Form
        name="part-group-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: partGroup.name,
          optional: partGroup.optional || false,
          custom_request_message: partGroup.custom_request_message,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="optional" valuePropName="checked">
          <Checkbox>Is optional?</Checkbox>
        </Form.Item>

        <p>Thumbnail</p>
        <div>
          <img
            src={
              partGroup.external_url +
              "/thumbnail_compressed/512.png?rerender=" +
              Date.now()
            }
          />
        </div>
        <>
          <FilesDropZone
            beforeUpload={async (fileData) => {
              const uploadFormData = new FormData();
              Object.keys(partGroup.thumbnail_post_url.fields).forEach(
                (key) => {
                  uploadFormData.append(
                    key,
                    partGroup.thumbnail_post_url.fields[key]
                  );
                }
              );
              uploadFormData.append("file", fileData);
              // @ts-ignore
              const s3UploadResponse = await ApiService.postToS3(
                partGroup.thumbnail_post_url?.url,
                uploadFormData
              );
              setTimeout(() => {
                setRefreshKey(refreshKey + 1);
              }, 10000);
              return false;
            }}
            fileList={[]}
            listType="picture"
            showUploadList={{ showRemoveIcon: false }}
          />
        </>

        <Form.Item
          label="Custom Request Instructions:"
          name="custom_request_message"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        {children}
      </Form>
    </div>
  );
};
