import React, { useEffect, useState } from "react";
import { Modal, Card, Checkbox, Image, Spin } from "antd";
import { useCompanyContext } from "contexts/CompanyContext";
import {
  MaterialModel,
  MeshMaterialModel,
  ProductsService,
} from "servicesNew/api";

interface AddMeshMaterialsModalProps {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (selectedMaterials: Required<MaterialModel>[]) => void;
}

export const AddMeshMaterialsModal: React.FC<AddMeshMaterialsModalProps> = ({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
}) => {
  const { company } = useCompanyContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [materialCategories, setMaterialCategories] = useState<any[]>([]);
  const [materials, setMaterials] = useState<any[]>([]);
  const [selectAllState, setSelectAllState] = useState<any>({});
  const [selectedMeshMaterials, setSelectedMeshMaterials] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    const fetchLibraryMaterialCategories = async () => {
      if (open && company?.id) {
        setLoading(true);
        try {
          const response =
            await ProductsService.productsLibraryMaterialCategoriesList({
              companyId: `${company.id}`,
            });
          setMaterialCategories(response || []);
        } catch (error) {
          console.error("Failed to fetch material categories:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLibraryMaterialCategories();
  }, [open, company?.id]);

  useEffect(() => {
    const fetchLibraryMaterials = async () => {
      if (open && company?.id) {
        setLoading(true);
        try {
          const response = await ProductsService.productsLibraryMaterialsList({
            companyId: `${company.id}`,
          });
          setMaterials(response || []);
        } catch (error) {
          console.error("Failed to fetch materials:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLibraryMaterials();
  }, [open, company?.id]);

  const handleOnSelect = (materialId: string) => {
    setSelectedMeshMaterials((prev) => ({
      ...prev,
      [materialId]: !prev[materialId],
    }));
  };

  const handleSubmit = () => {
    const selectedMaterials = Object.keys(selectedMeshMaterials)
      .filter((id) => selectedMeshMaterials[id])
      .map((id) => {
        const foundMaterial = materials.find(
          (material: MaterialModel) => material.id?.toString() === id
        );
        return foundMaterial;
      })
      .filter((material) => material !== null) as Required<MaterialModel>[];

    onSubmit(selectedMaterials);
  };

  const handleSelectAll = (categoryId: any, materials: any) => {
    const areAllSelected = materials.every(
      (material: any) => selectedMeshMaterials[material.id] === true
    );
    const newSelectAllState = !areAllSelected;
    setSelectAllState((prev: any) => ({
      ...prev,
      [categoryId]: newSelectAllState,
    }));

    const newSelectedMaterials = { ...selectedMeshMaterials };
    materials.forEach((material: any) => {
      if (material.id !== undefined) {
        newSelectedMaterials[material.id] = newSelectAllState;
      }
    });
    setSelectedMeshMaterials(newSelectedMaterials);
  };

  const getCategoryMaterials = (categoryId: any) => {
    return materials.filter((material: any) =>
      material.categories.some((category: any) => category.id === categoryId)
    );
  };

  return (
    <Modal
      title="Add Materials"
      open={open}
      onCancel={onCancel}
      onOk={handleSubmit}
      afterClose={() => {
        setSelectedMeshMaterials({});
        setSelectAllState({});
      }}
      okText="Add"
      className="add-materials-modal"
      cancelButtonProps={{ className: "cancel-btn" }}
      okButtonProps={{
        className: "upload-btn",
        disabled: Object.keys(selectedMeshMaterials).every(
          (id) => !selectedMeshMaterials[id]
        ),
      }}
    >
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="materials-content">
          {materialCategories.map((category) => {
            const categoryMaterials = getCategoryMaterials(category.id);
            const hasMaterials = categoryMaterials?.length > 0;
            const areAllSelected =
              hasMaterials &&
              categoryMaterials?.every(
                (material: any) =>
                  selectedMeshMaterials[material.id ?? ""] === true
              );

            return (
              <div key={category.id} className="material-category-card">
                <div className="material-category-name-checkbox">
                  <h4>{category.name}</h4>
                  <Checkbox
                    checked={areAllSelected}
                    disabled={!hasMaterials}
                    onChange={() =>
                      handleSelectAll(category.id, categoryMaterials)
                    }
                    className="select-all-checkbox"
                  >
                    SELECT ALL
                  </Checkbox>
                </div>

                <div className="materials-grid">
                  {categoryMaterials?.map((material: any) => (
                    <Card
                      key={material.id}
                      hoverable
                      className={`material-card ${
                        selectedMeshMaterials[material.id ?? ""]
                          ? "selected"
                          : ""
                      }`}
                      onClick={() =>
                        material.id !== undefined &&
                        handleOnSelect(material.id.toString())
                      }
                    >
                      <Checkbox
                        checked={
                          selectedMeshMaterials[material.id ?? ""] || false
                        }
                        onClick={(e) => e.stopPropagation()}
                      />

                      <div className="material-card-content">
                        <Image
                          src={material.internal_url || ""}
                          alt={material.name || ""}
                        />
                        <p>{material.name}</p>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};
