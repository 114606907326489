import React, { createContext, useState, useContext } from "react";
import { Company } from "services/types";

interface CompanyContextValue {
  company: Company | null;
  setCompany: (company: Company | null) => void;
  isLoadingCompany: boolean;
  setIsLoadingCompany: (value: boolean) => void;
}

const CompanyContext = createContext<CompanyContextValue>({
  company: null,
  setCompany: () => {},
  isLoadingCompany: false,
  setIsLoadingCompany: () => {},
});

export const CompanyContextProvider = (props: any) => {
  const [company, setCompany] = useState<Company | null>(null);
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false);

  const value = {
    company,
    setCompany,
    isLoadingCompany,
    setIsLoadingCompany,
  };

  return <CompanyContext.Provider value={value} {...props} />;
};

export const useCompanyContext = () => {
  return useContext<CompanyContextValue>(CompanyContext);
};
