import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import _ from "lodash";
import { addOrder } from "store/actions/app";
import { getParamsFromPathname, createMenuItem } from "utils";

class OrderSelectionMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrderId: this.props.selectedOrderId,
    };
  }

  handleOrderSelect = (v) => {
    this.setState({
      ...this.state,
      selectedOrderId: Number(v.key),
    });
    this.props.addOrder(Number(v.key), true);
  };

  renderMenuItems = ({ orders }) => [
    createMenuItem({
      label: "Your Orders",
      key: "sub",
      children: _.map(orders, ({ id, name }) =>
        createMenuItem({ label: name, key: id })
      ),
    }),
  ];

  render() {
    if (this.props.loading) {
      return <div>loading...</div>;
    }
    return (
      <Menu
        key={this.props.selectedCompanyId}
        onClick={this.handleOrderSelect}
        style={{ width: 241, zIndex: 1, position: "relative" }}
        defaultSelectedKeys={[`${this.state.selectedOrderId}`]}
        selectedKeys={[`${this.state.selectedOrderId}`]}
        mode="inline"
        items={this.renderMenuItems({
          orders: this.props.companies[this.props.selectedCompanyId].orders,
        })}
      />
    );
  }
}

const mapStateToProps = ({
  companies,
  app: { selectedCompanyId, selectedOrderId },
  router,
}) => {
  let { companyIdInUrl, orderIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  const loading = !companies[companyIdInUrl];
  return {
    loading,
    companies,
    selectedCompanyId: companyIdInUrl,
    selectedOrderId: orderIdInUrl,
  };
};

export default connect(mapStateToProps, {
  addOrder,
})(OrderSelectionMenu);
