import Title from "antd/lib/skeleton/Title";
import React, { Component } from "react";

class DimensionDisplay extends Component {
  state = {};
  render() {
    let {
      depth_en: depth,
      width_en: width,
      height_en: height,
      diameter_en: diameter,
    } = this.props.value;
    return (
      <>
        <h3 className="section-title">Dimensions</h3>
        <span className="editLink">edit</span>
        <div className="dimensions-display">
          <p>Width: {width || "N/A"}</p>
          <p>Depth:{depth || "N/A"}</p>
          <p>Height: {height || "N/A"}</p>
          <p>Diameter: {diameter || "N/A"}</p>
        </div>
      </>
    );
  }
}

export default DimensionDisplay;
