import axios from "axios";
import { BACKEND_URL } from "sharedConstants";

const getMe = async function () {
  const response = await axios({
    method: "get",
    url: `${BACKEND_URL}/me`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export { getMe };
