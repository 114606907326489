import ApiService from "services/ApiService";
import { ShellEntity } from "services/types";
import { AppDispatch } from "store";

export const setShells = (shells: ShellEntity.Shell[]) => {
  return {
    type: "SET_SHELLS",
    shells,
  };
};

export const isLoadingAllShells = (isLoadingAllShells: boolean) => {
  return {
    type: "IS_LOADING_ALL_SHELLS",
    isLoadingAllShells,
  };
};

export const getAllShells = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(isLoadingAllShells(true));
    // @ts-ignore
    const response = await ApiService.getAllShells();
    const { data } = response;
    const shells = data.map((shell: any) => {
      const modifiedShell = { ...shell };
      const versionsMap: any = {};
      modifiedShell.versions.forEach((v: any) => {
        versionsMap[v.id] = v;
      });
      modifiedShell.versions = versionsMap;
      return modifiedShell;
    });

    dispatch(setShells(shells));
    return shells;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(isLoadingAllShells(false));
  }
};
