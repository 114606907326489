import React from "react";
import Sider from "antd/es/layout/Sider";
import { ItemsExplorer } from "./components/ItemsExplorer";
import { useItemsContext } from "./contexts/ItemsContext";

export const RoomsSiderMenu = () => {
  const { spaceItem, selectedItem, selectItem } = useItemsContext();
  return (
    <Sider width={350} className="siderMenu siderMenu--rooms">
      <div className="explorer">
        {spaceItem && (
          <ItemsExplorer
            spaceItem={spaceItem}
            selectedKey={selectedItem?.uuid}
            onSelect={(key) => {
              selectItem(key);
            }}
          />
        )}
      </div>
    </Sider>
  );
};
