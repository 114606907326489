import React, { Component } from "react";
import CloseOutlinedBlue from "../../assets/svg/CloseOutlinedBlue";
import CloseOutlinedTransparent from "../../assets/svg/CloseOutlinedTransparent";
import PlusOutlinedYellow from "../../assets/svg/PlusOutlinedYellow";
import "./commentViewer.scss";
import Button from "../Button";
import { Divider } from "antd";
import { addCommentForItemVersion } from "store/actions/items";
import { connect } from "react-redux";
import { getParamsFromPathname } from "../../utils/params";
import _ from "lodash";
class CommentViewer extends Component {
  state = {
    commentBody: "",
  };

  render() {
    return (
      <div className="comment-viewer">
        <h3 className="comment-title">Add a Comment:</h3>
        <div className="button-area">
          <div
            className="button"
            onClick={() => this.props.handleAddHotSpotFxn()}
          >
            <PlusOutlinedYellow />
            <label>Add Hotspot</label>
          </div>
        </div>
        <textarea
          value={this.state.commentBody}
          onChange={(e) =>
            this.setState({
              commentBody: e.target.value,
            })
          }
        />
        <div className="submit-container">
          <Button
            text="Submit Comment"
            disabled={this.state.commentBody === ""}
            onClick={async () => {
              if (this.state.commentBody !== "") {
                await this.props.addCommentForItemVersion(
                  this.props.itemIdInUrl,
                  this.props.versionIdInUrl,
                  this.state.commentBody,
                  this.props.newHotspots
                );
                this.props.renderViewerRef.current.hotspots = [];
                this.setState({
                  commentBody: "",
                });
              }
            }}
          />
        </div>
        <Divider />
      </div>
    );
  }
}

const mapStateToProps = () => {
  let { itemIdInUrl, versionIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  return {
    itemIdInUrl,
    versionIdInUrl,
  };
};

export default connect(mapStateToProps, {
  addCommentForItemVersion,
})(CommentViewer);
