import produce from "immer";
import { ADD_OR_UPDATE_VARIANT } from "sharedConstants";

const INITIAL_STATE = {};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_OR_UPDATE_VARIANT:
      const { variantId, variantData } = action;
      if (draft[variantId]) {
        draft[variantId] = {
          ...draft[variantId],
          ...variantData,
          status_id: variantData.status_id === null ? 1 : variantData.status_id,
        };
      } else {
        if (variantData.status_id === null) {
          variantData.status_id = 1;
        }
        draft[variantId] = variantData;
      }

      break;
    default:
      break;
  }

  return draft;
});
