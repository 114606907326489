import React, { useMemo } from "react";
import { Dropdown, MenuProps, DropDownProps } from "antd";
import {
  CopyOutlined,
  FileAddOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { MixItemType } from "../types";
import { useItemsContext } from "../contexts/ItemsContext";

interface ItemDropdownActionsProps extends Omit<DropDownProps, "menu"> {
  item: MixItemType;
}

export const ItemDropdownActions = ({
  item,
  children,
  ...rest
}: ItemDropdownActionsProps) => {
  const { deleteItemByUUID, cloneItemByUUID, addNewRoom } = useItemsContext();
  const menuItems = useMemo(() => {
    const items: MenuProps["items"] = [];
    if (item.type === "space") {
      items.push({
        label: <span>Add Room</span>,
        icon: <FileAddOutlined />,
        key: "add-room",
        onClick: (e) => {
          e.domEvent.preventDefault();
          e.domEvent.stopPropagation();
          addNewRoom();
        },
      });
    } else if (item.type === "room") {
      items.push({
        label: <span>Clone Room</span>,
        icon: <CopyOutlined />,
        key: "clone-room",
        onClick: (e) => {
          e.domEvent.preventDefault();
          e.domEvent.stopPropagation();
          cloneItemByUUID(item.uuid);
        },
      });
      items.push({
        label: <span>Delete Room</span>,
        icon: <DeleteOutlined />,
        key: "delete-room",
        onClick: (e) => {
          e.domEvent.preventDefault();
          e.domEvent.stopPropagation();
          deleteItemByUUID(item.uuid);
        },
      });
    }

    return items;
  }, [addNewRoom, cloneItemByUUID, deleteItemByUUID, item.type, item.uuid]);

  return (
    <Dropdown
      menu={{ items: menuItems }}
      overlayClassName="item-dropdown-actions"
      disabled={!menuItems.length}
      {...rest}
    >
      {children}
    </Dropdown>
  );
};
