import React, { useCallback, useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";
import { SiderMenu } from "./siderMenu";
import { CommenterSection } from "../components/commenter";
import { BabylonView, BabylonViewWrapper } from "../components/babylon";
import { useSpaceContext } from "../contexts/SpaceContext";
import { Comment } from "../types";
import { useCommentContext } from "../contexts/CommentContext";
// import { useAppSelector } from "hooks";
import { useCommentsApi } from "../hooks";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";

const { Content } = Layout;

export const ShowroomFloorPlan = () => {
  const { space, spaceVersionId, spaceUrl } = useSpaceContext();
  // const { email } = useAppSelector((state: any) => state.profile);
  const [comments, setComments] = useState<Comment[]>([]);
  const { setComment, updateComment } = useCommentContext();
  const {
    apiFetchComments,
    apiFetchComment,
    apiCreateComment,
    apiUpdateComment,
    apiRemoveCommentFiles,
    apiAddCommentFile,
  } = useCommentsApi();

  const onSubmitCommentHandler = useCallback(
    async (commentPayload: Comment) => {
      if (!spaceVersionId || !space?.id) return;
      const _comment: Comment = { ...commentPayload, isDirty: false };
      if (_comment.id) {
        // Update comment
        const commentIndex = comments.findIndex((c) => c.id === _comment.id);
        if (commentIndex > -1) {
          const _commentResponse = await apiUpdateComment({
            spaceVersionId,
            noteTypeId: 1,
            spaceId: space.id,
            oldComment: comments[commentIndex],
            newComment: _comment,
          });

          const newComments = [...comments];
          newComments[commentIndex] = _commentResponse;
          setComment(_commentResponse);
          setComments(newComments);
        }
      } else {
        // Add new comment
        const _commentResponse = await apiCreateComment({
          spaceVersionId,
          noteTypeId: 1,
          spaceId: space.id,
          comment: _comment,
        });
        setComments((comments) => {
          return [...comments, _commentResponse];
        });
        setComment(_commentResponse);
      }
    },
    [
      apiCreateComment,
      apiUpdateComment,
      comments,
      setComment,
      space?.id,
      spaceVersionId,
    ]
  );

  const onUploadCommentFiles = useCallback(
    async (commentId: string, imagesUrl: string[]) => {
      if (imagesUrl.length) {
        const commentIndex = comments.findIndex((c) => c.id === commentId);
        if (commentIndex > -1) {
          const comment = { ...comments[commentIndex] };
          const uploadImage = async (imageUrl: string) => {
            const res = await fetch(imageUrl);
            const blobImage = (await res.blob()) as File;
            await apiAddCommentFile(
              commentId,
              "product_image",
              blobImage.name,
              blobImage
            );
          };
          await Promise.all(imagesUrl.map((url) => uploadImage(url)));
          const _commentWithoutAnnotation = await apiFetchComment(commentId);
          comment.images = _commentWithoutAnnotation.images;
          setComment(comment);
          const newComments = [...comments];
          newComments[commentIndex] = comment;
          setComments(newComments);
        }
      }
    },
    [apiAddCommentFile, apiFetchComment, comments, setComment]
  );

  const onRemoveCommentFiles = useCallback(
    async (commentId: string | number, fileIds: number[]) => {
      const commentIndex = comments.findIndex((c) => c.id === commentId);
      if (commentIndex > -1) {
        const fileIdsMap: { [key: number]: number } = {};
        fileIds.forEach((id) => {
          fileIdsMap[id] = id;
        });
        await apiRemoveCommentFiles(commentId, fileIds);
        const comment = { ...comments[commentIndex] };
        comment.images = comment.images?.filter(({ id }) => !fileIdsMap[id]);
        const newComments = [...comments];
        newComments[commentIndex] = comment;
        setComments(newComments);
        updateComment((_comment) => ({
          images: comment.images,
          isDirty: _comment.isDirty,
        }));
      }
    },
    [apiRemoveCommentFiles, comments, updateComment]
  );

  useEffect(() => {
    if (spaceVersionId) {
      apiFetchComments(spaceVersionId, 1).then((result) => {
        setComments(result);
      });
    }
  }, [apiFetchComments, spaceVersionId]);

  return (
    <>
      <Layout className="layout layout-showroom layout-showroom-3d layout-showroom--floor-plan">
        <Content>
          <Row className="page-first-section" wrap={false}>
            <Col className="page-first-section--left">
              <SiderMenu onSubmitComment={onSubmitCommentHandler} />
            </Col>
            <Col flex="auto" className="babylon-col">
              {spaceUrl ? (
                <BabylonViewWrapper>
                  <BabylonView
                    onAddCommentImages={onUploadCommentFiles}
                    url={spaceUrl}
                  />
                </BabylonViewWrapper>
              ) : (
                <FullAreaSpinner />
              )}
            </Col>
          </Row>
          <CommenterSection
            comments={comments}
            onSubmitComment={onSubmitCommentHandler}
            onRemoveImages={onRemoveCommentFiles}
          />
        </Content>
      </Layout>
    </>
  );
};
