import React from "react";
import { Avatar, Button, Card, Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import AmplifyService from "services/AmplifyService";
import { capitalizeFirstLetter } from "utils";
// import { logOut } from "@Bitreel/portal-login";
// import { sharedInterface } from "@Bitreel/portal-login";

export default class UserDropdown extends React.Component {
  //TODO: find a place to "reset password"

  render() {
    return (
      <>
        <Card style={{ width: 300 }} bordered={false}>
          <Row align="middle" justify={"start"}>
            <Col span={6}>
              <Avatar size="large" gap={2}>
                {capitalizeFirstLetter(this.props.profile.email)}
              </Avatar>
            </Col>
            <Col>
              <p>{this.props.profile.email}</p>
            </Col>
          </Row>
          <Divider />
          {this.props.profile.admin && (
            <Row justify="center" align="middle">
              <Link to={`/${this.props.selectedCompanyId}/settings/account`}>
                SETTINGS
              </Link>
            </Row>
          )}
          <Divider />
          <Row justify="center" align="middle">
            <Button
              shape="round"
              className="bitreel-button"
              size="large"
              onClick={() => {
                AmplifyService.userSignout()
                  .then(() => this.props.logoutFxn())
                  .catch((err) => console.warn(err));
              }}
            >
              LOG OUT
            </Button>
          </Row>
        </Card>
      </>
    );
  }
}
