import React from "react";
import { useEffect } from "react";
import { AppRoutes } from "routes";
import { amplifyConfig } from "configs/amplifyConfigure";
import { amplifyConfigBeta } from "configs/amplifyConfigure.beta";
import { Helmet } from "react-helmet";
import favicon from "assets/images/favicon.png";
import "@babylonjs/loaders";
import "styles/index.scss";
if (process.env.NODE_ENV === "development") {
  // Development mode
  require("babylonjs-inspector");
}

//config amplify
const startAmplify = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "beta":
    case "local":
      console.log("beta amplify used");
      amplifyConfigBeta();
      break;
    case "featureDev":
    case "prod":
    case "gamma":
    case "preview":
    default:
      console.log("default amplify used");
      amplifyConfig();
  }
};

startAmplify();

const injectGlobalScripts = ({
  id,
  src,
  innerHTML,
}: {
  id: string;
  src?: string;
  innerHTML?: string;
}) => {
  if (!document.getElementById(id)) {
    const script = document.createElement("script");
    script.id = id;
    script.async = true;
    if (src) script.src = src;
    if (innerHTML) script.innerHTML = innerHTML;
    document.body.appendChild(script);
  }
};

export const App = () => {
  useEffect(() => {
    injectGlobalScripts({
      id: "script-model-viewer",
      src: `https://model-viewer-scripts.bitreel.com/${process.env.REACT_APP_STAGE}/static/js/bitreel-model-viewer.js`,
    });
    injectGlobalScripts({
      id: "script-bitreel-assembler",
      src: `https://bitreel-viewer-scripts.s3.amazonaws.com/assembler/${
        process.env.REACT_APP_STAGE === "beta" ? "beta" : "prod"
      }/static/js/bitreel-assembler.js`,
    });
    injectGlobalScripts({
      id: "script-chunk-1",
      src: `https://bitreel-cb-scheduler.s3.amazonaws.com/static/js/main.chunk.js`,
    });

    injectGlobalScripts({
      id: "script-chunk-2",
      src: `https://bitreel-cb-scheduler.s3.amazonaws.com/static/js/2.chunk.js`,
    });

    injectGlobalScripts({
      id: "script-other",
      innerHTML: `!function(e){function r(r){for(var n,l,c=r[0],i=r[1],a=r[2],p=0,s=[];p<c.length;p++)l=c[p],Object.prototype.hasOwnProperty.call(o,l)&&o[l]&&s.push(o[l][0]),o[l]=0;for(n in i)Object.prototype.hasOwnProperty.call(i,n)&&(e[n]=i[n]);for(f&&f(r);s.length;)s.shift()();return u.push.apply(u,a||[]),t()}function t(){for(var e,r=0;r<u.length;r++){for(var t=u[r],n=!0,c=1;c<t.length;c++){var i=t[c];0!==o[i]&&(n=!1)}n&&(u.splice(r--,1),e=l(l.s=t[0]))}return e}var n={},o={1:0},u=[];function l(r){if(n[r])return n[r].exports;var t=n[r]={i:r,l:!1,exports:{}};return e[r].call(t.exports,t,t.exports,l),t.l=!0,t.exports}l.m=e,l.c=n,l.d=function(e,r,t){l.o(e,r)||Object.defineProperty(e,r,{enumerable:!0,get:t})},l.r=function(e){"undefined"!=typeof Symbol&&Symbol.toStringTag&&Object.defineProperty(e,Symbol.toStringTag,{value:"Module"}),Object.defineProperty(e,"__esModule",{value:!0})},l.t=function(e,r){if(1&r&&(e=l(e)),8&r)return e;if(4&r&&"object"==typeof e&&e&&e.__esModule)return e;var t=Object.create(null);if(l.r(t),Object.defineProperty(t,"default",{enumerable:!0,value:e}),2&r&&"string"!=typeof e)for(var n in e)l.d(t,n,function(r){return e[r]}.bind(null,n));return t},l.n=function(e){var r=e&&e.__esModule?function(){return e.default}:function(){return e};return l.d(r,"a",r),r},l.o=function(e,r){return Object.prototype.hasOwnProperty.call(e,r)},l.p="https://bitreel-cb-scheduler.s3.amazonaws.com/";var c=this["webpackJsonpco-browsing-scheduler"]=this["webpackJsonpco-browsing-scheduler"]||[],i=c.push.bind(c);c.push=r,c=c.slice();for(var a=0;a<c.length;a++)r(c[a]);var f=i;t()}([])`,
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bitreel Portal</title>
        <link rel="shortcut icon" id="favicon" href={favicon}></link>
      </Helmet>
      <AppRoutes />
    </>
  );
};
