import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import { Layout } from "antd";
import UMAdminConsole from "./src/core/umAdminConsole";

class MainAppContainer extends Component {
  render() {
    return (
      <>
        <Layout className="bitreel-product root layout">
          {/*{this.props.auth.user.admin ? <UMAdminConsole />: <p>Sorry! you don't have access</p>}*/}
          <UMAdminConsole />
        </Layout>
      </>
    );
  }
}

const mapStateToProps = ({ auth, users, companies }) => {
  return {
    auth,
    users,
    companies,
  };
};

export default connect(mapStateToProps, {})(withRouter(MainAppContainer));
