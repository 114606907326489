import React from "react";
import { Button, Form } from "antd";
import { useUIContext } from "../contexts/UIContext";

export const FormActionFooter = () => {
  const { isDirty, setIsDirty } = useUIContext();

  return (
    <Form.Item>
      <div className="commit-buttons">
        <Button disabled={!isDirty} type="primary" htmlType="submit">
          Submit
        </Button>
        <Button
          htmlType="reset"
          onClick={() => {
            setIsDirty(false);
          }}
        >
          Reset
        </Button>
      </div>
    </Form.Item>
  );
};
