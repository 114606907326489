import React, { Component } from "react";
import { withRouter } from "utils";
import { Row, message, Typography } from "antd";
import { Form, Input, Button } from "antd";
import { connect } from "react-redux";
import { selectLoginStage } from "store/actions/app";
import { SIGN_IN } from "sharedConstants";
import {
  validateContainNumber,
  validateEmail,
  validateLowerCase,
  validateMinimumLength,
  validateSpecialCharacter,
  validateUpperCase,
} from "utils";
import AmplifyService from "services/AmplifyService";
import { userLogin } from "store/actions/auth";
import ApiService from "services/ApiService";
const { Text, Link } = Typography;

//TODO: check email created in bitreel's DB before proceeding..needs public "get email" api
class SignupForm extends Component {
  constructor(props) {
    super(props);
  }

  loginFormRef = React.createRef();

  state = {
    submitLoading: false,
  };

  onFinish = (values) => {
    this.setState({ submitLoading: true });
    AmplifyService.userSignUp(values.email, values.newPassword)
      .then(async (user) => {
        this.setState({ submitLoading: false });
        this.props.selectLoginStage(SIGN_IN);
        await this.props.userLogin({
          username: values.email,
          password: values.newPassword,
        });
        await ApiService.postUserCredential(values.email, user.userSub);
        message.success("Sign up success!");
      })
      .catch(async (err) => {
        if (
          err.code === "UsernameExistsException" ||
          err.__type === "UsernameExistsException"
        ) {
          this.setState({ submitLoading: false });
          this.props.selectLoginStage(SIGN_IN);
          let vals = await this.props.userLogin({
            username: values.email,
            password: values.newPassword,
          });
          await ApiService.postUserCredential(values.email, vals.username);
          message.success("Sign up success!");
        }
      })
      .finally(() => this.setState({ submitLoading: false }));
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    return (
      <>
        <Row className="content_row" justify="center" align="middle">
          <h1>Sign up</h1>
        </Row>
        <Form
          name="basic"
          labelCol={{ span: 14, offset: 5 }}
          wrapperCol={{ span: 14, offset: 5 }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            email: new URLSearchParams(window.location.search).get(
              "sign_up_email"
            ),
          }}
          ref={this.loginFormRef}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                validator: (_, value) => validateEmail(value),
              },
            ]}
          >
            <Input disabled={true} />
          </Form.Item>

          <Form.Item
            label="New password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                validator: (_, value) => validateLowerCase(value),
              },
              {
                validator: (_, value) => validateUpperCase(value),
              },
              {
                validator: (_, value) => validateSpecialCharacter(value),
              },
              {
                validator: (_, value) => validateContainNumber(value),
              },
              {
                validator: (_, value) => validateMinimumLength(value),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              loading={this.state.submitLoading}
            >
              Sign up
            </Button>
            <Form.Item>
              <Text>Already have an account? </Text>
              <Text underline>
                <a onClick={() => this.props.selectLoginStage(SIGN_IN)}>
                  Sign in
                </a>
              </Text>
            </Form.Item>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  selectLoginStage,
  userLogin,
})(withRouter(SignupForm));
