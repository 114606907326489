import React, { Component } from "react";
import { connect } from "react-redux";
import { getParamsFromPathname } from "utils";
import "./create.scss";
// import nataliaImage from "./thenatalia.jpeg";
import {
  Tree,
  Row,
  Col,
  Upload,
  message,
  Drawer,
  Popconfirm,
  Button,
  Checkbox,
} from "antd";
import { CarryOutOutlined } from "@ant-design/icons";
import _ from "lodash";
import AddLayoutModal from "../components/creatorModals/AddLayout";
import AddPartGroupModal from "../components/creatorModals/AddPartGroup";
import AddPartModal from "../components/creatorModals/AddPart";
import AddMaterialModal from "../components/creatorModals/AddMaterial";
import { v4 as uuidv4 } from "uuid";
import LayoutDetails from "./create/LayoutDetails";
import { getLibraryMaterials } from "store/actions/materials";
class ProductCreate extends Component {
  state = {
    step: 1,
    imageToShow: null,
    selectedKeys: [],
    drawingDisabled: true,
    newItem: {
      layouts: [
        // {
        //     uuid: 'f3h8u3hf834hf8fh',
        //     name: '80w x 20d x 34h TEST ITEM',
        //     image: nataliaImage,
        //     basePrice: 100.22,
        //     partGroups: [
        //         {
        //             uuid: 'g294ujg9u4hgu4hgu45h8',
        //             name: 'Base',
        //             drawingData: {
        //                 lines: []
        //             },
        //             optional: false,
        //             parts: [
        //                 {
        //                     uuid: 'g45jhg8u4hgyq2cyhf43f',
        //                     name: "Standard Base",
        //                     priceMod: 1.20,
        //                     referenceFile: null,
        //                     materials: [
        //                         {
        //                             uuid: 'fdh3yfh384hf834hfg38hfg',
        //                             name: "Oak",
        //                             thumbnailData: null,
        //                             priceMod: 2.5,
        //                             materialBasePriceMod: 22.8
        //                         }
        //                     ]
        //                 }
        //             ]
        //         }
        //     ]
        // }
      ],
    },
  };

  componentDidMount() {
    this.props.getLibraryMaterials(this.props.companyIdInUrl);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("updated")
    // debugger
    // if (JSON.stringify(prevState.newItem) !== JSON.stringify(this.state.newItem)) {
    //     debugger
    //     console.log("trying to set layouts")
    //     this.props.setLayoutsFxn(this.state.newItem);
    // }
  }

  newItemTreeData = () => {
    return _.map(this.state.newItem.layouts, (layout) => {
      return {
        title: layout.name,
        key: `layoutlevel-${layout.uuid}`,
        icon: <CarryOutOutlined />,
        children: _.map(layout.partGroups, (pg) => {
          return {
            title: pg.name,
            key: `pglevel-${layout.uuid}-${pg.uuid}`,
            icon: <CarryOutOutlined />,
            children: _.map(pg.parts, (part) => {
              return {
                title: part.name,
                key: `partlevel-${layout.uuid}-${pg.uuid}-${part.uuid}`,
                icon: <CarryOutOutlined />,
                children: _.map(part.materials, (m) => {
                  return {
                    title: m.name,
                    key: `materiallevel-${layout.uuid}-${pg.uuid}-${part.uuid}-${m.uuid}`,
                    icon: <CarryOutOutlined />,
                    children: [],
                  };
                }),
              };
            }),
          };
        }),
      };
    });
  };

  redoDrawingForPartGroup = (layoutName, partGroupName) => {
    this.setState(
      {
        drawingDisabled: false,
      },
      () => {
        // message.info('Roughly shade the area that represents this part group on the product image and press DONE after')
        let copy = { ...this.state.newItem };
        let layout = copy.layouts.find((l) => l.uuid === layoutName);
        let partGroup = layout.partGroups.find(
          (pg) => pg.uuid === partGroupName
        );
        if (this.ref) {
          this.ref.clear();
        }
      }
    );
  };

  setImageForMaterial = (
    layoutName,
    partGroupName,
    partName,
    materialName,
    thumbnailData
  ) => {
    let copy = { ...this.state.newItem };
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    let part = partGroup.parts.find((p) => p.uuid === partName);
    let material = part.materials.find((m) => m.uuid === materialName);
    material.thumbnailData = thumbnailData;

    // debugger
    this.setState(
      {
        newItem: copy,
      },
      () => this.props.setLayoutsFxn(this.state.newItem)
    );
  };

  setImageForPartGroup = (
    layoutName,
    partGroupName,
    partName,
    thumbnailData,
    file
  ) => {
    let copy = { ...this.state.newItem };
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    let part = partGroup.parts.find((p) => p.uuid === partName);
    part.referenceFile = thumbnailData;
    part.referenceFileForUpload = file;

    this.setState(
      {
        newItem: copy,
      },
      () => this.props.setLayoutsFxn(this.state.newItem)
    );
  };

  saveDrawingForPartGroup = (layoutName, partGroupName) => {
    let copy = { ...this.state.newItem };
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    partGroup.drawingData = JSON.parse(this.ref.getSaveData());
    this.setState(
      {
        newItem: copy,
        drawingDisabled: true,
      },
      () => {
        this.props.setLayoutsFxn(this.state.newItem);
      }
    );
  };

  removeSelectedPart = () => {
    let copy = { ...this.state.newItem };
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let partGroupName = this.state.selectedKeys[0].split("-")[2];
    let partName = this.state.selectedKeys[0].split("-")[3];
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    let partIdx = partGroup.parts.findIndex((p) => p.uuid === partName);
    let part = partGroup.parts[partIdx];
    partGroup.parts.splice(partIdx, 1);
    this.setState(
      {
        newItem: copy,
        selectedKeys: [],
      },
      () => {
        this.props.setLayoutsFxn(this.state.newItem);
      }
    );
  };

  removeSelectedMaterial = () => {
    let copy = { ...this.state.newItem };
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let partGroupName = this.state.selectedKeys[0].split("-")[2];
    let partName = this.state.selectedKeys[0].split("-")[3];
    let materialName = this.state.selectedKeys[0].split("-")[4];
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    let partIdx = partGroup.parts.findIndex((p) => p.uuid === partName);
    let part = partGroup.parts[partIdx];
    let materialIdx = part.materials.findIndex((m) => m.uuid === materialName);
    part.materials.splice(materialIdx, 1);
    this.setState(
      {
        newItem: copy,
        selectedKeys: [],
      },
      () => {
        this.props.setLayoutsFxn(this.state.newItem);
      }
    );
  };

  removeSelectedPartGroup = () => {
    let copy = { ...this.state.newItem };
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let partGroupName = this.state.selectedKeys[0].split("-")[2];
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroupIdx = layout.partGroups.findIndex(
      (pg) => pg.uuid === partGroupName
    );
    let partGroup = layout.partGroups[partGroupIdx];

    if (partGroup.name === "Base") {
      message.warn("Base can not be removed");
      return;
    }
    layout.partGroups.splice(partGroupIdx, 1);
    this.setState(
      {
        newItem: copy,
        selectedKeys: [],
      },
      () => {
        this.props.setLayoutsFxn(this.state.newItem);
      }
    );
  };

  removeSelectedLayout = () => {
    let copy = { ...this.state.newItem };
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let layoutIdx = copy.layouts.findIndex((l) => l.uuid === layoutName);
    // let layout = copy.layouts[layoutIdx]

    copy.layouts.splice(layoutIdx, 1);
    this.setState(
      {
        newItem: copy,
        selectedKeys: [],
      },
      () => {
        this.props.setLayoutsFxn(this.state.newItem);
      }
    );
  };

  handleCloneLayout = () => {
    let copy = { ...this.state.newItem };
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let layout = copy.layouts.find((l) => l.uuid === layoutName);

    let clone = _.cloneDeep(layout);
    clone.uuid = uuidv4().replace(/-/g, "");
    clone.partGroups.forEach((pg) => {
      pg.uuid = uuidv4().replace(/-/g, "");
      pg.parts.forEach((p) => {
        p.uuid = uuidv4().replace(/-/g, "");
        p.materials.forEach((m) => {
          m.uuid = uuidv4().replace(/-/g, "");
        });
      });
    });
    copy.layouts.push({
      ...clone,
    });
    this.setState({
      newItem: copy,
    });
  };

  handleCopyMaterialsFromPart = (partKey) => {
    this.setState(
      {
        selectedPartToCopyTo: partKey,
      },
      () => {
        window.alert("Next part click will copy material - CLICK A PART");
      }
    );
    // store selected now
    // next selected, if selectednow, copy materials from part to old part
    // put selected back to seelected now and clear selected now after
  };

  renderSelectedInfoSection = () => {
    if (this.state.selectedKeys.length) {
      let type = this.state.selectedKeys[0].split("-")[0];
      let layoutName = this.state.selectedKeys[0].split("-")[1];
      let partGroupName = this.state.selectedKeys[0].split("-")[2];
      let partName = this.state.selectedKeys[0].split("-")[3];
      let materialName = this.state.selectedKeys[0].split("-")[4];
      let layout;
      let partGroup;
      switch (type) {
        case "layoutlevel":
          return (
            <div>
              <div
                onClick={() => {
                  this.setState({
                    showPartGroupModal: true,
                  });
                }}
              >
                add part group
              </div>
              <Button onClick={this.handleCloneLayout}>Clone Layout</Button>
              <Popconfirm
                onConfirm={this.removeSelectedLayout}
                onCancel={_.noop}
                title="Are you sure to delete this Layout? All drawing and data progress will be lost."
              >
                <div>Remove Layout</div>
              </Popconfirm>
            </div>
          );
        case "pglevel":
          layout = this.state.newItem.layouts.find(
            (l) => l.uuid === layoutName
          );
          partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
          console.log("checkbox value is: ", partGroup.optional);
          return (
            <div>
              {/* {this.state.drawingDisabled && <div onClick={() => {
                          this.redoDrawingForPartGroup(layoutName, partGroupName)
                      }}>redo drawing</div>}
                      {!this.state.drawingDisabled && <div onClick={() => {
                          this.saveDrawingForPartGroup(
                              layoutName,
                              partGroupName
                          )
                      }}>
                          DONE
                      </div>} */}
              <div
                onClick={() => {
                  this.setState({
                    showAddPartModal: true,
                  });
                }}
              >
                Add Part
              </div>
              <div
                onClick={() => {
                  this.removeSelectedPartGroup();
                }}
              >
                Remove Part Group
              </div>
              <label>optional ?</label>
              <Checkbox
                checked={partGroup.optional}
                onChange={(v) => {
                  this.updatePartChecked(v.target.checked);
                }}
              />
              {/* <div onClick={() => {
                          this.setState({
                              showDrawer: true
                          })
                      }}>
                          Edit Details / Attach References
                      </div> */}
            </div>
          );
        case "partlevel":
          return (
            <div>
              <div
                onClick={() => {
                  this.removeSelectedPart();
                }}
              >
                Remove Part
              </div>
              <div>
                <button
                  onClick={() => {
                    this.setState({
                      showAddMaterial: true,
                    });
                  }}
                >
                  Add Material
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    this.handleCopyMaterialsFromPart(
                      this.state.selectedKeys[0]
                    );
                  }}
                >
                  Copy Materials From Part
                </button>
              </div>
            </div>
          );
        case "materiallevel":
          layout = this.state.newItem.layouts.find(
            (l) => l.uuid === layoutName
          );
          partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
          let part = partGroup.parts.find((p) => p.uuid === partName);
          let material = part.materials.find((m) => m.uuid === materialName);
          return (
            <div
              onClick={() => {
                this.removeSelectedMaterial();
              }}
            >
              Remove Material
            </div>
          );
        default:
          return null;
      }
    }
  };

  activateDrawingForPartGroup = (layoutName, partGroupName) => {
    let copy = { ...this.state.newItem };
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    if (this.ref) {
      this.ref.clear();
      this.ref.loadSaveData(JSON.stringify(partGroup.drawingData), true);
    }
  };

  addPartGroup = (name) => {
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let copy = { ...this.state.newItem };
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    layout.partGroups.push({
      uuid: uuidv4().replace(/-/g, ""),
      name,
      optional: false,
      parts: [
        {
          uuid: uuidv4().replace(/-/g, ""),
          name: `Standard ${name}`,
          materials: [],
          referenceFile: null,
        },
      ],
      drawingData: {
        lines: [],
      },
    });
    this.setState(
      {
        newItem: copy,
        showPartGroupModal: false,
      },
      () => this.props.setLayoutsFxn(this.state.newItem)
    );
  };

  updatePartChecked = (checked) => {
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let partGroupName = this.state.selectedKeys[0].split("-")[2];
    let copy = { ...this.state.newItem };
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    partGroup.optional = checked;
    this.setState(
      {
        newItem: copy,
      },
      () => this.props.setLayoutsFxn(this.state.newItem)
    );
  };

  addPart = (name) => {
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let partGroupName = this.state.selectedKeys[0].split("-")[2];
    let copy = { ...this.state.newItem };
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    partGroup.parts.push({
      name,
      price_mod: 0,
      materials: [],
      uuid: uuidv4().replace(/-/g, ""),
      referenceFile: null,
    });
    this.setState(
      {
        newItem: copy,
        showAddPartModal: false,
      },
      () => this.props.setLayoutsFxn(this.state.newItem)
    );
  };

  addMaterial = (libraryMaterial) => {
    let layoutName = this.state.selectedKeys[0].split("-")[1];
    let partGroupName = this.state.selectedKeys[0].split("-")[2];
    let partName = this.state.selectedKeys[0].split("-")[3];
    let copy = { ...this.state.newItem };
    let layout = copy.layouts.find((l) => l.uuid === layoutName);
    let partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
    let part = partGroup.parts.find((p) => p.uuid === partName);
    part.materials.push({
      name: libraryMaterial.name,
      thumbnailData: null,
      uuid: uuidv4().replace(/-/g, ""),
      priceMod: 0,
      libMatId: libraryMaterial.id,
      materialPriceModOverrideDefault: libraryMaterial.price_mod,
    });
    this.setState(
      {
        newItem: copy,
        showAddMaterial: false,
      },
      () => this.props.setLayoutsFxn(this.state.newItem)
    );
  };

  renderModals = () => {
    return (
      <>
        <AddLayoutModal
          visible={this.state.showAddLayoutModal}
          onCancel={() => {
            this.setState({
              showAddLayoutModal: false,
            });
          }}
          onOk={(name, img, file) => {
            this.addLayout(name, img, file);
          }}
        />
        <AddPartGroupModal
          visible={this.state.showPartGroupModal}
          onCancel={() => {
            this.setState({
              showPartGroupModal: false,
            });
          }}
          onOk={(name) => {
            this.addPartGroup(name);
          }}
        />
        <AddPartModal
          visible={this.state.showAddPartModal}
          onCancel={() => {
            this.setState({
              showAddPartModal: false,
            });
          }}
          onOk={(name) => {
            this.addPart(name);
          }}
        />
        <AddMaterialModal
          visible={this.state.showAddMaterial}
          onCancel={() => {
            this.setState({
              showAddMaterial: false,
            });
          }}
          onOk={(libMat) => {
            this.addMaterial(libMat);
          }}
        />
      </>
    );
  };

  addLayout = (name, image, imageFile) => {
    let copy = { ...this.state.newItem };
    copy.layouts.push({
      name,
      image,
      imageFile,
      uuid: uuidv4().replace(/-/g, ""),
      partGroups: [
        {
          name: `Base`,
          uuid: uuidv4().replace(/-/g, ""),
          optional: false,
          parts: [
            {
              uuid: uuidv4().replace(/-/g, ""),
              name: `Standard Base`,
              materials: [],
              referenceFile: null,
            },
          ],
          drawingData: {
            lines: [],
          },
        },
      ],
    });
    this.setState(
      {
        newItem: copy,
        showAddLayoutModal: false,
      },
      () => this.props.setLayoutsFxn(this.state.newItem)
    );
  };

  renderPartDetails = () => {};

  renderMaterialDetails = () => {};

  updateLayouts = (newLayouts, collectionName) => {
    let copy = { ...this.state.newItem };
    copy.layouts = newLayouts;

    this.setState({
      newItem: copy,
    });
  };

  renderDrawerContent = () => {
    let type = this.state.selectedKeys[0].split("-")[0];
    let layoutName,
      partGroupName,
      partName,
      materialName,
      layout,
      partGroup,
      part,
      material;
    switch (type) {
      case "layoutlevel":
        let layoutUUID = this.state.selectedKeys[0].split("-")[1];
        layout = this.state.newItem.layouts.find((l) => l.uuid === layoutUUID);
        return (
          <div>
            <h2>Edit Layout</h2>
            <label>Name</label>
            <input
              value={layout.name}
              onChange={(e) => {
                let copy = { ...this.state.newItem };
                layout = copy.layouts.find((l) => l.uuid === layoutUUID);
                layout.name = e.target.value;
                this.setState(
                  {
                    newItem: copy,
                  },
                  () => this.props.setLayoutsFxn(this.state.newItem)
                );
              }}
            />
            <label>Base Price</label>
            <input
              type="number"
              value={layout.basePrice}
              onChange={(e) => {
                let copy = { ...this.state.newItem };
                layout = copy.layouts.find((l) => l.uuid === layoutUUID);
                layout.basePrice = Number(e.target.value);
                this.setState(
                  {
                    newItem: copy,
                  },
                  () => this.props.setLayoutsFxn(this.state.newItem)
                );
              }}
            />
          </div>
        );
      case "partlevel":
        layoutName = this.state.selectedKeys[0].split("-")[1];
        partGroupName = this.state.selectedKeys[0].split("-")[2];
        partName = this.state.selectedKeys[0].split("-")[3];
        materialName = this.state.selectedKeys[0].split("-")[4];
        layout = this.state.newItem.layouts.find((l) => l.uuid === layoutName);
        partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
        part = partGroup.parts.find((p) => p.uuid === partName);
        return (
          <div>
            <h2>Edit Part</h2>
            <label>Name</label>
            <input
              value={part.name}
              onChange={(e) => {
                let copy = { ...this.state.newItem };
                layout = copy.layouts.find((l) => l.uuid === layoutName);
                partGroup = layout.partGroups.find(
                  (pg) => pg.uuid === partGroupName
                );
                part = partGroup.parts.find((p) => p.uuid === partName);
                part.name = e.target.value;
                this.setState(
                  {
                    newItem: copy,
                  },
                  () => this.props.setLayoutsFxn(this.state.newItem)
                );
              }}
            />
            <label>Price Mod</label>
            <input
              type="number"
              value={part.priceMod}
              onChange={(e) => {
                let copy = { ...this.state.newItem };
                layout = copy.layouts.find((l) => l.uuid === layoutName);
                partGroup = layout.partGroups.find(
                  (pg) => pg.uuid === partGroupName
                );
                part = partGroup.parts.find((p) => p.uuid === partName);
                part.priceMod = Number(e.target.value);
                this.setState(
                  {
                    newItem: copy,
                  },
                  () => this.props.setLayoutsFxn(this.state.newItem)
                );
              }}
            />
            <Upload
              // accept=""
              showUploadList={false}
              beforeUpload={(file) => {
                // debugger
                const reader = new FileReader();
                reader.onload = () => {
                  this.setImageForPartGroup(
                    layoutName,
                    partGroupName,
                    partName,
                    reader.result,
                    file
                  );
                };
                reader.readAsDataURL(file);
                return false;
              }}
            >
              upload reference here
            </Upload>
            {part.referenceFile && (
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundImage: `url('${part.referenceFile}')`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            )}
          </div>
        );
      case "materiallevel":
        layoutName = this.state.selectedKeys[0].split("-")[1];
        partGroupName = this.state.selectedKeys[0].split("-")[2];
        partName = this.state.selectedKeys[0].split("-")[3];
        materialName = this.state.selectedKeys[0].split("-")[4];
        layout = this.state.newItem.layouts.find((l) => l.uuid === layoutName);
        partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
        part = partGroup.parts.find((p) => p.uuid === partName);
        material = part.materials.find((m) => m.uuid === materialName);
        return (
          <div>
            <h2>Edit Material</h2>
            <label>Default Library Price</label>
            <h4>{`${material.materialPriceModOverrideDefault}`}</h4>
            <label>Material Price</label>
            <input
              type="number"
              value={material.priceMod}
              onChange={(e) => {
                let copy = { ...this.state.newItem };
                layout = copy.layouts.find((l) => l.uuid === layoutName);
                partGroup = layout.partGroups.find(
                  (pg) => pg.uuid === partGroupName
                );
                part = partGroup.parts.find((p) => p.uuid === partName);
                material.priceMod = Number(e.target.value);
                this.setState(
                  {
                    newItem: copy,
                  },
                  () => this.props.setLayoutsFxn(this.state.newItem)
                );
              }}
            />
            {/* <Upload
                          // accept=""
                          showUploadList={false}
                          beforeUpload={(file) => {
                              // debugger
                              const reader = new FileReader();
                              reader.onload = () => {
                                  this.setImageForMaterial(
                                      layoutName,
                                      partGroupName,
                                      partName,
                                      materialName,
                                      reader.result
                                  )
                              };
                              reader.readAsDataURL(file)
                              return false;
                          }}
                          >upload here {material.name}</Upload>
                          {material.thumbnailData && <div style={{
                              width: '150px',
                              height: '150px',
                              backgroundImage: `url('${material.thumbnailData}')`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center' 
                          }} />} */}
          </div>
        );
      case "pglevel":
        layoutName = this.state.selectedKeys[0].split("-")[1];
        partGroupName = this.state.selectedKeys[0].split("-")[2];
        layout = this.state.newItem.layouts.find((l) => l.uuid === layoutName);
        partGroup = layout.partGroups.find((pg) => pg.uuid === partGroupName);
        return (
          <div>
            <h2>Edit Part Group</h2>
          </div>
        );
      default:
        return <h3>Nothing Selected</h3>;
    }
  };

  render() {
    // if (this.state.step === 2) {
    //     return <Screen className='product-create'>
    //         <FinalizeInfo
    //             newItem={this.state.newItem}
    //             company={this.props.company}
    //             order={this.props.order}
    //         />
    //     </Screen>
    // }

    // if (this.props.loading) {
    //     return <Screen>
    //         <FullAreaSpinner />
    //     </Screen>
    // }

    let layoutName;
    let layout;
    if (this.state.selectedKeys[0]) {
      layoutName = this.state.selectedKeys[0].split("-")[1];
      layout = this.state.newItem.layouts.find((l) => l.uuid === layoutName);
    }

    return (
      <div className="modal-content-container">
        <Row style={{ marginTop: "30px" }} className="layout-list">
          <Col span={24}>
            <Button type="primary">
              <div
                onClick={() => {
                  this.setState({
                    showAddLayoutModal: true,
                  });
                }}
              >
                Add New Layout
              </div>
            </Button>
          </Col>
          <Col span={4} className="innerlist-container">
            <Tree
              onSelect={(e) => {
                // debugger
                this.setState(
                  {
                    selectedKeys: e[0] ? [e[0]] : [],
                    newItem: { ...this.state.newItem },
                  },
                  () => {
                    if (this.state.selectedKeys.length) {
                      let layoutName = this.state.selectedKeys[0].split("-")[1];
                      let layout = this.state.newItem.layouts.find(
                        (l) => l.uuid === layoutName
                      );
                      let partGroupName =
                        this.state.selectedKeys[0].split("-")[2];
                      let partName = this.state.selectedKeys[0].split("-")[3];
                      // let materialName =
                      //   this.state.selectedKeys[0].split("-")[4];

                      let type = this.state.selectedKeys[0].split("-")[0];
                      if (this.state.selectedPartToCopyTo) {
                        if (type === "partlevel") {
                          let copy = { ...this.state.newItem };
                          let fromPartGroup = layout.partGroups.find(
                            (pg) => pg.uuid === partGroupName
                          );
                          let _fromPart = fromPartGroup.parts.find(
                            (p) => p.uuid === partName
                          );

                          let toLayoutName =
                            this.state.selectedPartToCopyTo.split("-")[1];
                          let toPartGroupName =
                            this.state.selectedPartToCopyTo.split("-")[2];
                          let toPartName =
                            this.state.selectedPartToCopyTo.split("-")[3];
                          let toLayout = copy.layouts.find(
                            (l) => l.uuid === toLayoutName
                          );
                          let toPartGroup = toLayout.partGroups.find(
                            (pg) => pg.uuid === toPartGroupName
                          );
                          let _toPart = toPartGroup.parts.find(
                            (p) => p.uuid === toPartName
                          );

                          // console.log("from part", _fromPart)

                          // console.log("to part", _toPart)

                          _fromPart.materials.forEach((m) => {
                            _toPart.materials.push({
                              ...m,
                              uuid: uuidv4().replace(/-/g, ""),
                            });
                          });

                          this.setState({
                            newItem: copy,
                            selectedPartToCopyTo: null,
                            selectedKeys: [this.state.selectedPartToCopyTo],
                          });
                        } else {
                          window.alert(
                            "You did not click a part, copy cancelled"
                          );
                          this.setState({
                            selectedPartToCopyTo: null,
                          });
                        }
                        return;
                      }

                      this.setState({
                        imageToShow: layout.image,
                      });
                      switch (type) {
                        case "layoutlevel":
                          this.setState({
                            showDrawer: true,
                          });
                          break;
                        case "pglevel":
                          this.activateDrawingForPartGroup(
                            layoutName,
                            partGroupName
                          );
                          break;
                        case "partlevel":
                          this.setState({
                            showDrawer: true,
                          });
                          break;
                        case "materiallevel":
                          this.setState({
                            showDrawer: true,
                          });
                          break;
                        default:
                          break;
                      }
                    }
                  }
                );
              }}
              showLine={false}
              showIcon={false}
              selectedKeys={this.state.selectedKeys}
              // onSelect={onSelect}
              treeData={this.newItemTreeData()}
            />
            {this.renderSelectedInfoSection()}
          </Col>
          {/* <Col span={19}>
                      {this.renderSelectedInfoSection()}
                  </Col> */}
          <Col span={20}>
            {layout && (
              <LayoutDetails
                addReferenceFileFxn={(file) =>
                  this.props.addReferenceFileFxn(file, layout.uuid)
                }
                layout={layout}
              />
            )}
          </Col>
        </Row>
        {/* <PortalButton text='test' onClick={() => {
                  console.log(this.ref.getSaveData())
              }} />
              <PortalButton text='Next' onClick={() => {
                  this.setState({
                      step: 2
                  })
              }} /> */}
        {this.renderModals()}
        <Drawer
          title="Details"
          placement="right"
          onClose={() => this.setState({ showDrawer: false })}
          open={this.state.showDrawer}
        >
          {this.state.selectedKeys.length && this.renderDrawerContent()}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ companies }, { setLayoutsFxn }) => {
  let { companyIdInUrl } = getParamsFromPathname(window.location.pathname);
  let loading = false;
  let company = companies[companyIdInUrl];
  let order = company.orders[Object.keys(company.orders)[0]];
  if (!loading) {
    return {
      loading,
      order,
      company,
      setLayoutsFxn,
      companyIdInUrl,
    };
  } else {
    return {
      loading,
      setLayoutsFxn,
      companyIdInUrl,
    };
  }
};

export default connect(
  mapStateToProps,
  {
    getLibraryMaterials,
  },
  undefined,
  { forwardRef: true }
)(ProductCreate);
