import produce from "immer";
import { ADD_BEHAVIOR, SET_BEHAVIORS } from "sharedConstants";

const INITIAL_STATE = [];

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BEHAVIORS:
      let { behaviors } = action;
      draft = behaviors;
      break;

    case ADD_BEHAVIOR:
      let { behavior } = action;
      draft.push(behavior);
      break;

    default:
      break;
  }

  return draft;
});
