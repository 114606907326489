import { Menu } from "antd";
import React, { useEffect, useRef } from "react";
import { MenuProps } from "rc-menu";

interface ContextMenuProps {
  open?: boolean;
  onClose?: () => void;
  onChange?: (value: boolean) => void;
  menu?: MenuProps;
  children?: React.ReactElement;
}

export const ContextMenu = ({
  open = false,
  onClose = () => {},
  onChange = () => {},
  menu,
  children,
}: ContextMenuProps) => {
  const contextMenuWrapper = useRef<any>(null);
  const contextMenuContainer = useRef<any>(null);

  const setCalculatedCoordinates = (
    e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>
  ) => {
    // if (e.button === 0 || e.button === 2) {
    // Get on click Coordinates

    const bounds = (e.target as any).getBoundingClientRect();
    contextMenuContainer.current.style.top = `${e.clientY - bounds.top - 23}px`;
    contextMenuContainer.current.style.left = `${
      e.clientX - bounds.left + 19
    }px`;
    // }
  };

  useEffect(() => {
    const handleContextmenu = (
      e: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>
    ) => {
      e.preventDefault();
    };
    contextMenuWrapper.current.addEventListener(
      "contextmenu",
      handleContextmenu
    );
  }, []);

  useEffect(() => {
    onChange(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div
      className={`context-menu-wrapper ${open ? "active" : ""}`}
      onPointerUp={setCalculatedCoordinates}
    >
      {children || null}
      <div
        className={`context-menu-layover ${open ? "active" : ""}`}
        ref={contextMenuWrapper}
        onPointerUp={() => {
          onClose();
        }}
      >
        <div className="context-menu-container" ref={contextMenuContainer}>
          <Menu {...menu} />
        </div>
      </div>
    </div>
  );
};
