import React, { useMemo } from "react";
import { Button, Dropdown, MenuProps } from "antd";
import variantImage from "assets/images/variant.png";
import { Product } from "../../../types";

interface ProductModalItemProps {
  sku: string;
  name?: string;
  status?: string;
  thumbnail?: string;
  onClick?: () => void;
  variants?: Product["variants"];
  disabled?: boolean;
}

export const ProductModalItem = ({
  sku,
  name = "",
  status = "",
  thumbnail = "",
  onClick = () => {},
  variants = [],
  disabled = false,
}: ProductModalItemProps) => {
  const items: MenuProps["items"] = useMemo(
    () => variants.map((variant) => ({ key: variant.id, label: variant.name })),
    [variants]
  );

  return (
    <div className="product-item">
      <div className="product-thumbnail-container">
        <img src={thumbnail} alt="product-model" />
        <Button
          disabled={disabled}
          type="primary"
          className="add-item-button"
          onClick={onClick}
        >
          ADD TO SHOWROOM
        </Button>
      </div>
      {!!variants.length && (
        <Dropdown
          menu={{
            items,
            selectable: true,
            onClick: ({ key }) => {
              console.log(key);
            },
          }}
          trigger={["click"]}
        >
          <Button type="link" className="variant-button">
            <img
              src={variantImage}
              alt="variant-icon"
              style={{ marginRight: "5px" }}
            />
            Choose Variant
          </Button>
        </Dropdown>
      )}
      <div className="product-details">
        <p>
          <u>{name}</u>
        </p>
        <p>Status: {status}</p>
        <p>Usage: 1x</p>
        <p>
          Sku: <i>{sku}</i>
        </p>
      </div>
    </div>
  );
};
