import React, { useCallback, useEffect, useRef } from "react";
import { Button, Descriptions, List, Popover } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useProductsContext } from "../../contexts/ProductsContext";
import { HistoryItem } from "../../contexts/ProductsHistoryContext";
import { ProductPlacement } from "../../types";

export const ProductsHistoryList = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    isLoading,
    historyStake,
    stakePointerIndex,
    activeHistoryItem,
    goBackwardAction,
    goForwardAction,
    enableBackward,
    enableForward,
  } = useProductsContext();

  const focusActiveState = useCallback(() => {
    if (activeHistoryItem) {
      const ul =
        containerRef.current?.getElementsByClassName("ant-list-items")[0];
      if (ul) {
        const li = ul.getElementsByClassName(
          `item-${activeHistoryItem.uuid}`
        )[0];
        if (li) li.scrollIntoView(false);
      }
    }
  }, [activeHistoryItem]);

  const generateHistoryChanges = useCallback(
    (
      { transition: { action, data }, stateBefore, stateAfter }: HistoryItem,
      index: number
    ) => {
      const changes = [];
      const actionTranslated = action === "UPDATE" ? "MOVE" : action;
      let text = actionTranslated;
      let content = null;
      const iterationParams: (keyof ProductPlacement["position"])[] = [
        "x",
        "y",
        "z",
      ];
      iterationParams.forEach((param) => {
        if (data?.position && data.position[param]) {
          changes.push({
            before: stateBefore?.placement.position[param],
            after: stateAfter?.placement.position[param],
            label: param.toLocaleUpperCase(),
          });
        }
      });

      if (data?.rotation?.y) {
        changes.push({
          before: stateBefore?.placement.rotation.y,
          after: stateAfter?.placement.rotation.y,
          label: "R",
        });
      }

      if (changes.length) {
        text = `${text} : ${changes.map((item) => item.label).join(" | ")}`;
        content = (
          <Descriptions column={{ xs: 1, sm: 1, md: 1 }} size="small" bordered>
            {changes.map((item, index) => (
              <Descriptions.Item key={index} label={item.label}>
                <span className="history-item-description-item">
                  <span className="timeline">Before:</span> {item.before}
                </span>
                <span className="history-item-description-item">
                  <span className="timeline">After:</span> {item.after}
                </span>
              </Descriptions.Item>
            ))}
          </Descriptions>
        );
      }

      return (
        <>
          {text}
          {content && (
            <Popover
              overlayClassName="history-item-description-popover"
              content={content}
              title={`${actionTranslated}: #${index}`}
            >
              <InfoCircleOutlined />
            </Popover>
          )}
        </>
      );
    },
    []
  );

  useEffect(() => {
    focusActiveState();
  }, [focusActiveState]);

  return (
    <div className="products-history" ref={containerRef}>
      <List
        size="small"
        header={
          <Button style={{ padding: 0 }} type="text" onClick={focusActiveState}>
            #History
          </Button>
        }
        footer={
          <>
            <Button
              size="small"
              onClick={() => {
                goBackwardAction();
              }}
              disabled={!enableBackward || isLoading}
            >
              <ArrowLeftOutlined /> Go back
            </Button>
            <Button
              size="small"
              onClick={() => {
                goForwardAction();
              }}
              disabled={!enableForward || isLoading}
            >
              Go forward <ArrowRightOutlined />
            </Button>
          </>
        }
        loading={isLoading}
        bordered
        dataSource={historyStake}
        renderItem={(item, index) => (
          <List.Item
            className={`item-${item.uuid} ${
              index === stakePointerIndex ? "active" : ""
            }`}
          >
            <>
              {index === stakePointerIndex && <ArrowRightOutlined />}#{index}{" "}
              {generateHistoryChanges(item, index)}
            </>
          </List.Item>
        )}
      />
    </div>
  );
};
