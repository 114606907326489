import React, { Component } from "react";

class FullAreaSpinner extends Component {
  state = {};
  render() {
    return (
      <div className="bitreel-fullarea-spinner">
        <div className="loader">
          <div className="spinner"></div>
          <h3 style={{ color: this.props.textColor }}>
            {this.props.loadingMessage}
          </h3>
        </div>
      </div>
    );
  }
}

export default FullAreaSpinner;
