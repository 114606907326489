import React, { useMemo } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { AdvancedProductCreator } from "./advancedProductCreator";
import { AdvancedProductCreator as AdvancedProductCreatorNew } from "./advancedProductCreatorNew";
import { PreviewProduct } from "./previewProduct";

export const ProductNewModule = () => {
  const { companyId } = useParams();
  const defaultProductPath = useMemo(() => {
    return `/${companyId}/products/`;
  }, [companyId]);

  return (
    <Routes>
      <Route
        element={<AdvancedProductCreator />}
        path={`:orderId/advanced-product-creator/edit/:itemCollectionId`}
      />
      <Route
        element={<AdvancedProductCreator />}
        path={`:orderId/advanced-product-creator/create`}
      />
      <Route
        element={<AdvancedProductCreatorNew />}
        path={`:orderId/advanced-product-creator-new/edit/:itemCollectionId`}
      />
      <Route
        element={<AdvancedProductCreatorNew />}
        path={`:orderId/advanced-product-creator-new/create`}
      />
      <Route
        element={<PreviewProduct />}
        path={`previewProduct/:itemCollectionId`}
      />
      <Route
        element={<Navigate to={defaultProductPath} replace />}
        path={`*`}
      />
    </Routes>
  );
};

export default ProductNewModule;
