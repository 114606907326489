import React, { useMemo, useCallback, useState, ReactElement } from "react";
import {
  Button,
  Dropdown,
  Menu,
  MenuProps,
  Spin,
  Modal,
  Input,
  Form,
} from "antd";
import { MoreOutlined, CopyOutlined, StarOutlined } from "@ant-design/icons";
import { getParamsFromPathname, createMenuItem } from "utils";
import { Showroom, ShowroomEntity } from "services/types";
import ApiService from "services/ApiService";
import { getAllShells } from "store/actions/shells";
import { getAllShowrooms } from "store/actions/showrooms";
import { useAppDispatch, useAppSelector } from "hooks";

export type SelectedShellArguments = {
  showroom: Showroom;
  shell: ShowroomEntity.Shell;
  shell_version: any;
};
interface SpacesMenuProps {
  selectedValue?: string;
  onSelect?: ({ showroom, shell }: SelectedShellArguments) => void;
}

export const SpacesMenu = ({ onSelect, selectedValue }: SpacesMenuProps) => {
  const dispatch = useAppDispatch();
  const { companyIdInUrl } = getParamsFromPathname(window.location.pathname);
  // const { isLoadingAllShells } = useAppSelector(
  //   (state: any) =>
  //     state.shells as { isLoadingAllShells: boolean; shells: Shell[] }
  // );
  const { showrooms, isLoadingAllShowrooms } = useAppSelector((state: any) => ({
    isLoadingAllShowrooms: state.showrooms.isLoadingAllShowrooms,
    showrooms: state.showrooms.showroomsInfo.filter(
      (showroom: Showroom) => `${showroom.company_id}` === `${companyIdInUrl}`
    ),
  }));

  /************** Handle Action ****************/
  const handleOnSelect = useCallback(
    ({ showroom, shell, shell_version }: SelectedShellArguments) => {
      if (onSelect) onSelect({ showroom, shell, shell_version });
    },
    [onSelect]
  );
  const handleMakeMaster = useCallback(
    async ({
      shell,
      shell_version,
    }: {
      shell: ShowroomEntity.Shell;
      shell_version: ShowroomEntity.ShellVersion;
    }) => {
      // @ts-ignore
      await ApiService.makeMasterShellVersion({
        shell_id: shell.id,
        shell_version_id: shell_version.id,
      });
      const getAllShellsAction = getAllShells();
      const getAllShowroomsAction = getAllShowrooms();
      await Promise.all([
        getAllShellsAction(dispatch),
        getAllShowroomsAction(dispatch),
      ]);
    },
    [dispatch]
  );
  const handleCloneShell = useCallback(
    async ({
      showroom,
      shell,
      shell_version,
      cloneName,
    }: {
      showroom: Showroom;
      shell: ShowroomEntity.Shell;
      shell_version: ShowroomEntity.ShellVersion;
      cloneName: string;
    }) => {
      try {
        // Call createShellVersion API
        // @ts-ignore
        const response = await ApiService.createShellVersion({
          shell_id: shell.id,
          copy_from_shell_version_id: shell_version.id,
        });

        const newShellVersionId = response.data.id;

        // Call associateShellVersionToShowroom API
        // @ts-ignore
        await ApiService.associateShellVersionToShowroom({
          showroomId: showroom.id,
          shellVersionId: newShellVersionId,
          data: {
            name: cloneName,
            should_include: false,
          },
        });

        // Fetch updated data
        const getAllShellsAction = getAllShells();
        const getAllShowroomsAction = getAllShowrooms();
        await Promise.all([
          getAllShellsAction(dispatch),
          getAllShowroomsAction(dispatch),
        ]);
      } catch (error) {
        console.error("Error in handleCloneShell:", error);
      }
    },
    [dispatch]
  );
  /********************************************/

  /************ Modal Logic *******************/
  const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);
  const [cloneName, setCloneName] = useState("");
  const [selectedShell, setSelectedShell] = useState<any>(null);
  const [selectedShellVersion, setSelectedShellVersion] = useState<any>(null);
  const [selectedShowroom, setSelectedShowroom] = useState<any>(null); // Added showroom state

  const openCloneModal = useCallback(
    ({
      showroom,
      shell,
      shell_version,
    }: {
      showroom: Showroom;
      shell: ShowroomEntity.Shell;
      shell_version: ShowroomEntity.ShellVersion;
    }) => {
      setSelectedShell(shell);
      setSelectedShellVersion(shell_version);
      setSelectedShowroom(showroom); // Save showroom
      setIsCloneModalVisible(true);
    },
    []
  );

  const handleCloneConfirm = useCallback(() => {
    if (selectedShell && selectedShellVersion && selectedShowroom) {
      handleCloneShell({
        showroom: selectedShowroom,
        shell: selectedShell,
        shell_version: selectedShellVersion,
        cloneName,
      });
      setIsCloneModalVisible(false);
      setCloneName("");
    }
  }, [
    selectedShell,
    selectedShellVersion,
    selectedShowroom,
    cloneName,
    handleCloneShell,
  ]);

  const handleCloneCancel = () => {
    setIsCloneModalVisible(false);
    setCloneName("");
  };
  /********************************************/

  /**************** Options *******************/
  const computedItems = useMemo(
    () =>
      showrooms.map((showroom: Showroom) =>
        createMenuItem({
          label: showroom.name,
          key: showroom.uuid,
          children: Object.values(showroom.shells).map((shell) =>
            createMenuItem({
              label: shell.name,
              key: `shell-${shell.id}`,
              children: Object.values(shell.shell_versions).map(
                (shell_version) =>
                  createMenuItem({
                    label: (
                      <SpaceMenuLabel
                        disabledOptions={{
                          "make-master": shell_version.is_master,
                        }}
                        onAction={({ action }) => {
                          switch (action) {
                            case "make-master":
                              handleMakeMaster({ shell, shell_version });
                              break;
                            case "clone-shell-version":
                              openCloneModal({
                                showroom,
                                shell,
                                shell_version,
                              });
                              break;
                          }
                        }}
                      >
                        <>
                          ( V.{shell_version.version_number} ) -{" "}
                          {shell_version.showroom_shell.name}
                        </>
                      </SpaceMenuLabel>
                    ),
                    icon: shell_version.is_master ? (
                      <StarOutlined className="master-icon" />
                    ) : null,
                    key: `shell_version-${shell_version.id}`,
                    onClick: () => {
                      handleOnSelect({
                        showroom,
                        shell,
                        shell_version: shell_version,
                      });
                    },
                  })
              ),
            })
          ),
        })
      ),
    [handleCloneShell, handleMakeMaster, handleOnSelect, showrooms]
  );
  /********************************************/

  return (
    <>
      <Spin spinning={isLoadingAllShowrooms} tip="Loading...">
        <Menu
          mode="inline"
          selectedKeys={
            selectedValue ? [`shell_version-${selectedValue}`] : undefined
          }
          items={computedItems}
        />
      </Spin>
      <Modal
        title="Clone Space Version"
        open={isCloneModalVisible}
        onOk={handleCloneConfirm}
        onCancel={handleCloneCancel}
        centered
      >
        <Form>
          <Form.Item label="Name">
            <Input
              value={cloneName}
              onChange={(e) => setCloneName(e.target.value)}
              placeholder="Enter clone name"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

interface SpaceMenuLabelProps {
  children?: ReactElement | string | null;
  disabledOptions?: { [key: string]: boolean };
  onAction?: ({
    action,
  }: {
    action: "make-master" | "clone-shell-version";
  }) => void;
}

const SpaceMenuLabel = ({
  children = null,
  disabledOptions = {},
  onAction = () => {},
}: SpaceMenuLabelProps) => {
  const items: MenuProps["items"] = [
    {
      label: "Make It Master",
      key: "make-master",
      icon: <StarOutlined />,
      onClick: () => {
        onAction({ action: "make-master" });
      },
      disabled: !!disabledOptions["make-master"],
    },
    {
      label: "Clone Space Version",
      key: "clone-shell-version",
      icon: <CopyOutlined />,
      onClick: () => {
        onAction({ action: "clone-shell-version" });
      },
      disabled: !!disabledOptions["clone-shell-version"],
    },
  ];
  return (
    <div className="menu-item-label">
      <span>{children}</span>
      <Dropdown
        menu={{
          items,
          onClick: ({ domEvent }) => {
            domEvent.stopPropagation();
          },
        }}
      >
        <Button
          shape="circle"
          type="link"
          icon={<MoreOutlined />}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Dropdown>
    </div>
  );
};
