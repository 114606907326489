import React, { useMemo, useState } from "react";
import { Image, Modal } from "antd";
import { FilesDropZone } from "./FilesDropZone";

interface ImageMultipleUploaderModalProps {
  isOpen: boolean;
  images?: string[];
  onClose: () => void;
  onSubmit?: (images: string[]) => void;
}

export const ImageMultipleUploaderModal = ({
  isOpen,
  images = [],
  onClose,
  onSubmit = () => {},
}: ImageMultipleUploaderModalProps) => {
  const [imagesUrl, setImagesUrl] = useState<string[]>([]);
  const allImages = useMemo(
    () => [...images, ...imagesUrl],
    [images, imagesUrl]
  );

  return (
    <Modal
      open={isOpen}
      width="auto"
      onCancel={onClose}
      onOk={() => {
        onSubmit(imagesUrl);
      }}
      centered
      className="image-multiple-uploader-modal"
    >
      <div className="images-group">
        {allImages && (
          <Image.PreviewGroup>
            {allImages.map((url, index) => (
              <Image key={index} width={178} height={178} src={url} />
            ))}
          </Image.PreviewGroup>
        )}
      </div>
      <FilesDropZone
        multiple
        onChange={({ urls }) => {
          setImagesUrl(urls);
        }}
        accept=".jpg,.jpeg,.png"
      />
    </Modal>
  );
};
