import ApiService from "services/ApiService";
import {
  SET_LIBRARY_MATERIALS,
  UPDATE_LIBRARY_MATERIAL,
  SET_LIBRARY_MATERIAL_CATEGORIES,
  UPDATE_LIBRARY_MATERIAL_CATEGORY,
} from "sharedConstants";

export const setLibraryMaterials = (materials) => {
  return {
    type: SET_LIBRARY_MATERIALS,
    materials,
  };
};

export const updateLibraryMaterial = (materialData) => {
  return {
    type: UPDATE_LIBRARY_MATERIAL,
    materialData,
  };
};

export const setLibraryMaterialCategories = (materialCategory) => {
  return {
    type: SET_LIBRARY_MATERIAL_CATEGORIES,
    materialCategory,
  };
};

export const updateLibraryMaterialCategory = (materialCategoryData) => {
  return {
    type: UPDATE_LIBRARY_MATERIAL_CATEGORY,
    materialCategoryData,
  };
};

export const patchLibraryMaterial =
  (materialId, materialData) => async (dispatch) => {
    try {
      const response = await ApiService.patchMaterial(materialId, materialData);
      dispatch(updateLibraryMaterial(response.data));
    } catch (e) {
      /* empty */
    }
  };

export const getLibraryMaterials = (companyId) => async (dispatch) => {
  let materialsRes = await ApiService.getLibraryMaterials(companyId);
  let final = {};
  materialsRes.data.forEach((material) => {
    final[material.id] = material;
  });
  await dispatch(setLibraryMaterials(final));
  return final;
};

export const getLibraryMaterialCategories = (companyId) => async (dispatch) => {
  try {
    let materialCategoryRes = await ApiService.getLibraryMaterialCategories(
      companyId
    );
    // Directly dispatch the array of material categories
    await dispatch(setLibraryMaterialCategories(materialCategoryRes.data));
  } catch (error) {
    console.error("Error fetching material categories:", error);
  }
};

/*export const getLibraryMaterialCategories = (companyId) => async (dispatch) => {
  let materialCategoryRes = await ApiService.getLibraryMaterialCategories(
    companyId
  );
  let final = {};
  materialCategoryRes.data.forEach((materialCategory) => {
    final[materialCategory.id] = materialCategory;
  });
  await dispatch(setLibraryMaterialCategories(final));
  return final;
};*/

export const patchLibraryMaterialCategory =
  (materialCategoryId, materialCategoryData) => async (dispatch) => {
    try {
      const response = await ApiService.patchMaterialCategories(
        materialCategoryId,
        materialCategoryData
      );
      dispatch(updateLibraryMaterialCategory(response.data));
    } catch (e) {
      /* empty */
    }
  };

export const uploadMaterialThumbnail =
  (fileData, url, fields) => async (dispatch) => {
    try {
      const uploadFormData = new FormData();

      Object.keys(fields).forEach((key) => {
        uploadFormData.append(key, fields[key]);
      });
      uploadFormData.append("file", fileData);
      const s3UploadResponse = await ApiService.postToS3(url, uploadFormData);

      // let ext = fileData.name.split(".").pop();
      //   dispatch(addToItemFileLocker(itemId, fileType, {
      //       id: s3UrlResponse.data.id,
      //       url: URL.createObjectURL(fileData),
      //       file_name: fileData.name,
      //       localImageUploadBlob: imageExtensions.includes(ext)
      //   }));

      return s3UploadResponse.data;
    } catch (error) {
      console.warn(error);
    }
  };
