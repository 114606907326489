import React, { Component } from "react";
import { Routes, Route } from "react-router";
import { withRouter } from "utils/withRouter";
import CollectionList from "./screens/list";
import ProductDetail from "./screens/detail";
import { connect } from "react-redux";
import { addOrder } from "store/actions/app";
import { getParamsFromPathname } from "utils";
import { history } from "services/history";
import { notification } from "antd";
class ProductModule extends Component {
  state = {};

  handleSelectedOrderUpdate = () => {
    let { companyIdInUrl, orderIdInUrl } = getParamsFromPathname(
      window.location.pathname
    );

    let keys = Object.keys(this.props.companies[companyIdInUrl].orders);
    if (orderIdInUrl) {
      this.props.addOrder(orderIdInUrl);
    } else {
      if (!keys.length) {
        if (!this.props.app.modalStates.createOrder.visible) {
          notification.error({
            message: "Company Has No Orders",
            duration: 1000,
          });
        }
      } else {
        this.props.addOrder(
          this.props.companies[companyIdInUrl].orders[keys[0]].id,
          true
        );
      }
    }
  };

  componentDidMount() {
    this.handleSelectedOrderUpdate();
  }

  componentDidUpdate = () => {
    let { orderIdInUrl } = getParamsFromPathname(window.location.pathname);
    if (!orderIdInUrl) {
      this.handleSelectedOrderUpdate();
    }
  };

  render() {
    let path = "";
    return (
      <Routes>
        <Route
          element={<ProductDetail />}
          path={`${path}/:orderId/:itemCollectionId/:itemId/:versionId/:variantId`}
          exact
        />
        <Route
          element={<ProductDetail />}
          path={`${path}/:orderId/:itemCollectionId/:itemId/:versionId`}
          exact
        />
        <Route
          element={<ProductDetail />}
          path={`${path}/:orderId/:itemCollectionId/:itemId`}
          exact
        />
        <Route
          element={<ProductDetail />}
          path={`${path}/:orderId/:itemCollectionId`}
          exact
        />
        <Route element={<CollectionList />} path={`${path}/:orderId`} exact />
        <Route element={<CollectionList />} path={`${path}/`} exact />
      </Routes>
    );
  }
}

const mapStateToProps = ({ app, companies, itemCollection }) => {
  const { companyIdInUrl, orderIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  if (!companies[companyIdInUrl].orders[orderIdInUrl]) {
    history.push(`/${companyIdInUrl}/products`);
  }
  return {
    app,
    selectedCompany: companies[app.selectedCompanyId],
    selectedOrderId: app.selectedOrderId,
    itemCollection,
    moduleState: app.moduleStates.product,
    companies,
  };
};

export default connect(mapStateToProps, {
  addOrder,
})(withRouter(ProductModule));
