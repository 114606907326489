import _ from "lodash";

export interface TaskSession {
  start: () => void;
  end: () => void;
}

export type TaskWrapperType = <T extends Array<any>, U>(
  fn: (...args: T) => Promise<U>
) => (...args: T) => Promise<U> | void;

export class Tasks {
  private __tasks: { [key: string]: string };
  private __callbackOnStart?: (tasksLength: number) => void;
  private __callbackOnEnd?: (tasksLength: number) => void;

  constructor() {
    this.__tasks = {};
  }

  public createTaskSession(): TaskSession {
    const sessionId = _.uniqueId("task-");
    return {
      start: () => {
        this.__tasks[sessionId] = sessionId;
        if (this.__callbackOnStart) this.__callbackOnStart(this.countTasks());
      },
      end: () => {
        delete this.__tasks[sessionId];
        if (this.__callbackOnEnd) this.__callbackOnEnd(this.countTasks());
      },
    };
  }

  public countTasks() {
    return Object.keys(this.__tasks).length;
  }

  public on(
    method: "start-task" | "end-task",
    callback: (tasksLength: number) => void
  ) {
    switch (method) {
      case "start-task":
        this.__callbackOnStart = callback;
        break;
      case "end-task":
        this.__callbackOnEnd = callback;
        break;
    }
  }

  public taskWrapper<T extends Array<any>, U>(
    fn: (...args: T) => Promise<U>
  ): (...args: T) => Promise<U> {
    return async (...args) => {
      const task = this.createTaskSession();
      task.start();
      try {
        const res = await fn(...args);
        task.end();
        return res;
      } catch (error) {
        task.end();
        throw error;
      }
    };
  }
}
