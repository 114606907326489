import React, { Component } from "react";
import { Link } from "react-router-dom";
import Screen from "components/Screen";
import { connect } from "react-redux";
import OrderSelector from "components/OrderSelecter";
import "./list.scss";
import ItemCollectionTable from "components/ItemCollectionTable";
import { getParamsFromPathname } from "utils";
import { setModalState, setProductFilter } from "store/actions/app";
import _ from "lodash";
import { Button, Checkbox, Select } from "antd";
import CreateOrder from "../modals/createOrder";
import CreateProduct from "../modals/createProduct";
import CreateVariant from "../modals/createVariant";
import CreateLayouts from "../modals/createLayouts";
import CreateVariantAdvanced from "../modals/createVariantAdvanced";
class ProductList extends Component {
  renderVariantSearchField = (filterName, filterConfig) => {
    switch (filterConfig.filterType) {
      case "text":
        return (
          <div
            className="bitreel-table-filter"
            key={`filter-input-${filterConfig.type}-${filterName}`}
          >
            <label>{filterConfig.label}</label>
            <input
              value={filterConfig.value}
              onChange={(e) =>
                this.props.setProductFilter(filterName, e.target.value)
              }
            />
          </div>
        );
      case "select":
        return (
          <div
            className="bitreel-table-filter"
            key={`filter-input-${filterConfig.type}-${filterName}`}
          >
            <label>{filterConfig.label}</label>
            <Select
              onChange={(v) => {
                this.props.setProductFilter(filterName, v);
              }}
              value={filterConfig.value}
              style={{ width: "200px" }}
              options={this.props.statuses
                .map((s) => {
                  return {
                    label: s.name,
                    value: s.id,
                  };
                })
                .concat([{ label: "Show All", value: -1 }])}
            />
          </div>
        );
      default:
        return null;
    }
  };

  filterProducts = (products) => {
    let localFiltered = _.filter(products, (p) => {
      let keep = true;
      _.forEach(this.props.productFilters, (filterObj, key) => {
        switch (filterObj.type) {
          case "text":
            if (
              keep &&
              p[key] &&
              p[key].toLowerCase().indexOf(filterObj.value.toLowerCase()) === -1
            ) {
              keep = false;
            }
            break;
          case "status":
            if (
              keep &&
              p.status !== filterObj.value &&
              filterObj.value !== -1
            ) {
              keep = false;
            }
            break;
          case "boolean":
            if (keep && p.archived !== filterObj.value) {
              keep = false;
            }
            break;
          default:
            break;
        }
      });
      return keep;
    });
    if (
      Object.keys(this.props.productModuleState.latestVariantResponse).length
    ) {
      localFiltered = _.filter(localFiltered, (p) => {
        return this.props.productModuleState.latestVariantResponse[p.id];
      });
    }
    return localFiltered;
  };

  renderFilters = () => {
    let i = -1;
    let filters = _.map(this.props.productFilters, (filterObj, key) => {
      i++;
      switch (filterObj.type) {
        case "variantSearch":
          return this.renderVariantSearchField(key, filterObj);
        case "text":
          return (
            <div className="bitreel-table-filter">
              <label>{filterObj.label}</label>
              <input
                value={filterObj.value}
                key={`filter-input-${i}`}
                onChange={(e) =>
                  this.props.setProductFilter(key, e.target.value)
                }
              />
            </div>
          );
        case "status":
          return (
            <div className="bitreel-table-filter" key={`status-input-${i}`}>
              <label>{filterObj.label}</label>
              <Select
                onChange={(v) => {
                  this.props.setProductFilter(key, v);
                }}
                value={filterObj.value}
                style={{ width: "200px" }}
                options={[
                  {
                    label: "Created",
                    value: "Created",
                  },
                  {
                    label: "In Progress",
                    value: "In Progress",
                  },
                  {
                    label: "Finalized",
                    value: "Finalized",
                  },
                  {
                    label: "Show All",
                    value: -1,
                  },
                ]}
              />
            </div>
          );
        case "boolean":
          return (
            <div className="bitreel-table-filter" key={`archive-input-${i}`}>
              <label>{filterObj.label}</label>
              <Checkbox
                checked={filterObj.value}
                onClick={(v) => {
                  this.props.setProductFilter(key, !filterObj.value);
                }}
              />
            </div>
          );
        default:
          break;
      }
    });
    return <div className="bitreel-filter-section">{filters}</div>;
  };

  render() {
    if (this.props.loading) {
      return (
        <Screen className="products-list">
          {this.props.profile.admin && (
            <div
              className="orders-menu"
              onClick={(e) => {
                e.preventDefault();
                this.props.setModalState("createOrder", { visible: true });
              }}
            >
              Create Order
            </div>
          )}
          <CreateOrder />
        </Screen>
      );
    }
    let products = this.filterProducts(this.props.itemCollection);
    return (
      <Screen className="products-list">
        <div className="orders-menu">
          <OrderSelector />
          <div className="product-actions-group">
            {this.props.profile.admin && (
              <Button
                block
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.setModalState("createOrder", { visible: true });
                }}
              >
                Create Order
              </Button>
            )}
            <Button
              block
              size="small"
              onClick={(e) => {
                e.preventDefault();
                this.props.setModalState("createItemSimple", { visible: true });
              }}
            >
              Add Item
            </Button>
            <Link
              to={`/${this.props.companyIdInUrl}/products-new/${this.props.orderIdInUrl}/advanced-product-creator/create`}
              style={{ width: "100%" }}
            >
              <Button
                className="new-advance-product-creator"
                size="small"
                block
              >
                Create Product
              </Button>
            </Link>
          </div>
        </div>
        <div className="table-container">
          {this.renderFilters()}
          <ItemCollectionTable
            loading={!Object.keys(this.props.itemCollection).length}
            data={products}
          />
        </div>
        <CreateOrder key="create-order-modal" />
        <CreateProduct key="create-product-modal" />
        <CreateVariant key="create-variant-modal" />
        <CreateVariantAdvanced key="create-variant-advanced-modal" />
        <CreateLayouts key="create-layout-modal" />
      </Screen>
    );
  }
}

const mapStateToProps = ({
  profile,
  app: {
    productFilters,
    moduleStates: { product: productModuleState },
  },
  companies,
  statuses,
}) => {
  let { orderIdInUrl, companyIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  let loading = !orderIdInUrl || !companyIdInUrl;
  if (loading) {
    return { loading, profile };
  } else {
    return {
      itemCollection:
        (companyIdInUrl &&
          companies[companyIdInUrl] &&
          companies[companyIdInUrl].orders[orderIdInUrl] &&
          companies[companyIdInUrl].orders[orderIdInUrl].item_collections) ||
        {},
      companyIdInUrl,
      orderIdInUrl,
      productFilters,
      statuses: statuses.itemStatuses,
      productModuleState,
      profile,
    };
  }
};

export default connect(mapStateToProps, {
  setProductFilter,
  setModalState,
})(ProductList);
