import { ItemCollectionConfigurationPublic } from "services/types";
import { searchVariantInOrder } from "services/variant";
import { Product, ProductPlacement } from "../types";

export const getProductPlacement = (productPlacementApi: {
  [key: string]: any;
}): ProductPlacement => ({
  id: productPlacementApi.id,
  productId: productPlacementApi.variant_id,
  variantId: productPlacementApi.variant_id,
  itemCollectionId: productPlacementApi.item_collection_id,
  isProps: productPlacementApi.is_prop,
  position: {
    x: productPlacementApi.position_x,
    y: productPlacementApi.position_y,
    z: productPlacementApi.position_z,
  },
  rotation: {
    x: productPlacementApi.rotation_x,
    y: productPlacementApi.rotation_y,
    z: productPlacementApi.rotation_z,
  },
});

export const getApiProductPlacement = (productPlacement: ProductPlacement) => ({
  position_x: productPlacement.position.x || undefined,
  position_y: productPlacement.position.y || undefined,
  position_z: productPlacement.position.z || undefined,
  rotation_y: productPlacement.rotation.y || undefined,
});

export const getProductsPlacement = (
  productsPlacementApi: any[]
): ProductPlacement[] => productsPlacementApi.map(getProductPlacement);

export const getProduct = (
  productApi: ItemCollectionConfigurationPublic,
  {
    companyId,
    orderId,
  }: { companyId: number | string; orderId: number | string }
): Product => {
  const variantDetailPageUrl = `/${companyId}/products/${orderId}/${productApi.id}/${productApi.default_variant.item_id}/${productApi.default_variant.item_version_id}/${productApi.default_variant.id}`;
  return {
    id: productApi.id,
    item_collection_id: productApi.default_variant.item_collection_id,
    sku: "",
    name: productApi.name,
    status: "approved",
    thumbnail: "",
    variant_detail_url: variantDetailPageUrl,
    variants: productApi.default_variant.default_parts.map(
      ({ id, name, external_url }) => ({
        id,
        name,
        model_url: external_url
          ? `${external_url}/lod0_with_textures/mesh.gltf`
          : "https://testasset.s3.amazonaws.com/example-products/product-example.glb", // This shouldn't happen
      })
    ),
    data: productApi,
  };
};

export const searchProducts = async (
  orderId: number | string | undefined,
  payload: any,
  searchTextDebounced: string
) => {
  const res = await searchVariantInOrder(
    orderId,
    "?page=1&per_page=1000" +
      (searchTextDebounced ? `&name=${searchTextDebounced}` : "")
  );
  const productWithThumbnails: Product[] = res.data.map((_product: any) => {
    const product: Product = {
      id: _product.id,
      item_collection_id: _product.item_collection_id,
      sku: _product.sub_sku,
      name: _product.name,
      status: _product.status,
      thumbnail: _product.thumbnail_url
        ? _product.thumbnail_url + "512.png"
        : "",
      variants: [],
      variant_detail_url: "",
      data: _product,
    };
    return product;
  });

  return productWithThumbnails;
};
