import React from "react";
import { Typography } from "antd";
import { RobotOutlined } from "@ant-design/icons";
import { DetailSection } from "modules/showrooms/components/DetailSection";
const { Title } = Typography;

export const AIGeneratedImages = () => (
  <DetailSection
    title="AI generated Images"
    description={
      <>
        Images generated by AI
        <i>(max 5 images generated per Room)</i>
      </>
    }
    divider={false}
    disabled
  >
    <Title style={{ textAlign: "center", marginTop: "70px" }}>
      <RobotOutlined /> Coming soon...
    </Title>
  </DetailSection>
);
