import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Use this for old reducers that have been used connect()
export const useAppDispatchWrapper = () => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (data: ((_dispatch: AppDispatch) => Promise<any>) | any) => {
    if (typeof data === "function") {
      return await data(dispatch);
    } else {
      return dispatch(data);
    }
  };
};
