import { Form, Input, message, Modal, Select } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React, { Component } from "react";
import { LuAsterisk } from "react-icons/lu";
import { connect } from "react-redux";
import { withRouter, getParamsFromPathname } from "utils";
import CloudUpload from "assets/svg/CloudUpload";
import { setModalState } from "store/actions/app";
import { uploadMaterialThumbnail } from "store/actions/materials";
import ApiService from "services/ApiService";
import { getLibraryMaterialCategories } from "store/actions/materials";

const { Option } = Select;

class NewMaterial extends Component {
  state = {
    categories: [],
  };

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.uploadRef = React.createRef();
  }

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories = async () => {
    try {
      const res = await ApiService.getLibraryMaterialCategories(
        this.props.companyIdInUrl
      );
      this.setState({ categories: res.data });
    } catch (error) {
      console.error("Failed to fetch categories", error);
      message.error("Failed to fetch categories");
    }
  };

  onSubmit = async () => {
    try {
      const uploadData = this.uploadRef.current.getFieldsValue();
      let uploadError = false;
      let errorStr = "";
      if (uploadError) {
        message.error(errorStr);
      }
      const detailValues = await this.formRef.current.validateFields();
      let matRes = await ApiService.postMaterial({
        name: detailValues.name,
        price_mod: detailValues.price_mod,
      });

      const patchPayload = {
        library: true,
        company_id: Number(this.props.companyIdInUrl),
      };

      let patchRes = await ApiService.patchMaterial(
        matRes.data.id,
        patchPayload
      );
      await ApiService.postMaterialCategoryAssociation({
        material_id: matRes.data.id,
        material_category_id: detailValues.categories,
      });
      this.props.setModalState("createLibraryMaterial", { visible: false });
      await this.props.uploadMaterialThumbnail(
        uploadData.thumbnail.file,
        matRes.data.post_url.url,
        matRes.data.post_url.fields
      );

      // Assuming material category ID is selected and available in `detailValues.categories`
    } catch (error) {
      console.warn(error);
    } finally {
      window.location.reload();
    }
  };

  render() {
    const { categories } = this.state;

    return (
      <Modal
        onCancel={() =>
          this.props.setModalState("createLibraryMaterial", { visible: false })
        }
        open={this.props.modalState.visible}
        onOk={async () => {
          await this.onSubmit();
        }}
      >
        <div>
          <Form ref={this.formRef}>
            <h4>
              Name
              <span>
                <LuAsterisk />
              </span>
            </h4>
            <Form.Item name="name">
              <Input />
            </Form.Item>

            <h4>Default Material Price</h4>
            <Form.Item name="price_mod">
              <Input />
            </Form.Item>

            <h4>
              Material Category
              <span>
                <LuAsterisk />
              </span>
            </h4>
            <Form.Item
              name="categories"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select placeholder="Select a category">
                {categories.map((category) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Form ref={this.uploadRef}>
            <h4>
              Reference Image
              <span>
                <LuAsterisk />
              </span>
            </h4>
            <Form.Item
              name="thumbnail"
              rules={[{ required: true, message: "Please add Thumbnail" }]}
              valuePropName="file"
            >
              <Dragger
                style={{ backgroundColor: "white" }}
                name="thumbnail"
                multiple={false}
                accept=".png,.jpg"
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
              >
                <p className="ant-upload-drag-icon">
                  <CloudUpload />
                </p>
                <p className="ant-upload-text">
                  Click or drag reference file to upload
                </p>
              </Dragger>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  app: {
    modalStates: { createLibraryMaterial: modalState },
  },
}) => {
  let { companyIdInUrl } = getParamsFromPathname(window.location.pathname);
  return {
    modalState,
    companyIdInUrl,
  };
};

export default connect(mapStateToProps, {
  setModalState,
  uploadMaterialThumbnail,
})(withRouter(NewMaterial));
