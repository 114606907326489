import JSZip from "jszip";
interface ExtractedFiles {
  [relativePath: string]: Blob | any;
}

export async function downloadAndUnzip(
  url: string | null
): Promise<ExtractedFiles> {
  // Fetch the file
  if (!url) {
    return {};
  }
  const response = await fetch(url);

  // Check if the response is ok
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Get the file data as ArrayBuffer
  const fileData = await response.arrayBuffer();

  // Create a new JSZip instance and load the downloaded data
  const zip = new JSZip();
  await zip.loadAsync(fileData);

  // Extract the files
  const extractedFiles: ExtractedFiles = {};
  const fileProcessingPromises: Promise<void>[] = [];

  zip.forEach((relativePath, zipEntry) => {
    if (!zipEntry.dir) {
      const fileProcessingPromise = zipEntry.async("blob").then((blob) => {
        extractedFiles[relativePath] = blob;
      });
      fileProcessingPromises.push(fileProcessingPromise);
    }
  });

  await Promise.all(fileProcessingPromises);
  return extractedFiles;
}
