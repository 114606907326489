import { Vector3 } from "@babylonjs/core";

/* eslint-disable react/no-unknown-property */
export const BabylonFallbackLoading = ({
  progress = 0,
}: {
  progress?: number;
}) => {
  return (
    <>
      <arcRotateCamera
        name="temporary-camera-3d"
        target={Vector3.Zero()}
        alpha={Math.PI / 2}
        beta={Math.PI / 4}
        radius={60}
        disposeInstanceOnUnmount
      />
      <hemisphericLight
        name="temporary-light"
        intensity={1}
        direction={Vector3.Up()}
        disposeInstanceOnUnmount
      />
      <rectangle name="rect" height="50px" width="150px">
        <rectangle>
          <textBlock
            text={`Loading ${progress}%`}
            fontStyle="bold"
            fontSize={20}
            color="white"
          />
        </rectangle>
      </rectangle>
    </>
  );
};
