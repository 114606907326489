import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import { Card, Layout, Menu } from "antd";
import CompanyProfile from "../companyProfile";
import UserSettings from "../userSettings";
import { getParamsFromPathname, createMenuItem } from "utils";
import AccountSettings from "../account/accountSettings";
const { Sider, Content } = Layout;

class UMAdminConsole extends Component {
  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  handleMenuClick({ key }) {
    this.props.router.navigate(
      `/${
        getParamsFromPathname(window.location.pathname).companyIdInUrl
      }/settings/${key}`
    );
  }

  renderMenuItems = () => {
    const options = [];
    if (this.props.profile.admin) {
      options.push(
        createMenuItem({ key: "profile", label: "Company Profile" }),
        createMenuItem({ key: "user", label: "User Settings" })
      );
    }
    options.push(createMenuItem({ key: "account", label: "Account Settings" }));
    return options;
  };

  render() {
    return (
      <>
        <Sider width={250} className="siderMenu">
          <Card bordered={false}>
            <h2>Welcome</h2>
            <p>{this.props.auth.user.email}</p>
          </Card>
          <Menu
            mode="inline"
            selectedKeys={[window.location.pathname.split("/")[3]]}
            onClick={({ key, keyPath, domEvent }) =>
              this.handleMenuClick({ key, keyPath, domEvent })
            }
            style={{ height: "100%", borderRight: 0 }}
            items={this.renderMenuItems()}
          />
        </Sider>
        <Content className="contentRightSide">
          {window.location.pathname.split("/")[3] === "account" && (
            <AccountSettings />
          )}
          {window.location.pathname.split("/")[3] === "profile" && (
            <CompanyProfile />
          )}
          {window.location.pathname.split("/")[3] === "user" && (
            <UserSettings />
          )}
        </Content>
      </>
    );
  }
}

const mapStateToProps = ({ auth, users, companies, profile }) => {
  return {
    auth,
    users,
    companies,
    profile,
  };
};

export default connect(mapStateToProps, {})(withRouter(UMAdminConsole));
