import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import _ from "lodash";

import { Modal, Row, Col, Button } from "antd";
import { setModalState } from "store/actions/app";
import { patchVariant } from "store/actions/variant";
import { getParamsFromPathname } from "utils";
import "./createVariantAdvanced.scss";
import ProductDetailsCreate from "../../components/productDetails/productDetailsCreate";
import Assembler from "./Assembler";
import { getLibraryMaterials } from "store/actions/materials";
import ApiService from "services/ApiService";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";

class CreateVariantModal extends Component {
  constructor(props) {
    super(props);
    this.assemblerRef = React.createRef();
    this.eventAdded = false;
    this.state = {
      selectedParts: {},
      selectedMaterials: {},
      pendingPartForMaterial: null,
      variantInfo: {},
    };
  }

  componentDidMount() {
    this.props.getLibraryMaterials(this.props.companyIdInUrl);
  }

  setSelectedParts = (parts) => {
    this.setState({
      selectedParts: parts,
    });
  };

  setVariantInfo = (variantInfo) => {
    this.setState(
      {
        variantInfo: {
          ...this.state.variantInfo,
          ...variantInfo,
        },
      },
      () => {
        console.log(variantInfo);
      }
    );
  };

  onOrderFinish = async (fields) => {
    this.setState(
      {
        creatingVariant: true,
      },
      async () => {
        let variantRes = await ApiService.postVariant("TestGuy" + Date.now());
        await mapSeries(
          _.map(this.state.selectedParts, (p) => p),
          async (part, key) => {
            let materialRes = await ApiService.postMaterial(
              this.state.selectedMaterials[part.id].name + Date.now()
            );
            await ApiService.addPartToVariant(
              part.id,
              variantRes.data.id,
              materialRes.data.id
            );
          }
        );
        let { thumbnail, ...fields } = this.state.variantInfo;
        await this.props.patchVariant(variantRes.data.id, fields);
        this.props.setModalState("createVariantAdvanced", { visible: false });
        this.setState({
          creatingVariant: false,
        });
      }
    );
  };

  renderSelectedParts = () => {
    return (
      <div className="selectedParts">
        {_.map(this.state.selectedParts, (part, key) => {
          return (
            <div
              key={key}
              className="selected-part"
              onClick={() => this.setState({ pendingPartForMaterial: part.id })}
            >
              <div
                className={`part-name ${
                  this.state.selectedMaterials[part.id] ? "completed" : ""
                }`}
              >
                {part.name}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderMaterialCards = () => {
    return (
      <Modal
        className="part-material-select"
        open={this.state.pendingPartForMaterial}
        onCancel={() => this.setState({ pendingPartForMaterial: null })}
      >
        <div className="material-cards-container">
          {_.map(this.props.materials, (m, key) => {
            return (
              <div className="material-card" key={key}>
                <div className="top">
                  <h4>Material Name</h4>
                  <div>{m.name}</div>
                </div>
                <div className="bottom">
                  <div
                    className="material-image"
                    style={{ backgroundImage: `url(${m.internal_url})` }}
                  />
                </div>
                <Button
                  onClick={() =>
                    this.setState({
                      selectedMaterials: {
                        ...this.state.selectedMaterials,
                        [this.state.pendingPartForMaterial]: m,
                      },
                      pendingPartForMaterial: null,
                    })
                  }
                >
                  Select
                </Button>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  };

  render() {
    const { setModalState, isLoading, modalState } = this.props;

    return (
      <>
        <Modal
          title={"Create Variant Advanced"}
          open={modalState.visible}
          onOk={this.onOrderFinish}
          onCancel={() =>
            setModalState("createVariantAdvanced", { visible: false })
          }
          destroyOnClose={true}
          cancelText="Cancel"
          okText="Create"
          okButtonProps={{
            disabled:
              _.filter(
                this.state.selectedParts,
                (part, key) => !this.state.selectedMaterials[part.id]
              ).length > 0,
            className: "bitreel-button-primary",
          }}
          cancelButtonProps={{
            disabled: isLoading,
            className: "bitreel-button",
          }}
          closable={!isLoading}
          maskClosable={!isLoading}
          className="create-variant-advanced-modal"
        >
          {this.state.creatingVariant ? (
            <FullAreaSpinner />
          ) : (
            <Row>
              <Col span={12} key="col-1">
                {this.renderSelectedParts()}
                <Assembler
                  itemCollectionId={this.props.modalState.itemCollectionId}
                  setSelectedPartsFxn={this.setSelectedParts}
                  token={this.props.token}
                />
              </Col>
              <Col span={12} className="details-container" key="col-2">
                <ProductDetailsCreate
                  setVariantInfoFxn={this.setVariantInfo}
                  onFinishFxn={this.onOrderFinish}
                />
              </Col>
            </Row>
          )}
        </Modal>
        {this.renderMaterialCards()}
      </>
    );
  }
}

const mapSeries = async (iterable, action) => {
  for (const x of iterable) {
    await action(x);
  }
};

const mapStateToProps = (
  {
    materials,
    auth,
    app: {
      modalStates: { createVariantAdvanced: modalState },
    },
  },
  { itemCollectionId }
) => {
  let { companyIdInUrl, orderIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  return {
    modalState,
    isLoading: false,
    companyIdInUrl,
    orderIdInUrl,
    itemCollectionId,
    token: auth.token,
    materials,
  };
};

export default connect(mapStateToProps, {
  setModalState,
  getLibraryMaterials,
  patchVariant,
})(withRouter(CreateVariantModal));
