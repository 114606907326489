import { useCallback } from "react";
import ApiService from "services/ApiService";
import { getAnnotation, getComment } from "../utils/dataMapComments";
import { Comment } from "../types";
import { AnnotationType } from "../types";

export const useCommentsApi = () => {
  const apiFetchComments = useCallback(
    async (spaceVersionId: string | number, noteTypeId: number) => {
      // @ts-ignore
      const { data } = await ApiService.getShowroomShellNotes();
      const { data: dataAnnotations } =
        // @ts-ignore
        await ApiService.getShowroomShellAnnotations();

      const dataAnnotationsMapByNote: { [key: string]: any } = {};
      dataAnnotations.forEach((dataAnnotation: any) => {
        if (!dataAnnotationsMapByNote[dataAnnotation.shell_note.id])
          dataAnnotationsMapByNote[dataAnnotation.shell_note.id] = [];
        dataAnnotationsMapByNote[dataAnnotation.shell_note.id].push(
          dataAnnotation
        );
      });

      const commentsMap: { [key: string]: Comment } = {};
      data.forEach((rawData: any) => {
        if (
          `${spaceVersionId}` === `${rawData.shell_version.id}` &&
          rawData.note_type_id === noteTypeId
        ) {
          const _comment = getComment(rawData, dataAnnotationsMapByNote);
          commentsMap[_comment.id] = _comment;
        }
      });
      return Object.values(commentsMap);
    },
    []
  );

  const apiFetchComment = useCallback(async (commentId: string | number) => {
    // @ts-ignore
    const { data } = await ApiService.getShowroomShellNotes(commentId);
    const _comment = getComment(data, {});
    return _comment;
  }, []);

  const apiCreateAnnotation = useCallback(
    async (
      spaceId: string | number,
      commentId: string | number,
      annotation: AnnotationType
    ) => {
      const annotatiuonPayload = {
        pX: annotation.x,
        pY: annotation.y,
        pZ: annotation.z,
        rX: 0,
        rY: 0,
        rZ: 0,
        rW: 0,
        shell_id: spaceId,
        shell_note_id: commentId,
      };
      // @ts-ignore
      const { data } = await ApiService.createShowroomShellAnnotation(
        annotatiuonPayload
      );

      return getAnnotation(data);
    },
    []
  );

  const apiDeleteAnnotation = useCallback(
    async (annotationId: string | number) => {
      // @ts-ignore
      await ApiService.deleteShowroomShellAnnotation(annotationId);
    },
    []
  );

  const apiAddCommentFile = useCallback(
    async (
      commentId: string | number,
      fileType: "product_image" | "reference_file",
      fileName: string,
      fileData: Blob
    ) => {
      const fileNameSplit = fileName.split(".");
      fileNameSplit[0] = `${fileNameSplit[0]}-${Date.now()}`;
      const fileNameModified = fileNameSplit.join(".");
      // @ts-ignore
      const { data } = await ApiService.getShowroomShellFileLocker(commentId, {
        file_name: fileNameModified,
        key: fileType,
      });

      try {
        const uploadFormData = new FormData();
        Object.keys(data.url.fields).forEach((key) => {
          uploadFormData.append(key, data.url.fields[key]);
        });
        uploadFormData.append("file", fileData);
        // @ts-ignore
        const s3UploadResponse = await ApiService.postToS3(
          data.url.url,
          uploadFormData
        );
        return s3UploadResponse;
      } catch (error) {
        console.warn(error);
      }
    },
    []
  );

  const apiRemoveCommentFiles = useCallback(
    async (commentId: string | number, fileIds: number[]) => {
      // TODO
      // @ts-ignore
      const { data } = await ApiService.deleteShowroomShellFiles(
        commentId,
        fileIds
      );
    },
    []
  );

  const apiUpdateComment = useCallback(
    async ({
      spaceVersionId,
      noteTypeId,
      spaceId,
      oldComment,
      newComment,
    }: {
      spaceVersionId: string | number;
      noteTypeId: number;
      spaceId: string | number;
      oldComment: Comment;
      newComment: Comment;
    }) => {
      // Update Comment description & status
      const commentPayload = {
        shell_version_id: spaceVersionId,
        note_type_id: noteTypeId,
        note: newComment.description,
        is_resolved: newComment.status === "resolved",
      };
      // @ts-ignore
      const { data } = await ApiService.updateShowroomShellNote(
        newComment.id,
        commentPayload
      );
      const comment = getComment(data, {});
      comment.annotations = oldComment.annotations;

      // Add or Delete annotations
      const annotationsToAdd: AnnotationType[] = [];
      const annotationsToRemove: AnnotationType[] = [];
      Object.values(newComment.annotations).forEach((_annotation) => {
        if (!oldComment.annotations[_annotation.id]) {
          annotationsToAdd.push(_annotation);
        }
      });
      Object.values(oldComment.annotations).forEach((_annotation) => {
        if (!newComment.annotations[_annotation.id]) {
          annotationsToRemove.push(_annotation);
        }
      });

      if (annotationsToAdd.length) {
        const addAnnotationsPromises: Promise<AnnotationType>[] = [];
        annotationsToAdd.forEach((annotation) => {
          addAnnotationsPromises.push(
            apiCreateAnnotation(spaceId, comment.id, annotation)
          );
        });
        const results = await Promise.all(addAnnotationsPromises);
        results.forEach((_annotation) => {
          comment.annotations[_annotation.id] = _annotation;
        });
      }

      if (annotationsToRemove.length) {
        const removeAnnotationsPromises: Promise<void>[] = [];
        annotationsToRemove.forEach((annotation) => {
          removeAnnotationsPromises.push(apiDeleteAnnotation(annotation.id));
        });
        await Promise.all(removeAnnotationsPromises);
        annotationsToRemove.forEach((_annotation) => {
          delete comment.annotations[_annotation.id];
        });
      }

      return comment;
    },
    [apiCreateAnnotation, apiDeleteAnnotation]
  );

  const apiCreateComment = useCallback(
    async ({
      spaceVersionId,
      noteTypeId,
      spaceId,
      comment,
    }: {
      spaceVersionId: string | number;
      noteTypeId: number;
      spaceId: string | number;
      comment: Comment;
    }) => {
      const commentPayload = {
        shell_version_id: spaceVersionId,
        note_type_id: noteTypeId,
        note: comment.description,
        is_resolved: false,
      };
      // @ts-ignore
      const { data } = await ApiService.createShowroomShellNote(commentPayload);
      const newComment = getComment(data, {});
      const annotationsToAdd = Object.values(comment.annotations);
      if (annotationsToAdd.length) {
        const addAnnotationsPromises: Promise<AnnotationType>[] = [];
        annotationsToAdd.forEach((annotation) => {
          addAnnotationsPromises.push(
            apiCreateAnnotation(spaceId, newComment.id, annotation)
          );
        });
        const results = await Promise.all(addAnnotationsPromises);
        results.forEach((_annotation) => {
          newComment.annotations[_annotation.id] = _annotation;
        });
      }
      return newComment;
    },
    [apiCreateAnnotation]
  );

  return {
    apiFetchComments,
    apiFetchComment,
    apiCreateComment,
    apiUpdateComment,
    apiCreateAnnotation,
    apiAddCommentFile,
    apiRemoveCommentFiles,
  };
};
