export function getParamsFromPathname(pathName) {
  let split = pathName.split("/");
  const params = {
    companyIdInUrl: split[1] || null,
    orderIdInUrl: null,
    itemCollectionIdInUrl: null,
    itemIdInUrl: null,
    versionIdInUrl: null,
    variantIdInUrl: null,
  };
  if (split[2] === "products") {
    Object.assign(params, {
      orderIdInUrl: split[3] || null,
      itemCollectionIdInUrl: split[4] || null,
      itemIdInUrl: split[5] || null,
      versionIdInUrl: split[6] || null,
      variantIdInUrl: split[7] || null,
    });
  }
  return params;
}

export function replaceInPath(paramToReplace, newValue, path, keepRest = true) {
  let paramsArr = [
    "-not used-",
    "companyIdInUrl",
    "-not used-",
    "orderIdInUrl",
    "itemCollectionIdInUrl",
    "itemIdInUrl",
    "versionIdInUrl",
    "variantIdInUrl",
  ];

  let newPath = path.split("/");
  let paramIdx = paramsArr.indexOf(paramToReplace);

  if (!keepRest) {
    let stop = false;
    for (let i = newPath.length - 1; i >= 0; i--) {
      if (!stop) {
        if (paramsArr[i] === paramToReplace) {
          stop = true;
          console.log("stopped");
        } else {
          newPath.splice(i, 1);
          console.log("removing " + paramsArr[i]);
        }
      } else {
        console.log("wat");
      }
    }
  }

  if (newPath[paramIdx]) {
    newPath[paramIdx] = newValue;
  } else {
    if (newPath.length === paramIdx) {
      newPath.push(newValue);
    }
  }

  return newPath.join("/");
}
