import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import _ from "lodash";

import { Spin, Modal, Form, Input, Button } from "antd";
import { setModalState } from "store/actions/app";
import { createVariant } from "store/actions/variant";
import { getParamsFromPathname } from "../../../../utils/params";

let setOrder = _.noop;
let openCreateOrderModal = _.noop;

class CreateVariantModal extends Component {
  orderFormRef = React.createRef();

  onOrderFinish = async () => {
    const { setModalState, orderIdInUrl, companyIdInUrl } = this.props;
    const data = await this.orderFormRef.current.validateFields();

    await this.props.createVariant(
      this.props.modalState.partGroupId,
      data.name,
      companyIdInUrl,
      orderIdInUrl,
      this.props.modalState.itemCollectionId
    );
    setModalState("createVariant", { visible: false, partGroupId: null });
  };

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  render() {
    const { setModalState, isLoading, modalState } = this.props;

    return (
      <>
        <Modal
          title={"Create Variant"}
          open={modalState.visible}
          onOk={this.onOrderFinish}
          onCancel={() =>
            setModalState("createVariant", {
              visible: false,
              partGroupId: null,
            })
          }
          destroyOnClose={true}
          cancelText="Cancel"
          okText="Create"
          okButtonProps={{
            disabled: isLoading,
            className: "bitreel-button-primary",
          }}
          cancelButtonProps={{
            disabled: isLoading,
            className: "bitreel-button",
          }}
          closable={!isLoading}
          maskClosable={!isLoading}
        >
          <Spin spinning={isLoading}>
            <Form
              layout={"vertical"}
              name="order"
              onFinish={this.onOrderFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.orderFormRef}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input new variant's name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
            <Button
              onClick={() => {
                this.props.setModalState("createVariant", { visible: false });
                this.props.setModalState("createVariantAdvanced", {
                  visible: true,
                  itemCollectionId: modalState.itemCollectionId,
                });
              }}
            >
              Advanced
            </Button>
          </Spin>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (
  {
    app: {
      modalStates: { createVariant: modalState },
    },
  },
  { itemCollectionId }
) => {
  let { companyIdInUrl, orderIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  return {
    modalState,
    isLoading: false,
    companyIdInUrl,
    orderIdInUrl,
    itemCollectionId,
  };
};

export default connect(mapStateToProps, {
  openCreateOrderModal,
  setOrder,
  setModalState,
  createVariant,
})(withRouter(CreateVariantModal));
