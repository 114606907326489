import React, { useState } from "react";
import { Modal, ModalProps, UploadFile } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Player, BigPlayButton } from "video-react";

interface VideoPlayerPreviewListProps {
  value?: UploadFile<any>[];
}

export const VideoPlayerPreviewList = ({
  value = [],
}: VideoPlayerPreviewListProps) => {
  return (
    <div className="video-player-preview-list">
      {value.map(({ uid, url, name }) => (
        <div className="video-player-preview-item" key={`video-item-${uid}`}>
          <PlayCircleOutlined />
          <div className="title">{name}</div>
        </div>
      ))}
    </div>
  );
};
interface VideoPlayerPreviewProps {
  value: UploadFile<any>;
}

export const VideoPlayerPreview = ({ value }: VideoPlayerPreviewProps) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  return (
    <>
      <div
        className="video-player-preview-item"
        onClick={() => {
          setIsOpenModal(true);
        }}
      >
        <PlayCircleOutlined />
        <div className="title">{value.name}</div>
      </div>
      {isOpenModal && (
        <VideoPlayerModal
          value={value}
          open={true}
          onCancel={() => {
            setIsOpenModal(false);
          }}
        />
      )}
    </>
  );
};

interface VideoPlayerModalProps extends ModalProps {
  value: UploadFile<any>;
}

export const VideoPlayerModal = ({ value, ...rest }: VideoPlayerModalProps) => {
  return (
    <Modal
      footer={null}
      width="60%"
      rootClassName="video-player-modal"
      {...rest}
    >
      <Player playsInline src={value.url}>
        <BigPlayButton position="center" />
      </Player>
    </Modal>
  );
};
