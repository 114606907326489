import React, { useMemo, useState } from "react";
import { Button, Checkbox, Form, Input, UploadFile } from "antd";
import { useParts } from "../hooks";
import { PartModelType, SelectedItem } from "./../types";
import { useUIContext } from "../contexts/UIContext";
import { MediaDropZone } from "components/Media";
import { FilesDropZone } from "components/Media";
import { imageExtensions } from "sharedConstants";
import ApiService from "services/ApiService";

interface PartViewProps {
  selectedItem: SelectedItem;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const PartView = ({ selectedItem, children }: PartViewProps) => {
  console.log("Selected Item in PartView:", selectedItem);
  //console.log("Price Mod of Selected Item:", selectedItem?.item?.price_mod);
  const { updatePart } = useParts();
  const { setIsDirty } = useUIContext();
  const [refreshKey, setRefreshKey] = useState(0);
  const part = selectedItem.item as PartModelType;

  const validateFileTypes = useMemo(
    () => ({
      "image/png": { maxSize: 5000000 },
      "image/jpg": { maxSize: 5000000 },
      "image/jpeg": { maxSize: 5000000 },
    }),
    []
  );

  /*const isImage = (filename: string) => {
    const filenameSplit = filename.split(".");
    return imageExtensions.includes(filenameSplit[filenameSplit.length - 1]);
  };

  const fileList = useMemo<UploadFile[]>(
    () =>
      part.reference_file.map((file: any) => ({
        name: `${file.file_name}`,
        uid: file.id,
        thumbUrl: isImage(file.file_name) ? file.url : undefined,
        url: file.url,
      })) || [],
    [part.reference_file]
  );*/

  const onFinish = async (values: any) => {
    await updatePart({
      _uuid: part._uuid,
      id: part.id,
      part_group_id: part.part_group_id,
      name: values.name,
      price_mod: values.price_mod,
      visible: values.visible,
      custom_request_message: values.custom_request_message,
    });
    setIsDirty(false);
  };

  const partAssociationGroup =
    part.part_association_groups && part.part_association_groups.length > 0
      ? part.part_association_groups[0]
      : { id: [], type: [], ui_message: [] };
  console.log(" partForImage", part);
  return (
    <div className="part-view">
      <Form
        name="part-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: part.name,
          price_mod: part.price_mod || 0,
          visible: part.visible,
          part_association_group_id: partAssociationGroup.id,
          part_association_type: partAssociationGroup.type,
          part_association_ui_message: partAssociationGroup.ui_message,
          custom_request_message: part.custom_request_message,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Price Mod"
          name="price_mod"
          rules={[{ required: true, message: "Please input the price mod!" }]}
        >
          <Input addonBefore="$" type="number" />
        </Form.Item>

        <Form.Item name="visible" valuePropName="checked">
          <Checkbox>Is visible?</Checkbox>
        </Form.Item>

        <p>Thumbnail</p>
        <div>
          <img
            src={
              part.internal_url?.thumbnail
              //part.external_url +
              //"/thumbnail_compressed/256.png?rerender=" +
              //Date.now()
            }
          />
        </div>
        <>
          <FilesDropZone
            beforeUpload={async (fileData) => {
              const uploadFormData = new FormData();
              Object.keys(part.thumbnail_post_url.fields).forEach((key) => {
                uploadFormData.append(key, part.thumbnail_post_url.fields[key]);
              });
              uploadFormData.append("file", fileData);
              // @ts-ignore
              const s3UploadResponse = await ApiService.postToS3(
                part.thumbnail_post_url?.url,
                uploadFormData
              );
              setTimeout(() => {
                setRefreshKey(refreshKey + 1);
              }, 10000);
              return false;
            }}
            fileList={[]}
            listType="picture"
            showUploadList={{ showRemoveIcon: false }}
          />
        </>
        {/*}
          <Form.Item
          label="Thumbnails"
          getValueFromEvent={({ urlFileList }) => urlFileList}
          name="files"
        >
          <MediaDropZone
            multiple
            accept=".jpg,.jpeg,.png"
            validateFileTypes={validateFileTypes}
            maxCount={10}
          
          />
        </Form.Item>
            */}

        <Form.Item
          label="Part Association Group ID"
          name="part_association_group_id"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item label="Part Association Type" name="part_association_type">
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Part Association UI Message"
          name="part_association_ui_message"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Custom Request Instructions:"
          name="custom_request_message"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        {children}
      </Form>
    </div>
  );
};
