import { uniqueId } from "lodash";
import {
  CollectionModelType,
  ItemModelType,
  ItemVersionModelType,
  PartGroupModelType,
  PartModelType,
  MeshMaterialModelType,
} from "../types";

import {
  ItemCollectionModel,
  ItemModel,
  ItemVersionModel,
  PartGroupModel,
  PartModel,
  MeshMaterialModel,
} from "servicesNew/api";

import { Item } from "../../../../servicesNew/api/models/Item";
import { ItemVersion } from "../../../../servicesNew/api/models/ItemVersion";
import { PartGroup } from "../../../../servicesNew/api/models/PartGroup";
import { Part } from "../../../../servicesNew/api/models/Part";
import { MeshMaterial } from "../../../../servicesNew/api/models/MeshMaterial";

export const getCollectionModel = (
  collectionResponse: Required<ItemCollectionModel>
) => {
  const collection: CollectionModelType = {
    _uuid: uniqueId("collection-"),
    //external_url: collectionResponse.external_url || null, // If external_url is undefined, use null
    //thumbnail_post_url: collectionResponse.thumbnail_post_url || null, // If thumbnail_post_url is undefined, use null
    ...collectionResponse,
    childrens: [],
  };
  return collection;
};

export const getItemModel = (itemResponse: Item): ItemModelType => {
  const item: ItemModelType = {
    _uuid: uniqueId("item-"),
    id: itemResponse.id || 0, // If id is undefined, use 0
    name: itemResponse.name || null, // If name is undefined, use null
    created_at: itemResponse.created_at || "", // If created_at is undefined, use an empty string
    updated_at: itemResponse.updated_at || "", // If updated_at is undefined, use an empty string
    is_active: itemResponse.is_active || false, // If is_active is undefined, use false
    is_deleted: itemResponse.is_deleted || false, // If is_deleted is undefined, use false
    deleted_at: itemResponse.deleted_at || null, // If deleted_at is undefined, use null
    old_id: itemResponse.old_id || null, // If old_id is undefined, use null
    price: itemResponse.price || 0, // If price is undefined, use 0
    product_url: itemResponse.product_url || null, // If product_url is undefined, use null
    sku: itemResponse.sku || null, // If sku is undefined, use null
    dimensions: itemResponse.dimensions || null, // If dimensions is undefined, use null
    description: itemResponse.description || null, // If description is undefined, use null
    item_collection_id: itemResponse.item_collection_id || null, // If item_collection is undefined, use null
    company: itemResponse.company || null, // If company is undefined, use null
    order_id: itemResponse.order_id || 0, // If id is undefined, use 0
    current_version_id: itemResponse.current_version_id || 0, // If current_version is undefined, use null
    list_order: itemResponse.list_order || 0,
    custom_request_message: itemResponse.custom_request_message || null, // If custom_request_message is undefined, use null
    childrens: [],
  };
  return item;
};

export const getItemVersionModel = (
  itemVersionResponse: ItemVersion
): ItemVersionModelType => {
  const itemVersion: ItemVersionModelType = {
    _uuid: uniqueId("itemVersion-"),
    name: `${itemVersionResponse.version_number}`,
    id: itemVersionResponse.id || 0, // If id is undefined, use 0
    created_at: itemVersionResponse.created_at || "", // If created_at is undefined, use an empty string
    updated_at: itemVersionResponse.updated_at || "", // If updated_at is undefined, use an empty string
    is_active: itemVersionResponse.is_active || false, // If is_active is undefined, use false
    is_deleted: itemVersionResponse.is_deleted || false, // If is_deleted is undefined, use false
    deleted_at: itemVersionResponse.deleted_at || null, // If deleted_at is undefined, use null
    old_id: itemVersionResponse.old_id || null, // If old_id is undefined, use null
    version_number: itemVersionResponse.version_number || 0, // If version_number is undefined, use 0
    is_published: itemVersionResponse.is_published || false, // If is_published is undefined, use false
    base_price: itemVersionResponse.base_price || 0, // If base_price is undefined, use 0
    is_master: itemVersionResponse.is_master || false, // If is_master is undefined, use false
    status: itemVersionResponse.status || ItemVersion.status.CREATED, // If status is undefined, use CREATED
    item: itemVersionResponse.item || null, // If item is undefined, use null
    thumbnail: itemVersionResponse.thumbnail || null, // If thumbnail is undefined, use null
    reference_file: itemVersionResponse.reference_file || [], // If reference_files is undefined, use an empty array
    //product_image: itemVersionResponse.product_image || [], // If images is undefined, use an empty array
    images: itemVersionResponse.images || [], // If images is undefined, use an empty array
    files: itemVersionResponse.files || [], // If files is undefined, use an empty array
    external_url: itemVersionResponse.external_url || null, // If external_url is undefined, use null
    thumbnail_post_url: itemVersionResponse.thumbnail_post_url || null, // If thumbnail_post_url is undefined, use null
    extra_price_modifier: itemVersionResponse.extra_price_modifier || [], // If extra_price_modifier is undefined, use 0
    list_order: itemVersionResponse.list_order || 0,
    custom_request_message: itemVersionResponse.custom_request_message || null, // If custom_request_message is undefined, use null
    destination_item_version_id:
      itemVersionResponse.destination_item_version_id || null, // If destination_item_version is undefined, use null
    origin_item_version_id: itemVersionResponse.origin_item_version_id || null, // If origin_item_version is undefined, use null
    //files: {
    //  product_image: itemVersionResponse.product_image || [],
    //  reference_file: itemVersionResponse.reference_file || [],
    //},
    childrens: [],
    part_groups: itemVersionResponse.part_groups || null,
  };
  return itemVersion;
};

export const getPartGroupModel = (
  partGroupResponse: PartGroup
): PartGroupModelType => {
  const partGroup: PartGroupModelType = {
    _uuid: uniqueId("partGroup-"),
    id: partGroupResponse.id || 0, // If id is undefined, use 0
    created_at: partGroupResponse.created_at || "", // If created_at is undefined, use an empty string
    updated_at: partGroupResponse.updated_at || "", // If updated_at is undefined, use an empty string
    is_active: partGroupResponse.is_active || false, // If is_active is undefined, use false
    is_deleted: partGroupResponse.is_deleted || false, // If is_deleted is undefined, use false
    deleted_at: partGroupResponse.deleted_at || null, // If deleted_at is undefined, use null
    old_id: partGroupResponse.old_id || null, // If old_id is undefined, use null
    name: partGroupResponse.name || null, // If name is undefined, use null
    item_version_id: partGroupResponse.item_version_id || null, // If item_version is undefined, use null
    thumbnail: partGroupResponse.thumbnail || null, // If thumbnail is undefined, use null
    version_number: partGroupResponse.version_number || 0, // If version_number is undefined, use 0
    is_published: partGroupResponse.is_published || false, // If is_published is undefined, use false
    base_price: partGroupResponse.base_price || 0, // If base_price is undefined, use 0
    is_master: partGroupResponse.is_master || false, // If is_master is undefined, use false
    childrens: [],
    item: null,
    reference_files: [],
    images: [],
    status: partGroupResponse.status || "",
    optional: partGroupResponse.optional || false, // If optional is undefined, use false
    list_order: partGroupResponse.list_order || 0,
    custom_request_message: partGroupResponse.custom_request_message || null, // If custom_request_message is undefined, use null
    external_url: partGroupResponse.external_url || null, // If external_url is undefined, use null
    thumbnail_post_url: partGroupResponse.thumbnail_post_url || null, // If thumbnail_post_url is undefined, use null
    origin_part_group_id: partGroupResponse.origin_part_group_id || null,
    destination_part_group_id:
      partGroupResponse.destination_part_group_id || null,
    parts: partGroupResponse.parts || [],
  };
  return partGroup;
};

export const getPartModel = (partResponse: Part): PartModelType => {
  const part: PartModelType = {
    _uuid: uniqueId("part-"),
    id: partResponse.id || 0, // If id is undefined, use 0
    created_at: partResponse.created_at || "", // If created_at is undefined, use an empty string
    updated_at: partResponse.updated_at || "", // If updated_at is undefined, use an empty string
    is_active: partResponse.is_active || false, // If is_active is undefined, use false
    is_deleted: partResponse.is_deleted || false, // If is_deleted is undefined, use false
    deleted_at: partResponse.deleted_at || null, // If deleted_at is undefined, use null
    old_id: partResponse.old_id || null, // If old_id is undefined, use null
    name: partResponse.name || null, // If name is undefined, use null
    sku: partResponse.sku || null, // If sku is undefined, use null
    uuid: partResponse.uuid || "", // If uuid is undefined, use an empty string
    part_group_id: partResponse.part_group_id || 0, // If part_group is undefined, use 0
    thumbnail: partResponse.thumbnail || null, // If thumbnail is undefined, use null
    price_mod: partResponse.price_mod || 0, // If price_mod is undefined, use 0
    childrens: [],
    item_version_id: partResponse.item_version_id || null, // If item_version is undefined, use null
    version_number: partResponse.version_number || 0, // If version_number is undefined, use 0
    is_published: partResponse.is_published || false, // If is_published is undefined, use false
    base_price: partResponse.base_price || 0, // If base_price is undefined, use 0
    is_master: partResponse.is_master || false, // If is_master is undefined, use false
    internal_url: partResponse.internal_url || [], // If internal_url is undefined, use null
    external_url: partResponse.external_url || null, // If external_url is undefined, use null
    custom_request_message: partResponse.custom_request_message || null, // If custom_request_message is undefined, use null
    thumbnail_post_url: partResponse.thumbnail_post_url || null, // If thumbnail_post_url is undefined, use null
    part_association_groups: partResponse.part_association_groups || [], // If meshMaterialAssociation is undefined, use null
    part_association_group_id: partResponse.part_association_group_id || null, // If meshMaterialAssociation is undefined, use null
    ui_message: partResponse.ui_message || null, // If ui_message is undefined, use null
    type: partResponse.type || null, // If type is undefined, use null
    list_order: partResponse.list_order || 0,
    visible: partResponse.visible || false, // If visible is undefined, use true
    destination_part_id: partResponse.destination_part_id || null,
    origin_part_id: partResponse.origin_part_id || null,
    item: null,
    reference_file: [],
    images: [],
    status: partResponse.status || "",
    part_group: {
      id: undefined,
      created_at: undefined,
      updated_at: undefined,
      is_active: undefined,
      is_deleted: undefined,
      deleted_at: undefined,
      old_id: undefined,
      name: undefined,
      item_version_id: undefined,
      thumbnail: undefined,
      version_number: undefined,
      is_published: undefined,
      base_price: undefined,
      is_master: undefined,
      item: undefined,
      reference_files: undefined,
      images: undefined,
      status: undefined,
    },
    materials: partResponse.materials || [],
  };
  return part;
};

export const getMeshMaterialModel = (
  meshMaterialResponse: MeshMaterial,
  materialPriceModOverrideDefault: number
  //price_mod: number
): MeshMaterialModelType => {
  const material: MeshMaterialModelType = {
    _uuid: uniqueId("meshMaterial-"),
    id: meshMaterialResponse.id || 0, // If id is undefined, use 0
    created_at: meshMaterialResponse.created_at || "", // If created_at is undefined, use an empty string
    updated_at: meshMaterialResponse.updated_at || "", // If updated_at is undefined, use an empty string
    is_active: meshMaterialResponse.is_active || false, // If is_active is undefined, use false
    is_deleted: meshMaterialResponse.is_deleted || false, // If is_deleted is undefined, use false
    deleted_at: meshMaterialResponse.deleted_at || null, // If deleted_at is undefined, use null
    old_id: meshMaterialResponse.old_id || null, // If old_id is undefined, use null
    name: meshMaterialResponse.name || null, // If name is undefined, use null
    uuid: meshMaterialResponse.uuid || "", // If uuid is undefined, use an empty string
    manifest: meshMaterialResponse.manifest || null, // If manifest is undefined, use null
    material: meshMaterialResponse.material || null, // If material is undefined, use null
    part: meshMaterialResponse.part || null, // If part is undefined, use null
    company_id: meshMaterialResponse.company_id || null, // If company_id is undefined, use null
    destination_part_id: meshMaterialResponse.destination_part_id || null, // If destination_part_id is undefined, use null
    origin_material_ids: meshMaterialResponse.id
      ? [meshMaterialResponse.id]
      : [],
    company: meshMaterialResponse.company || null, // If company is undefined, use null
    thumbnail: meshMaterialResponse.thumbnail || null, // If thumbnail is undefined, use null
    categories: meshMaterialResponse.categories || [],
    material_category_associations:
      meshMaterialResponse.material_category_associations || [],
    internal_url: meshMaterialResponse.internal_url || null, // If internal_url is undefined, use null
    price_mod: meshMaterialResponse.price_mod || [], // If price_mod is undefined, use 0
    //price_mod:
    //  price_mod !== undefined && price_mod !== null
    //    ? price_mod
    //    : meshMaterialResponse.price_mod !== undefined
    //    ? meshMaterialResponse.price_mod
    //    : 0, // If price_mod is undefined, use 0
    materialPriceModOverrideDefault:
      materialPriceModOverrideDefault !== undefined &&
      materialPriceModOverrideDefault !== null
        ? materialPriceModOverrideDefault
        : meshMaterialResponse.materialPriceModOverrideDefault !== undefined
        ? meshMaterialResponse.materialPriceModOverrideDefault
        : 0, // If price_mod is undefined, use 0
    //materialPriceModOverrideDefault:
    //  meshMaterialResponse.materialPriceModOverrideDefault || 0, // If materialPriceModOverrideDefault is undefined, use 0
    imageUrl: meshMaterialResponse.internal_url || "", // If imageUrl is undefined, use an empty string
    material_associations: meshMaterialResponse.material_associations || [], // If meshMaterialAssociation is undefined, use null
    material_association_groups:
      meshMaterialResponse.material_association_groups || [], // If meshMaterialAssociation is undefined, use null
    material_association_group_id:
      meshMaterialResponse.material_association_group_id || null, // If meshMaterialAssociation is undefined, use null
    ui_message: meshMaterialResponse.ui_message || null, // If ui_message is undefined, use null
    type: meshMaterialResponse.type || null, // If type is undefined, use null
    item_version_id: meshMaterialResponse.item_version_id || null, // If item_version_id is undefined, use null
    list_order: meshMaterialResponse.list_order || 0, // If list_order is undefined, use null
    visible: meshMaterialResponse.visible || false, // If visible is undefined, use true
    library: meshMaterialResponse.library || false, // If library is undefined, use false
    custom_request_message: meshMaterialResponse.custom_request_message || null, // If custom_request_message is undefined, use null
    /*list_order:
      list_order !== undefined && list_order !== null
        ? list_order
        : meshMaterialResponse.list_order !== undefined
        ? meshMaterialResponse.list_order
        : 0, // If list_order is undefined, use 0*/
  };
  return material;
};
