import React, { useMemo } from "react";
import { Table } from "antd";
import { format } from "date-fns";
import { useCommenterTableSearch } from "./useCommenterTableSearch";
import { useCommenterTableMultiSelect } from "./useCommenterTableMultiSelect";
import type { ColumnsType } from "antd/es/table";
import { Comment } from "../../types";
import _ from "lodash";

type CommentDataIndex =
  | "id"
  | "email"
  | "version"
  | "description"
  | "status"
  | "createdOn";
interface CommentDataType extends Pick<Comment, CommentDataIndex> {}

interface CommenterTableProps {
  value?: string | null;
  onSelect?: (value: CommentDataType) => void;
  comments?: CommentDataType[];
}

export const CommenterTable = ({
  value = null,
  onSelect = () => {},
  comments = [],
}: CommenterTableProps) => {
  const { getColumnSearchProps } = useCommenterTableSearch<CommentDataType>();
  const { getColumnMultiselectProps } =
    useCommenterTableMultiSelect<CommentDataType>();

  const versionsFilterOptions = useMemo(
    () =>
      _.uniqBy(comments, ({ version }) => version).map(({ version }) => ({
        label: `Version ${version}`,
        value: version,
      })),
    [comments]
  );

  const allCommentsIds = useMemo(() => comments.map((c) => c.id), [comments]);

  const columns: ColumnsType<CommentDataType> = [
    {
      title: "Commentor",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      width: "25%",
    },
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "Version",
      dataIndex: "version",
      ...getColumnMultiselectProps("version", versionsFilterOptions),
      render: (value) => (
        <span className="table-item-version">Version: {value}</span>
      ),
      width: "25%",
    },
    {
      title: "Status",
      dataIndex: "status",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (value) => <span className={`status-${value}`}>{value}</span>,
      width: "25%",
    },
    {
      title: "Date",
      dataIndex: "createdOn",
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.createdOn && b.createdOn) {
          return a.createdOn.getTime() - b.createdOn.getTime();
        }
        return -1;
      },
      render: (value) =>
        value ? format(value, "yyyy-MM-dd HH-mm:ss zzzz") : "",
      width: "25%",
    },
  ];

  return (
    <div className="commenter-table">
      <Table
        columns={columns}
        dataSource={comments}
        rowKey="id"
        rowSelection={{
          type: "radio",
          selectedRowKeys: value ? [value] : [],
        }}
        expandable={{
          expandedRowRender: (record) => (
            <p className="description" onClick={() => onSelect(record)}>
              {record.description}
            </p>
          ),
          expandedRowKeys: allCommentsIds,
        }}
        onRow={(record) => ({
          onClick: () => {
            onSelect(record);
          },
        })}
        size="middle"
      />
    </div>
  );
};
