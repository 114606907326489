import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import { Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { openEditUserModal } from "store/actions/app";
import { getParamsFromPathname } from "../../../../utils/params";
import { filter } from "lodash";

class EditUserModal extends Component {
  constructor(props) {
    super(props);
    this.onModulePermissionChange = this.onModulePermissionChange.bind(this);
  }

  userFormRef = React.createRef();

  onModulePermissionChange(value) {
    console.log(value);
  }

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  componentDidUpdate() {
    const selectUser = filter(
      this.props.companies[
        getParamsFromPathname(window.location.pathname).companyIdInUrl
      ].users,
      (user) => user.id === this.props.app.moduleStates.user.editUserId
    )[0];
    this.userFormRef.current &&
      this.userFormRef.current.setFieldsValue({
        email: selectUser ? selectUser.email : null,
      });
  }

  render() {
    return (
      <div>
        <Modal
          title="Basic Modal"
          open={this.props.app.modalStates.editUserModal.visible}
          onOk={() => this.props.openEditUserModal(false)}
          onCancel={() => this.props.openEditUserModal(false)}
        >
          <Form
            layout={"vertical"}
            name="user"
            onFinish={this.onUserFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.userFormRef}
            initialValues={{
              userAccess: ["all", "products", "cb"],
              email: null,
            }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input user email - must be unique!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="User Access"
              name="userAccess"
              rules={[
                {
                  required: true,
                  message: "Please select at least one module access!",
                },
              ]}
            >
              <Checkbox.Group
                style={{ width: "100%" }}
                disabled
                onChange={() => this.onModulePermissionChange()}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox value="all">All</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="products">Products</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="cb">Co Browse</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ app, companies }) => {
  return {
    app,
    companies,
  };
};

export default connect(mapStateToProps, {
  openEditUserModal,
})(withRouter(EditUserModal));
