import React, { Component } from "react";
import { Button, Input, Modal, Popconfirm } from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import { setModalState } from "store/actions/app";
import { addToItemFilelocker } from "store/actions/items";
import ApiService from "services/ApiService";
import { getParamsFromPathname, withRouter } from "utils";
import ProductCreate from "../../screens/create";
import loadItemVersionForEdit from "../../utils/loadItemVersionForEdit";
import patchItemVersionForEdit from "../../utils/patchItemVersionForEdit";

const mapSeries = async (iterable, action) => {
  for (const x of iterable) {
    await action(x);
  }
};
class CreateLayouts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layouts: [],
      itemCollectionName: "New Item Collection -- " + Date.now(),
      referenceFiles: [],
      itemCollectionIdToTry: 0,
      itemVersionLoaded: false,
    };
    this.ref = React.createRef(null);
  }

  setLayouts = (dataCopy) => {
    this.setState({ layouts: dataCopy.layouts });
  };

  addReferenceFile = (file, layoutuuid) => {
    this.state.referenceFiles.push({
      file,
      layoutuuid,
    });
  };

  handleFinalizeItem = async () => {
    let editMode = this.state.itemVersionLoaded;

    if (editMode) {
      await patchItemVersionForEdit({
        layouts: this.state.layouts,
        collectionName: this.state.itemCollectionName,
        itemVersionData: this.state.itemVersionLoaded,
      });
    } else {
      let {
        data: { id: itemCollectionId },
      } = await this.createItemCollection();

      await mapSeries(this.state.layouts, async (item) => {
        let {
          data,
          data: { id: itemId },
        } = await this.createItem(itemCollectionId, item.name);

        let versionId = data.versions[0].id;

        // await ApiService.patchItemVersion(versionId, {
        //     name: item.name
        // })

        let refsToAdd = [];

        this.state.referenceFiles.forEach((f) => {
          if (f.layoutuuid === item.uuid) {
            refsToAdd.push(f);
          }
        });

        if (item.imageFile) {
          await this.addFileLocker(itemId, item.imageFile);
        }

        await mapSeries(refsToAdd, async (ref) => {
          await this.addFileLocker(itemId, ref.file);
        });

        await mapSeries(item.partGroups, async (pg) => {
          let {
            data: { id: partGroupId },
          } = await ApiService.postPartGroup(pg.name, versionId);

          if (pg.optional) {
            await ApiService.patchPartGroup(partGroupId, {
              optional: true,
            });
          }

          await mapSeries(pg.parts, async (part) => {
            let partRes = await ApiService.postPart(
              part.name,
              partGroupId,
              part.priceMod
            );

            await mapSeries(part.materials, async (m) => {
              let materialRes = await ApiService.postMaterial({
                name: m.name,
                part_id: partRes.data.id,
              });

              if (m.priceMod) {
                await ApiService.postPartMaterialAccociation({
                  partId: partRes.data.id,
                  materialId: materialRes.data.id,
                  priceMod: m.priceMod,
                });
              }
            });

            return true;
          });
        });
      });
    }
    this.props.setModalState("createItemAdvanced", { visible: false });
  };

  addFileLocker = async (itemId, file) => {
    await this.props.addToItemFilelocker(
      itemId,
      file,
      "reference_file",
      file.name
    );
  };

  createItemCollection = async () => {
    let res = await ApiService.postItemCollection(
      this.props.orderIdInUrl,
      this.state.itemCollectionName
    );
    return res;
  };

  createItem = async (itemCollectionId, itemName) => {
    let res = await ApiService.postItem({
      name: itemName,
      order_id: this.props.orderIdInUrl,
      item_collection_id: itemCollectionId,
    });
    return res;
  };

  render() {
    return (
      <>
        <Modal
          onOk={this.handleFinalizeItem}
          onCancel={() =>
            this.props.setModalState("createItemAdvanced", { visible: false })
          }
          className="create-layouts"
          open={this.props.modalState.visible}
        >
          {this.state.itemVersionLoaded ? (
            <div>
              <h4>Item Version Loaded - Edit Mode Enabled</h4>
            </div>
          ) : (
            <>
              <label>Load Item Version</label>
              <div>
                <input
                  type="number"
                  onChange={(e) => {
                    this.setState({
                      itemCollectionIdToTry: Number(e.target.value),
                    });
                  }}
                />
                <Popconfirm
                  disabled={!this.state.itemCollectionIdToTry}
                  onConfirm={async () => {
                    let { layoutsToLoad, collectionName, itemVersionData } =
                      await loadItemVersionForEdit(
                        this.state.itemCollectionIdToTry
                      );
                    this.setLayouts({ layouts: layoutsToLoad });
                    this.ref.current.updateLayouts(
                      layoutsToLoad,
                      collectionName
                    );
                    this.setState({
                      itemCollectionName: collectionName,
                      itemVersionLoaded: itemVersionData,
                    });
                  }}
                  onCancel={_.noop}
                  title="Are you sure you want to load this item version ? Any progress on your current item will be lost."
                >
                  <Button disabled={!this.state.itemCollectionIdToTry}>
                    Edit Item Version Id
                  </Button>
                </Popconfirm>
              </div>
            </>
          )}
          <hr style={{ margin: "15px 0px" }} />
          <label>Collection Name</label>
          <Input
            value={this.state.itemCollectionName}
            placeholder="Item Collection Name"
            onChange={(e) =>
              this.setState({ itemCollectionName: e.target.value })
            }
          />
          <ProductCreate
            ref={this.ref}
            addReferenceFileFxn={this.addReferenceFile}
            setLayoutsFxn={this.setLayouts}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (
  {
    app: {
      modalStates: { createItemAdvanced: modalState },
    },
  },
  ownProps
) => {
  let { orderIdInUrl, companyIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  return {
    modalState,
    orderIdInUrl,
    companyIdInUrl,
  };
};

export default connect(mapStateToProps, {
  setModalState,
  addToItemFilelocker,
})(withRouter(CreateLayouts));
