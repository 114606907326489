import React, { Component } from "react";
import Cloudupload from "assets/svg/CloudUpload";
import { notification, Upload } from "antd";
import "./dropzone.scss";
import { addToItemFilelocker } from "store/actions/items";
import { connect } from "react-redux";

class BRDropzone extends Component {
  state = {
    fileList: [],
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList }, () => {
      this.onFinish();
    });
  };

  onFinish = () => {
    let dataSources = this.state.fileList;

    if (dataSources.length) {
      this.setState({ loading: true }, () => {
        let promises = [];
        const fileTypes = [
          "image/png",
          "image/x-png",
          "image/pjpeg",
          "image/jpg",
          "image/jpeg",
          "image/gif",
        ];
        let fileLockerPointer = "reference_file";

        dataSources.forEach((dataSource) => {
          if (fileTypes.includes(dataSource.type)) {
            fileLockerPointer = "product_image";
          }

          promises.push(
            this.props.addToItemFilelocker(
              this.props.itemId,
              dataSource.originFileObj,
              fileLockerPointer,
              dataSource.name
            )
          );
        });

        Promise.all(promises, ([...items]) => {
          console.log(items);
        });
      });
    } else {
      notification.error({
        message: "Bad Input",
        description: "Must include either a product image or reference file!",
      });
    }
  };

  render = () => {
    return (
      <Upload.Dragger
        showUploadList={false}
        style={{ backgroundColor: "white", padding: 20 }}
        name="file"
        multiple={true}
        fileList={this.state.fileList}
        onChange={this.handleChange}
        beforeUpload={(file) => {
          return false;
        }}
      >
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Upload.Dragger>
    );
  };
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { addToItemFilelocker })(BRDropzone);
