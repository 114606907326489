import React, { useMemo } from "react";
import { Button, Card } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { ItemTypeLabel, SelectedItem } from "../types";
import { useUIContext } from "../contexts/UIContext";
import { ItemDropdownActions } from "./ItemDropdownActions";

interface FormViewWrapperProps {
  selectedItem: SelectedItem;
  enableFooter?: boolean;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const FormViewWrapper = ({
  selectedItem,
  enableFooter = false,
  children = null,
}: FormViewWrapperProps) => {
  const { isDirty, setIsDirty } = useUIContext();
  const footerActions = useMemo(
    () => (
      <div className="page-footer">
        <Button
          htmlType="reset"
          onClick={() => {
            setIsDirty(false);
          }}
          type="primary"
          shape="round"
          ghost
        >
          CANCEL
        </Button>
        <Button
          disabled={!isDirty}
          type="primary"
          shape="round"
          htmlType="submit"
        >
          SAVE
        </Button>
      </div>
    ),
    [isDirty, setIsDirty]
  );

  return (
    <Card
      className="content-wrapper-card"
      // size="small"
      title={
        <span>
          <b>{ItemTypeLabel[selectedItem.type]}:</b> <i>{selectedItem.name}</i>
        </span>
      }
      extra={
        <ItemDropdownActions item={selectedItem.item}>
          <Button shape="circle" icon={<SettingOutlined />} type="text" />
        </ItemDropdownActions>
      }
      actions={enableFooter ? [footerActions] : undefined}
    >
      {children}
    </Card>
  );
};
