import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import { Col, Form, Input, Modal, Row } from "antd";
import { openCreateUserModal } from "store/actions/app";
import { createUserAndLinkToCurrentCompany } from "store/actions/user";
import { getParamsFromPathname } from "../../../../utils/params";
import { Checkbox } from "antd";

class CreateUserModal extends Component {
  constructor(props) {
    super(props);
    this.onModulePermissionChange = this.onModulePermissionChange.bind(this);
  }

  userFormRef = React.createRef();

  onModulePermissionChange(value) {
    console.log(value);
  }

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  onUserFinish = async () => {
    const { createUserAndLinkToCurrentCompany } = this.props;
    const data = await this.userFormRef.current.validateFields();
    createUserAndLinkToCurrentCompany(
      data,
      getParamsFromPathname(window.location.pathname).companyIdInUrl
    );
  };

  render() {
    return (
      <div>
        <Modal
          title={"Create User"}
          open={this.props.app.modalStates.createUserModal.visible}
          onOk={this.onUserFinish}
          okButtonProps={{
            className: "bitreel-button-primary",
            loading: this.props.isCreatingUser,
          }}
          cancelButtonProps={{
            className: "bitreel-button",
            disabled: this.props.isCreatingUser,
          }}
          onCancel={() => this.props.openCreateUserModal(false)}
          destroyOnClose={true}
          cancelText="Cancel"
          okText="Create"
          closable={!this.props.isCreatingUser}
        >
          <Form
            layout={"vertical"}
            name="user"
            onFinish={this.onUserFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.userFormRef}
            initialValues={{ userAccess: ["all", "products", "cb"] }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input user email - must be unique!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="User Access"
              name="userAccess"
              rules={[
                {
                  required: true,
                  message: "Please select at least one module access!",
                },
              ]}
            >
              <Checkbox.Group
                style={{ width: "100%" }}
                disabled
                onChange={() => this.onModulePermissionChange()}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox value="all">All</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="products">Products</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="cb">Co Browse</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ app, isCreatingUser }) => {
  return {
    app,
    isCreatingUser,
  };
};

export default connect(mapStateToProps, {
  openCreateUserModal,
  createUserAndLinkToCurrentCompany,
})(withRouter(CreateUserModal));
