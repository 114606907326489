/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Item } from "../models/Item";
import type { ItemCollection } from "../models/ItemCollection";
import type { ItemVersion } from "../models/ItemVersion";
import type { Material } from "../models/Material";
import type { MaterialCategory } from "../models/MaterialCategory";
import type { MeshMaterial } from "../models/MeshMaterial";
import type { MeshMaterialAssociation } from "../models/MeshMaterialAssociation";
import type { Part } from "../models/Part";
import type { PartGroup } from "../models/PartGroup";
import type { Variant } from "../models/Variant";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

import { ItemModelType } from "../../../modules/productNew/advancedProductCreatorNew/types";
import { getItemModel } from "../../../modules/productNew/advancedProductCreatorNew/utils/dataModel";
import { ItemModel } from "servicesNew/api";

interface itemResponse {
  items: Array<ItemModel>;
  // Include other properties that your API returns, if any
}

import { ItemVersionModelType } from "../../../modules/productNew/advancedProductCreatorNew/types";
import { getItemVersionModel } from "../../../modules/productNew/advancedProductCreatorNew/utils/dataModel";
import { ItemVersionModel } from "servicesNew/api";

interface itemVersionResponse {
  versions: Array<ItemVersionModel>;
  // Include other properties that your API returns, if any
}

import { PartGroupModelType } from "../../../modules/productNew/advancedProductCreatorNew/types";
import { getPartGroupModel } from "../../../modules/productNew/advancedProductCreatorNew/utils/dataModel";
import { PartGroupModel } from "servicesNew/api";

interface partGroupResponse {
  part_groups: Array<PartGroupModel>;
  // Include other properties that your API returns, if any
}

import { PartModelType } from "../../../modules/productNew/advancedProductCreatorNew/types";
import { getPartModel } from "../../../modules/productNew/advancedProductCreatorNew/utils/dataModel";
import { PartModel } from "servicesNew/api";

interface partResponse {
  parts: Array<PartModel>;
  // Include other properties that your API returns, if any
}

import { MeshMaterialModelType } from "../../../modules/productNew/advancedProductCreatorNew/types";
import { getMeshMaterialModel } from "../../../modules/productNew/advancedProductCreatorNew/utils/dataModel";
import { MeshMaterialModel } from "servicesNew/api";

interface meshMaterialResponse {
  materials: Array<MeshMaterialModel>;
  // Include other properties that your API returns, if any
}
type PartGroupModelResponse = {
  partGroups: Array<PartGroupModelType>;
  apiResponse: partGroupResponse;
};
export class ProductsService {
  /**
   * API endpoint that allows item collections to be viewed or edited.
   * @returns any
   * @throws ApiError
   */
  public static productsItemCollectionsList({}: //limit,
  //offset,
  {
    /**
     * Number of results to return per page.
     */
    //limit?: number,
    /**
     * The initial index from which to return the results.
     */
    //offset?: number,
  }): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<ItemCollection>;
  }> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/item_collections/",
      //query: {
      //'limit': limit,
      //'offset': offset,
      //},
    });
  }

  /**
   * API endpoint that allows item collections to be viewed or edited.
   * @returns ItemCollection
   * @throws ApiError
   */
  public static productsItemCollectionsCreate({
    data,
  }: {
    data: ItemCollection;
  }): CancelablePromise<ItemCollection> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/item_collections/",
      body: data,
    });
  }

  /**
   * API endpoint that allows item collections to be viewed or edited.
   * @returns ItemCollection
   * @throws ApiError
   */
  public static productsItemCollectionsRead({
    id,
  }: {
    /**
     * A unique integer value identifying this item collection.
     */
    id: number;
  }): CancelablePromise<ItemCollection> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/item_collections/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * API endpoint that allows item collections to be viewed or edited.
   * @returns ItemCollection
   * @throws ApiError
   */
  public static productsItemCollectionsUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this item collection.
     */
    id: number;
    data: ItemCollection;
  }): CancelablePromise<ItemCollection> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/item_collections/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * API endpoint that allows item collections to be viewed or edited.
   * @returns ItemCollection
   * @throws ApiError
   */
  public static productsItemCollectionsPartialUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this item collection.
     */
    id: number;
    data: ItemCollection;
  }): CancelablePromise<ItemCollection> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/item_collections/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * API endpoint that allows item collections to be viewed or edited.
   * @returns void
   * @throws ApiError
   */
  public static productsItemCollectionsDelete({
    id,
  }: {
    /**
     * A unique integer value identifying this item collection.
     */
    id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/item_collections/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static async productsItemVersionsList({
    item,
    limit,
    offset,
  }: {
    item?: string;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<Array<ItemVersionModelType>> {
    const itemVersions: itemVersionResponse = await __request(OpenAPI, {
      method: "GET",
      url: "/items/{id}",
      path: {
        id: item,
      },
    });

    let results: Array<ItemVersionModelType> = [];
    console.log("itemVersions in api", itemVersions);
    if (itemVersions.versions) {
      results = itemVersions.versions.map(getItemVersionModel);
    }

    if (limit) {
      results = results.slice(offset || 0, (offset || 0) + limit);
    }

    return results;
  }

  /**
   * @returns ItemVersion
   * @throws ApiError
   */
  public static productsItemVersionsCreate({
    data,
  }: {
    data: ItemVersion;
  }): CancelablePromise<ItemVersion> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/item_versions/",
      body: data,
    });
  }

  /**
   * @returns ItemVersion
   * @throws ApiError
   */
  public static productsItemVersionsRead({
    id,
  }: {
    /**
     * A unique integer value identifying this item version.
     */
    id: number;
  }): CancelablePromise<ItemVersion> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/item_versions/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ItemVersion
   * @throws ApiError
   */
  public static productsItemVersionsUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this item version.
     */
    id: number;
    data: ItemVersion;
  }): CancelablePromise<ItemVersion> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/item_versions/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns ItemVersion
   * @throws ApiError
   */
  public static productsItemVersionsPartialUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this item version.
     */
    id: number;
    data: ItemVersion;
  }): CancelablePromise<ItemVersion> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/item_versions/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static productsItemVersionsDelete({
    id,
  }: {
    /**
     * A unique integer value identifying this item version.
     */
    id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/item_versions/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static async productsItemsList({
    nameIcontains,
    descriptionIcontains,
    itemCollection,
    limit,
    offset,
  }: {
    nameIcontains?: string;
    descriptionIcontains?: string;
    itemCollection?: string;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<{
    results: any;
    itemsResponse: itemResponse;
  }> {
    const itemsResponse: itemResponse = await __request(OpenAPI, {
      method: "GET",
      url: "/item_collections/{id}",
      path: {
        id: itemCollection,
      },
    });

    let results: Array<ItemModelType> = [];

    if (itemsResponse.items) {
      // Change this line
      results = itemsResponse.items.map(getItemModel); // And this line
    }

    if (nameIcontains) {
      results = results.filter(
        (item: ItemModelType) =>
          item.name &&
          item.name.toLowerCase().includes(nameIcontains.toLowerCase())
      );
    }

    if (descriptionIcontains) {
      results = results.filter(
        (item: ItemModelType) =>
          item.description &&
          item.description
            .toLowerCase()
            .includes(descriptionIcontains.toLowerCase())
      );
    }

    if (limit) {
      results = results.slice(offset || 0, (offset || 0) + limit);
    }

    return { results, itemsResponse };
  }

  /**
   * @returns Item
   * @throws ApiError
   */
  public static productsItemsCreate({
    data,
  }: {
    data: Item;
  }): CancelablePromise<Item> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/items/",
      body: data,
    });
  }

  /**
   * @returns Item
   * @throws ApiError
   */
  public static productsItemsRead({
    id,
  }: {
    /**
     * A unique integer value identifying this item.
     */
    id: number;
  }): CancelablePromise<Item> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/items/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Item
   * @throws ApiError
   */
  public static productsItemsUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this item.
     */
    id: number;
    data: Item;
  }): CancelablePromise<Item> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/items/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns Item
   * @throws ApiError
   */
  public static productsItemsPartialUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this item.
     */
    id: number;
    data: Item;
  }): CancelablePromise<Item> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/items/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static productsItemsDelete({
    id,
  }: {
    /**
     * A unique integer value identifying this item.
     */
    id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/items/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static async productsLibraryMaterialsList({
    companyId,
    limit,
    offset,
  }: {
    companyId?: string;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<Array<Material>> {
    const response: Material[] = await __request(OpenAPI, {
      method: "GET",
      url: "/matterials/{companyId}/",
      path: {
        companyId: companyId,
      },
    });
    /*
        let results: Array<MeshMaterialModelType> = [];

        if (meshMaterials.materials) {
            results = meshMaterials.materials.map(getMeshMaterialModel);
        }
    
        if (limit) {
            results = results.slice(offset || 0, (offset || 0) + limit);
        }
    
        return results;*/

    return response;
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static async productsLibraryMaterialCategoriesList({
    companyId,
    limit,
    offset,
  }: {
    companyId?: string;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<Array<MaterialCategory>> {
    const response: MaterialCategory[] = await __request(OpenAPI, {
      method: "GET",
      url: "/material_category/company/{companyId}/",
      path: {
        companyId: companyId,
      },
    });
    /*
        let results: Array<MeshMaterialModelType> = [];

        if (meshMaterials.materials) {
            results = meshMaterials.materials.map(getMeshMaterialModel);
        }
    
        if (limit) {
            results = results.slice(offset || 0, (offset || 0) + limit);
        }
    
        return results;*/

    return response;
  }

  /**
   * @returns Material
   * @throws ApiError
   */
  public static productsMaterialsCreate({
    data,
  }: {
    data: Material;
  }): CancelablePromise<Material> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/matterials/",
      body: data,
    });
  }

  /**
   * @returns Material
   * @throws ApiError
   */
  public static productsMaterialsRead({
    materialId,
  }: {
    /**
     * A unique integer value identifying this material.
     */
    materialId: number;
  }): CancelablePromise<Material> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/matterials/{meterialId}/",
      path: {
        materialId: materialId,
      },
    });
  }

  /**
   * @returns Material
   * @throws ApiError
   */
  public static productsMaterialsUpdate({
    materialId,
    data,
  }: {
    /**
     * A unique integer value identifying this material.
     */
    materialId: number;
    data: Material;
  }): CancelablePromise<Material> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/matterials/{meterialId}/",
      path: {
        materialId: materialId,
      },
      body: data,
    });
  }

  /**
   * @returns Material
   * @throws ApiError
   */
  public static productsMaterialsPartialUpdate({
    materialId,
    data,
  }: {
    /**
     * A unique integer value identifying this material.
     */
    materialId: number;
    data: Material;
  }): CancelablePromise<Material> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/matterials/{materialId}/",
      path: {
        materialId: materialId,
      },
      body: data,
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static productsMaterialsDelete({
    materialId,
  }: {
    /**
     * A unique integer value identifying this material.
     */
    materialId: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/matterials/{id}/",
      path: {
        materialId: materialId,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static async productsMeshMaterialsList({
    part,
    //material,
    limit,
    offset,
  }: {
    part?: string;
    //material?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<Array<MeshMaterialModelType>> {
    const meshMaterials: meshMaterialResponse = await __request(OpenAPI, {
      method: "GET",
      url: "/parts/{id}",
      path: {
        id: part,
      },
    });

    let results: Array<MeshMaterialModelType> = [];

    if (meshMaterials.materials) {
      results = meshMaterials.materials.map(getMeshMaterialModel);
    }

    if (limit) {
      results = results.slice(offset || 0, (offset || 0) + limit);
    }

    return results;
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsMeshMaterialsCopy({
    data,
  }: {
    data: MeshMaterial /*company_id, origin_part_id, destination_part_id, origin_material_ids,*/;
  }): CancelablePromise<MeshMaterialModelType[]> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/bulk_copy_materials/",
      body: data,
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsPushLibraryMaterialChanges({
    data,
  }: {
    data: MeshMaterial /*company_id, origin_part_id, destination_part_id, origin_material_ids,*/;
  }): CancelablePromise<MeshMaterialModelType[]> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/push_library_material_changes/",
      body: data,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  // public static productsCopyPartThumbnails({
  //   data,
  // }: {
  //   data: Part /*company_id, origin_part_id, destination_part_id, origin_material_ids,*/;
  // }): CancelablePromise<Part> {
  //   return __request(OpenAPI, {
  //     method: "POST",
  //     url: "/copy_part_thumbnails/",
  //     body: data,
  //   });
  // }

  // /**
  //  * @returns any
  //  * @throws ApiError
  //  */
  public static productsCopyPartGroupThumbnails({
    data,
  }: {
    data: PartGroup /*company_id, origin_part_id, destination_part_id, origin_material_ids,*/;
  }): CancelablePromise<PartGroup> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/copy_part_group_thumbnails/",
      body: data,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static productsCopyItemVersionThumbnails({
    data,
  }: {
    data: ItemVersion /*company_id, origin_part_id, destination_part_id, origin_material_ids,*/;
  }): CancelablePromise<ItemVersion> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/copy_item_version_thumbnails/",
      body: data,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static async productsMeshMaterialsForAssociationGroupList({
    itemVersion,
    //material,
    limit,
    offset,
  }: {
    itemVersion?: string;
    //material?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<Array<MeshMaterialAssociation>> {
    const meshMaterials: MeshMaterialAssociation = await __request(OpenAPI, {
      method: "GET",
      url: "/item_versions/{id}",
      path: {
        id: itemVersion,
      },
    });

    let results: Array<MeshMaterialModelType> = [];

    //if (meshMaterials.materials) {
    //    results = meshMaterials.materials.map(getMeshMaterialModel);
    //}

    if (limit) {
      results = results.slice(offset || 0, (offset || 0) + limit);
    }

    return results;
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsMeshMaterialAssociationGroupCreate({
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    data: MeshMaterialAssociation;
  }): CancelablePromise<MeshMaterialAssociation> {
    return __request(OpenAPI, {
      method: "POST",
      url: `/material_association_group/`,
      /*path: {
                'materialId': materialId,
            },*/
      body: data,
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsMeshMaterialAssociationGroupModify({
    materialId,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    materialId: number;
    data: MeshMaterial;
  }): CancelablePromise<MeshMaterialAssociation> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: `/material_asssociation_group/${materialId}/`,
      path: {
        materialId: materialId,
      },
      body: data,
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsMeshMaterialAssociationCreate({
    materialId,
    materialAssociationGroupId,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    materialId: number;
    materialAssociationGroupId: number;
    data: MeshMaterial;
  }): CancelablePromise<MeshMaterialModelType> {
    return __request(OpenAPI, {
      method: "POST",
      url: `/material_associations/${materialId}/${materialAssociationGroupId}/`,
      path: {
        materialId: materialId,
        materialAssociationGroupId: materialAssociationGroupId,
      },
      body: data,
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsMeshMaterialAssociationModify({
    materialId,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    materialId: number;
    data: MeshMaterial;
  }): CancelablePromise<MeshMaterialAssociation> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: `/material_asssociations/${materialId}/`,
      path: {
        materialId: materialId,
      },
      body: data,
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */

  public static productsMeshMaterialAssociationDelete({
    material_id,
    material_association_group_id,
  }: {
    /**
     * A unique integer value identifying this part group.
     */
    material_id: number;
    material_association_group_id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/material_associations/{material_id}/{material_association_group_id}",
      path: {
        material_id: material_id,
        material_association_group_id: material_association_group_id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static productsMeshMaterialAssociationGroupDelete({
    material_association_group_id,
  }: {
    /**
     * A unique integer value identifying this part group.
     */

    material_association_group_id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/material_association_group/{material_association_group_id}",
      path: {
        material_association_group_id: material_association_group_id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static productsPartAssociationDelete({
    part_id,
    part_association_group_id,
  }: {
    /**
     * A unique integer value identifying this part group.
     */
    part_id: number;
    part_association_group_id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/part_associations/{part_id}/{part_association_group_id}",
      path: {
        part_id: part_id,
        part_association_group_id: part_association_group_id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static productsPartAssociationGroupDelete({
    part_association_group_id,
  }: {
    /**
     * A unique integer value identifying this part group.
     */

    part_association_group_id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/part_association_group/{part_association_group_id}",
      path: {
        part_association_group_id: part_association_group_id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static productsMeshMaterialsRead({
    id,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    id: number;
  }): CancelablePromise<MeshMaterial> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/mesh_materials/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsMeshMaterialsUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    id: number;
    data: MeshMaterial;
  }): CancelablePromise<MeshMaterial> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/materials/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsPartMaterialModifierUpdate({
    partId,
    materialId,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    partId: number;
    materialId: number;
    data: MeshMaterial;
  }): CancelablePromise<MeshMaterial> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: `/part_material_modifier/${partId}/${materialId}/`,
      path: {
        partId: partId,
        materialId: materialId,
      },
      body: data,
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsPartMaterialModifierDelete({
    partId,
    materialId,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    partId: number;
    materialId: number;
  }): CancelablePromise<MeshMaterial> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: `/part_material_modifier/${partId}/${materialId}/`,
      path: {
        partId: partId,
        materialId: materialId,
      },
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsMeshMaterialsPartialUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    id: number;
    data: MeshMaterial;
  }): CancelablePromise<MeshMaterial> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/mesh_materials/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static productsMeshMaterialsDelete({
    id,
  }: {
    /**
     * A unique integer value identifying this material.
     */
    id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/materials/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsMaterialCategoryAssociationsDelete({
    categoryId,
    materialId,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    categoryId: any;
    materialId: number;
  }): CancelablePromise<MeshMaterial> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: `/material_category_associations/${materialId}/${categoryId}`,
      path: {
        categoryId: categoryId,
        materialId: materialId,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  //UPDATED CODE
  public static async productsPartGroupsList({
    itemVersion,
    limit,
    offset,
  }: {
    itemVersion?: string;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<{
    results: Array<PartGroupModelType>;
    partGroups: partGroupResponse;
  }> {
    const partGroups: partGroupResponse = await __request(OpenAPI, {
      method: "GET",
      url: "/item_versions/{id}",
      path: {
        id: itemVersion,
      },
    });

    let results: Array<PartGroupModelType> = [];

    if (partGroups.part_groups) {
      results = partGroups.part_groups.map(getPartGroupModel);
    }

    if (limit) {
      results = results.slice(offset || 0, (offset || 0) + limit);
    }
    //This returns the result and the whole response of the api
    return { results, partGroups };
  }
  // old code
  // public static async productsPartGroupsList({
  //   itemVersion,
  //   limit,
  //   offset,
  // }: {
  //   itemVersion?: string;
  //   /**
  //    * Number of results to return per page.
  //    */
  //   limit?: number;
  //   /**
  //    * The initial index from which to return the results.
  //    */
  //   offset?: number;
  // }): Promise<Array<PartGroupModelType>> {
  //   const partGroups: partGroupResponse = await __request(OpenAPI, {
  //     method: "GET",
  //     url: "/item_versions/{id}",
  //     path: {
  //       id: itemVersion,
  //     },
  //   });
  //   console.log("itemVersionsApi", partGroups);
  //   let results: Array<PartGroupModelType> = [];

  //   if (partGroups.part_groups) {
  //     results = partGroups.part_groups.map(getPartGroupModel);
  //   }

  //   if (limit) {
  //     results = results.slice(offset || 0, (offset || 0) + limit);
  //   }

  //   return results;
  // }

  //   Promise<PartGroupModelResponse> {
  //     const partGroups: partGroupResponse = await __request(OpenAPI, {
  //       method: "GET",
  //       url: "/item_versions/{id}",
  //       path: {
  //         id: itemVersion,
  //       },
  //     });
  //     console.log("partGroups2", partGroups);

  //     let results: Array<PartGroupModelType> = [];

  //     if (partGroups.part_groups) {
  //       results = partGroups.part_groups.map(getPartGroupModel);
  //     }

  //     if (limit) {
  //       results = results.slice(offset || 0, (offset || 0) + limit);
  //     }

  //     return {
  //       partGroups: results,
  //       apiResponse: partGroups,
  //     };
  //   }

  /**
   * @returns PartGroup
   * @throws ApiError
   */
  public static productsPartGroupsCreate({
    data,
  }: {
    data: PartGroup;
  }): CancelablePromise<PartGroup> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/part_groups/",
      body: data,
    });
  }

  /**
   * @returns PartGroup
   * @throws ApiError
   */
  public static productsPartGroupsRead({
    id,
  }: {
    /**
     * A unique integer value identifying this part group.
     */
    id: number;
  }): CancelablePromise<PartGroup> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/part_groups/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns PartGroup
   * @throws ApiError
   */
  public static productsPartGroupsUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this part group.
     */
    id: number;
    data: PartGroup;
  }): CancelablePromise<PartGroup> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/part_groups/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns PartGroup
   * @throws ApiError
   */
  public static productsPartGroupsPartialUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this part group.
     */
    id: number;
    data: PartGroup;
  }): CancelablePromise<PartGroup> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/part_groups/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static productsPartGroupsDelete({
    id,
  }: {
    /**
     * A unique integer value identifying this part group.
     */
    id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/part_groups/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static async productsPartsList({
    partGroup,
    limit,
    offset,
  }: {
    partGroup?: string;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<Array<PartModelType>> {
    const parts: partResponse = await __request(OpenAPI, {
      method: "GET",
      url: "/part_groups/{id}",
      path: {
        id: partGroup,
      },
    });

    let results: Array<PartModelType> = [];
    console.log("partsApi", parts);
    if (parts.parts) {
      results = parts.parts.map(getPartModel);
    }

    if (limit) {
      results = results.slice(offset || 0, (offset || 0) + limit);
    }

    return results;
  }

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartsCreate({
    data,
  }: {
    data: Part;
  }): CancelablePromise<Part> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/parts/",
      body: data,
    });
  }

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartsClone({
    data,
  }: {
    data: Part;
  }): CancelablePromise<Part> {
    console.log("Parts data", data);

    return __request(OpenAPI, {
      method: "POST",
      url: "/parts/",
      body: data,
    });
  }

  /**
   * @returns MeshMaterial
   * @throws ApiError
   */
  public static productsCopyPartThumbnails({
    data,
  }: {
    data: Part;
  }): CancelablePromise<Part> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/copy_part_thumbnails/",
      body: data,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartsRead({
    id,
  }: {
    /**
     * A unique integer value identifying this part.
     */
    id: number;
  }): CancelablePromise<Part> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/parts/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartsUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this part.
     */
    id: number;
    data: Part;
  }): CancelablePromise<Part> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/parts/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartsPartialUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this part.
     */
    id: number;
    data: Part;
  }): CancelablePromise<Part> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/parts/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static productsPartsDelete({
    id,
  }: {
    /**
     * A unique integer value identifying this part.
     */
    id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/parts/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */

  public static async productsPartsForAssociationGroupList({
    itemVersion,
    //material,
    limit,
    offset,
  }: {
    itemVersion?: string;
    //material?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): Promise<Array<Part>> {
    const parts: Part = await __request(OpenAPI, {
      method: "GET",
      url: "/item_versions/{id}",
      path: {
        id: itemVersion,
      },
    });

    let results: Array<PartModelType> = [];

    //if (part.parts) {
    //    results = parts.parts.map(getPartModel);
    //}

    if (limit) {
      results = results.slice(offset || 0, (offset || 0) + limit);
    }

    return results;
  }

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartAssociationGroupCreate({
    data,
  }: {
    /**
     * A unique integer value identifying this part.
     */
    data: Part;
  }): CancelablePromise<Part> {
    return __request(OpenAPI, {
      method: "POST",
      url: `/part_association_group/`,
      /*path: {
                'partId': partId,
            },*/
      body: data,
    });
  }

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartAssociationGroupModify({
    partId,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    partId: number;
    data: Part;
  }): CancelablePromise<Part> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: `/part_asssociation_group/${partId}/`,
      path: {
        partId: partId,
      },
      body: data,
    });
  }

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartAssociationCreate({
    partId,
    partAssociationGroupId,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    partId: number;
    partAssociationGroupId: number;
    data: Part;
  }): CancelablePromise<PartModelType> {
    return __request(OpenAPI, {
      method: "POST",
      url: `/part_associations/${partId}/${partAssociationGroupId}/`,
      path: {
        partId: partId,
        partAssociationGroupId: partAssociationGroupId,
      },
      body: data,
    });
  }

  /**
   * @returns Part
   * @throws ApiError
   */
  public static productsPartAssociationModify({
    partId,
    data,
  }: {
    /**
     * A unique integer value identifying this mesh material.
     */
    partId: number;
    data: Part;
  }): CancelablePromise<Part> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: `/part_asssociations/${partId}/`,
      path: {
        partId: partId,
      },
      body: data,
    });
  }

  /**
   * @returns any
   * @throws ApiError
   */
  public static productsVariantsList({
    nameIcontains,
    limit,
    offset,
  }: {
    nameIcontains?: string;
    /**
     * Number of results to return per page.
     */
    limit?: number;
    /**
     * The initial index from which to return the results.
     */
    offset?: number;
  }): CancelablePromise<{
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Array<Variant>;
  }> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/variants/",
      query: {
        name__icontains: nameIcontains,
        limit: limit,
        offset: offset,
      },
    });
  }

  /**
   * @returns Variant
   * @throws ApiError
   */
  public static productsVariantsCreate({
    data,
  }: {
    data: Variant;
  }): CancelablePromise<Variant> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/variants/",
      body: data,
    });
  }

  /**
   * @returns Variant
   * @throws ApiError
   */
  public static productsVariantsRead({
    id,
  }: {
    /**
     * A unique integer value identifying this variant.
     */
    id: number;
  }): CancelablePromise<Variant> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/variants/{id}/",
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Variant
   * @throws ApiError
   */
  public static productsVariantsUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this variant.
     */
    id: number;
    data: Variant;
  }): CancelablePromise<Variant> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/variants/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns Variant
   * @throws ApiError
   */
  public static productsVariantsPartialUpdate({
    id,
    data,
  }: {
    /**
     * A unique integer value identifying this variant.
     */
    id: number;
    data: Variant;
  }): CancelablePromise<Variant> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/variants/{id}/",
      path: {
        id: id,
      },
      body: data,
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static productsVariantsDelete({
    id,
  }: {
    /**
     * A unique integer value identifying this variant.
     */
    id: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/variants/{id}/",
      path: {
        id: id,
      },
    });
  }
}
