import { ReactElement } from "react";

export interface MenuItemParams {
  label: ReactElement | string;
  key: string;
  icon?: ReactElement | null;
  children?: any[];
  type?: string;
  onClick?: () => void;
}

// Is used to crate menu item
export const createMenuItem = ({
  label,
  key,
  icon,
  children,
  type,
  onClick,
}: MenuItemParams): MenuItemParams => ({
  key,
  icon,
  children,
  label,
  type,
  onClick,
});
