import axios from "axios";
import { BACKEND_URL, GET_METHOD, PATCH_METHOD } from "sharedConstants";

const patchCompany = async function (id, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/companies/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: data,
  });
  return response;
};

const getCompany = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/companies/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getAllCompanies = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/companies`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export { getCompany, getAllCompanies, patchCompany };
