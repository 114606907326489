import React, { Component } from "react";

class TextAreaInput extends Component {
  state = {};
  render() {
    return (
      <div className="product-textarea-input">
        <textarea
          onChange={(ev) => {
            this.props.setParentValue(ev.target.value);
          }}
          defaultValue={this.props.value}
        />
      </div>
    );
  }
}

export default TextAreaInput;
