import axios from "axios";
import {
  BACKEND_URL,
  GET_METHOD,
  POST_METHOD,
  DELETE_METHOD,
  PATCH_METHOD,
} from "sharedConstants";

const getItemCopy = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/items/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const patchItem = async function (id, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/items/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const patchItemVersion = async function (id, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/item_versions/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const getItemVersion = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_versions/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getAllItemStatuses = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_statuses`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const postItemVersion = async function (versionData) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/item_versions`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: versionData,
  });
  return response;
};

const postItemNote = async function (noteBody, itemVersionId) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/item_notes`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      note: noteBody,
      item_version_id: itemVersionId,
    },
  });
  return response;
};

const postItem = async function (itemData) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/items`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: itemData,
  });
  return response;
};

const deleteItem = async function (id) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/items/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const deleteLayout = async function (id) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/item_versions/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getVariants = async function (itemId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/variants/${itemId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getVersion = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_versions/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getItemCollection = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_collections/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getItemCollectionConfigurations = async function (
  item_collection_id,
  params
) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_collection_configurations/${item_collection_id}`,
    params,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getItemCollectionConfigurationsPublic = async function (
  item_collection_id,
  params
) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_collection_configurations_public/${item_collection_id}`,
    params,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const patchItemCollection = async function (id, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/item_collections/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const postItemCollection = async function (orderId, name, list_order) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/item_collections`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      order_id: orderId,
      name,
      list_order: list_order,
    },
  });
  return response;
};

const getItemCollectionsForOrder = async function (orderId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_collections?order_id=${orderId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const postPartGroup = async function (name, itemVersionId, list_order) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/part_groups`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      item_version_id: itemVersionId,
      name,
      // optional,
      list_order: list_order,
    },
  });
  return response;
};
// const postPartGroup = async function (name, itemVersionId, optional) {
//   const response = await axios({
//     method: POST_METHOD,
//     url: `${BACKEND_URL}/part_groups`,
//     headers: { Authorization: `Bearer ${this.token}` },
//     data: {
//       item_version_id: itemVersionId,
//       name,
//       optional,
//     },
//   });
//   return response;
// };

const patchPartGroup = async function (partGroupId, patch) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/part_groups/${partGroupId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: patch,
  });
  return response;
};

const deletePartGroup = async function (partGroupId) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/part_groups/${partGroupId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getPart = async function (itemId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/parts/${itemId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const postPart = async function (
  partName,
  partGroupId,
  partPriceMod,
  list_order
) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/parts`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      name: partName,
      part_group_id: partGroupId,
      price_mod: partPriceMod,
      list_order: list_order,
    },
  });
  return response;
};

const patchPart = async function (partId, patch) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/parts/${partId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: patch,
  });
  return response;
};

const deletePart = async function (partId) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/parts/${partId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const moveItemCollectionToOrder = async function (
  itemCollectionId,
  newOrderId
) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/move_item_collection_order`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      item_collection_id: itemCollectionId,
      new_order_id: newOrderId,
    },
  });
  return response;
};

const archiveItemCollectionsOrVariants = async function (
  archived,
  itemCollectionIds = [],
  variantIds = []
) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/archive_item_collections_or_variants`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      item_collection_ids: itemCollectionIds,
      variant_ids: variantIds,
      archived,
    },
  });
  return response;
};

const patchThumbnail = async function (thumbnailId, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/item_thumbnails/${thumbnailId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const postThumbnail = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/item_thumbnails`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const postItemCollectionThumbnail = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/item_collection_thumbnails/`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const getItemThumbnail = async function (thumbnailId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_thumbnails/${thumbnailId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const postItemCreateSimpleQWERTY = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/item_create_simple`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });

  return response;
};

const postItemVersionUpdateStatus = async function (itemVersionId, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/item_versions/${itemVersionId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });

  return response;
};

const postItemAnnotation = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/annotations`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });

  return response;
};

const getLibraryMaterials = async function (companyId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/matterials/${companyId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getLibraryMaterialCategories = async function (companyId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/material_category/company/${companyId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

/*const getLibraryMaterialCategories = async function (companyId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/material_category/${companyId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};*/

const bulkDisableETLForItem = async function ({ itemVersionId, disableETL }) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/bulk_disable_etl`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      item_version_id: itemVersionId,
      disable_etl: disableETL,
    },
  });
  return response;
};

const bulkCopyMaterials = async function ({
  company_id,
  origin_part_id,
  destination_part_id,
  origin_material_ids,
}) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/bulk_copy_materials`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      company_id,
      origin_part_id,
      destination_part_id,
      origin_material_ids,
    },
  });

  return response;
};

const deleteMaterial = async function (materialId) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/materials/${materialId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const deleteMaterialCategory = async function (materialCategoryId) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/material_category/${materialCategoryId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getPartGroupCopy = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/part_groups/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getPartCopy = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/parts/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getMaterialCopy = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/materials/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export {
  getItemCopy,
  getItemVersion,
  getAllItemStatuses,
  postItemVersion,
  postItemNote,
  postItem,
  deleteItem,
  getVariants,
  getVersion,
  getItemCollection,
  getItemCollectionConfigurations,
  getItemCollectionConfigurationsPublic,
  getPart,
  patchThumbnail,
  postThumbnail,
  postItemCreateSimpleQWERTY,
  postItemVersionUpdateStatus,
  postItemCollectionThumbnail,
  getItemThumbnail,
  patchItem,
  patchItemVersion,
  postItemAnnotation,
  postItemCollection,
  getItemCollectionsForOrder,
  patchItemCollection,
  postPartGroup,
  deletePartGroup,
  postPart,
  deletePart,
  getLibraryMaterials,
  getLibraryMaterialCategories,
  patchPartGroup,
  archiveItemCollectionsOrVariants,
  moveItemCollectionToOrder,
  bulkDisableETLForItem,
  patchPart,
  bulkCopyMaterials,
  deleteLayout,
  deleteMaterial,
  deleteMaterialCategory,
  getPartGroupCopy,
  getPartCopy,
  getMaterialCopy,
};
