import React, { useCallback, useState } from "react";
import { notification } from "antd";
import {
  patchItemVersion,
  patchItem,
  postLayoutTree,
  postReferenceFiles,
  deleteLayout,
} from "../utils/api";
import { useLayoutsContext } from "../contexts/LayoutsContext";
import { CollectionType, ItemRemoveType, LayoutType } from "../types";
import { useUIContext } from "../contexts/UIContext";
import { deleteItem } from "services/item";

export const useLayouts = () => {
  const { setSelectedKey, setLayouts, updateItemByUUID } = useLayoutsContext();
  const { taskWrapper } = useUIContext();

  const [selectedItem, setSelectedItem] = useState<ItemRemoveType | null>(null);
  const [selectedLayout, setSelectedLayout] = useState<LayoutType | null>(null);

  const addLayoutTree = useCallback(
    async (
      name: string,
      {
        orderId,
        itemCollectionId,
      }: { orderId: number; itemCollectionId: number }
    ) => {
      const result = await postLayoutTree(name, {
        orderId,
        itemCollectionId,
      });

      if (result) {
        const { item, layout } = result;

        if (item && layout) {
          setLayouts((prevLayouts) => [...prevLayouts, layout]);
          console.log("Setting selected layout: ", layout);
          setSelectedLayout(layout);
          console.log("Setting selected item: ", item);
          setSelectedItem(item);
          notification.success({
            message: "Layout successfully created!",
          });
        }
      }
    },
    [setLayouts, setSelectedLayout, setSelectedItem]
  );

  const updateLayout = useCallback(
    async (
      layout: Pick<
        LayoutType,
        "uuid" | "apiItemId" | "apiVersionId" | "name" | "basePrice"
      >
    ) => {
      await patchItem(layout);
      await patchItemVersion(layout);
      updateItemByUUID(layout.uuid, (_layout) => ({
        ..._layout,
        ...layout,
      }));
      notification.success({
        message: "Layout successfully updated!",
      });
    },
    [updateItemByUUID]
  );

  const addReferenceFiles = useCallback(
    async ({
      item,
      fileData,
      fileName,
    }: {
      item: ItemRemoveType;
      fileData: Blob;
      fileName: string;
    }) => {
      const response = await postReferenceFiles({
        id: item.apiItemId,
        fileData,
        key: "reference_file",
        file_name: fileName,
      });
      const {
        id,
        url: {
          url: baseUrl,
          fields: { key, AWSAccessKeyId, signature },
        },
      } = response.data;
      // const url = `${baseUrl}${key}?AWSAccessKeyId=${AWSAccessKeyId}&Signature=${signature}`;
      const filenameSplit = key.split("/");
      const file_name = filenameSplit[filenameSplit.length - 1];
      const referenceFile = { id, url: "", file_name };
      updateItemByUUID(item.uuid, (_item) => {
        (_item as ItemRemoveType).files.reference_file?.push(referenceFile);
        return _item;
      });
      return referenceFile;
    },
    [updateItemByUUID]
  );

  const removeLayout = useCallback(
    async ({ item, layout }: { item: ItemRemoveType; layout: LayoutType }) => {
      console.log("Starting removeLayout function with layout: ", item, layout);
      if (item && layout) {
        // @ts-ignore
        const layoutApiResponse = await deleteLayout(layout.apiVersionId);
        console.log("Response from deleteLayout API call: ", layoutApiResponse);
        //await deleteItem(layout.apiItemId);
        if (layoutApiResponse) {
          updateItemByUUID(item.uuid, (_item) => {
            console.log("Inside updateItemByUUID callback with _item: ", _item);
            if (_item) {
              (_item as ItemRemoveType).layouts = (
                _item as ItemRemoveType
              ).layouts.filter((l) => layout.uuid !== l.uuid);
              console.log("Updated _item after removing layout: ", _item);
              return _item;
            }
            console.log("Returning empty object as _item is not defined");
            return {};
          });
          setSelectedKey(item.uuid);
          notification.success({
            message: "Layout successfully deleted!",
          });
        }
      }
    },
    [setSelectedKey, updateItemByUUID]
  );

  return {
    addLayoutTree: taskWrapper(addLayoutTree),
    updateLayout: taskWrapper(updateLayout),
    addReferenceFiles: taskWrapper(addReferenceFiles),
    removeLayout: taskWrapper(removeLayout),
  };
};
