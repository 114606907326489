import React, { useEffect, useState } from "react";
import { Button, Card, Layout } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { RoomsSiderMenu } from "./RoomsSiderMenu";
import { ItemsContextProvider, useItemsContext } from "./contexts/ItemsContext";
import { UIContextProvider } from "./contexts/UIContext";
import { RoomView } from "./views/RoomView";
import { SpaceView } from "./views/SpaceView";
import { EnvironmentView } from "./views/EnvironmentView";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { generateNewRoomItem, generateNewSpaceItem } from "./utils/dataModel";
import { ItemsBreadcrumb } from "./components/ItemsBreadcrumb";
import { ItemTypeLabel } from "./types";
const { Content } = Layout;

export const ShowroomLookAndFeelCore = () => {
  const {
    spaceItem,
    selectedItem,
    setSpaceItem,
    selectItem,
    modalContextHolder,
  } = useItemsContext();
  const [loading, setLoading] = useState<boolean>(false);

  // Load items
  useEffect(() => {
    // Dump data
    if (!spaceItem) {
      const newSpaceItem = generateNewSpaceItem();
      newSpaceItem.rooms = [
        generateNewRoomItem(),
        generateNewRoomItem(),
        generateNewRoomItem(),
      ];
      setSpaceItem(newSpaceItem);
      selectItem(newSpaceItem.uuid, true);
      setLoading(false);
    }
  }, [selectItem, setSpaceItem, spaceItem]);
  return (
    <Layout className="layout layout-showroom layout-showroom--look-and-feel">
      {loading ? (
        <FullAreaSpinner />
      ) : (
        <>
          <RoomsSiderMenu />
          <Content>
            {selectedItem ? (
              <>
                <ItemsBreadcrumb
                  selectedItem={selectedItem}
                  onSelect={(uuid) => {
                    selectItem(uuid);
                  }}
                />
                {/* <Card
                  className="content-wrapper-card"
                  // size="small"
                  title={
                    <span>
                      <b>{ItemTypeLabel[selectedItem.type]}:</b>{" "}
                      <i>{selectedItem.name}</i>
                    </span>
                  }
                > */}
                {selectedItem.type === "space" ? (
                  <SpaceView
                    key={selectedItem.uuid}
                    selectedItem={selectedItem}
                  />
                ) : selectedItem.type === "room" ? (
                  <RoomView
                    key={selectedItem.uuid}
                    selectedItem={selectedItem}
                  />
                ) : selectedItem.type === "environment" ? (
                  <EnvironmentView
                    key={selectedItem.uuid}
                    selectedItem={selectedItem}
                  />
                ) : null}
                {/* </Card> */}
              </>
            ) : null}
          </Content>
        </>
      )}
      {modalContextHolder}
    </Layout>
  );
};

export const ShowroomLookAndFeel = ({ ...rest }) => {
  return (
    <UIContextProvider>
      <ItemsContextProvider>
        <ShowroomLookAndFeelCore {...rest} />
      </ItemsContextProvider>
    </UIContextProvider>
  );
};
