import React, { useState } from "react";
import { Layout, Divider, Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { CommenterForm } from "../components/commenter";
import { useSpaceContext } from "../contexts/SpaceContext";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { UpdateModelModal } from "../components/updateModelModal";
import { ProductsList } from "./products/productsList";
import { SidebarProductEditor } from "./products/sidebarProductEditor";
import { ProductsHistoryList } from "./products/productsHistoryList";
import { Comment, ShowroomProduct } from "../types";
import { useAppSelector } from "hooks";
const { Sider } = Layout;
const { Panel } = Collapse;

interface SiderMenuProps {
  onSubmitComment?: (comment: Comment) => void;
  selectedProduct?: ShowroomProduct | null;
  onChangeSelectedProduct?: (value: ShowroomProduct) => void;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const SiderMenu = ({
  onSubmitComment = () => {},
  selectedProduct = null,
  onChangeSelectedProduct = () => {},
  children = null,
}: SiderMenuProps) => {
  /*************** Hooks *****************/
  const { space, isLoadingSpace } = useSpaceContext();
  const { admin } = useAppSelector((state: any) => state.profile);
  /***************************************/

  /*************** State *****************/
  const [openUpdateModelModal, setOpenUpdateModelModal] =
    useState<boolean>(false);
  /***************************************/

  return (
    <Sider width={400} className="siderMenu">
      {isLoadingSpace ? (
        <FullAreaSpinner />
      ) : (
        <>
          <h2>{space?.name}</h2>
          <h4>
            <b>Status:</b> Processing
          </h4>
          <h4>
            <b>Version Number:</b> {space?.current_version_id}
          </h4>
          <Collapse
            accordion
            ghost
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined /> : <PlusOutlined />
            }
            expandIconPosition="end"
          >
            <Panel header="Add Products" key="products">
              <ProductsList />
            </Panel>
            <Panel header="Add Comment" key="comment">
              <CommenterForm
                onSubmit={(comment: Comment) => {
                  onSubmitComment(comment);
                }}
              />
            </Panel>
          </Collapse>

          {selectedProduct && (
            <>
              <Divider />
              <SidebarProductEditor
                value={selectedProduct}
                onChange={onChangeSelectedProduct}
              />
            </>
          )}

          {/* <Button shape="round" className="upgrade-button" onClick={() => {}}>
            PENDING APPROVAL
          </Button> */}
          {children}
          <ProductsHistoryList />
          {openUpdateModelModal && (
            <UpdateModelModal
              open={true}
              onCancel={() => {
                setOpenUpdateModelModal(false);
              }}
            />
          )}
        </>
      )}
    </Sider>
  );
};
