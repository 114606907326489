import React, { Component } from "react";
import { connect } from "react-redux";
import GenericFile from "assets/svg/GenericFile";
import BRDropzone from "components/Dropzone";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { imageExtensions } from "sharedConstants";
import { getParamsFromPathname } from "utils";
import "./fileLocker.scss";

class FileLocker extends Component {
  state = {
    selectedPreviewUrl: null,
  };

  renderFile = (f, index) => {
    let split = f.url.split(".");
    let type = split[split.length - 1].split("?")[0];
    let isImage = imageExtensions.includes(type) || f.localImageUploadBlob;
    return (
      <a className="file-locker-item" key={`file-index-${index}`} href={f.url}>
        {isImage ? (
          <div
            className="locker-item-image"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setState({
                selectedPreviewUrl: f.url,
              });
            }}
            style={{ background: `url(${f.url})` }}
          />
        ) : (
          <div className="locker-item-image">
            <GenericFile />
          </div>
        )}
        <div className="locker-item-details">
          <p>{f.file_name}</p>
        </div>
      </a>
    );
  };

  renderFiles = () => {
    let files = [
      ...this.props.itemDetails.file_locker.product_image,
      ...this.props.itemDetails.file_locker.reference_file,
    ];
    return (
      <div className="filelocker-items-container">
        {files.map((f, index) => {
          return this.renderFile(f, index);
        })}
      </div>
    );
  };

  renderImagePreview = () => {
    if (!this.state.selectedPreviewUrl) {
      return null;
    }
    return (
      <div
        className="file-locker-image-preview-container"
        onClick={() => {
          this.setState({ selectedPreviewUrl: null });
        }}
      >
        <div className="file-locker-image-preview-container__inner">
          <img src={this.state.selectedPreviewUrl} />
        </div>
      </div>
    );
  };

  render() {
    if (this.props.loading) {
      return (
        <div className="file-locker-container">
          <FullAreaSpinner />
        </div>
      );
    }
    return (
      <div className="file-locker-container">
        <h1>File Locker</h1>
        <h2>(Model Reference Materials)</h2>
        <BRDropzone itemId={this.props.itemDetails.id} />
        {this.renderFiles()}
        {this.renderImagePreview()}
      </div>
    );
  }
}

const mapStateToProps = ({ items, variants }) => {
  let {
    orderIdInUrl,
    companyIdInUrl,
    itemCollectionIdInUrl,
    versionIdInUrl,
    variantIdInUrl,
    itemIdInUrl,
  } = getParamsFromPathname(window.location.pathname);

  let loading =
    !companyIdInUrl ||
    !orderIdInUrl ||
    !itemCollectionIdInUrl ||
    !versionIdInUrl ||
    !variantIdInUrl ||
    !items[itemIdInUrl] ||
    !variants[variantIdInUrl] ||
    !items[itemIdInUrl].versions[versionIdInUrl] ||
    !items[itemIdInUrl].versions[versionIdInUrl]._detailsFetched;

  if (!loading) {
    return {
      loading,
      itemDetails: items[itemIdInUrl],
    };
  } else {
    return {
      loading,
    };
  }
};

export default connect(mapStateToProps, {})(FileLocker);
