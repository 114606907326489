import React from "react";
import { Typography, Divider } from "antd";
const { Title, Paragraph } = Typography;

interface DetailSectionProps {
  title?: string | React.ReactElement;
  description?: string | React.ReactElement;
  children?: React.ReactElement | null;
  divider?: boolean;
  disabled?: boolean;
}

export const DetailSection = ({
  title,
  description,
  children,
  divider = true,
  disabled = false,
}: DetailSectionProps) => (
  <section className={`detail-section ${disabled ? "disabled" : ""}`}>
    {title && <Title level={4}>{title}</Title>}
    {description && <Paragraph>{description}</Paragraph>}
    {children}
    {divider && <Divider />}
  </section>
);
