import axios from "axios";
import {
  BACKEND_URL,
  GET_METHOD,
  POST_METHOD,
  DELETE_METHOD,
} from "sharedConstants";

const getAllUsers = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/users`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};
//add user to DB
const postUser = async function (user) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/users`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { email: user.email },
  });
  return response;
};

//send amplify credential to DB
const postUserCredential = async function (username, userSub) {
  const response = await axios({
    method: POST_METHOD,
    headers: { Authorization: `Bearer ${this.token}` },
    url: `${BACKEND_URL}/new_user`,
    data: { email: username, userSub: userSub },
  });

  return response;
};

const postUserCompany = async function (userId, companyId) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/user_companies/${userId}/${companyId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const deleteUserCompany = async function (userId, companyId) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/user_companies/${userId}/${companyId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export {
  getAllUsers,
  postUser,
  postUserCompany,
  deleteUserCompany,
  postUserCredential,
};
