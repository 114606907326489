/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Annotation } from './Annotation';

export type Comment = {
    readonly id?: number;
    readonly annotations?: Array<Annotation>;
    readonly created_at?: string;
    readonly updated_at?: string;
    is_active?: boolean;
    is_deleted?: boolean;
    deleted_at?: string | null;
    old_id?: number | null;
    name?: string | null;
    text?: string | null;
    status_field?: Comment.status_field;
    user?: number | null;
    showroom_version: number;
    images: Array<string>;
};

export namespace Comment {

    export enum status_field {
        CREATED = 'Created',
        CLIENT_REVIEW = 'Client Review',
        BITREEL_REVIEW = 'Bitreel Review',
        FINALIZED = 'Finalized',
    }


}

