import React, { useMemo } from "react";
import {
  InsertRowBelowOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  CloseOutlined,
  RetweetOutlined,
  DragOutlined,
} from "@ant-design/icons";
import { MenuActionType, FocusedProductType } from "modules/showrooms/types";
import { useEngineContext } from "modules/showrooms/contexts/EngineContext";
import { Matrix, Vector3 } from "@babylonjs/core";
import { Tooltip } from "antd";

interface CircleMenuProps {
  value: FocusedProductType;
  onToggle: () => void;
  onClick?: (value: MenuActionType) => void;
}

export const CircleMenu = ({
  value,
  onToggle,
  onClick = () => {},
}: CircleMenuProps) => {
  const { scene } = useEngineContext();

  const isActive = useMemo(() => value?.mode === "CIRCLE_MENU", [value?.mode]);

  const isHiddenMode = useMemo(
    () =>
      value?.mode
        ? ["SMART_ROTATE", "FREE_MOVE", "SMART_MOVE"].includes(value.mode)
        : false,
    [value?.mode]
  );

  const coordinates2D = useMemo(() => {
    if (value && isActive && scene) {
      const { x, y, z } = value.showroomProduct.placement.position;
      const width = scene.getEngine().getRenderWidth();
      const height = scene.getEngine().getRenderHeight();
      const pos = Vector3.Project(
        new Vector3(x, y, z), //vector to project
        Matrix.Identity(), //world matrix
        scene.getTransformMatrix(), //transform matrix
        // @ts-ignore
        { x: 0, y: 0, width, height } //viewport
      );
      return { x: pos.x, y: pos.y };
    }
  }, [isActive, scene, value]);

  return (
    <div className={`circle-menu ${isHiddenMode ? "hidden-mode" : ""}`}>
      <div
        className={`circle-menu_product ${isActive ? "expanded" : ""}`}
        onClick={onToggle}
      >
        <div
          className="menu"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            top: `calc(${coordinates2D?.y}px - 50px)` || "unset",
            left: `calc(${coordinates2D?.x}px - 50px)` || "unset",
          }}
        >
          <span className="menu-item" onClick={() => onClick("SMART_ROTATE")}>
            <Tooltip
              placement="top"
              title="Rotate to align walls"
              arrow={false}
            >
              <RetweetOutlined />
            </Tooltip>
          </span>
          <span className="menu-item" onClick={() => onClick("ROTATE_FORWARD")}>
            <Tooltip placement="topLeft" title="Rotate 45º" arrow={false}>
              <RotateRightOutlined />
            </Tooltip>
          </span>
          <span className="menu-item" onClick={() => onClick("FREE_MOVE")}>
            <Tooltip
              placement="topLeft"
              title="Move to another position"
              arrow={false}
            >
              <DragOutlined />
            </Tooltip>
          </span>
          <span className="menu-item" onClick={() => onToggle()}>
            <Tooltip placement="bottom" title="Close menu" arrow={false}>
              <CloseOutlined />
            </Tooltip>
          </span>
          <span className="menu-item" onClick={() => onClick("SMART_MOVE")}>
            <Tooltip
              placement="topRight"
              title="Snap into the floor"
              arrow={false}
            >
              <InsertRowBelowOutlined />
            </Tooltip>
          </span>
          <span
            className="menu-item"
            onClick={() => onClick("ROTATE_BACKWARD")}
          >
            <Tooltip placement="topRight" title="Rotate -45º" arrow={false}>
              <RotateLeftOutlined />
            </Tooltip>
          </span>
        </div>
      </div>
      <Tooltip placement="left" title="Dismiss" arrow={false}>
        <div
          className="dismiss"
          onClick={() => {
            console.log("Dismiss");
            onToggle();
          }}
        >
          <CloseOutlined />
        </div>
      </Tooltip>
    </div>
  );
};
