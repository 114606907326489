import {
  ItemCollectionModel,
  ItemModel,
  ItemVersionModel,
  PartGroupModel,
  PartModel,
  MeshMaterialModel,
} from "servicesNew/api";

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type ModelType =
  | "collection"
  | "item"
  | "itemVersion"
  | "partGroup"
  | "part"
  | "meshMaterial"
  | "partMaterial";

export enum ModelTypeLabel {
  collection = "Collection",
  item = "Item",
  itemVersion = "Version",
  partGroup = "Part Group",
  part = "Part",
  meshMaterial = "Mesh Material",
  partMaterial = "Part Material",
}

export interface BaseModelType {
  _uuid: string;
  readonly id: number;
  name: string | null;
}

export interface CollectionModelType
  extends BaseModelType,
    Required<ItemCollectionModel> {
  childrens: ItemModelType[];
}

export interface ItemModelType extends BaseModelType, Required<ItemModel> {
  childrens: ItemVersionModelType[];
}

export interface ItemVersionModelType
  extends BaseModelType,
    Required<ItemVersionModel> {
  childrens: PartGroupModelType[];
}

export interface PartGroupModelType
  extends BaseModelType,
    Required<PartGroupModel> {
  childrens: PartModelType[];
}

export interface PartModelType extends BaseModelType, Required<PartModel> {
  childrens: MeshMaterialModelType[];
}

export interface MeshMaterialModelType
  extends BaseModelType,
    Required<MeshMaterialModel> {}

export interface SelectedItem extends BaseModelType {
  type: ModelType;
  item: MixType;
  data: {
    collection: CollectionModelType;
    item?: ItemModelType;
    itemVersion?: ItemVersionModelType;
    partGroup?: PartGroupModelType;
    part?: PartModelType;
    meshMaterial?: MeshMaterialModelType;
  };
}
export type MeshMaterial = {
  _uuid: string;
  id: number;
  created_at: string;
  is_active: boolean;
};

export type ThirdLevelChild = {
  base_price: number;
  _uuid: string;
  id: number;
  childrens: MeshMaterial[];
};

export type SecondLevelChild = {
  id: number;
  _uuid: string;
  childrens: ThirdLevelChild[];
};

export type PartGroup = {
  base_price: number;
  childrens: SecondLevelChild[];
};
export type ItemExtractedData = {
  level0: Array<{ id: number; _uuid: string }>;
  level1: Array<{ id: number; _uuid: string }>;
  level2: Array<{ id: number; _uuid: string }>;
  level3: Array<{ id: number; _uuid: string }>;
  level4: Array<{ id: number; _uuid: string }>;
};
export type PartGroupExtractedData = {
  level0: Array<{ id: number; _uuid: string }>;
  level1: Array<{ id: number; _uuid: string }>;
  level2: Array<{ id: number; _uuid: string }>;
};
export type MixType =
  | CollectionModelType
  | ItemModelType
  | ItemVersionModelType
  | PartGroupModelType
  | PartModelType
  | MeshMaterialModelType;

export type MixTypePartial = Partial<MixType>;
