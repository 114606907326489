import React from "react";
import { Button, Form, Input } from "antd";
import { useCollection } from "../hooks";
import { CollectionType, SelectedItem } from "./../types";
import { useUIContext } from "../contexts/UIContext";

interface CollectionViewProps {
  //collection?: CollectionType;
  selectedItem?: SelectedItem;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const CollectionView = ({ selectedItem }: CollectionViewProps) => {
  const { updateCollection } = useCollection();
  const { isDirty, setIsDirty } = useUIContext();
  const collection = selectedItem?.item as CollectionType;

  const onFinish = async (values: any) => {
    await updateCollection({
      uuid: collection.uuid,
      apiItemId: collection.apiItemId,
      name: values.name,
    });
    setIsDirty(false);
  };

  return (
    <div className="collection-view">
      <Form
        name="collection-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: collection.name,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <div className="commit-buttons">
            <Button disabled={!isDirty} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              htmlType="reset"
              onClick={() => {
                setIsDirty(false);
              }}
            >
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
