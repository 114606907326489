import { store } from "store";
import ApiService from "services/ApiService";
import { history } from "services/history";
import {
  ADD_OR_UPDATE_ORDER,
  LOGIN_STAGE,
  SET_APP_INITIAL_LOADING,
  SET_APP_LOADING_DATA,
  SET_MODAL_STATE,
  SET_SELECTED_COMPANY_ID,
  SET_SELECTED_ITEM_VERSION,
  UPDATE_APP_MODULE_STATE,
  UPDATE_PRODUCT_FILTER,
} from "sharedConstants";
import { OPEN_CREATE_USER_MODAL, OPEN_EDIT_USER_MODAL } from "sharedConstants";
import { getParamsFromPathname, replaceInPath } from "utils";

import ADB from "awesome-debounce-promise";
import _ from "lodash";

export const setAppInitialLoadingState = (appIsInitialLoading) => {
  return {
    type: SET_APP_INITIAL_LOADING,
    appIsInitialLoading,
  };
};

export const setSelectedItem = (selectedItemVersion, selectedItemId) => {
  return {
    type: SET_SELECTED_ITEM_VERSION,
    selectedItemVersion,
    selectedItemId,
  };
};

const checkVariantFilters = async ({ dispatch, orderIdInUrl }) => {
  let searchString = "?page=1&per_page=1000";
  let {
    app: { productFilters },
  } = store.getState();
  _.forEach(productFilters, (f) => {
    if (f.type === "variantSearch") {
      let val;
      if (f.filterType === "text") {
        val = f.value.trim();
      } else {
        val = f.value;
      }
      if (val !== "" && val !== -1) {
        searchString += `&${f.searchKey}=${val}`;
      }
    }
  });
  if (searchString !== "?page=1&per_page=1000") {
    let res = await ApiService.searchVariantInOrder(orderIdInUrl, searchString);
    let final = {};
    res.data.forEach((v) => {
      if (!final[v.item_collection_id]) {
        final[v.item_collection_id] = {
          [v.id]: v,
        };
      } else {
        final[v.item_collection_id][v.id] = v;
      }
    });
    dispatch(
      updateModuleState("product", {
        latestVariantResponse: !res.data.length ? { qq: "cachoo" } : final,
      })
    );
  } else {
    dispatch(
      updateModuleState("product", {
        latestVariantResponse: {},
      })
    );
  }
};

const checkVariantFiltersDebounced = ADB(checkVariantFilters, 1000);

export const setProductFilter =
  (filterKey, filterValue) => async (dispatch) => {
    let { orderIdInUrl } = getParamsFromPathname(window.location.pathname);
    dispatch({
      type: UPDATE_PRODUCT_FILTER,
      filterKey,
      filterValue,
    });
    checkVariantFiltersDebounced({ dispatch, orderIdInUrl });
  };

export const addOrder = (orderId, shouldUpdateUrl) => async (dispatch) => {
  let { companyIdInUrl, orderIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );

  if (orderIdInUrl !== orderId && shouldUpdateUrl) {
    let newPath = replaceInPath(
      "orderIdInUrl",
      orderId,
      window.location.pathname
    );

    // dispatch(replace(newPath)); // TODO
    history.replace(newPath);
  }

  let orderRes = await ApiService.getOrder(orderId);

  let final = {};
  orderRes.data.item_collections.forEach((item) => {
    final[item.id] = item;
  });
  orderRes.data.item_collections = final;

  dispatch({
    type: ADD_OR_UPDATE_ORDER,
    companyId: companyIdInUrl,
    orderId,
    orderDetails: orderRes.data,
  });
};

export const setAppIsLoadingData = (isLoadingData) => {
  return {
    type: SET_APP_LOADING_DATA,
    isLoadingData,
  };
};

export const setModalState = (modalName, state) => {
  return {
    type: SET_MODAL_STATE,
    state,
    modalName,
  };
};

export const updateModuleState = (moduleName, moduleState) => {
  return {
    type: UPDATE_APP_MODULE_STATE,
    moduleState,
    moduleName,
  };
};

export const setSelectedCompanyIdOnly = (selectedCompanyId) => {
  return {
    type: SET_SELECTED_COMPANY_ID,
    selectedCompanyId,
  };
};

export const setSelectedCompanyId = (selectedCompanyId, hist) => (dispatch) => {
  let state = store.getState();
  let selectedCompany = state.companies[selectedCompanyId];
  if (selectedCompany) {
    let keys = Object.keys(selectedCompany.orders);
    if (selectedCompany && keys.length) {
      dispatch(addOrder(selectedCompany.orders[keys[0]].id));
    }
  }

  dispatch({
    type: SET_SELECTED_COMPANY_ID,
    selectedCompanyId,
  });
};

//User Modal State
export const openEditUserModal = (isOpened, uid) => {
  return {
    type: OPEN_EDIT_USER_MODAL,
    isOpened,
    uid,
  };
};

//User Modal State
export const openCreateUserModal = (isOpened) => {
  return {
    type: OPEN_CREATE_USER_MODAL,
    isOpened,
  };
};

//Login Module Stage
export const selectLoginStage = (stage) => {
  return {
    type: LOGIN_STAGE,
    stage,
  };
};
