import ApiService from "services/ApiService";
import { SET_PROFILE } from "sharedConstants";

export const setProfile = (profile) => {
  return {
    type: SET_PROFILE,
    profile,
  };
};

export const getAndSetProfile = () => {
  return async (dispatch) => {
    function onSuccess(profile) {
      dispatch(setProfile(profile));
      return profile;
    }
    try {
      const profileRes = await ApiService.getMe();
      return onSuccess(profileRes.data);
    } catch (error) {
      console.warn(error);
    }
  };
};
