/* eslint-disable no-async-promise-executor */
import ApiService from "services/ApiService";

export default async function patchItemVersionForEdit({ layouts }) {
  // patch item collection name

  const patchPart = (part) => {
    return new Promise(async (res) => {
      await ApiService.patchPart(part.apiId, {
        name: part.name,
        price_mod: part.priceMod,
      });
      setTimeout(() => {
        res(true);
      }, 100);
    });
  };

  const patchMaterial = (part, mat) => {
    return new Promise(async (res) => {
      await ApiService.patchMaterial(mat.apiId, {
        name: mat.name,
      });

      await ApiService.patchPartMaterialAccociation({
        partId: part.apiId,
        materialId: mat.apiId,
        priceMod: mat.priceMod,
      });
      setTimeout(() => {
        res(true);
      }, 100);
    });
  };

  return new Promise(async (res) => {
    await mapSeries(layouts, async (layout) => {
      await ApiService.patchItem(layout.apiItemId, {
        name: layout.name,
      });
      await ApiService.patchItemVersion(layout.apiVersionId, {
        base_price: layout.basePrice,
      });

      await mapSeries(layout.partGroups, async (pg) => {
        await ApiService.patchPartGroup(pg.apiId, {
          name: pg.name,
          optional: pg.optional,
        });

        await mapSeries(pg.parts, async (part) => {
          await patchPart(part);

          await mapSeries(part.materials, async (mat) => {
            await patchMaterial(part, mat);
          });
        });
      });
    });
    res(true);
  });
}

const mapSeries = async (iterable, action) => {
  for (const x of iterable) {
    await action(x);
  }
};
