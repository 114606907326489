/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef } from "react";
import { Vector3, ArcRotateCamera } from "@babylonjs/core";
import { CameraActionsType } from "./types";

interface BabylonCamera3DProps {
  cameraRef?: React.MutableRefObject<CameraActionsType | null>;
}

export const BabylonCamera3D = ({
  cameraRef = { current: null },
}: BabylonCamera3DProps) => {
  const arcCameraRef = useRef<ArcRotateCamera | null>(null);

  useEffect(() => {
    cameraRef.current = {
      zoomIn: () => {
        if (arcCameraRef.current) {
          arcCameraRef.current.radius -= 1;
        }
      },
      zoomOut: () => {
        if (arcCameraRef.current) {
          arcCameraRef.current.radius += 1;
        }
      },
      zoomReset: () => {
        if (arcCameraRef.current) {
          arcCameraRef.current.radius = 55;
        }
      },
    };
  }, [cameraRef]);

  return (
    <arcRotateCamera
      name="camera-3d"
      target={Vector3.Zero()}
      alpha={Math.PI / 2}
      beta={Math.PI / 4}
      radius={60}
      minZ={0.1}
      ref={arcCameraRef}
    />
  );
};
