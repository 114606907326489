import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { CompanyContextProvider } from "contexts/CompanyContext";
import { LoginLayout } from "layout/LoginLayout";
import { PortalLayout } from "layout/PortalLayout";
import { Login } from "modules/login/login";
import ShowroomsModule from "modules/showrooms";
import ProductModule from "modules/product";
import ProductNewModule from "modules/productNew";
import SettingsModule from "modules/settings";
import MaterialsModule from "modules/materials";
import { Page404 } from "modules/pages";

export const AppRoutes = () => (
  <BrowserRouter>
    <CompanyContextProvider>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<PortalLayout />}>
          <Route path="/:companyId/products/*" element={<ProductModule />} />
          <Route
            path="/:companyId/products-new/*"
            element={<ProductNewModule />}
          />
          <Route path="/:companyId/showrooms/*" element={<ShowroomsModule />} />
          <Route path="/:companyId/settings/*" element={<SettingsModule />} />
          <Route path="/:companyId/materials/*" element={<MaterialsModule />} />
          <Route path="/*" />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </CompanyContextProvider>
  </BrowserRouter>
);
