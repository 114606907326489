import _ from "lodash";
import React, { Component } from "react";
import "./index.scss";
class PortalButton extends Component {
  state = {};
  render() {
    return (
      <div
        onClick={this.props.onClick || _.noop}
        className={`portal-button ${this.props.disabled ? "disabled" : ""}`}
      >
        <p>{this.props.text}</p>
      </div>
    );
  }
}

export default PortalButton;
