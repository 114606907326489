import React, { Component } from "react";
import { withRouter } from "utils";
import { Card, Col, Row, Space, Typography } from "antd";
import { Form, Input, Button } from "antd";
import { connect } from "react-redux";
import { selectLoginStage } from "store/actions/app";
import { FORGOT_PASSWORD, SIGN_IN } from "sharedConstants";
import { validateEmail } from "utils";
import { userLogin } from "store/actions/auth";
const { Text, Link } = Typography;

class LoginForm extends Component {
  onFinish = (values) => {
    this.props.userLogin(values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    return (
      <Col offset={4} span={16}>
        <Row className="content_row" justify="center" align="middle">
          <h1>Sign in</h1>
        </Row>
        <Form
          name="basic"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                validator: (_, value) => validateEmail(value),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Password" required>
            <Form.Item
              name="password"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <a onClick={() => this.props.selectLoginStage(FORGOT_PASSWORD)}>
              <Text underline>Forgot your password?</Text>
            </a>
          </Form.Item>
          <Form.Item>
            <Button type="primary" block htmlType="submit">
              Sign in
            </Button>
            <Form.Item noStyle>
              <Text>Need an account? </Text>
              <Text underline>
                <a onClick={() => this.props.selectLoginStage(SIGN_IN)}>
                  Contact Admin
                </a>
              </Text>
            </Form.Item>
          </Form.Item>
        </Form>
      </Col>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  selectLoginStage,
  userLogin,
})(withRouter(LoginForm));
