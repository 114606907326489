import {
  ADD_ORDER_FOR_COMPANY,
  ADD_OR_UPDATE_ORDER,
  SET_COMPANIES,
  SET_ITEM_COLLECTION_DETAILS_FOR_ORDER,
  UPDATE_COMPANY,
  UPDATE_ITEM_COLLECTION_ARCHIVED,
  MOVE_ITEM_COLLECTION_TO_ORDER,
} from "sharedConstants";
import produce from "immer";

const INITIAL_STATE = {};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COMPANY:
      const { company } = action;
      draft[company.id] = company;
      break;
    case SET_COMPANIES:
      const { companies } = action;
      draft = companies;
      console.log("setting companies");

      break;

    case ADD_OR_UPDATE_ORDER:
      const { companyId, orderId, orderDetails } = action;

      draft[companyId].orders[orderId] = {
        ...draft[companyId].orders[orderId],
        ...orderDetails,
        _detailsFetched: true,
      };

      break;

    case SET_ITEM_COLLECTION_DETAILS_FOR_ORDER:
      const { companyId: cId, orderId: oId, itemCollectionDetails } = action;
      draft[cId].orders[oId].item_collections[itemCollectionDetails.id] = {
        ...draft[cId].orders[oId].item_collections[itemCollectionDetails.id],
        ...itemCollectionDetails,
        _detailsFetched: true,
      };
      break;

    case ADD_ORDER_FOR_COMPANY:
      const { newOrderData } = action;
      draft[newOrderData.company_id].orders[newOrderData.id] = newOrderData;
      break;

    case UPDATE_ITEM_COLLECTION_ARCHIVED:
      // it seems this is not doing anything
      const {
        companyId: compId,
        orderId: ordId,
        itemColllectionId,
        archived,
      } = action.payload;
      draft[compId].orders[ordId].item_collections[itemColllectionId] = {
        ...draft[compId].orders[ordId].item_collections[itemColllectionId],
        archived,
      };
      draft = { ...draft };
      break;

    case MOVE_ITEM_COLLECTION_TO_ORDER:
      const { itemCollectionId, newOrderId, oldOrderId } = action.payload;
      const targetCompanyId = action.payload.companyId;
      console.log("Move payload:", action.payload);

      if (!draft[targetCompanyId]) {
        console.error("Company not found:", targetCompanyId);
        break;
      }

      if (!draft[targetCompanyId].orders[newOrderId]) {
        console.error("New order not found:", newOrderId);
        break;
      }

      // Ensure item_collections exists in the new order
      if (!draft[targetCompanyId].orders[newOrderId].item_collections) {
        draft[targetCompanyId].orders[newOrderId].item_collections = {};
      }

      // If the item collection is still in the old order, move it
      if (
        draft[targetCompanyId].orders[oldOrderId]?.item_collections?.[
          itemCollectionId
        ]
      ) {
        const itemCollection =
          draft[targetCompanyId].orders[oldOrderId].item_collections[
            itemCollectionId
          ];
        delete draft[targetCompanyId].orders[oldOrderId].item_collections[
          itemCollectionId
        ];
        draft[targetCompanyId].orders[newOrderId].item_collections[
          itemCollectionId
        ] = itemCollection;
      }

      console.log("Item collection move operation completed");
      break;

    default:
      break;
  }

  return draft;
});
