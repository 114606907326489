/* eslint-disable react/no-unknown-property */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AbstractMesh,
  BoundingInfo,
  Nullable,
  PlaneRotationGizmo,
  Tools,
  Vector3,
} from "@babylonjs/core";
import { ShowroomGizmoType, ShowroomProduct } from "modules/showrooms/types";
import { ProductModel } from "./ProductModel";
import { uniqueId } from "lodash";
import { ILoadedModel } from "react-babylonjs";

interface ProductModelWrapperProps {
  value: ShowroomProduct;
  onClick?: (product: ShowroomProduct) => void;
  onChange?: (product: ShowroomProduct) => void;
  selected?: boolean;
  updateShowroomProductState?: (showroomProduct: ShowroomProduct) => void;
  showroomGizmoMode?: ShowroomGizmoType;
}

export const ProductModelWrapper = ({
  value,
  onClick = () => {},
  onChange = () => {},
  selected = false,
  updateShowroomProductState = () => {},
  showroomGizmoMode,
}: ProductModelWrapperProps) => {
  const { product, placement } = value;
  const ref = useRef<Nullable<AbstractMesh>>(null);
  const productVariant = useMemo(() => product.variants[0], [product.variants]);
  const { rootUrl, sceneFilename } = useMemo(() => {
    const url = productVariant.model_url;
    const rootUrl = url.slice(0, url.lastIndexOf("/") + 1);
    const sceneFilename = url.slice(url.lastIndexOf("/") + 1);
    const sceneName = sceneFilename.split("?")[0] || "sceneView";
    return { rootUrl, sceneFilename, sceneName };
  }, [productVariant.model_url]);
  const [uniqueIdOnChange, setUniqueIdOnChange] = useState<string>(
    uniqueId("product-item")
  );

  const handleOnChange = useCallback(() => {
    if (ref.current) {
      const position = ref.current.position;
      const rotation = ref.current.rotation;
      const currentValues = {
        position: value.placement.position,
        rotation: value.placement.rotation,
      };
      const newValues = {
        position: {
          x: position.x,
          y: position.y,
          z: position.z,
        },
        rotation: {
          x: Tools.ToDegrees(rotation.x),
          y: Tools.ToDegrees(rotation.y),
          z: Tools.ToDegrees(rotation.z),
        },
      };
      if (JSON.stringify(currentValues) !== JSON.stringify(newValues)) {
        onChange({
          ...value,
          placement: {
            ...value.placement,
            ...newValues,
          },
        });
      }
    }
  }, [onChange, value]);

  const onCreatedPlaneRotationGizmo = useCallback(
    (instance: PlaneRotationGizmo) => {
      instance.dragBehavior.onDragEndObservable.clear();
      instance.dragBehavior.onDragEndObservable.add((data) => {
        handleOnChange();
      });
    },
    [handleOnChange]
  );

  const handleModelLoaded = useCallback(
    (model: ILoadedModel) => {
      const showroomProduct = { ...value };
      if (model.animationGroups) {
        for (const animation of model.animationGroups) {
          animation.stop();
        }
      }
      if (model.meshes) {
        let min = model.meshes[0].getBoundingInfo().boundingBox.minimumWorld;
        let max = model.meshes[0].getBoundingInfo().boundingBox.maximumWorld;

        for (let i = 0; i < model.meshes.length; i++) {
          const meshMin =
            model.meshes[i].getBoundingInfo().boundingBox.minimumWorld;
          const meshMax =
            model.meshes[i].getBoundingInfo().boundingBox.maximumWorld;

          min = Vector3.Minimize(min, meshMin);
          max = Vector3.Maximize(max, meshMax);
          model.meshes[i].metadata = {
            ...model.meshes[i].metadata,
            rootMesh: model.meshes[0],
          };
        }

        model.meshes[0].setBoundingInfo(new BoundingInfo(min, max));
        showroomProduct.product.rootMesh = model.meshes[0];
      }

      showroomProduct.product.animation = model.animationGroups;
      updateShowroomProductState(showroomProduct);
    },
    [updateShowroomProductState, value]
  );

  // Dirty hack regard to the react-babylonjs :(
  useEffect(() => {
    setUniqueIdOnChange(uniqueId("product-item"));
  }, [onChange]);

  return (
    <ProductModel
      id={`product-placement-${placement.id}`}
      name={`product-item-${placement.id}`}
      rootUrl={rootUrl}
      sceneFilename={sceneFilename + `?placementId=${placement.id}`}
      ref={ref}
      onClick={(event) => {
        if (event.sourceEvent.button === 0) {
          onClick(value);
        }
      }}
      onModelLoadFinished={handleModelLoaded}
      metadata={{ id: placement.id, type: "product-item" }}
      position={
        new Vector3(
          placement.position.x,
          placement.position.y,
          placement.position.z
        )
      }
      rotation={
        new Vector3(
          Tools.ToRadians(placement.rotation.x),
          Tools.ToRadians(placement.rotation.y),
          Tools.ToRadians(placement.rotation.z)
        )
      }
    >
      {selected && (
        <>
          {showroomGizmoMode === "ROTATION" && (
            <planeRotationGizmo
              planeNormal={new Vector3(0, 1, 0)}
              thickness={3}
              scaleRatio={0.9}
              key={uniqueIdOnChange}
              onCreated={onCreatedPlaneRotationGizmo}
            />
          )}
          {showroomGizmoMode === "POSITION" && (
            <positionGizmo
              updateGizmoPositionToMatchAttachedMesh
              scaleRatio={0.9}
              onDragStartObservable={() => {}}
              onDragEndObservable={() => {
                handleOnChange();
              }}
            />
          )}
        </>
      )}
    </ProductModel>
  );
};
