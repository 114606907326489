export const getStatusColor = (statusId) => {
  switch (statusId) {
    case 0:
      return "red";
    case 1:
      return "blue";
    case 2:
      return "red";
    case 3:
      return "red";
    case 4:
      return "green";
    default:
      return "#000";
  }
};
