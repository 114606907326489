import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  SpaceItemType,
  MixItemType,
  SelectedItem,
  RoomItemType,
} from "../types";
import { Modal } from "antd";
import { useUIContext } from "./UIContext";
import { ModalStaticFunctions } from "antd/es/modal/confirm";
import { generateNewRoomItem } from "../utils/dataModel";

const ItemWalker = (
  iterable: any[],
  callback: (item: MixItemType, parent: MixItemType | null) => void | boolean,
  parent: MixItemType | null = null
) => {
  for (const i of iterable) {
    const response = callback(i as MixItemType, parent);
    if (response) return;
    if (i.environment && callback(i.environment as MixItemType, parent)) {
      return;
    }

    const newIterable: any[] = i.rooms || [];
    if (newIterable.length) {
      if (newIterable.length) ItemWalker(newIterable, callback, i);
    }
  }
};

interface ItemsContextValue {
  spaceItem: SpaceItemType | null;
  setSpaceItem: (value: React.SetStateAction<SpaceItemType | null>) => void;
  selectItem: (uuid: string, force?: boolean) => Promise<void> | void;
  selectedItem: SelectedItem | null;
  getItemByUUID: (uuid: string) => SelectedItem | null;
  updateItemByUUID: (
    uuid: string,
    callback: (item: MixItemType, parent: MixItemType | null) => MixItemType
  ) => void;
  deleteItemByUUID: (uuid: string) => void;
  cloneItemByUUID: (uuid: string) => void;
  addNewRoom: () => RoomItemType | void;
  modal: Omit<ModalStaticFunctions, "warn">;
  modalContextHolder: React.ReactElement;
}

const ItemsContext = createContext<ItemsContextValue>({
  spaceItem: null,
  setSpaceItem: () => {},
  selectItem: () => {},
  selectedItem: null,
  getItemByUUID: () => null,
  updateItemByUUID: () => {},
  deleteItemByUUID: () => {},
  cloneItemByUUID: () => {},
  addNewRoom: () => {},
  modal: {} as Omit<ModalStaticFunctions, "warn">,
  modalContextHolder: <></>,
});

export const ItemsContextProvider = (props: any) => {
  const [modal, modalContextHolder] = Modal.useModal();
  const { isDirty, setIsDirty } = useUIContext();
  const [spaceItem, setSpaceItem] = useState<SpaceItemType | null>(null);
  const [selectedKey, setSelectedKey] = useState<string>("");
  const reverseMapByUUID = useMemo(() => {
    const mapped: { [key: string]: SelectedItem } = {};
    if (spaceItem) {
      mapped[spaceItem.uuid] = {
        uuid: spaceItem.uuid,
        type: "space",
        name: spaceItem.name,
        item: spaceItem,
        data: { spaceItem },
      };
      mapped[spaceItem.environment.uuid] = {
        uuid: spaceItem.environment.uuid,
        type: "environment",
        name: spaceItem.environment.name,
        item: spaceItem.environment,
        data: { spaceItem, environmentItem: spaceItem.environment },
      };
      spaceItem.rooms.forEach((roomItem) => {
        mapped[roomItem.uuid] = {
          uuid: roomItem.uuid,
          type: "room",
          name: roomItem.name,
          item: roomItem,
          data: { spaceItem, roomItem },
        };
      });
    }

    return mapped;
  }, [spaceItem]);
  const selectedItem = useMemo<SelectedItem | null>(
    () => reverseMapByUUID[selectedKey] || null,
    [reverseMapByUUID, selectedKey]
  );

  const getItemByUUID = useCallback(
    (uuid: string) => reverseMapByUUID[uuid],
    [reverseMapByUUID]
  );
  const updateItemByUUID = useCallback(
    (
      uuid: string,
      callback: (
        item: MixItemType,
        parent: MixItemType | null
      ) => Partial<MixItemType>
    ): void => {
      const item = getItemByUUID(uuid);
      if (item) {
        const copyOfSpaceItem: SpaceItemType = JSON.parse(
          JSON.stringify(spaceItem)
        );
        ItemWalker([copyOfSpaceItem], (_item, _parent) => {
          if (_item.uuid === uuid) {
            Object.assign(_item, callback(_item, _parent));
            return true;
          }
        });
        setSpaceItem(copyOfSpaceItem);
      }
    },
    [getItemByUUID, spaceItem]
  );

  const deleteItemByUUID = useCallback(
    (uuid: string) => {
      const item = getItemByUUID(uuid);
      if (item) {
        const copyOfSpaceItem: SpaceItemType | null = JSON.parse(
          JSON.stringify(spaceItem)
        );
        ItemWalker([copyOfSpaceItem], (_item, _parent) => {
          if (_item.uuid === uuid) {
            if (_parent) {
              if (_parent.type === "space") {
                _parent.rooms = _parent.rooms.filter(
                  (_room) => _room.uuid !== _item.uuid
                );
                if (selectedKey === uuid) setSelectedKey(_parent.uuid);
              }
            }
            return true;
          }
        });
        setSpaceItem(copyOfSpaceItem);
      }
    },
    [getItemByUUID, selectedKey, spaceItem]
  );

  const cloneItemByUUID = useCallback(
    (uuid: string) => {
      const item = getItemByUUID(uuid);
      if (item) {
        const copyOfSpaceItem: SpaceItemType | null = JSON.parse(
          JSON.stringify(spaceItem)
        );
        ItemWalker([copyOfSpaceItem], (_item, _parent) => {
          if (_item.uuid === uuid) {
            if (_parent) {
              if (_item.type === "room") {
                const roomItem = generateNewRoomItem(_item as RoomItemType);
                roomItem.name = `${roomItem.name} (Clone)`;
                (_parent as SpaceItemType).rooms.push(roomItem);
              }
            }
            return true;
          }
        });
        setSpaceItem(copyOfSpaceItem);
      }
    },
    [getItemByUUID, spaceItem]
  );

  const addNewRoom = useCallback(() => {
    if (spaceItem) {
      const _room = generateNewRoomItem();
      const newSpaceItem = {
        ...spaceItem,
        rooms: [...spaceItem.rooms, _room],
      };
      setSpaceItem(newSpaceItem);
      return _room;
    }
  }, [spaceItem]);

  const handleOnDiscardChanges = useCallback(() => {
    return new Promise((resolve) => {
      modal.confirm({
        title: `Do you want to discard changes?`,
        icon: <ExclamationCircleOutlined />,
        okText: "Discard",
        cancelText: "Cancel",
        okButtonProps: { danger: true },
        maskClosable: true,
        onOk: () => {
          setIsDirty(false);
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  }, [modal, setIsDirty]);

  const selectItem = useCallback(
    async (uuid: string, force = false) => {
      if (isDirty && !force) {
        const discardChanges = await handleOnDiscardChanges();
        if (discardChanges) setSelectedKey(uuid);
      } else {
        setSelectedKey(uuid);
      }
    },
    [handleOnDiscardChanges, isDirty]
  );

  const value = {
    spaceItem,
    setSpaceItem,
    selectItem,
    selectedItem,
    getItemByUUID,
    updateItemByUUID,
    deleteItemByUUID,
    cloneItemByUUID,
    addNewRoom,
    modal,
    modalContextHolder,
  };

  return <ItemsContext.Provider value={value} {...props} />;
};

export const useItemsContext = () => {
  return useContext<ItemsContextValue>(ItemsContext);
};
