import React, { useCallback } from "react";
import { notification } from "antd";
import { AtLeast, CollectionType, ItemRemoveType } from "../types";
import { useUIContext } from "../contexts/UIContext";
import { useLayoutsContext } from "../contexts/LayoutsContext";
import { ProductsService } from "servicesNew/api";
//import { createItemTree } from "../utils/api";

export const useItem = () => {
  const { setSelectedKey, setItems, updateItemByUUID } = useLayoutsContext();
  const { taskWrapper } = useUIContext();

  const updateItem = useCallback(
    async (item: AtLeast<ItemRemoveType, /*"_uuid" | "id" |*/ "name">) => {
      if (typeof item.id === "number" && typeof item._uuid === "string") {
        await ProductsService.productsItemsUpdate({
          id: item.id,
          data: { name: item.name },
        });

        updateItemByUUID(item._uuid, () => ({ ...item, name: item.name }));
        notification.success({
          message: "Item successfully updated!",
        });
      } else {
        console.error("Missing item id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  const removeItem = useCallback(
    async ({ item }: { item: ItemRemoveType }) => {
      if (item) {
        // @ts-ignore
        const itemApiResponse = await deleteItem(item.apiItemId);
        if (itemApiResponse) {
          updateItemByUUID(item.uuid, (_item, _collection) => {
            if (_collection) {
              (_collection as CollectionType).items = (
                _collection as CollectionType
              ).items.filter(({ _uuid }) => _uuid !== item._uuid);
              return _item;
            }
            return {};
          });
          setSelectedKey(item.uuid);
          notification.success({
            message: "Item successfully deleted!",
          });
        }
      }
    },
    [setSelectedKey, updateItemByUUID]
  );

  /* 
  const addItem = useCallback(
    async ({
      collection,
      name,
    }: {
      collection: CollectionModelType;
      name: string;
    }) => {
      const itemTree = await createItemTree({
        collectionId: collection.id,
        name,
      });
      updateItemByUUID(collection._uuid, (_item) => {
        (_item as CollectionModelType).childrens.push(itemTree);
        return _item;
      });
      notification.success({
        message: "Item successfully created!",
      });
    },
    [updateItemByUUID]
  );

  const removeItem = useCallback(
    async ({ item }: { item: ItemRemoveType }) => {
      await ProductsService.productsItemsDelete({
        id: item.id,
      });
      updateItemByUUID(item._uuid, (_item, _collection) => {
        (_collection as CollectionType).item = (
          _collection as CollectionType
        ).item.filter(({ _uuid }) => _uuid !== item._uuid);
        return {};
      });
      notification.success({
        message: "Item successfully removed!",
      });
    },
    [updateItemByUUID]
  );
    */

  return {
    updateItem: taskWrapper(updateItem),
    //addItem: taskWrapper(addItem),
    removeItem: taskWrapper(removeItem),
  };
};
