import { Vector3 } from "@babylonjs/core";
import { useRef } from "react";

interface PointersType {
  pointerDownCoordinates: Vector3 | null;
  pointerUpCoordinates: Vector3 | null;
  pointerDownLongPress: boolean;
  isPressed: boolean;
}

export const usePrecisionClick = (precision = 0.2) => {
  const pointersRef = useRef<PointersType>({
    pointerDownCoordinates: null,
    pointerUpCoordinates: null,
    pointerDownLongPress: false,
    isPressed: false,
  });

  const resetPointerClick = () => {
    pointersRef.current = {
      pointerDownCoordinates: null,
      pointerUpCoordinates: null,
      pointerDownLongPress: false,
      isPressed: false,
    };
  };

  const setPointerDown = (pointerCoordinates: Vector3) => {
    pointersRef.current.pointerDownCoordinates = pointerCoordinates;
  };

  const setPointerUp = (pointerCoordinates: Vector3) => {
    pointersRef.current.pointerUpCoordinates = pointerCoordinates;
  };

  const setPointerDownLongPress = (value = true) => {
    pointersRef.current.pointerDownLongPress = value;
  };

  const getPointerDownLongPress = () => {
    return pointersRef.current.pointerDownLongPress;
  };

  const setIsPressed = (value: boolean) => {
    pointersRef.current.isPressed = value;
  };

  const getIsPressed = () => {
    return pointersRef.current.isPressed;
  };

  const validateClick = () => {
    // Check if u are clicking outside the precision
    const { pointerDownCoordinates, pointerUpCoordinates } =
      pointersRef.current;
    if (pointerDownCoordinates && pointerUpCoordinates) {
      return validateClickDistance(
        pointerDownCoordinates,
        pointerUpCoordinates
      );
    }
    return false;
  };

  const validateClickDistance = (pointA: Vector3, pointB: Vector3) => {
    return Vector3.Distance(pointA, pointB) < precision;
  };

  return {
    resetPointerClick,
    setPointerDown,
    setPointerUp,
    setPointerDownLongPress,
    getPointerDownLongPress,
    validateClick,
    validateClickDistance,
    setIsPressed,
    getIsPressed,
  };
};
