import * as auth from "./auth";
import * as company from "./company";
import * as fileLocker from "./file_locker";
import * as item from "./item";
import * as note from "./note";
import * as order from "./order";
import * as shell from "./shell";
import * as showroom from "./showroom";
import * as user from "./user";
import * as variant from "./variant";
import * as statuses from "./status";
import * as assets from "./assets";
import * as behaviors from "./behaviors";

const obj = {
  auth,
  company,
  fileLocker,
  item,
  note,
  order,
  shell,
  showroom,
  user,
  variant,
  statuses,
  assets,
  behaviors,
};

export default obj;
