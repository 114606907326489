import React, { Component } from "react";
import "./textArea.scss";

class TextDisplay extends Component {
  state = {};
  render() {
    return (
      <div className={`${this.props.bold ? "bold-text" : ""}`}>
        {this.props.title && (
          <h3 className="section-title">{this.props.title}</h3>
        )}
        {this.props.showEdit && <span className="editLink">edit</span>}
        {this.props.prefix}
        {this.props.value}
        {this.props.showEditAfter && <span className="editLink">edit</span>}
      </div>
    );
  }
}

export default TextDisplay;
