import React, { Component } from "react";
import { Table, Button } from "antd";
import { connect } from "react-redux";
import _ from "lodash";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { getParamsFromPathname } from "../../utils/params";
import { getItemCollectionDetailsForOrder } from "store/actions/company";
import "./itemCollectionTable.scss";
import { updateModuleState } from "store/actions/app";
import ItemExpanded from "./ItemExpanded";
import { ProductsService } from "servicesNew/api";
import {
  UPDATE_ITEM_COLLECTION_LIST_ORDER,
  UPDATE_ITEM_COLLECTION_ORDER,
} from "sharedConstants";

const columns = (component) => [
  {
    title: "Collection Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (name) => {
      return name;
    },
  },
  {
    title: "Sku",
    dataIndex: "sku",
    key: "sku",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Updated",
    dataIndex: "updated",
    key: "updated",
    sorter: {
      compare: (a, b) => Date.parse(a.updated) - Date.parse(b.updated),
      multiple: 1,
    },
  },
  {
    title: "Move",
    key: "move",
    render: (text, record, index) => (
      <span>
        <Button
          icon={<ArrowUpOutlined />}
          onClick={() => component.handleMoveItem(record, "up")}
          disabled={index === 0}
        />
        <Button
          icon={<ArrowDownOutlined />}
          onClick={() => component.handleMoveItem(record, "down")}
          disabled={index === component.props.itemCollection.length - 1}
        />
      </span>
    ),
  },
];

class ItemCollectionTable extends Component {
  state = {
    expandedRowKeys: [],
  };

  sortItemCollection = (itemCollection) => {
    return _.sortBy(itemCollection, [
      (item) => (item.list_order !== null ? item.list_order : Infinity),
      (item, index) => (item.list_order === null ? index : 0),
    ]);
  };

  handleRowExpand = (expanded, item) => {
    if (expanded) {
      if (!item._detailsFetched) {
        this.props.getItemCollectionDetailsForOrder(
          this.props.companyIdInUrl,
          this.props.orderIdInUrl,
          item.id
        );
      }

      this.setState({
        expandedRowKeys: [
          ...this.state.expandedRowKeys,
          `item-collection-row-${item.id}`,
        ],
      });
    } else {
      let newArr = [...this.state.expandedRowKeys];
      let idx = newArr.findIndex((i) => i === `item-collection-row-${item.id}`);
      newArr.splice(idx, 1);
      this.setState({
        expandedRowKeys: newArr,
      });
    }
  };

  handleExpandedRowRender = (itemCollection) => {
    return (
      <div className="bitreel-expanded-itemcollection-row">
        <ItemExpanded itemCollectionId={itemCollection.id} />
      </div>
    );
  };

  handleMoveItem = (item, direction) => {
    const { itemCollection } = this.props;
    const sortedItemCollection = this.sortItemCollection(itemCollection);
    const index = sortedItemCollection.findIndex((i) => i.id === item.id);
    const newIndex = direction === "up" ? index - 1 : index + 1;

    if (newIndex >= 0 && newIndex < sortedItemCollection.length) {
      const newItemCollection = [...sortedItemCollection];
      const [movedItem] = newItemCollection.splice(index, 1);
      newItemCollection.splice(newIndex, 0, movedItem);

      // Update only the moved item and its sibling
      const updatedItems = [
        { id: movedItem.id, list_order: newIndex },
        { id: newItemCollection[index].id, list_order: index },
      ];

      // Call the API to update the list_order
      this.updateItemCollectionOrder(updatedItems);

      // Update the local state or Redux store
      this.props.updateItemCollectionOrder(newItemCollection);
    }
  };

  updateItemCollectionOrder = (updatedItems) => {
    updatedItems.forEach((item) => {
      this.props.updateItemCollectionListOrder(item.id, item.list_order);
      ProductsService.productsItemCollectionsUpdate({
        id: item.id,
        data: { list_order: item.list_order },
      }).catch(console.error);
    });
  };

  render() {
    const sortedItemCollection = this.sortItemCollection(
      this.props.itemCollection
    );

    return (
      <div className="bitreel-item-collection-table">
        <Table
          loading={this.props.loading}
          pagination={{
            pageSize: this.props.productModuleState.listTablePageSize,
            onChange: (page, pageSize) => {
              this.props.updateModuleState("product", {
                listTablePageSize: pageSize,
                listTablePageNumber: page,
              });
            },
            current: this.props.productModuleState.listTablePageNumber,
          }}
          rowKey={(itemCollection) =>
            `item-collection-row-${itemCollection.id}`
          }
          columns={columns(this)}
          expandable={{
            expandedRowRender: (record) => this.handleExpandedRowRender(record),
            rowExpandable: (record) => record.name !== "Not Expandable",
            onExpandedRowsChange: (expandedRows) => {
              this.props.updateModuleState("product", {
                listTableExpandedKeys: expandedRows,
              });
            },
            expandedRowKeys:
              this.props.productModuleState.listTableExpandedKeys,
            onExpand: this.handleRowExpand,
          }}
          dataSource={sortedItemCollection}
        />
      </div>
    );
  }
}

const mapStateToProps = (
  {
    companies,
    statuses,
    app: {
      moduleStates: { product: productModuleState },
    },
  },
  { data: itemCollection }
) => {
  let { orderIdInUrl, companyIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  let selectedOrder =
    orderIdInUrl &&
    companyIdInUrl &&
    companies[companyIdInUrl].orders[orderIdInUrl];
  if (!selectedOrder) {
    return {
      noOrderSelected: true,
      statuses: statuses.itemStatuses,
    };
  }
  return {
    itemCollection: itemCollection || [],
    companyIdInUrl,
    statuses: statuses.itemStatuses,
    orderIdInUrl,
    productModuleState,
  };
};

export default connect(mapStateToProps, {
  getItemCollectionDetailsForOrder,
  updateModuleState,
  updateItemCollectionOrder: (newOrder) => ({
    type: UPDATE_ITEM_COLLECTION_ORDER,
    payload: newOrder,
  }),
  updateItemCollectionListOrder: (id, listOrder) => ({
    type: UPDATE_ITEM_COLLECTION_LIST_ORDER,
    payload: { id, listOrder },
  }),
})(ItemCollectionTable);
