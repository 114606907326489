import React, { useState } from "react";
import { Button, Layout, Divider } from "antd";
// import { useShowroomContext } from "../contexts/ShowroomContext";
import { CommenterForm } from "../components/commenter";
import { useSpaceContext } from "../contexts/SpaceContext";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { UpdateModelModal } from "../components/updateModelModal";
import { Comment } from "../types";
import { useAppSelector } from "hooks";
import { uploadFbxZip } from "modules/product/admin/adminFunctions";
import { downloadAndUnzip } from "modules/product/admin/helperFunctions";
const { Sider } = Layout;

interface SiderMenuProps {
  onSubmitComment?: (comment: Comment) => void;
}

export const SiderMenu = ({ onSubmitComment = () => {} }: SiderMenuProps) => {
  // const { showroom } = useShowroomContext();
  const { space, isLoadingSpace, spaceVersion, setSpaceUrl } =
    useSpaceContext();
  const { admin } = useAppSelector((state: any) => state.profile);
  const [openUpdateModelModal, setOpenUpdateModelModal] =
    useState<boolean>(false);
  const [confirmLoadingUpdateModelModal, setConfirmLoadingUpdateModelModal] =
    useState<boolean>(false);

  // const spaceVersionOptions = useMemo<SelectProps["options"]>(
  //   () =>
  //     Object.values(space?.versions || []).map(
  //       ({ active, id, version_number }) => ({
  //         value: `${id}`,
  //         label: `Version ${version_number}`,
  //         disabled: !active,
  //       })
  //     ) || [],
  //   [space?.versions]
  // );

  // const handleChange = (value: string) => {
  //   console.log(`selected ${value}`);
  // };
  return (
    <Sider width={400} className="siderMenu">
      {isLoadingSpace ? (
        <FullAreaSpinner />
      ) : (
        <>
          <h2>{space?.name}</h2>
          <h4>
            <b>Status:</b> Processing
          </h4>
          {admin && (
            <Button
              onClick={() => {
                setOpenUpdateModelModal(true);
              }}
              type="link"
              style={{ padding: 0 }}
            >
              <u>Upload Shell</u>
            </Button>
          )}
          {/* <Select
            defaultValue={`${space?.current_version_id}`}
            style={{ width: 120 }}
            onChange={handleChange}
            options={spaceVersionOptions}
          /> */}
          <Divider />
          <CommenterForm
            onSubmit={(comment: Comment) => {
              onSubmitComment(comment);
            }}
          />
          {/* <Divider />
          <Button shape="round" className="upgrade-button" onClick={() => {}}>
            PENDING APPROVAL
          </Button> */}
          {openUpdateModelModal && (
            <UpdateModelModal
              open={true}
              confirmLoading={confirmLoadingUpdateModelModal}
              onCancel={() => {
                setOpenUpdateModelModal(false);
              }}
              onSubmit={(file) => {
                setConfirmLoadingUpdateModelModal(true);
                uploadFbxZip(file, spaceVersion?.fbx_urls.fbx_post_url).then(
                  (response) => {
                    setConfirmLoadingUpdateModelModal(false);
                    setOpenUpdateModelModal(false);
                    if (spaceVersion) {
                      downloadAndUnzip(spaceVersion.fbx_urls.fbx_url).then(
                        (files) => {
                          try {
                            const glbFileName = Object.keys(files).find(
                              (name) => name.endsWith(".glb")
                            );
                            if (glbFileName === undefined)
                              throw new Error("glb not found in zip file");
                            const url = URL.createObjectURL(files[glbFileName]);
                            setSpaceUrl(url);
                          } catch (error) {
                            console.error("error glb not found in zip file");
                          }
                        }
                      );
                    }
                  }
                );
              }}
            />
          )}
        </>
      )}
    </Sider>
  );
};
