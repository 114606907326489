import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, notification } from "antd";
import { createItemCollectionTree } from "../utils/api";

interface AddCollectionViewProps {
  companyId: number;
  orderId: number;
}

export const AddCollectionView = ({
  companyId,
  orderId,
}: AddCollectionViewProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const collectionTree = await createItemCollectionTree({
        companyId,
        collectionName: values.collectionName,
        itemName: values.itemName,
      });
      notification.success({
        message: "Collection successfully created!",
      });
      navigate(
        `/${companyId}/products-new/${orderId}/advanced-product-creator-new/edit/${collectionTree.id}`
      );
    } catch (error) {
      notification.error({
        message: "Failed to create collection!",
      });
    }
    setLoading(false);
  };

  return (
    <div className="add-collection-view">
      <Form
        form={form}
        name="add-collection-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          collectionName: "",
          layoutName: "",
        }}
      >
        <Form.Item
          label="Collection Name"
          name="collectionName"
          rules={[
            { required: true, message: "Please input the collection name!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Item Name"
          name="itemName"
          rules={[{ required: true, message: "Please input the item name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <div className="commit-buttons">
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                form.resetFields();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
