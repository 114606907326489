import produce from "immer";

const INITIAL_STATE = {
  isCreatingUser: false,
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case "IS_CREATING_USER":
      draft.isCreatingUser = action.isCreatingUser;
      break;
    default:
      break;
  }

  return draft;
});
