import React, { useState } from "react";
import { Modal } from "antd";
import { FilesDropZone } from "components/Media";

interface UpdateModelModalProps {
  open?: boolean;
  confirmLoading?: boolean;
  onCancel?: () => void;
  onSubmit?: (file: File) => void;
}

export const UpdateModelModal = ({
  open = false,
  confirmLoading = false,
  onCancel = () => {},
  onSubmit = () => {},
}: UpdateModelModalProps) => {
  const [uploadFile, setUploadfile] = useState<File>();
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        if (uploadFile) {
          onSubmit(uploadFile);
        }
      }}
      title="Update Model"
      okText="Upload"
      centered
      className="update-model-modal"
      cancelButtonProps={{ className: "cancel-btn" }}
      okButtonProps={{ className: "upload-btn", disabled: !uploadFile }}
    >
      <div className="content-header">
        <h3>3D Model File</h3>
        <p>*Add render hotspots after file uploaded</p>
      </div>
      <FilesDropZone
        maxCount={1}
        beforeUpload={(file) => {
          setUploadfile(file);
          return false;
        }}
        onChange={({ fileList }) => {
          if (!fileList.length) setUploadfile(undefined);
        }}
      />
    </Modal>
  );
};
