import React, { useCallback, useMemo } from "react";
import { Breadcrumb } from "antd";
import { SelectedItem } from "../types";
import { HomeOutlined } from "@ant-design/icons";

interface ItemsBreadcrumbProps {
  selectedItem: SelectedItem;
  onSelect?: (key: string) => void;
}

export const ItemsBreadcrumb = ({
  selectedItem,
  onSelect = () => {},
}: ItemsBreadcrumbProps) => {
  const {
    data: { spaceItem, environmentItem, roomItem },
  } = selectedItem;

  const breadcrumbItems = useMemo(() => {
    return [spaceItem, environmentItem, roomItem]
      .filter((i) => !!i)
      .map((item: any) => ({
        title: item.name,
        uuid: item.uuid,
        type: item.type,
      }));
  }, [environmentItem, roomItem, spaceItem]);

  const breadcrumbItemRender = useCallback(
    (item: any) => {
      return (
        <span
          onClick={() => {
            onSelect(item.uuid);
          }}
        >
          {item.type === "space" && <HomeOutlined />} {item.title}
        </span>
      );
    },
    [onSelect]
  );

  return (
    <Breadcrumb
      items={breadcrumbItems}
      itemRender={breadcrumbItemRender}
      className="items-breadcrumb"
    />
  );
};
