import produce from "immer";
import _ from "lodash";
import {
  ADD_COMMENT,
  ADD_TO_ITEM_FILE_LOCKER,
  ADD_OR_REPLACE_ITEM_VERSION,
  ADD_OR_UPDATE_ITEM,
} from "sharedConstants";

const INITIAL_STATE = {};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TO_ITEM_FILE_LOCKER:
      let { itemId: itemIdForLocker, itemData: lockerData, itemType } = action;
      draft[itemIdForLocker].file_locker[itemType] = [
        ...draft[itemIdForLocker].file_locker[itemType],
        lockerData,
      ];
      break;

    case ADD_OR_UPDATE_ITEM:
      let { itemId, itemData } = action;
      if (!draft[itemId]) {
        draft[itemId] = {
          versions: {},
        };
      }

      let final = {};
      if (itemData.versions && itemData.versions.length) {
        itemData.versions.forEach((v) => {
          final[v.id] = v;
        });
      }

      _.forEach(draft[itemId].versions, (v) => {
        final[v.id] = {
          ...v,
          ...final[v.id],
        };
      });

      itemData.versions = final;

      draft[itemId] = {
        ...draft[itemId],
        ...itemData,
      };

      break;

    case ADD_OR_REPLACE_ITEM_VERSION:
      const { itemId: itemId_, versionData } = action;
      draft[itemId_].versions[versionData.id] = {
        ...draft[itemId_].versions[versionData.id],
        ...versionData,
        _detailsFetched: true,
      };
      break;

    case ADD_COMMENT:
      const {
        itemId: commentItemId,
        itemVersionId,
        commentData,
        email,
      } = action;
      let newNotes = [...draft[commentItemId].versions[itemVersionId].notes];
      newNotes.unshift(commentData);
      draft[commentItemId].versions[itemVersionId].notes = newNotes;
      draft[commentItemId].notes.unshift({
        ...commentData,
        user: email,
        version_number:
          draft[commentItemId].versions[itemVersionId].version_number,
      });
      break;

    default:
      break;
  }

  return draft;
});
