import React, { Component } from "react";
import "./screen.scss";

class Screen extends Component {
  state = {};
  render() {
    return (
      <div className={`screen-container ${this.props.className || ""}`}>
        {this.props.children}
      </div>
    );
  }
}

export default Screen;
