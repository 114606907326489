import ApiService from "services/ApiService";
import {
  ADD_ORDER_FOR_COMPANY,
  SET_COMPANIES,
  SET_ITEM_COLLECTION_DETAILS_FOR_ORDER,
  UPDATE_COMPANY,
} from "sharedConstants";

import { message } from "antd";
import { setAppIsLoadingData } from "./app";
import { getAndSetItem, getItemVersion } from "./items";
import { getAndSetVariant } from "./variant";
import _ from "lodash";

export const setCompanies = (companies) => {
  return {
    type: SET_COMPANIES,
    companies,
  };
};

export const updateCompany = (company) => {
  return {
    type: UPDATE_COMPANY,
    company,
  };
};

export const addNewOrder = (newOrderData) => {
  return {
    type: ADD_ORDER_FOR_COMPANY,
    newOrderData,
  };
};

export const setItemCollectionDetailsForOrder = (
  companyId,
  orderId,
  itemCollectionDetails
) => {
  return {
    type: SET_ITEM_COLLECTION_DETAILS_FOR_ORDER,
    companyId,
    orderId,
    itemCollectionDetails,
  };
};

export const updateCompanyInfo = (id, data) => async (dispatch) => {
  try {
    // console.log(data);
    dispatch(setAppIsLoadingData(true));
    const response = await ApiService.patchCompany(id, data);
    dispatch(updateCompany(response.data));
    message.success("Company Info Updated!");
    return response;
  } catch (e) {
    message.error(e);
    console.warn(e);
  } finally {
    dispatch(setAppIsLoadingData(false));
  }
};

export const getAllCompanies = () => async (dispatch) => {
  try {
    let response;

    response = await ApiService.getCompanies();

    let companiesById = {};
    let ordersById = {};

    response.data.forEach((value) => {
      companiesById[value.id] = value;
      value.orders.forEach((o) => {
        ordersById[o.id] = o;
      });
      companiesById[value.id].orders = ordersById;
      ordersById = {};
    });

    // final.orders = finalOrders
    await dispatch(setCompanies(companiesById));
    return companiesById;
  } catch (error) {
    console.warn(error);
  } finally {
    /* empty */
  }
};

const mapSeries = async (iterable, action) => {
  for (const x of iterable) {
    await action(x);
  }
};

export const getItemCollectionDetailsForOrder =
  (companyId, orderId, itemCollectionId) => async (dispatch) => {
    let collectionResponse = await ApiService.getItemCollection(
      itemCollectionId
    );
    await dispatch(
      setItemCollectionDetailsForOrder(
        companyId,
        orderId,
        collectionResponse.data
      )
    );

    await mapSeries(collectionResponse.data.items, (item) =>
      handleItemAndVersions(dispatch, item)
    );

    // collectionResponse.data.items.forEach(async i => {
    //   await dispatch(getAndSetItem(i.id))
    //   let versionRes = await dispatch(getItemVersion(i.id, i.current_version_id))
    //   versionRes.variants.forEach(async variantId => {
    //     await dispatch(getAndSetVariant(variantId))
    //   })
    // })
  };

const handleItemAndVersions = async (dispatch, item) => {
  const itemRes = await dispatch(getAndSetItem(item.id));

  // let versionRes = await dispatch(
  //   getItemVersion(item.id, item.current_version_id)
  // );
  // Extract versions from itemRes
  const versions = itemRes.data.versions;

  // Find the current version using the item.current_version_id
  const versionRes = versions[item.current_version_id];
  //  console.log("currentVersion", currentVersion);

  let chunks = _.chunk(versionRes.variants, 3);

  await mapSeries(chunks, (chunk) => handleVariant(dispatch, chunk));
};

// const handleItemAndVersions = async (dispatch, item) => {
//   const itemRes = await dispatch(getAndSetItem(item.id));
//   console.log("itemRes", itemRes);
//   let versionRes = await dispatch(
//     getItemVersion(item.id, item.current_version_id)
//   );
//   console.log("versionRes", versionRes);

//   let chunks = _.chunk(versionRes.variants, 3);

//   await mapSeries(chunks, (chunk) => handleVariant(dispatch, chunk));
// };

const handleVariant = async (dispatch, chunk) => {
  let count = chunk.length;
  return new Promise((res) => {
    chunk.forEach(async (variantId) => {
      await dispatch(getAndSetVariant(variantId));
      count--;
      if (!count) {
        res();
      }
    });
  });
  // await dispatch(getAndSetVariant(variantId))
};

export const createOrder = (companyId, orderName) => async (dispatch) => {
  let newOrderResponse = await ApiService.postOrder({
    company_id: companyId,
    name: orderName,
  });
  dispatch(addNewOrder(newOrderResponse.data));
  return newOrderResponse.data;
};

export const createItemSimple =
  (orderId, itemName, companyId) => async (dispatch) => {
    let newItemResponse = await ApiService.postItemCreateSimpleQWERTY({
      order_id: orderId,
      name: itemName,
    });
    let collectionResponse = await ApiService.getItemCollection(
      newItemResponse.data.item_collection_id
    );
    await dispatch(
      setItemCollectionDetailsForOrder(
        companyId,
        orderId,
        collectionResponse.data
      )
    );
    collectionResponse.data.items.forEach(async (i) => {
      await dispatch(getAndSetItem(i.id));
      let versionRes = await dispatch(
        getItemVersion(i.id, i.current_version_id)
      );
      versionRes.variants.forEach(async (variantId) => {
        await dispatch(getAndSetVariant(variantId));
      });
    });
    return newItemResponse.data;
  };
