import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Layout, Modal, Skeleton } from "antd";
import { LayoutsContextProvider } from "../advancedProductCreator/contexts/LayoutsContext";
import { UIContextProvider } from "../advancedProductCreator/contexts/UIContext";
import { useAppSelector } from "hooks";
const { Content, Sider } = Layout;

interface BitreelModelViewerElement extends HTMLElement {
  "item-collection-id"?: number;
  token?: string;
  // Add more properties and methods as needed
}

interface BitreelModelViewerProps
  extends React.HTMLAttributes<BitreelModelViewerElement> {
  portalMode?: boolean;
  "item-collection-id"?: number;
  token?: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "bitreel-model-viewer": React.DetailedHTMLProps<
        BitreelModelViewerProps,
        BitreelModelViewerElement
      >;
    }
  }
}

interface PreviewProductProps {
  itemCollectionId: number;
}

const PreviewProductCore = ({ itemCollectionId }: PreviewProductProps) => {
  const { token } = useAppSelector((state) => state.auth);
  return (
    <Layout className="preview-product-page">
      <Content>
        <div className="preview-product-render-container">
          <bitreel-model-viewer
            portalMode={true}
            key="bitreel-model-viewer"
            id="bitreel-model-viewer"
            item-collection-id={itemCollectionId}
            //ref={this.renderViewerRef}
            token={token}
          />
        </div>
      </Content>
    </Layout>
  );
};

export const PreviewProduct = () => {
  const { itemCollectionId } = useParams();
  return itemCollectionId ? (
    <LayoutsContextProvider>
      <UIContextProvider>
        <PreviewProductCore itemCollectionId={Number(itemCollectionId)} />
      </UIContextProvider>
    </LayoutsContextProvider>
  ) : (
    <div className="preview-product-page-wrapper-empty">
      <h2>Wrong url</h2>
    </div>
  );
};
