import SkeletonInput from "antd/lib/skeleton/Input";
import React, { Component } from "react";

class AudioInput extends Component {
  state = {};
  render() {
    return null;
  }
}

export default AudioInput;
