import axios from "axios";
import { BACKEND_URL, GET_METHOD, POST_METHOD } from "sharedConstants";

const createShellNote = async function (noteData) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/shell_notes`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      note: noteData.note,
      shell_version_id: noteData.shell_version_id,
      note_type_id: noteData.note_type_id,
    },
  });
  return response;
};

const getAllShellNoteTypes = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/shell_note_types`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export { createShellNote, getAllShellNoteTypes };
