import React, { Component } from "react";

class FullScreenSpinner extends Component {
  state = {};
  render() {
    return (
      <div className="bitreel-fullscreen-spinner">
        <div className="loader">
          <div className="spinner"></div>
          <h3>
            {this.props.message ? this.props.message : "Loading Portal Data..."}
          </h3>
        </div>
      </div>
    );
  }
}

export default FullScreenSpinner;
