import { SET_PROFILE } from "sharedConstants";
import produce from "immer";

const INITIAL_STATE = {};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROFILE:
      const { profile } = action;
      draft = profile;
      break;

    default:
      break;
  }

  return draft;
});
