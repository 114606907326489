import { Button, Switch, Tooltip, notification, Input, Checkbox } from "antd";
import { history } from "services/history";
import { Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { patchVariant } from "store/actions/variant";
import { getParamsFromPathname, replaceInPath } from "utils";
import { createNewItemVersion } from "store/actions/items";
import { CopyOutlined } from "@ant-design/icons";
const { TextArea } = Input;

class ApproveItem extends Component {
  state = {};

  handleApproveVersion = (value) => {
    this.props.patchVariant(
      this.props.variantIdInUrl,
      {
        status_id: value,
      },
      false
    );
  };

  handleChangeClickable = (e) => {
    this.props.patchVariant(
      this.props.variantIdInUrl,
      {
        clickable: e.target.checked,
      },
      false
    );
  };

  handleChangeIsExternal = (e) => {
    this.props.patchVariant(
      this.props.variantIdInUrl,
      {
        is_external_asset: e.target.checked,
      },
      false
    );
  };

  handleChangeProp = (e) => {
    this.props.patchVariant(
      this.props.variantIdInUrl,
      {
        is_prop: e.target.checked,
      },
      false
    );
  };

  renderNewVersionButton = () => {
    return (
      <Button
        type="primary"
        style={{ width: "200px" }}
        onClick={async () => {
          let newVersionRes = await this.props.createNewItemVersion(
            this.props.itemId
          );
          let url = replaceInPath(
            "versionIdInUrl",
            newVersionRes.id,
            window.location.pathname
          );
          url = replaceInPath("variantIdInUrl", newVersionRes.variants[0], url);
          history.push(url);
        }}
      >
        Create New Version
      </Button>
    );
  };

  getEmbedCodeBlock = () => {
    const { part_groups } = this.props.versionDetails;
    let bitAttribute = `item-collection-id="${this.props.itemDetails.item_collection_id}?defaultItemVersion=${this.props.versionId}"`;
    if (
      part_groups.length === 1 &&
      part_groups[0].length &&
      part_groups[0][0]?.parts?.length === 1
    ) {
      bitAttribute = `item-collection-id="${this.props.itemDetails.item_collection_id}?defaultVariant=${this.props.variantData.id}"`;
    }
    const scriptText = `<bitreel-model-viewer id="bitreel-model-viewer" ${bitAttribute}></bitreel-model-viewer>`;

    return (
      <>
        <Button
          type="link"
          style={{ marginTop: "15px", padding: "0" }}
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(scriptText);
              notification.success({
                message: "Successfully copied on clipboad!",
              });
            } catch (err) {
              notification.error({
                message: "Failed to copy!",
              });
            }
          }}
        >
          <CopyOutlined /> Copy Embed code
        </Button>
        <TextArea
          onClick={(e) => {
            e.target.select();
          }}
          autoSize={{ minRows: 4, maxRows: 4 }}
          value={scriptText}
          readOnly
        ></TextArea>
      </>
    );
  };

  render() {
    if (this.props.loading) {
      return null;
    }
    let hasGlb =
      this.props.variantData.default_parts_internal[0].internal_url.glb;
    return (
      <div className="approve-item-switch">
        <h3>Variant Status</h3>
        <Tooltip
          title={
            hasGlb
              ? this.props.versionDetails.status_id === 4
                ? "This version has been Finalized. Please create a new version below."
                : ""
              : "Model must be uploaded to change status from Created"
          }
        >
          <Select
            disabled={
              (!hasGlb || this.props.versionDetails.status_id === 4) &&
              !this.props.profile.admin
            }
            style={{ width: "150px" }}
            onChange={this.handleApproveVersion}
            options={this.props.itemStatuses
              .map((s) => {
                if (s.name === "Created") {
                  if (this.props.versionDetails.status_id !== 1) {
                    return null;
                  }
                } else {
                  // if (this.props.profile.admin) {
                  //     if (s.name === 'Bitreel Review') {
                  //         return null
                  //     }
                  // } else {
                  //     if (s.name === 'Client Review') {
                  //         return null
                  //     }
                  // }
                }
                return {
                  label: s.name,
                  value: s.id,
                };
              })
              .filter((v) => v)}
            value={this.props.versionDetails.status_id}
          />
        </Tooltip>
        <div style={{ marginTop: "15px" }}>
          {this.props.versionDetails.status_id === 4 &&
            this.props.profile.admin &&
            this.renderNewVersionButton()}
        </div>
        <div style={{ marginTop: "15px" }}>
          {this.props.profile.admin && (
            <Checkbox
              checked={this.props.variantData.clickable}
              onChange={this.handleChangeClickable}
            >
              Clickable
            </Checkbox>
          )}
        </div>
        <div style={{ marginTop: "15px" }}>
          {this.props.profile.admin && (
            <Checkbox
              checked={this.props.variantData.is_external_asset}
              onChange={this.handleChangeIsExternal}
            >
              External Asset
            </Checkbox>
          )}
        </div>
        <div style={{ marginTop: "15px" }}>
          {this.props.profile.admin && (
            <Checkbox
              checked={this.props.variantData.is_prop}
              onChange={this.handleChangeProp}
            >
              Prop Item
            </Checkbox>
          )}
        </div>
        {this.props.versionId && this.getEmbedCodeBlock()}
      </div>
    );
  }
}

const mapStateToProps = ({
  items,
  variants,
  profile,
  statuses: { itemStatuses },
}) => {
  let {
    orderIdInUrl,
    companyIdInUrl,
    itemCollectionIdInUrl,
    versionIdInUrl,
    variantIdInUrl,
    itemIdInUrl,
  } = getParamsFromPathname(window.location.pathname);

  let loading =
    !companyIdInUrl ||
    !orderIdInUrl ||
    !itemCollectionIdInUrl ||
    !versionIdInUrl ||
    !variantIdInUrl ||
    !items[itemIdInUrl] ||
    !variants[variantIdInUrl] ||
    !items[itemIdInUrl].versions[versionIdInUrl] ||
    !items[itemIdInUrl].versions[versionIdInUrl]._detailsFetched;

  if (!loading) {
    return {
      loading,
      itemDetails: items[itemIdInUrl],
      versionId: Number(versionIdInUrl),
      itemId: Number(itemIdInUrl),
      versionDetails: variants[variantIdInUrl],
      itemStatuses,
      profile,
      variantIdInUrl,
      variantData: variants[variantIdInUrl],
    };
  } else {
    return {
      loading,
    };
  }
};

export default connect(mapStateToProps, {
  patchVariant,
  createNewItemVersion,
})(ApproveItem);
