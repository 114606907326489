import React from "react";
import {
  ShowroomProductBase,
  ShowroomProduct,
  ShowroomGizmoType,
  FocusedProductType,
} from "../../types";
import { ProductModelWrapper } from "./ProductModelWrapper";
import { ModelFallback } from "./ModelFallback";
import { Vector3 } from "@babylonjs/core";

interface ProductModelWrapperListProps {
  value: ShowroomProductBase[];
  onClick?: (product: ShowroomProduct) => void;
  onChange?: (product: ShowroomProduct) => void;
  focusedShowroomProduct?: FocusedProductType;
  updateShowroomProductState?: (showroomProduct: ShowroomProduct) => void;
  showroomGizmoMode?: ShowroomGizmoType;
}

export const ProductModelWrapperList = ({
  value,
  onClick = () => {},
  onChange = () => {},
  focusedShowroomProduct,
  updateShowroomProductState = () => {},
  showroomGizmoMode,
}: ProductModelWrapperListProps) => {
  return (
    <>
      {value.map((showroomProduct) =>
        showroomProduct.product ? (
          <ProductModelWrapper
            key={`product-placement-${showroomProduct.placement.id}`}
            // onClick={handleOnProductClick}
            onChange={(_product: ShowroomProduct) => onChange(_product)}
            selected={
              focusedShowroomProduct?.showroomProduct.placement.id ===
                showroomProduct.placement.id &&
              focusedShowroomProduct?.mode === "GIZMO_MENU"
            }
            value={showroomProduct as ShowroomProduct}
            updateShowroomProductState={(_product: ShowroomProduct) => {
              updateShowroomProductState(_product);
            }}
            showroomGizmoMode={showroomGizmoMode}
          />
        ) : (
          <ModelFallback
            position={
              new Vector3(
                showroomProduct.placement.position.x,
                showroomProduct.placement.position.y,
                showroomProduct.placement.position.z
              )
            }
            rotation={
              new Vector3(
                showroomProduct.placement.rotation.x,
                showroomProduct.placement.rotation.y,
                showroomProduct.placement.rotation.z
              )
            }
            key={`product-fallback-${showroomProduct.placement.id}`}
          />
        )
      )}
    </>
  );
};
