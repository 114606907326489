import produce from "immer";
import {
  UPDATE_ITEM_COLLECTION_LIST_ORDER,
  UPDATE_ITEM_COLLECTION_ORDER,
} from "sharedConstants";

const INITIAL_STATE = {};

const itemCollectionsReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_ITEM_COLLECTION_LIST_ORDER:
        const { id, listOrder } = action.payload;
        if (draft[id]) {
          draft[id] = { ...draft[id], list_order: listOrder };
        }
        break;

      case UPDATE_ITEM_COLLECTION_ORDER:
        const { newOrder } = action.payload;
        if (Array.isArray(newOrder)) {
          newOrder.forEach((item, index) => {
            if (draft[item.id]) {
              draft[item.id] = { ...draft[item.id], list_order: index };
            }
          });
        }
        break;

      default:
        // No default action needed, as Immer will return the draft if no changes are made
        break;
    }
  });

export default itemCollectionsReducer;
