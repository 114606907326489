import React, { useState, useEffect } from "react";
import { Button, Dropdown, Slider, Space, Tooltip } from "antd";
import { ShowroomProduct } from "modules/showrooms/types";
import { AnimationGroup } from "@babylonjs/core";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { useScene } from "react-babylonjs";
import { useEngineContext } from "modules/showrooms/contexts/EngineContext";
import { throttle } from "lodash";

interface AnimationContainerProps {
  value: ShowroomProduct;
  onChange?: (product: ShowroomProduct) => void;
}

export const AnimationContainer = ({
  value,
  onChange = () => {},
}: AnimationContainerProps) => {
  const [currentAnimation, setCurrentAnimation] =
    React.useState<AnimationGroup | null>(null);
  const [maxFrame, setMaxFrame] = useState(0);
  const { product } = value;
  const [progressValue, setProgressValue] = useState(0);
  const { scene } = useEngineContext();

  useEffect(() => {
    // play and pause to set the animatables
    if (currentAnimation && scene) {
      const updateAnimationProgress = () => {
        if (
          currentAnimation &&
          currentAnimation.animatables.length > 0 &&
          currentAnimation.isPlaying
        ) {
          setProgressValue(currentAnimation.animatables[0].masterFrame);
        }
      };
      const throttleUpdate = throttle(updateAnimationProgress, 100);
      currentAnimation.play();
      currentAnimation.pause();
      currentAnimation.goToFrame(0);
      setProgressValue(0);
      const maxFrame = currentAnimation.animatables[0].toFrame;
      setMaxFrame(maxFrame);
      scene.unregisterBeforeRender(throttleUpdate);
      scene.registerBeforeRender(throttleUpdate);
    }
  }, [currentAnimation, scene]);

  useEffect(() => {
    if (product.animation && product.animation.length > 1) {
      setCurrentAnimation(product.animation[0]);
    }
  }, [product.animation]);

  const handleSliderChange = (value: number) => {
    if (currentAnimation) {
      if (currentAnimation.animatables.length === 0) {
        //if the animatables are not set, play and pause to set them
        currentAnimation.play();
      }
      currentAnimation.pause();
      setProgressValue(value);
      currentAnimation.goToFrame(value);
    }
  };

  return (
    <>
      {product && product.animation && (
        <div>
          <div className="product-animation">
            <h3>Animation:</h3>
            <Space>
              <Dropdown
                menu={{
                  items: product.animation.map((animation, index) => {
                    return {
                      key: index,
                      label: <span>{animation.name}</span>,
                    };
                  }),
                  onClick: (item) => {
                    setCurrentAnimation(
                      product.animation
                        ? product.animation[Number(item.key)]
                        : null
                    );
                  },
                }}
              >
                <Button size="small">
                  <Space>
                    {currentAnimation
                      ? currentAnimation.name
                      : "Select an animation"}
                  </Space>
                </Button>
              </Dropdown>
              <div>
                <Button
                  size="small"
                  onClick={() => {
                    if (currentAnimation) {
                      currentAnimation.play();
                    }
                  }}
                >
                  <PlayCircleOutlined />
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    if (currentAnimation) {
                      currentAnimation.pause();
                    }
                  }}
                >
                  <PauseCircleOutlined />
                </Button>
              </div>
            </Space>
          </div>
          <Slider
            min={0}
            max={maxFrame}
            onChange={handleSliderChange}
            value={progressValue}
          />
        </div>
      )}
    </>
  );
};
