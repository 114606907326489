import React, { createContext, useState, useContext, useEffect } from "react";
import { Showroom } from "services/types";
import { ShowroomGizmoType, ShowroomModeType } from "../types";

interface ShowroomContextValue {
  showroom: Showroom | null;
  setShowroom: (showroom: React.SetStateAction<Showroom | null>) => void;
  showroomMode: ShowroomModeType;
  setShowroomMode: (
    showroomMode: React.SetStateAction<ShowroomModeType>
  ) => void;
  showroomGizmoMode: ShowroomGizmoType;
  setShowroomGizmoMode: (
    showroomMode: React.SetStateAction<ShowroomGizmoType>
  ) => void;
  showroomModeParams: {
    [key: string]: string;
  };
  setShowroomModeParams: (
    showroomModeParams: React.SetStateAction<{
      [key: string]: string;
    }>
  ) => void;
}

const ShowroomContext = createContext<ShowroomContextValue>({
  showroom: null,
  setShowroom: () => {},
  showroomMode: "IDLE",
  setShowroomMode: () => {},
  showroomGizmoMode: "SELECT",
  setShowroomGizmoMode: () => {},
  showroomModeParams: {},
  setShowroomModeParams: () => {},
});

export const ShowroomContextProvider = (props: any) => {
  const [showroom, setShowroom] = useState<Showroom | null>(null);
  const [showroomMode, setShowroomMode] = useState<ShowroomModeType>("IDLE");
  const [showroomGizmoMode, setShowroomGizmoMode] =
    useState<ShowroomGizmoType>("SELECT");
  const [showroomModeParams, setShowroomModeParams] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    setShowroomModeParams({});
  }, [showroomMode]);

  const value = {
    showroom,
    setShowroom,
    showroomMode,
    setShowroomMode,
    showroomGizmoMode,
    setShowroomGizmoMode,
    showroomModeParams,
    setShowroomModeParams,
  };

  return <ShowroomContext.Provider value={value} {...props} />;
};

export const useShowroomContext = () => {
  return useContext<ShowroomContextValue>(ShowroomContext);
};
