import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { getAllShells } from "store/actions/shells";
import { Shell } from "services/types";

export const useFetchShells = () => {
  const dispatch = useAppDispatch();
  const {
    shells,
    isLoadingAllShells,
  }: {
    shells: Shell[];
    isLoadingAllShells: boolean;
  } = useAppSelector((state: any) => state.shells);

  useEffect(() => {
    const getAllShellsAction = getAllShells();
    getAllShellsAction(dispatch);
  }, [dispatch]);

  return {
    shells,
    isLoadingAllShells,
  };
};
