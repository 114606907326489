import produce from "immer";

const INITIAL_STATE = {
  isLoadingAllShells: false,
  // shells: [],
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case "IS_LOADING_ALL_SHELLS":
      draft.isLoadingAllShells = action.isLoadingAllShells;
      break;
    case "SET_SHELLS":
      draft.shells = action.shells;
      break;
    default:
      break;
  }

  return draft;
});
