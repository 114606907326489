import React, { useState } from "react";
import { Input, Modal } from "antd";

interface AddModalProps {
  title?: string;
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (url: string) => void;
}

export const AddModal = ({
  title = "Add",
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
}: AddModalProps) => {
  const [name, setName] = useState<string>("");
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={() => {
        onSubmit(name);
      }}
      title={title}
      okText="Submit"
      centered
      className="add-modal"
      cancelButtonProps={{ className: "cancel-btn" }}
      okButtonProps={{ className: "upload-btn", disabled: !name }}
    >
      <span>Name</span>
      <Input
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
    </Modal>
  );
};
