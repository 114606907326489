import React, { useState } from "react";
import { Modal, Card, Checkbox, Spin, Form, Input, Select } from "antd";
import {
  MaterialModel,
  MeshMaterialModel,
  PartGroupModel,
  PartModel,
  ProductsService,
} from "servicesNew/api";
import {
  MeshMaterialModelType,
  PartGroupModelType,
  SelectedItem,
} from "../../../productNew/advancedProductCreatorNew/types";
import { useMeshMaterials } from "../hooks";
import { MeshMaterialAssociation } from "servicesNew/api/models/MeshMaterialAssociation";

interface CreateMeshMaterialAssociationModalProps {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (selectedMeshMaterials: Required<MeshMaterialModel>[]) => void;
  itemVersionData?: any; // Replace 'any' with your actual itemVersionData type
  selectedPartId?: number;
  //materialData?: MeshMaterialModelType;
}

export const CreateMeshMaterialAssociationModal: React.FC<
  CreateMeshMaterialAssociationModalProps
> = ({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
  itemVersionData,
  selectedPartId,
}) => {
  const [selectedMeshMaterials, setSelectedMeshMaterials] = useState<
    Record<string, boolean>
  >({});
  const [form] = Form.useForm();
  const { createMeshMaterialAssociationGroup, createMeshMaterialAssociation } =
    useMeshMaterials();
  const meshMaterial = itemVersionData as MeshMaterialModelType;
  const materialData = meshMaterial as MeshMaterialModelType;

  const handleOnSelectMaterial = (materialId: number, isSelected: boolean) => {
    console.log(
      `Selecting material: ${materialId}, Selected state: ${isSelected}`
    );
    setSelectedMeshMaterials((prevSelected) => ({
      ...prevSelected,
      [materialId]: isSelected,
    }));
  };

  const handleSubmit = async () => {
    let selectedMeshMaterialModels: Required<MeshMaterialModel>[]; // Declare selectedMeshMaterialModels here
    try {
      const values = await form.validateFields();
      console.log("Form values:", values);
      const selectedMeshMaterialIds = Object.keys(selectedMeshMaterials).filter(
        (key) => selectedMeshMaterials[key]
      );
      selectedMeshMaterialModels = selectedMeshMaterialIds.map((id) => {
        // Assuming you have a way to get the full MeshMaterialModel object by ID:
        const meshMaterialModel = itemVersionData; // You need to implement this function
        return {
          ...meshMaterialModel,
          id: parseInt(id), // Ensure ID is a number
        } as Required<MeshMaterialModel>;
      });
      const response = await createMeshMaterialAssociationGroup({
        _uuid: meshMaterial._uuid,
        id: meshMaterial.id,
        ui_message: values.ui_message,
        type: values.type,
        item_version_id: itemVersionData.data.itemVersion.id,
      });
      if (response?.id) {
        console.log(
          "Material Association Group created successfully.",
          response
        );
        const materialAssociationGroupId = response.id;
        for (const meshMaterialModel of selectedMeshMaterialModels) {
          const materialData = {
            ...meshMaterialModel,
            material_association_group_id: materialAssociationGroupId,
            ui_message: values.ui_message,
            type: values.type,
            item_version_id: itemVersionData.data.itemVersion.id,
          };

          try {
            const assocResponse = await createMeshMaterialAssociation(
              materialData
            );
            console.log(
              `Association created for part ID ${meshMaterialModel.id}`,
              assocResponse
            );
          } catch (assocError) {
            console.error(
              `Error creating association for part ID ${meshMaterialModel.id}`,
              assocError
            );
          }
        }
        window.location.reload();
      } else {
        console.log("No response from createMaterialAssociationGroup");
      }
    } catch (error) {
      console.log("Validation Failed:", error);
      selectedMeshMaterialModels = []; // Handle the error case by initializing to an empty array or appropriate error handling
    }
    onSubmit(selectedMeshMaterialModels); // Now it is accessible
  };

  return (
    <Modal
      title="Add Material Association"
      open={open}
      onCancel={onCancel}
      onOk={handleSubmit}
      afterClose={() => {
        setSelectedMeshMaterials({});
      }}
      okText="Create"
      className="add-associations-modal"
      cancelButtonProps={{ className: "cancel-btn" }}
      okButtonProps={{
        className: "upload-btn",
        disabled:
          Object.values(selectedMeshMaterials).filter(Boolean).length < 2,
      }}
    >
      <Spin spinning={!itemVersionData} size="large">
        <div className="associations-content">
          {itemVersionData?.data?.itemVersion?.childrens?.map(
            (partGroup: PartGroupModelType) => (
              <div key={partGroup._uuid}>
                <h2>{partGroup.name}</h2>
                {partGroup.childrens.map((part) => (
                  <div key={part._uuid}>
                    <h3>{part.name}</h3>
                    {part.childrens
                      .filter((material) => {
                        const isSelectedMaterial =
                          material.id === Number(itemVersionData?.id);
                        const isDifferentPart =
                          part._uuid.toString() !==
                          itemVersionData?.data?.part?._uuid?.toString();
                        return isSelectedMaterial || isDifferentPart;
                      })
                      .map((material) => (
                        <Card
                          key={material.id}
                          hoverable
                          className="associations-card"
                        >
                          <Checkbox
                            checked={!!selectedMeshMaterials[material.id]}
                            onChange={(e) =>
                              handleOnSelectMaterial(
                                material.id,
                                e.target.checked
                              )
                            }
                          />
                          <div className="associations-card-content">
                            <img
                              src={material.internal_url || ""}
                              alt={material.name || ""}
                            />
                            <p>{material.name}</p>
                          </div>
                        </Card>
                      ))}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      </Spin>
      <Form form={form} layout="vertical" name="materialAssociationForm">
        <Form.Item
          name="ui_message"
          label="Association UI Message"
          rules={[
            {
              required: true,
              message: "Please input the association UI message!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Association Type"
          rules={[
            { required: true, message: "Please select the association type!" },
          ]}
        >
          <Select>
            <Select.Option value="invalid">Invalid</Select.Option>
            <Select.Option value="dictate">Dictate</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
