import React, { useState } from "react";
import { Badge, Button, Col, Drawer, Row } from "antd";
import { SearchOutlined, CommentOutlined } from "@ant-design/icons";
import { useCommentContext } from "../../contexts/CommentContext";
import { useEngineContext } from "../../contexts/EngineContext";
import { CommenterTable } from "./commenterTable";
import { CommenterViewer } from "./commenterViewer";
import { CommenterConfirmModal } from "./commenterConfirmModal";
import { Comment } from "../../types";

interface CommenterSectionProps {
  comments?: Comment[];
  onSubmitComment?: (comment: Comment) => void;
  onRemoveImages?: (commentId: string | number, ids: number[]) => void;
}

export const CommenterSection = ({
  comments = [],
  onSubmitComment = () => {},
  onRemoveImages = () => {},
}: CommenterSectionProps) => {
  const { comment, setComment } = useCommentContext();
  const { showHotspots, setShowHotspots, setIsFocusedEngine } =
    useEngineContext();
  const [open, setOpen] = useState(false);
  const [confirmComment, setConfirmComment] = useState<Comment | null>(null);

  const handleSwitchComment = (_comment: Comment | null) => {
    if (_comment) setComment(_comment);
    setShowHotspots(false);
    setConfirmComment(null);
  };

  return (
    <>
      <Button
        className="commenter-drawer-button"
        type="text"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Badge size="small" offset={[-3, 3]} count={comments.length}>
          <CommentOutlined />
        </Badge>
      </Button>

      <Drawer
        title={
          <>
            <SearchOutlined style={{ marginRight: "5px" }} />
            All comments ({comments.length})
          </>
        }
        placement="bottom"
        height={"50%"}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        className="commenter-section"
      >
        <Row className="commenter-section--body" gutter={20}>
          <Col span={12}>
            <CommenterTable
              comments={comments}
              value={comment?.id || null}
              onSelect={(_comment) => {
                if (comment.isDirty) {
                  setConfirmComment(_comment as Comment);
                } else {
                  handleSwitchComment(_comment as Comment);
                }
              }}
            />
          </Col>
          <Col span={12}>
            <CommenterViewer
              value={comment.id ? comment : null}
              showHotspots={showHotspots}
              onClickShowHotspots={() => {
                setShowHotspots((value) => {
                  const newValue = !value;
                  if (newValue) {
                    setIsFocusedEngine(true);
                  }
                  return !value;
                });
                setOpen(false);
              }}
              onRemoveImages={onRemoveImages}
            />
          </Col>
        </Row>
      </Drawer>

      <CommenterConfirmModal
        open={!!confirmComment}
        onCancel={() => {
          setConfirmComment(null);
        }}
        onOk={() => {
          onSubmitComment(comment);
          handleSwitchComment(confirmComment);
        }}
        onDiscard={() => {
          handleSwitchComment(confirmComment);
        }}
        disableSubmit={!comment.description}
      />
    </>
  );
};
