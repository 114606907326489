import React, { useRef, useState } from "react";
import { InputNumber, MenuProps, Dropdown, Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const stepItems: MenuProps["items"] = [
  {
    label: "Step with 1",
    key: "1",
  },
  {
    label: "Step with 0.1",
    key: "0.1",
  },
  {
    label: "Step with 0.01",
    key: "0.01",
  },
  {
    label: "Step with 0.001",
    key: "0.001",
  },
  {
    label: "Step with 0.0001",
    key: "0.0001",
  },
];

interface PrecisionInputNumberProps {
  value: number;
  onChange?: (value: number | null) => void;
  defaultStep?: string;
  addonBefore?: React.ReactNode;
}

export const PrecisionInputNumber = ({
  value,
  onChange = () => {},
  defaultStep = "1",
  addonBefore,
}: PrecisionInputNumberProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [step, setStep] = useState<string>(defaultStep);
  return (
    <InputNumber
      className="precision-input-number"
      style={{ width: "100%" }}
      value={value}
      step={step}
      onChange={onChange}
      ref={inputRef}
      addonAfter={
        <Dropdown
          menu={{
            items: stepItems,
            selectable: true,
            selectedKeys: [step],
            onSelect: ({ key }) => {
              setStep(key);
              inputRef.current?.focus();
            },
          }}
          trigger={["click"]}
        >
          <Button type="ghost" size="small">
            <SettingOutlined />
          </Button>
        </Dropdown>
      }
      addonBefore={addonBefore}
    />
  );
};
