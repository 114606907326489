import axios from "axios";
import {
  BACKEND_URL,
  GET_METHOD,
  PATCH_METHOD,
  POST_METHOD,
  DELETE_METHOD,
} from "sharedConstants";

export const updateVariant = async function (id, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/variants/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

export const updateVariantStatus = async function (id, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/variants_change_status/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

export const getVariant = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/variants_internal/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export const searchVariantInOrder = async function (orderId, query) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/variants_search/${orderId}${query}`,
  });
  return response;
};

export const checkItemExists = async function (url) {
  const response = await axios({
    method: "HEAD",
    url: url,
    skipValidation: true,
  });
  return response;
};

export const postVariant = async function (variantName) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/variants`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { name: variantName },
  });
  return response;
};

export const postPartMaterialAccociation = async function ({
  partId,
  materialId,
  priceMod,
}) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/part_material_modifier/${partId}/${materialId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { price_mod: priceMod },
  });
  return response;
};

export const patchPartMaterialAccociation = async function ({
  partId,
  materialId,
  priceMod,
}) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/part_material_modifier/${partId}/${materialId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { price_mod: priceMod },
  });
  return response;
};

export const postMaterial = async function (data) {
  console.log("data", data);
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/materials`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

export const postMaterialCategory = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/material_category`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

export const postMaterialCategoryAssociation = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/material_category_associations`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

export const deleteMaterialCategoryAssociation = async function (data) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/material_category_associations`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

export const patchMaterial = async function (materialId, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/materials/${materialId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

export const patchMaterialCategory = async function (materialCategoryId, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/material_category/${materialCategoryId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

export const patchMaterialCategoryAssociation = async function (
  material_id,
  material_category_id
) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/material_category_associations/${material_id}/${material_category_id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { material_id, material_category_id },
  });
  return response;
};

export const addPartToVariant = async function (partId, variantId, materialId) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/part_variants/${partId}/${variantId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { material_id: materialId },
  });
  return response;
};

export const createSimpleVariant = async function (partGroupId, variantName) {
  const partRes = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/parts`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      name: variantName,
      part_group_id: partGroupId,
    },
  });

  const variantRes = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/variants`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { name: variantName },
  });

  const materialRes = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/materials`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { name: `${variantName} - Material` },
  });

  const addPartToVariantRes = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/part_variants/${partRes.data.id}/${variantRes.data.id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: { material_id: materialRes.data.id },
  });

  return addPartToVariantRes;
};
