import React from "react";
import { Layout, Typography } from "antd";
import { ShowroomsSiderMenu } from "../components/showroomsSiderMenu";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { useSpaceContext } from "../contexts/SpaceContext";
const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const ShowroomEmpty = () => {
  const { isLoadingSpace } = useSpaceContext();

  return isLoadingSpace ? (
    <FullAreaSpinner />
  ) : (
    <div className="showroom-layout-wrapper">
      <Layout className="layout layout-showroom layout-showroom--empty">
        <ShowroomsSiderMenu />
        <Content>
          <div className="showroom-header">
            <div className="showroom-header--content">
              <Title level={2}>There are no spaces.</Title>
              <Paragraph>Please create spaces before.</Paragraph>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};
