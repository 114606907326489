import React, { useState } from "react";
import { Modal, Card, Checkbox, Spin, Form, Input, Select } from "antd";
import { PartGroupModel, PartModel, ProductsService } from "servicesNew/api";
import {
  PartModelType,
  ItemVersionModelType,
  PartGroupModelType,
  SelectedItem,
} from "../../../productNew/advancedProductCreatorNew/types";
import { useParts } from "../hooks";

interface CreatePartAssociationModalProps {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (selectedPart: Required<PartModel>[]) => void;
  itemVersionData?: any; // Replace 'any' with your actual itemVersionData type
  selectedPartGroupId?: number;
  partData?: PartModelType;
}

export const CreatePartAssociationModal: React.FC<
  CreatePartAssociationModalProps
> = ({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
  itemVersionData,
  selectedPartGroupId,
}) => {
  const [selectedParts, setSelectedParts] = useState<Record<string, boolean>>(
    {}
  );
  const [form] = Form.useForm();
  const { createPartAssociationGroup, createPartAssociation } = useParts();
  const part = itemVersionData as PartModelType;
  const partData = part as PartModelType;

  const handleOnSelectPart = (partId: number, isSelected: boolean) => {
    console.log(`Selecting part: ${partId}, Selected state: ${isSelected}`);
    setSelectedParts((prevSelected) => ({
      ...prevSelected,
      [partId]: isSelected,
    }));
  };
  console.log("partInPartData", itemVersionData);
  const handleSubmit = async () => {
    let selectedPartModels: Required<PartModel>[]; // Declare selectedPartModels here
    try {
      const values = await form.validateFields();
      console.log("Form values:", values);
      const selectedPartIds = Object.keys(selectedParts).filter(
        (key) => selectedParts[key]
      );
      selectedPartModels = selectedPartIds.map((id) => {
        // Assuming you have a way to get the full PartModel object by ID:
        const partModel = itemVersionData; // You need to implement this function
        return {
          ...partModel,
          id: parseInt(id), // Ensure ID is a number
        } as Required<PartModel>;
      });
      const response = await createPartAssociationGroup({
        _uuid: part._uuid,
        id: part.id,
        ui_message: values.ui_message,
        type: values.type,
        item_version_id: itemVersionData.data.itemVersion.id,
      });
      if (response?.id) {
        console.log("Part Association Group created successfully.", response);
        const partAssociationGroupId = response.id;
        for (const partModel of selectedPartModels) {
          const partData = {
            ...partModel,
            part_association_group_id: partAssociationGroupId,
            ui_message: values.ui_message,
            type: values.type,
            item_version_id: itemVersionData.data.itemVersion.id,
          };
          console.log("partData:", partData);
          try {
            const assocResponse = await createPartAssociation(partData);
            console.log(
              `Association created for part ID ${partModel.id}`,
              assocResponse
            );
          } catch (assocError) {
            console.error(
              `Error creating association for part ID ${partModel.id}`,
              assocError
            );
          }
        }
        window.location.reload();
      } else {
        console.log("No response from createPartAssociationGroup");
      }
    } catch (error) {
      console.log("Validation Failed:", error);
      selectedPartModels = []; // Handle the error case by initializing to an empty array or appropriate error handling
    }
    onSubmit(selectedPartModels); // Now it is accessible
  };

  return (
    <Modal
      title="Add Part Association"
      open={open}
      onCancel={onCancel}
      onOk={handleSubmit}
      afterClose={() => {
        setSelectedParts({});
      }}
      okText="Create"
      className="add-associations-modal"
      cancelButtonProps={{ className: "cancel-btn" }}
      okButtonProps={{
        className: "upload-btn",
        disabled: Object.values(selectedParts).filter(Boolean).length < 2,
      }}
    >
      <Spin spinning={!itemVersionData} size="large">
        <div className="associations-content">
          {itemVersionData?.data?.itemVersion?.childrens?.map(
            (partGroup: PartGroupModelType) => (
              //(itemVersion: ItemVersionModelType) =>
              //itemVersion.childrens.map((partGroup) => (
              <div key={partGroup._uuid}>
                <h3>{partGroup.name}</h3>
                {partGroup.childrens
                  .filter((part) => {
                    const isSelectedPart =
                      part.id === Number(itemVersionData?.id);
                    const isDifferentPartGroup =
                      partGroup._uuid.toString() !==
                      itemVersionData?.data?.partGroup?._uuid?.toString();
                    return isSelectedPart || isDifferentPartGroup;
                  })
                  .map((part) => (
                    <Card key={part.id} hoverable className="associations-card">
                      <Checkbox
                        checked={!!selectedParts[part.id]}
                        onChange={(e) =>
                          handleOnSelectPart(part.id, e.target.checked)
                        }
                      />
                      <div className="associations-card-content">
                        <img
                          src={
                            part.external_url +
                            "/thumbnail_compressed/256.png?rerender=" +
                            Date.now()
                          }
                          alt={part.name || ""}
                        />
                        <p>{part.name}</p>
                      </div>
                    </Card>
                  ))}
              </div>
            )
          )}
        </div>
      </Spin>
      <Form form={form} layout="vertical" name="partAssociationForm">
        <Form.Item
          name="ui_message"
          label="Association UI Message"
          rules={[
            {
              required: true,
              message: "Please input the association UI message!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Association Type"
          rules={[
            { required: true, message: "Please select the association type!" },
          ]}
        >
          <Select>
            <Select.Option value="invalid">Invalid</Select.Option>
            <Select.Option value="dictate">Dictate</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
