import React, { Component } from "react";

const numberToPrice = (x) => {
  if (x) {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

class PriceDisplay extends Component {
  state = {};
  render() {
    let { price, price_alternate, price_ca } = this.props.value;
    return (
      <div className="product-description-display">
        <h3 className="section-title">Price</h3>
        <span className="editLink">edit</span>
        {price && price !== "" && (
          <p>
            <span>Price:</span>
            {price}
          </p>
        )}
        {price_alternate && price_alternate !== "" && (
          <p>
            <span>Alt Price:</span>
            {price_alternate}
          </p>
        )}
        {price_ca && price_ca !== "" && (
          <p>
            <span>CA Price:</span>
            {price_ca}
          </p>
        )}
      </div>
    );
  }
}

export default PriceDisplay;
