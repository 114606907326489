import { message, Upload } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { patchVariant } from "store/actions/variant";
import { uploadAsset } from "services/assets";

class ThumbnailDisplay extends Component {
  state = {};
  render() {
    // debugger
    return (
      <>
        <Upload
          showUploadList={false}
          beforeUpload={async (file) => {
            message.loading("Uploading Thumnail... ", 1);
            let assetId = await uploadAsset(
              this.props.value.assetId,
              file,
              true,
              "thumbnail",
              "thumbnail.png"
            );
            return false;
          }}
        >
          Click here to upload new thumbnail.
        </Upload>
      </>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  patchVariant,
})(ThumbnailDisplay);
