import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import {
  FocusedProductType,
  PlacementCoordinates,
  Product,
  ProductPlacement,
  ShowroomProduct,
  ShowroomProductBase,
  ShowroomModeType,
} from "../types";
import { useProductsApi } from "../hooks";
import {
  HistoryActions,
  HistoryItem,
  useProductsHistoryContext,
  ProductsHistoryContextValue,
  TransitionDataCallback,
} from "./ProductsHistoryContext";
import { useEngineContext } from "./EngineContext";
import { uniqueId } from "lodash";
import { Vector3 } from "@babylonjs/core";
import _ from "lodash";
import { useShowroomContext } from "./ShowroomContext";

interface ProductsContextValue
  extends Omit<
    ProductsHistoryContextValue,
    "createHistorySession" | "goBackward" | "goForward"
  > {
  isLoading: boolean;
  updateShowroomProductState: (showroomProduct: ShowroomProduct) => void;
  // loadProducts: (concurrency?: number) => void;
  loadProductsDynamic: (concurrency?: number) => void;
  clipboardProduct: ShowroomProduct | null;
  showroomProducts: {
    [key: string]: ShowroomProductBase;
  };
  showroomProductsArray: ShowroomProductBase[];
  focusedShowroomProduct: FocusedProductType;
  setFocusedShowroomProductById: (
    value: {
      id: string | number;
      mode: ShowroomModeType;
    } | null
  ) => void;
  updateProductQueueDebounced: (
    _product: ShowroomProduct
  ) => Promise<void> | void;
  addProduct: (
    _product: Product,
    _placement?: PlacementCoordinates
  ) => Promise<void> | void;
  deleteProduct: (_product: ShowroomProduct) => Promise<void> | void;
  cutProduct: (placementId: string) => void;
  pasteProduct: ({
    x,
    y,
    z,
  }: {
    x: number;
    y: number;
    z: number;
  }) => Promise<ShowroomProduct | void> | void;
  goBackwardAction: () => Promise<void> | void;
  goForwardAction: () => Promise<void> | void;
}

const ProductsContext = createContext<ProductsContextValue>({
  isLoading: false,
  // loadProducts: () => {},
  updateShowroomProductState: () => {},
  loadProductsDynamic: () => {},
  clipboardProduct: null,
  showroomProducts: {},
  showroomProductsArray: [],
  focusedShowroomProduct: null,
  setFocusedShowroomProductById: () => {},
  updateProductQueueDebounced: () => {},
  addProduct: () => {},
  deleteProduct: () => {},
  cutProduct: () => {},
  pasteProduct: () => {},
  historyStake: [],
  stakePointerIndex: -1,
  activeHistoryItem: null,
  goBackwardAction: () => {},
  goForwardAction: () => {},
  enableBackward: false,
  enableForward: false,
});

export const ProductContextProvider = (props: any) => {
  /*************** Hooks *****************/
  const { scene } = useEngineContext();
  const { showroomMode, setShowroomMode } = useShowroomContext();
  const {
    historyStake,
    stakePointerIndex,
    activeHistoryItem,
    createHistorySession,
    goBackward,
    goForward,
    enableBackward,
    enableForward,
  } = useProductsHistoryContext();
  const {
    loading,
    apiAddProduct,
    apiDeleteProduct,
    apiFetchAllProductsDynamic,
    apiUpdateProductPositionQueue,
    apiUpdateProductPositionQueueDebounced,
  } = useProductsApi();
  /***************************************/

  /*************** State *****************/
  // All products in showroom
  const [showroomProducts, setShowroomProducts] = useState<{
    [key: string]: ShowroomProductBase;
  }>({});
  const [clipboardProduct, setClipboardProduct] =
    useState<ShowroomProduct | null>(null);
  const showroomProductsArray = useMemo(
    () => Object.values(showroomProducts),
    [showroomProducts]
  );
  const [focusedShowroomProductId, setFocusedShowroomProductId] = useState<
    string | number
  >("");

  const focusedShowroomProduct: FocusedProductType = useMemo(() => {
    if (focusedShowroomProductId) {
      if (
        showroomProducts[focusedShowroomProductId] &&
        showroomProducts[focusedShowroomProductId].product
      ) {
        return {
          showroomProduct: showroomProducts[
            focusedShowroomProductId
          ] as ShowroomProduct,
          mode: showroomMode,
        };
      }
    }
    return null;
  }, [focusedShowroomProductId, showroomMode, showroomProducts]);
  const setFocusedShowroomProductById = useCallback(
    (value: { id: string | number; mode: ShowroomModeType } | null) => {
      if (value) {
        setFocusedShowroomProductId(value.id);
        setShowroomMode(value.mode);
      } else {
        setFocusedShowroomProductId("");
        setShowroomMode("IDLE");
      }
    },
    [setShowroomMode]
  );
  /***************************************/

  /*************** Generic ***************/
  const updateProductPlacementLocalState = useCallback(
    (placement: ProductPlacement) => {
      if (showroomProducts[placement.id]) {
        const productCopy = { ...showroomProducts[placement.id] };
        productCopy.placement = JSON.parse(JSON.stringify(placement));
        setShowroomProducts({
          ...showroomProducts,
          [placement.id]: productCopy,
        });
        return productCopy;
      }
    },
    [showroomProducts]
  );
  const removeProductLocally = useCallback((placementId: string) => {
    setShowroomProducts((productPlacements) => {
      const copyProductPlacements = { ...productPlacements };
      delete copyProductPlacements[placementId];
      return copyProductPlacements;
    });
  }, []);
  const generateMovementsDiff: TransitionDataCallback = useCallback(
    ({ startState, endState }) => {
      if (startState && endState) {
        const data: { [key: string]: any } = {};
        const iterationMovement: ("position" | "rotation")[] = [
          "position",
          "rotation",
        ];
        const iterationParams: (keyof ProductPlacement["position"])[] = [
          "x",
          "y",
          "z",
        ];
        iterationMovement.forEach((movementType) => {
          iterationParams.forEach((param) => {
            const beforeParam = startState.placement[movementType][param]
              ? startState.placement[movementType][param].toFixed(8)
              : null;
            const afterParam = endState.placement[movementType][param]
              ? endState.placement[movementType][param].toFixed(8)
              : null;
            if ((beforeParam || afterParam) && beforeParam !== afterParam) {
              if (!data[movementType]) {
                data[movementType] = {};
              }
              data[movementType][param] = true;
            }
          });
        });
        if (Object.keys(data).length) {
          return data;
        }
      }
    },
    []
  );
  /***************************************/

  /************* Base Actions *************/
  const updateProductQueueDebouncedBase = useCallback(
    async (_product: ShowroomProduct, fnCallback?: (data: any) => void) => {
      // Update local state
      updateProductPlacementLocalState(_product.placement);

      // Update Server-Side Promise Queue Debounced
      await apiUpdateProductPositionQueueDebounced(_product);
      // On last queue response -> Source of truth
      // Sometimes it's delayed and prevent user to move smoothly
      // const updatedPlacement = getProductPlacement(data as any);
      // updateProductPlacementLocalState(updatedPlacement);
    },
    [apiUpdateProductPositionQueueDebounced, updateProductPlacementLocalState]
  );
  const updateProductQueueBase = useCallback(
    async (_showroomProduct: ShowroomProduct) => {
      const copyOfShowroomProducts = { ...showroomProducts };
      copyOfShowroomProducts[_showroomProduct.placement.id] = _showroomProduct;
      setShowroomProducts(copyOfShowroomProducts);
      // Update Server-Side Promise Queue
      await apiUpdateProductPositionQueue(_showroomProduct);
    },
    [apiUpdateProductPositionQueue, showroomProducts]
  );
  const addProductBase = useCallback(
    async (
      _product: Product,
      _placementCoordinates: PlacementCoordinates = {
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
      }
    ) => {
      const placementApi = {
        position_x: _placementCoordinates.position.x,
        position_y: _placementCoordinates.position.y,
        position_z: _placementCoordinates.position.z,
        rotation_y: _placementCoordinates.rotation.y,
      };
      const _showroomProduct = await apiAddProduct(_product, placementApi);
      if (_showroomProduct) {
        // Update state
        const copyOfShowroomProducts = { ...showroomProducts };
        copyOfShowroomProducts[_showroomProduct.placement.id] =
          _showroomProduct;
        setShowroomProducts(copyOfShowroomProducts);
        return _showroomProduct;
      }
      return null;
    },
    [apiAddProduct, showroomProducts]
  );
  const deleteProductBase = useCallback(
    async (_product: ShowroomProduct) => {
      const { placement } = _product;
      await apiDeleteProduct(placement);
      removeProductLocally(placement.id);
    },
    [apiDeleteProduct, removeProductLocally]
  );
  const cutProductBase = useCallback(
    (placementId: string) => {
      setClipboardProduct(showroomProducts[placementId] as ShowroomProduct);
      removeProductLocally(placementId);
    },
    [removeProductLocally, showroomProducts]
  );
  const pasteProductBase = useCallback(
    async ({ x, y, z }: { x: number; y: number; z: number }) => {
      if (clipboardProduct) {
        const copyOfClipboard: ShowroomProduct = {
          product: clipboardProduct.product,
          placement: {
            ...clipboardProduct.placement,
            position: { x, y: y + 0.5, z },
          },
        };
        await updateProductQueueBase(copyOfClipboard);
        setClipboardProduct(null);
        return copyOfClipboard;
      }
    },
    [clipboardProduct, updateProductQueueBase]
  );
  /***************************************/

  /*************** Actions ***************/
  const updateProductQueueDebounced = useCallback(
    async (_product: ShowroomProduct) => {
      const { startState, endState, clearSession } = createHistorySession(
        {
          action: HistoryActions.UPDATE,
          sessionId: _product.placement.id,
        },
        generateMovementsDiff
      );
      startState(showroomProducts[_product.placement.id] as ShowroomProduct);
      try {
        await updateProductQueueDebouncedBase(_product);
        endState(_product);
      } catch (e) {
        clearSession();
        throw e;
      }
    },
    [
      createHistorySession,
      generateMovementsDiff,
      showroomProducts,
      updateProductQueueDebouncedBase,
    ]
  );
  const updateProductQueue = useCallback(
    async (_showroomProduct: ShowroomProduct) => {
      const { startState, endState } = createHistorySession(
        {
          action: HistoryActions.UPDATE,
          sessionId: _showroomProduct.placement.id,
        },
        generateMovementsDiff
      );
      startState(
        showroomProducts[_showroomProduct.placement.id] as ShowroomProduct
      );
      await updateProductQueueBase(_showroomProduct);
      endState(_showroomProduct);
    },
    [
      createHistorySession,
      generateMovementsDiff,
      showroomProducts,
      updateProductQueueBase,
    ]
  );
  const deleteProduct = useCallback(
    async (_product: ShowroomProduct) => {
      const { startState, endState } = createHistorySession({
        action: HistoryActions.DELETE,
        sessionId: _product.placement.id,
      });
      startState(_product);
      await deleteProductBase(_product);
      endState(null);
    },
    [createHistorySession, deleteProductBase]
  );
  const addProduct = useCallback(
    async (_product: Product, _placementCoordinates?: PlacementCoordinates) => {
      const { startState, endState } = createHistorySession({
        action: HistoryActions.CREATE,
        sessionId: uniqueId(`add-product-${_product.id}`),
      });
      startState(null);
      let _placementCoordinatesToSet = _placementCoordinates;
      if (!_placementCoordinatesToSet) {
        _placementCoordinatesToSet = {
          position: { x: 0, y: 0, z: 0 },
          rotation: { x: 0, y: 0, z: 0 },
        };
        // Place the product in the camera view
        const camera = scene?.cameras[0];
        if (camera) {
          const pickedWithRay = scene.pickWithRay(camera.getForwardRay());
          if (pickedWithRay?.pickedPoint) {
            const { x, y, z } = pickedWithRay.pickedPoint;
            _placementCoordinatesToSet.position = { x, y, z };
          }
        }
      }

      const showroomProduct = await addProductBase(
        _product,
        _placementCoordinatesToSet
      );
      endState(showroomProduct);
    },
    [addProductBase, createHistorySession, scene]
  );
  const cutProduct = useCallback(
    (placementId: string) => {
      // const { startState, endState } = createHistorySession({
      //   action: HistoryActions.CLIPBOARD_CUT,
      // });
      // startState(showroomProducts[placementId]);
      cutProductBase(placementId);
      // endState(showroomProducts[placementId]);
    },
    [cutProductBase]
  );
  const pasteProduct = useCallback(
    async ({ x, y, z }: { x: number; y: number; z: number }) => {
      if (clipboardProduct) {
        const { startState, endState } = createHistorySession(
          {
            action: HistoryActions.UPDATE,
            sessionId: clipboardProduct.placement.id,
          },
          generateMovementsDiff
        );
        startState(clipboardProduct);
        const product = await pasteProductBase({ x, y, z });
        if (product) {
          endState(product);
          return product;
        }
      }
    },
    [
      clipboardProduct,
      createHistorySession,
      generateMovementsDiff,
      pasteProductBase,
    ]
  );
  // const loadProducts = useCallback(
  //   async (concurrency = 3) => {
  //     await apiFetchAllProducts({
  //       // Fetch all positions
  //       cbAfterFetchPositions: (placements) => {
  //         const _showroomProductsPlacements: {
  //           [key: string]: ShowroomProductBase;
  //         } = {};
  //         placements.forEach((placement) => {
  //           _showroomProductsPlacements[placement.id] = { placement };
  //         });
  //         setShowroomProducts(_showroomProductsPlacements);
  //         // if (scene && scene.cameras[0]) {
  //         //   const direction = scene.cameras[0].getDirection(Vector3.Distance());
  //         //   console.log(Vector3);
  //         //   console.log(direction);
  //         // }
  //       },
  //       // Fetch products by chunk
  //       cbAfterConcurrencyFetch: (_showroomProductsArray) => {
  //         const _tempShowroomProducts: {
  //           [key: string]: ShowroomProduct;
  //         } = {};
  //         _showroomProductsArray.forEach((_tempShowroomProduct) => {
  //           _tempShowroomProducts[_tempShowroomProduct.placement.id] =
  //             _tempShowroomProduct;
  //         });
  //         setShowroomProducts((prev) => ({
  //           ...prev,
  //           ..._tempShowroomProducts,
  //         }));
  //       },
  //       concurrency,
  //     });
  //   },
  //   [apiFetchAllProducts]
  // );
  const loadProductsDynamic = useCallback(
    async (concurrency = 3) => {
      await apiFetchAllProductsDynamic({
        // Fetch all positions
        callbackSortPositions: (placements, index) => {
          // Get camera look point
          let cameraLookAtPoint: Vector3;
          if (scene?.cameras[0]) {
            const [camera] = scene.cameras;
            const pickedWithRay = scene.pickWithRay(camera.getForwardRay());
            if (pickedWithRay?.pickedPoint) {
              // Camera point to mesh
              cameraLookAtPoint = pickedWithRay.pickedPoint;
            }
          }
          // @ts-ignore
          if (!cameraLookAtPoint) cameraLookAtPoint = new Vector3(0, 0, 0);

          // Sort positions by distance
          const sortedPlacements = _.sortBy<ProductPlacement>(placements, [
            ({ position }) =>
              Vector3.Distance(
                cameraLookAtPoint,
                new Vector3(position.x, position.y, position.z)
              ),
          ]);

          // Set Products positions for the first time
          if (index === 0) {
            const _showroomProductsPlacements: {
              [key: string]: ShowroomProductBase;
            } = {};
            sortedPlacements.forEach((placement) => {
              _showroomProductsPlacements[placement.id] = { placement };
            });
            setShowroomProducts(_showroomProductsPlacements);
          }

          return sortedPlacements;
        },
        // Fetch products by chunk
        callbackBulk: (_showroomProductsArray) => {
          const _tempShowroomProducts: {
            [key: string]: ShowroomProduct;
          } = {};
          _showroomProductsArray.forEach((_tempShowroomProduct) => {
            _tempShowroomProducts[_tempShowroomProduct.placement.id] =
              _tempShowroomProduct;
          });
          setShowroomProducts((prev) => ({
            ...prev,
            ..._tempShowroomProducts,
          }));
        },
        concurrency,
      });
    },
    [apiFetchAllProductsDynamic, scene]
  );
  /***************************************/

  /************** Callbacks **************/
  const goBackwardAction = useCallback(async () => {
    goBackward(async (historyItem: HistoryItem) => {
      const { transition, stateBefore } = historyItem;
      // Restore from clipboard anyway
      if (clipboardProduct) {
        setShowroomProducts({
          ...showroomProducts,
          [clipboardProduct.placement.id]: clipboardProduct,
        });
        setClipboardProduct(null);
      }
      switch (transition.action) {
        case "UPDATE":
          // Update product position
          if (stateBefore) await updateProductQueueBase(stateBefore);
          break;
        default:
          break;
      }
    });
  }, [clipboardProduct, goBackward, showroomProducts, updateProductQueueBase]);

  const goForwardAction = useCallback(async () => {
    goForward(async (historyItem: HistoryItem) => {
      const { transition, stateAfter } = historyItem;
      switch (transition.action) {
        case "UPDATE":
          // Update product position
          if (stateAfter) await updateProductQueueBase(stateAfter);
          break;
        default:
          break;
      }
    });
  }, [goForward, updateProductQueueBase]);
  const updateShowroomProductState = useCallback(
    (_showroomProduct: ShowroomProduct) => {
      setShowroomProducts((prev) => ({
        ...prev,
        [_showroomProduct.placement.id]: _showroomProduct,
      }));
    },
    []
  );
  /***************************************/

  const value: ProductsContextValue = {
    isLoading: loading,
    // loadProducts,
    updateShowroomProductState,
    loadProductsDynamic,
    clipboardProduct,
    showroomProducts,
    showroomProductsArray,
    focusedShowroomProduct,
    setFocusedShowroomProductById,
    updateProductQueueDebounced,
    addProduct,
    deleteProduct,
    cutProduct,
    pasteProduct,
    historyStake,
    stakePointerIndex,
    activeHistoryItem,
    enableBackward,
    enableForward,
    goBackwardAction,
    goForwardAction,
  };

  return <ProductsContext.Provider value={value} {...props} />;
};

export const useProductsContext = () => {
  return useContext<ProductsContextValue>(ProductsContext);
};
