import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatchWrapper, useAppSelector } from "hooks";
import FullScreenSpinner from "components/Loaders/FullScreenSpinner";
import { selectLoginStage } from "store/actions/app";
import { Layout, Row } from "antd";
import { SIGN_UP } from "sharedConstants";
const { Content } = Layout;

export const LoginLayout = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const appDispatchWrapper = useAppDispatchWrapper();
  const { appLoadingData }: { appLoadingData: boolean } = useAppSelector(
    (state) => state.app
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      if (!pathname.startsWith("/login")) navigate("/login");
    } else {
      navigate("/");
    }
  }, [navigate, pathname, token]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const signUpEmail = urlParams.get("sign_up_email");
    signUpEmail && appDispatchWrapper(selectLoginStage(SIGN_UP));
  }, [appDispatchWrapper]);

  return appLoadingData ? (
    <FullScreenSpinner message={"Logging in...Please wait!"} />
  ) : (
    <Layout className="login-layout-container">
      <Content className="content">
        <Row className="content_row" justify="center" align="middle">
          <Outlet />
        </Row>
      </Content>
    </Layout>
  );
};
