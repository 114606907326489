import axios from "axios";
import {
  BACKEND_URL,
  DELETE_METHOD,
  GET_METHOD,
  PATCH_METHOD,
  POST_METHOD,
} from "sharedConstants";

const getAllShowrooms = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/showroom_admin?exclude_shell_variants=true`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getShowroomShellVariantBulk = async function (showroomShellId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/showroom_shell_variant_bulk/${showroomShellId}?sendProps=true`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getShowroomShellVariantSingle = async function (variantRowId) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/showroom_shell_variant_single/${variantRowId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const updateShowroomShellVariantSingle = async function (
  variantRowId,
  data = {}
) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/showroom_shell_variant_single/${variantRowId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const deleteShowroomShellVariantSingle = async function (variantRowId) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/showroom_shell_variant_single/${variantRowId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const addShowroomShellVariantSingle = async function (
  showroomShellId,
  variantRowId,
  data
) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/showroom_shell_variants/${showroomShellId}/${variantRowId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const getShowroomShellNotes = async function (id = "") {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/shell_notes/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const createShowroomShellNote = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/shell_notes`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const updateShowroomShellNote = async function (id, data) {
  const response = await axios({
    method: PATCH_METHOD,
    url: `${BACKEND_URL}/shell_notes/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const getShowroomShellAnnotations = async function (id = "") {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/shell_annotations/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const createShowroomShellAnnotation = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/shell_annotations`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const deleteShowroomShellAnnotation = async function (id) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/shell_annotations/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getShowroomShellFileLocker = async function (noteId = "", data) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/shell_note_file_locker/${noteId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data,
  });
  return response;
};

const deleteShowroomShellFiles = async function (noteId = "", ids = []) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/shell_note_file_locker/${noteId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      files: ids,
    },
  });
  return response;
};

export {
  getAllShowrooms,
  getShowroomShellVariantBulk,
  getShowroomShellVariantSingle,
  updateShowroomShellVariantSingle,
  deleteShowroomShellVariantSingle,
  addShowroomShellVariantSingle,
  getShowroomShellNotes,
  createShowroomShellNote,
  updateShowroomShellNote,
  getShowroomShellAnnotations,
  createShowroomShellAnnotation,
  deleteShowroomShellAnnotation,
  getShowroomShellFileLocker,
  deleteShowroomShellFiles,
};
