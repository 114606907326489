import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";

import { Modal, Select, message } from "antd";
import { setModalState } from "store/actions/app";
import ApiService from "services/ApiService";

const { Option } = Select;

class EditVariantBehaviorsModal extends Component {
  state = {};
  onBehaviorFormFinished = async () => {
    if (this.state.selectedNewBehaviorId) {
      await ApiService.addVariantBehavior(
        this.props.variantData.id,
        this.state.selectedNewBehaviorId
      );
      message.success("Behavior Added");
      this.props.setModalState("editVariantBehaviors", {
        visible: false,
      });
    } else {
      message.info("You must select a behavior to add");
    }
  };

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  render() {
    const { setModalState, isLoading, modalState } = this.props;

    return (
      <>
        <Modal
          title={"Edit Variant Behaviors"}
          open={modalState.visible}
          onOk={this.onBehaviorFormFinished}
          onCancel={() =>
            setModalState("editVariantBehaviors", { visible: false })
          }
          destroyOnClose={true}
          cancelText="Cancel"
          okText="Add New Selected Behavior"
          okButtonProps={{
            disabled: isLoading,
            className: "bitreel-button-primary",
          }}
          cancelButtonProps={{
            disabled: isLoading,
            className: "bitreel-button",
          }}
          closable={!isLoading}
          maskClosable={!isLoading}
        >
          <div>Add Behavior</div>
          <Select
            showSearch
            placeholder="Select a behavior"
            dropdownMatchSelectWidth
            style={{ width: "400px" }}
            onChange={(value) => {
              this.setState({
                selectedNewBehaviorId: value,
              });
            }}
            defaultValue={null}
          >
            {this.props.behaviors.map((b) => {
              return (
                <Option key={b.id} value={b.id}>
                  {b.name}
                </Option>
              );
            })}
          </Select>
          <div>Current Behaviors</div>
          <ul>
            {this.props.variantData &&
              this.props.variantData.behavior.map((b, index) => (
                <li key={`item-${b.id}-${index}`}>
                  <p>{b}</p>
                  <div
                    onClick={() => {
                      ApiService.removeVariantBehavior(
                        this.props.variantData.id,
                        this.props.behaviors.find((behavior) => {
                          return behavior.name === b;
                        }).id
                      );
                    }}
                  >
                    Remove Behavior
                  </div>
                </li>
              ))}
          </ul>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({
  behaviors,
  app: {
    modalStates: { editVariantBehaviors: modalState },
  },
}) => {
  //   let { companyIdInUrl } = getParamsFromPathname(window.location.pathname)
  return {
    modalState,
    isLoading: false,
    behaviors,
  };
};

export default connect(mapStateToProps, {
  setModalState,
})(withRouter(EditVariantBehaviorsModal));
