import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card, Layout, Modal, Skeleton } from "antd";
import {
  LayoutsContextProvider,
  useLayoutsContext,
} from "./contexts/LayoutsContext";
import {
  useItem,
  useLayouts,
  usePartGroups,
  useParts,
  useMaterials,
} from "./hooks";
import { fetchCollectionItems } from "./utils/api";
import { LayoutsExplorer } from "./components/layoutsExplorer";
import { AddCollectionView } from "./views/addCollectionView";
import { ItemView } from "./views/itemView";
import { LayoutView } from "./views/layoutView";
import { PartGroupView } from "./views/partGroupView";
import { PartView } from "./views/partView";
import { MaterialView } from "./views/materialView";
import { LayoutBreadcrumb } from "./components/layoutBreadcrumb";
import { ActionsMenu } from "./components/actionsMenu";
import { AddModal } from "./components/addModal";
import { AddMaterialsModal } from "./components/addMaterialsModal";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { ItemType, ItemTypeLabel } from "./types";
import { getMaterial } from "./utils/dataModel";
import { UIContextProvider, useUIContext } from "./contexts/UIContext";
import { CollectionView } from "./views/collectionView";
const { Content, Sider } = Layout;

interface AdvancedProductCreatorCoreProps {
  companyId: number;
  orderId: number;
  itemCollectionId?: number;
}

const AdvancedProductCreatorCore = ({
  companyId,
  orderId,
  itemCollectionId,
}: AdvancedProductCreatorCoreProps) => {
  const { isDirty, setIsDirty } = useUIContext();
  const mode = useMemo(
    () => (itemCollectionId ? "edit" : "create"),
    [itemCollectionId]
  );
  const { removeItem } = useItem();
  const { addLayoutTree, removeLayout } = useLayouts();
  const { addPartGroup, clonePartGroup, removePartGroup } = usePartGroups();
  const { addPart, clonePart, removePart } = useParts();
  const { cloneMaterials, removeMaterial } = useMaterials();
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, modalContextHolder] = Modal.useModal();
  const [addModalState, setAddModalState] = useState<{
    open: boolean;
    title: string;
    type: ItemType | null;
  }>({ open: false, title: "Add", type: null });
  const [openMaterialsModal, setOpenMaterialsModal] = useState<boolean>(false);
  const {
    collection,
    items,
    setItems,
    setCollection,
    layouts,
    setLayouts,
    selectedKey,
    setSelectedKey,
    selectedItem,
  } = useLayoutsContext();

  /**************** Actions ******************/
  const actionOnAdd = useCallback(
    (name: string, type: ItemType) => {
      const { layout, partGroup } = selectedItem?.data || {};
      switch (type) {
        case "layout":
          if (itemCollectionId)
            addLayoutTree(name, { orderId, itemCollectionId });
          break;
        case "partGroup":
          if (layout) addPartGroup({ name }, { layout });
          break;
        case "part":
          if (partGroup) addPart({ name }, { partGroup });
          break;
        default:
          break;
      }
    },
    [
      addLayoutTree,
      addPart,
      addPartGroup,
      itemCollectionId,
      orderId,
      selectedItem?.data,
    ]
  );

  const actionOnDelete = useCallback(
    (type: ItemType) => {
      if (selectedItem) {
        const {
          data: { item, layout, partGroup, part, material },
        } = selectedItem;

        switch (type) {
          case "item":
            if (item) removeItem({ item });
            break;
          case "layout":
            console.log("Item: ", item, " Layout: ", layout);
            if (item && layout) removeLayout({ item, layout });
            //if (item) removeItem({ item });
            break;
          case "partGroup":
            console.log("Layout: ", layout, " Part Group: ", partGroup);
            if (layout && partGroup) removePartGroup({ layout, partGroup });
            break;
          case "part":
            if (partGroup && part) removePart({ partGroup, part });
            break;
          case "material":
            if (part && material) removeMaterial({ part, material });
            break;
          default:
            break;
        }
      }
    },
    [
      removeItem,
      removeLayout,
      removePartGroup,
      removePart,
      removeMaterial,
      selectedItem,
    ]
  );
  const actionOnClone = useCallback(
    async (type: ItemType) => {
      if (selectedItem) {
        const {
          data: { partGroup, part },
        } = selectedItem;
        switch (type) {
          case "part":
            if (part) clonePart({ part });
            break;
          case "partGroup":
            if (partGroup) clonePartGroup({ partGroup });
            break;
          default:
            break;
        }
      }
    },
    [clonePart, clonePartGroup, selectedItem]
  );
  const onAddMaterial = useCallback(
    (materials: any) => {
      if (selectedItem && selectedItem) {
        const { part } = selectedItem.data;
        if (part) {
          cloneMaterials({
            destinationPart: part,
            materials: materials.map((material: any) => getMaterial(material)),
          });
        }
      }
    },
    [cloneMaterials, selectedItem]
  );
  /*******************************************/

  /**************** Handlers *****************/
  const handleOnAdd = useCallback((type: ItemType) => {
    if (type !== "material") {
      setAddModalState({
        open: true,
        title: `Add ${ItemTypeLabel[type]}`,
        type: type,
      });
    } else {
      setOpenMaterialsModal(true);
    }
  }, []);

  const handleOnClone = useCallback(
    (type: ItemType) => {
      modal.confirm({
        title: `Do you want to clone this ${ItemTypeLabel[type]}`,
        icon: <ExclamationCircleOutlined />,
        okText: "Clone",
        cancelText: "Cancel",
        maskClosable: true,
        onOk: () => {
          actionOnClone(type);
        },
      });
    },
    [actionOnClone, modal]
  );
  const handleOnDelete = useCallback(
    (type: ItemType) => {
      modal.confirm({
        title: `Do you want to delete this ${ItemTypeLabel[type]}`,
        icon: <ExclamationCircleOutlined />,
        okText: "Delete",
        cancelText: "Cancel",
        okButtonProps: { danger: true },
        maskClosable: true,
        onOk: () => {
          actionOnDelete(type);
        },
      });
    },
    [actionOnDelete, modal]
  );
  const handleOnDiscardChanges = useCallback(() => {
    return new Promise((resolve) => {
      modal.confirm({
        title: `Do you want to discard changes?`,
        icon: <ExclamationCircleOutlined />,
        okText: "Discard",
        cancelText: "Cancel",
        okButtonProps: { danger: true },
        maskClosable: true,
        onOk: () => {
          setIsDirty(false);
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    });
  }, [modal, setIsDirty]);

  const handleOnSelect = useCallback(
    async (uuid: string) => {
      if (isDirty) {
        const discardChanges = await handleOnDiscardChanges();
        if (discardChanges) setSelectedKey(uuid);
      } else {
        setSelectedKey(uuid);
      }
    },
    [handleOnDiscardChanges, isDirty, setSelectedKey]
  );
  /*******************************************/

  useEffect(() => {
    setIsDirty(false);
  }, [setIsDirty, selectedKey]);

  useEffect(() => {
    if (itemCollectionId) {
      setLoading(true);
      fetchCollectionItems(itemCollectionId)
        .then(
          ({
            collection: _collection,
            //items: _items,
            //versions: _layouts,
          }) => {
            setCollection(_collection);
            //setItems(_items);
            //setLayouts(_layouts);
            setLoading(false);
          }
        )
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [itemCollectionId, setCollection /*, setItems, setLayouts*/]);

  console.log("Selected Item: ", selectedItem);
  console.log("Selected Key: ", selectedKey);

  return (
    <Layout className="advanced-product-creator-wrapper">
      <Sider width={350}>
        {loading ? (
          <div style={{ padding: "20px" }}>
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 3, width: "100%" }}
            />
          </div>
        ) : (
          <LayoutsExplorer
            collection={collection}
            selectedKey={selectedKey}
            //={items}
            //layouts={layouts}
            onSelect={handleOnSelect}
            disableAddLayout={mode === "create"}
            onAddLayout={() => {
              handleOnAdd("layout");
            }}
          />
        )}
      </Sider>
      <Content>
        {mode === "create" ? (
          <Card
            className="content-wrapper-card"
            size="small"
            title={<span>Create New Collection</span>}
          >
            <AddCollectionView companyId={companyId} orderId={orderId} />
          </Card>
        ) : (
          <>
            {loading ? (
              <FullAreaSpinner />
            ) : (
              <>
                {selectedItem && (
                  <LayoutBreadcrumb
                    selectedItem={selectedItem}
                    onSelect={handleOnSelect}
                  />
                )}
                {selectedItem && (
                  <Card
                    className="content-wrapper-card"
                    size="small"
                    title={
                      <span>
                        <b>{ItemTypeLabel[selectedItem.type]}:</b>{" "}
                        <i>{selectedItem.name}</i>
                      </span>
                    }
                  >
                    <ActionsMenu
                      onAdd={handleOnAdd}
                      onClone={handleOnClone}
                      onDelete={handleOnDelete}
                      selectedItem={selectedItem}
                    />
                    {selectedItem.type === "collection" ? (
                      <CollectionView
                        key={selectedItem.uuid}
                        selectedItem={selectedItem}
                      />
                    ) : selectedItem.type === "item" ? (
                      <ItemView
                        key={selectedItem.uuid}
                        selectedItem={selectedItem}
                      />
                    ) : selectedItem.type === "layout" ? (
                      <LayoutView
                        key={selectedItem.uuid}
                        selectedItem={selectedItem}
                      />
                    ) : selectedItem.type === "partGroup" ? (
                      <PartGroupView
                        key={selectedItem.uuid}
                        selectedItem={selectedItem}
                      />
                    ) : selectedItem.type === "part" ? (
                      <PartView
                        key={selectedItem.uuid}
                        selectedItem={selectedItem}
                      />
                    ) : selectedItem.type === "material" ? (
                      <MaterialView
                        key={selectedItem.uuid}
                        selectedItem={selectedItem}
                      />
                    ) : null}
                  </Card>
                )}
                {/*}
                {selectedKey === "collection" && collection && (
                  <Card
                    className="content-wrapper-card"
                    size="small"
                    title={
                      <span>
                        <b>{ItemTypeLabel["collection"]}:</b>{" "}
                        <i>{collection.name}</i>
                      </span>
                    }
                  >
                    <CollectionView collection={collection} />
                  </Card>
                )}
                {selectedKey === "item" && items && (
                  <Card
                    className="content-wrapper-card"
                    size="small"
                    title={
                      <span>
                        <b>{ItemTypeLabel["item"]}:</b> <i>{items.name}</i>
                      </span>
                    }
                  >
                    <ItemView item={items} />
                  </Card>
                )}
                  */}
              </>
            )}
          </>
        )}
      </Content>
      {addModalState.open && (
        <AddModal
          title={addModalState.title}
          open={addModalState.open}
          onSubmit={(name) => {
            if (addModalState.type) actionOnAdd(name, addModalState.type);
            setAddModalState({ open: false, title: "", type: null });
          }}
          onCancel={() => {
            setAddModalState({ open: false, title: "", type: null });
          }}
        />
      )}
      <AddMaterialsModal
        open={openMaterialsModal}
        onCancel={() => {
          setOpenMaterialsModal(false);
        }}
        onSubmit={(materials) => {
          onAddMaterial(materials);
          setOpenMaterialsModal(false);
        }}
      />
      {modalContextHolder}
    </Layout>
  );
};

export const AdvancedProductCreator = ({ ...rest }) => {
  const { companyId, orderId, itemCollectionId } = useParams();
  return companyId && orderId ? (
    <LayoutsContextProvider>
      <UIContextProvider>
        <AdvancedProductCreatorCore
          companyId={Number(companyId)}
          orderId={Number(orderId)}
          itemCollectionId={Number(itemCollectionId)}
          {...rest}
        />
      </UIContextProvider>
    </LayoutsContextProvider>
  ) : (
    <div className="advanced-product-creator-wrapper-empty">
      <h2>Wrong url</h2>
    </div>
  );
};
