export const mapSeries = async <T>(
  iterable: T[],
  action: (item: T) => Promise<void>
) => {
  for (const x of iterable) {
    await action(x);
  }
};

export const eachParallel = async <T, R = void>(
  iterable: T[],
  cb: (item: T, index: number, iterable: T[]) => Promise<R>
) => {
  const promises = [];
  let index = 0;
  for (const item of iterable) {
    promises.push(cb(item, index, iterable));
    index += 1;
  }
  return Promise.all(promises);
};

interface ConcurrencySeriesDynamicProp<T, R = any> {
  iterable: () => Promise<T[]> | T[];
  cb: (item: T, iterable: T[]) => Promise<R> | R;
  cbBatch?: (results: R[], iterable: T[]) => Promise<void> | void;
}

export const concurrencySeriesDynamic = async <T, R = any>({
  iterable,
  cb,
  cbBatch,
}: ConcurrencySeriesDynamicProp<T, R>) => {
  const results: R[] = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const iterations = await iterable();
    if (!iterations.length) break;
    const promises = iterations.map((item) => cb(item, iterations));
    const promisesResult = await Promise.all(promises);
    if (cbBatch) await cbBatch(promisesResult, iterations);
    results.push(...promisesResult);
  }
  return results;
};

export const concurrencySeries = async <T, R = any>(
  iterable: T[],
  {
    cb,
    cbBatch,
  }: {
    cb: (item: T, index: number, iterable: T[]) => Promise<R> | R;
    cbBatch?: (
      results: R[],
      index: number,
      iterable: T[]
    ) => Promise<void> | void;
  },
  concurrency: number
) => {
  let [result, promises, index]: [any[], any[], number] = [[], [], 0];
  for (const item of iterable) {
    promises.push(cb(item, index, iterable));
    index += 1;
    if (promises.length === concurrency) {
      const promisesResult = await Promise.all(promises);
      result = result.concat(promisesResult);
      if (cbBatch) await cbBatch(promisesResult, index, iterable);
      promises = [];
    }
  }
  if (promises.length) {
    const promisesResult = await Promise.all(promises);
    result = result.concat(promisesResult);
    if (cbBatch) await cbBatch(promisesResult, index, iterable);
  }
  return result;
};
