import React, { useCallback } from "react";
import { notification } from "antd";
import { bulkCopyMaterials, patchMaterial, deleteMaterial } from "../utils/api";
import { useLayoutsContext } from "../contexts/LayoutsContext";
import { MaterialType, PartType } from "../types";
import { getMaterial } from "../utils/dataModel";
import _ from "lodash";
import { useUIContext } from "../contexts/UIContext";

export const useMaterials = () => {
  const { companyId, updateItemByUUID, setSelectedKey, getItemByUUID } =
    useLayoutsContext();
  const { taskWrapper } = useUIContext();

  // const addMaterials = useCallback(
  //   async (
  //     materials: { name: string; image_url: string }[],
  //     { part, layout }: { part: PartType; layout: LayoutType }
  //   ) => {
  //     const materialsApiResponse = await postMaterials(part.apiId, materials);

  //     if (materialsApiResponse) {
  //       const materials = materialsApiResponse.map((materialApiResponse) =>
  //         getMaterial(materialApiResponse.data, 0)
  //       );
  //       updateItemByUUID(part.uuid, (_part) => {
  //         (_part as PartType).materials = [
  //           ...(_part as PartType).materials,
  //           ...materials,
  //         ];
  //         return _part;
  //       });
  //       setSelectedKey(materials[materials.length - 1].uuid);
  //       notification.success({
  //         message: "Materials successfully created!",
  //       });
  //     }
  //   },
  //   [setSelectedKey, updateItemByUUID]
  // );

  const updateMaterial = useCallback(
    async (
      part: PartType,
      material: Pick<MaterialType, "uuid" | "apiId" | "name" | "priceMod">
    ) => {
      await patchMaterial(part, material);
      updateItemByUUID(material.uuid, (_material) => ({
        ..._material,
        ...material,
      }));
      notification.success({
        message: "Material successfully updated!",
      });
    },
    [updateItemByUUID]
  );

  const cloneMaterials = useCallback(
    async ({
      originPart,
      destinationPart,
      materials,
    }: {
      originPart?: PartType;
      destinationPart: PartType;
      materials: MaterialType[];
    }) => {
      /*************** Check for dublicates by name *****************/
      const diffMaterials = _.differenceWith(
        materials,
        destinationPart.materials,
        (valueA, valueB) => valueA.name === valueB.name
      );
      const duplicatesCount = materials.length - diffMaterials.length;
      if (!diffMaterials.length) {
        notification.warning({
          message: "All selected materials already exists!",
        });
        return;
      }
      const materialIds = diffMaterials.map((m) => m.apiId);
      /**************************************************************/

      const copyResponse = await bulkCopyMaterials({
        company_id: companyId,
        origin_part_id: originPart?.apiId || undefined,
        destination_part_id: destinationPart.apiId,
        origin_material_ids: materialIds,
      });
      const materialsCopied: MaterialType[] = copyResponse.data.map(
        (materialApi: any) => getMaterial(materialApi)
      );

      updateItemByUUID(destinationPart.uuid, (_destinationPart) => {
        (_destinationPart as PartType).materials = [
          ...(_destinationPart as PartType).materials,
          ...materialsCopied,
        ];
        return _destinationPart;
      });
      notification.success({
        message: "Materials successfully cloned!",
        description: duplicatesCount
          ? `Skipped ${duplicatesCount} ${
              duplicatesCount > 1 ? `dublicates` : "dublicate"
            }!`
          : undefined,
      });
    },
    [companyId, updateItemByUUID]
  );

  const removeMaterial = useCallback(
    async ({ part, material }: { part: PartType; material: MaterialType }) => {
      if (part && material) {
        // @ts-ignore
        const materialApiResponse = await deleteMaterial(material.apiId);
        if (materialApiResponse) {
          updateItemByUUID(part.uuid, (_part) => {
            (_part as PartType).materials = (
              _part as PartType
            ).materials.filter((m) => material.uuid !== m.uuid);
            return _part;
          });
          setSelectedKey(part.uuid);
          notification.success({
            message: "Material successfully deleted!",
          });
        }
      }
    },
    [setSelectedKey, updateItemByUUID]
  );

  return {
    updateMaterial: taskWrapper(updateMaterial),
    cloneMaterials: taskWrapper(cloneMaterials),
    removeMaterial: taskWrapper(removeMaterial),
  };
};
