import React, { useMemo, useRef, useState } from "react";
import { Button, message, Modal, Upload } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload/interface";
import { UploadFile } from "antd/lib/upload/interface";

const UploadButton = ({ loading }: { loading?: boolean }) => (
  <div>
    {loading ? <UploadOutlined /> : <PlusOutlined />}
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

type ImageType = "png" | "svg" | "jpg" | "jpeg" | "svg+xml";
type CustomRcFile = RcFile & { isValid?: boolean };

interface ImageUploaderProps {
  allowedType?: ImageType[];
  maxSize?: number;
  accept?: string;
}

export const ImageUploader = ({
  allowedType = ["jpeg", "jpg", "png", "svg", "svg+xml"],
  maxSize = 10,
  accept = ".jpeg, .jpg, .png, .svg",
}: ImageUploaderProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const file = useMemo(() => fileList[0] || null, [fileList]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const wrapperRef = useRef<any>(null);

  const handleBeforeUpload = (rcFile: RcFile) => {
    let isValid = true;
    if (isValid && allowedType) {
      if (!allowedType.find((ext) => `image/${ext}` === rcFile.type)) {
        isValid = false;
        message.error(
          `You can only upload ${allowedType
            .map((ext) => ext.toUpperCase())
            .join("/")} file!`
        );
      }
    }

    if (isValid && maxSize) {
      if (rcFile.size / 1024 / 1024 > maxSize) {
        isValid = false;
        message.error(`Image must be smaller than ${maxSize}MB!`);
      }
    }

    (rcFile as CustomRcFile).isValid = isValid;
    return false;
  };

  const handleOnReplace = () => {
    try {
      wrapperRef.current.querySelectorAll('input[type="file"]')[0].click();
    } catch {
      /* empty */
    }
  };

  const handleOnRemove = () => {
    try {
      wrapperRef.current.getElementsByClassName("anticon-delete")[0].click();
    } catch {
      /* empty */
    }
  };

  const handleCancel = () => {
    setPreviewOpen(false);
    setPreviewImage("");
    setPreviewTitle("");
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  return (
    <div ref={wrapperRef} className="image-uploader">
      <Upload
        fileList={fileList}
        beforeUpload={handleBeforeUpload}
        onRemove={() => {
          setFileList([]);
        }}
        onChange={({ fileList }) => {
          if (fileList[0] && (fileList[0] as CustomRcFile).isValid) {
            setFileList(fileList);
          }
        }}
        maxCount={1}
        listType="picture-card"
        onPreview={handlePreview}
        accept={accept}
      >
        {!file && <UploadButton />}
      </Upload>
      {file && (
        <div className="file-properties">
          <div className="file-name">{file.name}</div>
          <div className="file-actions">
            <Button type="link" onClick={handleOnRemove}>
              remove
            </Button>
            <span className="divider">|</span>
            <Button type="link" onClick={handleOnReplace}>
              replace
            </Button>
          </div>
        </div>
      )}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        width={700}
        centered
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};
