import { configureStore, Reducer } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
} from "redux-persist";
import ApiService from "services/ApiService";

export const initStore = (rootReducers: Reducer) => {
  const authTransform = createTransform(
    (outboundState) => {
      return outboundState;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (outboundState: any, key) => {
      if (!ApiService.token && outboundState.token) {
        ApiService.setToken(outboundState.token);
      }
      return outboundState;
    },
    { whitelist: ["auth"] }
  );

  const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"],
    transforms: [authTransform],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducers);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).store = store;
  }

  setupListeners(store.dispatch);

  return store;
};
