import ApiService from "services/ApiService";
import { ADD_BEHAVIOR, SET_BEHAVIORS } from "sharedConstants";

export const setBehaviors = (behaviors) => {
  return {
    type: SET_BEHAVIORS,
    behaviors,
  };
};

export const getAndSetBehaviors = () => async (dispatch) => {
  let behaviors = await ApiService.getBehaviors();
  dispatch(setBehaviors(behaviors.data));
};

export const addBehavior = (behaviorName) => async (dispatch) => {
  let res = await ApiService.postBehavior(behaviorName);
  dispatch({
    type: ADD_BEHAVIOR,
    behavior: res.data,
  });
};
