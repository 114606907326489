export const BACKEND_URL =
  process.env.REACT_APP_STAGE === "beta"
    ? "https://bitscene-api-dev.bitreel.com/api"
    : "https://bitscene-api.bitreel.com/api";

export const BACKEND_NEW_URL =
  process.env.REACT_APP_STAGE === "beta"
    ? "https://bitscene-api-dev.bitreel.com/api"
    : "https://bitscene-api.bitreel.com/api";

export const FORM_LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const UPDATE_LIBRARY_MATERIAL = "UPDATE_LIBRARY_MATERIAL";
export const UPDATE_LIBRARY_MATERIAL_CATEGORY =
  "UPDATE_LIBRARY_MATERIAL_CATEGORY";
export const GET_METHOD = "get";
export const POST_METHOD = "post";
export const PATCH_METHOD = "patch";
export const DELETE_METHOD = "delete";
export const LOGIN_URL = "/login";

export const UPDATE_ITEM_COLLECTION_ARCHIVED =
  "UPDATE_ITEM_COLLECTION_ARCHIVED";
export const MOVE_ITEM_COLLECTION_TO_ORDER = "MOVE_ITEM_COLLECTION_TO_ORDER";
export const SET_COMPANIES = "SET_COMPANIES";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const SET_COMPANY_DETAIL = "SET_COMPANY_DETAIL";
export const SET_ORDER = "SET_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const CREATE_COMPANY = "CREATE_COMPANY";

export const SET_ME = "SET_ME";

export const SET_ITEM = "SET_ITEM";
export const SET_STATUSES = "SET_STATUSES";
export const SET_FILE_LOCKER = "SET_FILE_LOCKER";

export const SET_ACTIVE_COMMENT_ID = "SET_ACTIVE_COMMENT_ID";

export const SET_HOTSPOTS = "SET_HOTSPOTS";
export const ADD_HOTSPOT = "ADD_HOTSPOT";
export const REMOVE_HOTSPOT = "REMOVE_HOTSPOT";
export const SELECT_HOTSPOT = "SELECT_HOTSPOT";

export const SET_SELECTED_COMPANY_ID = "SET_SELECTED_COMPANY_ID";
export const SET_APP_INITIAL_LOADING = "SET_APP_INITIAL_LOADING";

export const SET_TOKEN = "SET_TOKEN";
export const SET_USER = "SET_USER";
export const SET_REFRESH = "SET_REFRESH";
export const SET_USER_LOADING = "SET_USER_LOADING";

export const SET_USERS = "SET_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const ADD_USER = "ADD_USER";

export const QR_VIEW_URL = "/products/view_qr";
export const NO_AUTH_URLS = [LOGIN_URL, QR_VIEW_URL];
export const LOGIN_ENDPOINT = `${BACKEND_URL}/oauth`;
export const SET_ITEM_STATUSES = "SET_ITEM_STATUSES";

export const HOME_URL = "/products";
export const MODEL_SELECT_URL = "/products/:cid";
export const MODEL_INFO_URL = "/products/:cid/:pid";

export const LOADED_HOTSPOT = "hotspot-load";
export const NEW_HOTSPOT = "hotspot-new";

export const SET_MODAL_STATE = "SET_MODAL_STATE";
export const SET_PROFILE = "SET_PROFILE";
export const SET_APP_LOADING_DATA = "SET_APP_LOADING_DATA";

export const SET_ITEM_COLLECTION = "SET_ITEM_COLLECTION";

export const UPDATE_APP_MODULE_STATE = "UPDATE_APP_MODULE_STATE";

export const ADD_OR_REPLACE_ITEM_VERSION = "ADD_OR_REPLACE_ITEM_VERSION";
export const SET_SELECTED_ITEM_VERSION = "SET_SELECTED_ITEM_VERSION";

export const SET_LIBRARY_MATERIALS = "SET_LIBRARY_MATERIALS";
export const SET_LIBRARY_MATERIAL_CATEGORIES =
  "SET_LIBRARY_MATERIAL_CATEGORIES";

export const ADD_ITEM_DETAILS = "ADD_ITEM_DETAILS";
export const ADD_OR_UPDATE_VARIANT = "ADD_OR_UPDATE_VARIANT";
export const SET_SELECTED_ORDER = "SET_SELECTED_ORDER";
export const ADD_OR_UPDATE_ITEM = "ADD_OR_UPDATE_ITEM";
export const ADD_OR_UPDATE_ORDER = "ADD_OR_UPDATE_ORDER";

export const UPDATE_ITEM_COLLECTION_LIST_ORDER =
  "UPDATE_ITEM_COLLECTION_LIST_ORDER";
export const UPDATE_ITEM_COLLECTION_ORDER = "UPDATE_ITEM_COLLECTION_ORDER";

export const OPEN_EDIT_USER_MODAL = "OPEN_EDIT_USER_MODAL";
export const OPEN_CREATE_USER_MODAL = "OPEN_CREATE_USER_MODAL";

export const ADD_COMMENT = "ADD_COMMENT";

export const ADD_TO_ITEM_FILE_LOCKER = "ADD_TO_ITEM_FILE_LOCKER";
export const UPDATE_PRODUCT_FILTER = "UPDATE_PRODUCT_FILTER";

export const ADD_ORDER_FOR_COMPANY = "ADD_ORDER_FOR_COMPANY";

export const SET_BEHAVIORS = "SET_BEHAVIORS";
export const ADD_BEHAVIOR = "ADD_BEHAVIOR";

export const imageExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "svg",
  "webp",
  "tiff",
  "tif",
  "jPG",
];

export const LOGIN_STAGE = "LOGIN_STAGE";

export const SIGN_IN = "SIGN_IN";
export const SIGN_UP = "SIGN_UP";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const AWS_SQS = `${BACKEND_URL}/script_one`;
export const SET_ITEM_COLLECTION_DETAILS_FOR_ORDER =
  "SET_ITEM_COLLECTION_DETAILS_FOR_ORDER";
