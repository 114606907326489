import { uniqueId } from "lodash";
import {
  LayoutType,
  PartGroupType,
  PartType,
  MaterialType,
  ItemRemoveType,
  CollectionType,
} from "../types";

export const getCollection = (apiCollection: any) => {
  const collection: CollectionType = {
    uuid: uniqueId("collection-"),
    _uuid: uniqueId("collection-"),
    name: apiCollection.name,
    items: [],
    layouts: [],
    versions: [],
    apiItemId: apiCollection.id,
    status: apiCollection.status,
    description: apiCollection.description,
    id: apiCollection.id,
    apiId: apiCollection.id,
    price: apiCollection.price,
    dimensions: apiCollection.dimensions,
    files: {
      product_image: apiCollection?.file_locker?.product_image || [],
      reference_file: apiCollection?.file_locker?.reference_file || [],
    },
  };
  return collection;
};

export const getItem = (apiItem: any) => {
  const item: ItemRemoveType = {
    uuid: uniqueId("item-"),
    _uuid: uniqueId("item-"),
    name: apiItem.name,
    layouts: [],
    versions: [],
    apiId: apiItem.id,
    apiItemId: apiItem.id,
    status: apiItem.status,
    description: apiItem.description,
    id: apiItem.id,
    price: apiItem.price,
    dimensions: apiItem.dimensions,
    files: {
      product_image: apiItem?.file_locker?.product_image || [],
      reference_file: apiItem?.file_locker?.reference_file || [],
    },
  };
  return item;
};

export const getLayout = (apiLayout: any /*, apiItem: any*/) => {
  const layout: LayoutType = {
    _uuid: uniqueId("layout-"),
    uuid: uniqueId("layout-"),
    name: apiLayout.name,
    apiItemId: apiLayout.item_id,
    //image,
    //imageFile,
    items: [],
    layouts: [],
    versions: [],
    partGroups: [],
    basePrice: apiLayout.base_price,
    id: apiLayout.id,
    apiId: apiLayout.id,
    apiVersionId: apiLayout.id,
    files: {
      product_image: apiLayout?.file_locker?.product_image || [],
      reference_file: apiLayout?.file_locker?.reference_file || [],
    },
    external_url: apiLayout.external_url,
    thumbnail_post_url: apiLayout.thumbnail_post_url,
    status: apiLayout.status,
    price: apiLayout.price,
    description: apiLayout.description,
    dimensions: apiLayout.dimensions,
  };
  return layout;
};

export const getPartGroup = (apiPartgroup: any) => {
  const partGroup: PartGroupType = {
    uuid: uniqueId("partGroup-"),
    _uuid: uniqueId("partGroup-"),
    name: apiPartgroup.name,
    optional: apiPartgroup.optional as boolean,
    id: apiPartgroup.id,
    apiId: apiPartgroup.id,
    parts: [],
    status: apiPartgroup.status,
    apiItemId: apiPartgroup.item_id,
    description: apiPartgroup.description,
    price: apiPartgroup.price,
    dimensions: apiPartgroup.dimensions,
    layouts: [],
    versions: [],
    files: {
      product_image: apiPartgroup?.file_locker?.product_image || [],
      reference_file: apiPartgroup?.file_locker?.reference_file || [],
    },
  };
  return partGroup;
};

export const getPart = (apiPart: any) => {
  const part: PartType = {
    uuid: uniqueId("part-"),
    _uuid: uniqueId("part-"),
    name: apiPart.name,
    priceMod: apiPart.price_mod,
    id: apiPart.id,
    apiId: apiPart.id,
    materials: [],
    thumbnail_post_url: apiPart.thumbnail_post_url,
    external_url: apiPart.external_url,
    status: apiPart.status,
    description: apiPart.description,
    price: apiPart.price,
    dimensions: apiPart.dimensions,
    layouts: [],
    versions: [],
    apiItemId: apiPart.item_id,
    files: {
      product_image: apiPart?.file_locker?.product_image || [],
      reference_file: apiPart?.file_locker?.reference_file || [],
    },
  };
  return part;
};

export const getMaterial = (
  apiMaterial: any,
  priceMod = 0
  // partId: number,
  // materialModDict: any
) => {
  // let priceMod = 0;
  // if (materialModDict[partId] && materialModDict[partId][apiMaterial.id]) {
  //   priceMod = materialModDict[partId][apiMaterial.id];
  // }
  const material: MaterialType = {
    uuid: uniqueId("meterial-"),
    _uuid: uniqueId("meterial-"),
    name: apiMaterial.name,
    priceMod, // TODO needs to lookup from price mod dict in item version :((
    materialPriceModOverrideDefault: 0, // sigh need to look up lib price too..
    id: apiMaterial.id,
    apiId: apiMaterial.id,
    imageUrl: apiMaterial.internal_url,
    apiItemId: apiMaterial.item_id,
    status: apiMaterial.status,
    description: apiMaterial.description,
    price: apiMaterial.price,
    dimensions: apiMaterial.dimensions,
    layouts: [],
    versions: [],
    files: {
      product_image: apiMaterial?.file_locker?.product_image || [],
      reference_file: apiMaterial?.file_locker?.reference_file || [],
    },
  };
  return material;
};
