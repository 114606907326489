import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import { Button, Divider, Row, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import { map } from "lodash";
import { getParamsFromPathname } from "../../../../utils/params";
import { openCreateUserModal, openEditUserModal } from "store/actions/app";
import EditUserModal from "./editUserModal";
import CreateUserModal from "./createUserModal";
import { store } from "store";

const columns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    disabled: true,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    disabled: true,
    sorter: (a, b) => a > b,
  },
  {
    title: "Action",
    dataIndex: "",
    key: "x",
    render: (text, record, index) => (
      <Row justify={"end"}>
        <a onClick={() => store.dispatch(openEditUserModal(true, record.key))}>
          Edit User Info
        </a>
        <Divider type="vertical" />
        <a>Deactivate User</a>
      </Row>
    ),
  },
];

class UserSettings extends Component {
  constructor(props) {
    super(props);

    this.userDataTransform = this.userDataTransform.bind(this);
  }

  userDataTransform() {
    return map(
      this.props.companies[
        getParamsFromPathname(window.location.pathname).companyIdInUrl
      ].users,
      (user) => {
        return {
          key: user.id,
          status: user.active ? "Active" : "Deactivated",
          email: user.email,
        };
      }
    );
  }

  render() {
    return (
      <div className={"screen-container"}>
        <Row justify="center" align="middle">
          <h1>User Settings</h1>
        </Row>
        <Row justify="start" align="middle">
          <Button
            className="portal-button"
            onClick={() => this.props.openCreateUserModal(true)}
          >
            CREATE NEW USER
          </Button>
        </Row>
        <Row justify="center" align="middle">
          <Content className="tableContent">
            <Table columns={columns} dataSource={this.userDataTransform()} />
          </Content>
        </Row>
        <EditUserModal />
        <CreateUserModal />
      </div>
    );
  }
}

const mapStateToProps = ({ companies }) => {
  return {
    companies,
  };
};

export default connect(mapStateToProps, {
  openEditUserModal,
  openCreateUserModal,
})(withRouter(UserSettings));
