import React, { Component } from "react";
import { Routes, Route } from "react-router";
import { withRouter } from "utils";
import { connect } from "react-redux";
import MaterialsListScreen from "./screens/list";
class MaterialsModule extends Component {
  render() {
    return (
      <Routes>
        <Route element={<MaterialsListScreen />} path={`*`} />
      </Routes>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(withRouter(MaterialsModule));
