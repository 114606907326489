import React from "react";
import { Card, Row } from "antd";
import { SIGN_UP, FORGOT_PASSWORD, SIGN_IN } from "sharedConstants";
import logo from "assets/images/loginLogo.png";
import ForgotPasswordForm from "./components/forgotPasswordForm";
import LoginForm from "./components/loginForm";
import SignupForm from "./components/signupForm";
import { useAppSelector } from "hooks";

export const Login = () => {
  const { selectedStage }: { selectedStage: string } = useAppSelector(
    (state) => state.app.moduleStates.login
  );

  return (
    <Card className="login_card">
      <Row className="content_row" justify="center" align="middle">
        <img className="login_logo_img" alt="bitreel" src={logo} />
      </Row>
      {selectedStage === SIGN_IN ? (
        <LoginForm />
      ) : selectedStage === FORGOT_PASSWORD ? (
        <ForgotPasswordForm />
      ) : selectedStage === SIGN_UP ? (
        <SignupForm />
      ) : null}
    </Card>
  );
};
