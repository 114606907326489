import { Modal, Upload } from "antd";
import React, { Component } from "react";
import CloudUpload from "assets/svg/CloudUpload";

class AddLayout extends Component {
  state = {
    img: null,
  };
  render() {
    return (
      <Modal
        title="Add Part Group"
        open={this.props.visible}
        onOk={() => this.props.onOk(this.state.name)}
        onCancel={this.props.onCancel}
      >
        <label>Part Group Name:</label>
        <div>
          <input
            style={{ width: "350px" }}
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          ></input>
        </div>
      </Modal>
    );
  }
}

export default AddLayout;
