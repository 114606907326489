import produce from "immer";
import {
  LOGIN_STAGE,
  OPEN_CREATE_USER_MODAL,
  OPEN_EDIT_USER_MODAL,
  SET_APP_INITIAL_LOADING,
  SET_APP_LOADING_DATA,
  SET_MODAL_STATE,
  SET_SELECTED_COMPANY_ID,
  SET_SELECTED_ITEM_VERSION,
  SET_SELECTED_ORDER,
  SIGN_IN,
  UPDATE_APP_MODULE_STATE,
  UPDATE_PRODUCT_FILTER,
} from "sharedConstants";
import { getParamsFromPathname } from "utils";

export const APP_INITIAL_STATE = {
  appIsInitialLoading: true,
  appLoadingData: false,
  modalStates: {
    createProduct: {
      visible: false,
    },
    createOrder: {
      visible: false,
    },
    createUserModal: {
      visible: false,
    },
    editUserModal: {
      visible: false,
    },
    createItemSimple: {
      visible: false,
    },
    createVariant: {
      visible: false,
    },
    createItemAdvanced: {
      visible: false,
    },
    createLibraryMaterialCategory: {
      visible: false,
    },
    createLibraryMaterial: {
      visible: false,
    },
    createVariantAdvanced: {
      visible: false,
    },
    createBehavior: {
      visible: false,
    },
    editVariantBehaviors: {
      visible: false,
    },
  },
  moduleStates: {
    product: {
      listTablePageSize: 10,
      listTablePageNumber: 1,
      listTableExpandedKeys: [],
      latestVariantResponse: {},
    },
    user: {
      editUserId: null,
    },
    login: {
      selectedStage: SIGN_IN,
    },
  },
  productFilters: {
    name: {
      value: "",
      type: "variantSearch",
      searchKey: "name",
      filterType: "text",
      label: "Variant Name",
    },
    category: {
      value: "",
      type: "variantSearch",
      searchKey: "category",
      filterType: "text",
      label: "Variant Category",
    },
    subSku: {
      value: "",
      type: "variantSearch",
      searchKey: "sub_sku",
      filterType: "text",
      label: "Variant Sub Sku",
    },
    variantStatus: {
      value: -1,
      type: "variantSearch",
      searchKey: "status_id",
      filterType: "select",
      label: "Variant Status",
    },
    status: {
      value: -1,
      type: "status",
      label: "Collection Status",
    },
    archived: {
      value: false,
      type: "boolean",
      label: "Archived",
    },
  },
};

let previousLocationChange = "";

export default produce((draft = APP_INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_STAGE:
      draft.moduleStates.login.selectedStage = action.stage;
      break;

    case OPEN_EDIT_USER_MODAL:
      draft.modalStates.editUserModal.visible = action.isOpened;
      draft.moduleStates.user.editUserId = action.uid;
      break;

    case OPEN_CREATE_USER_MODAL:
      draft.modalStates.createUserModal.visible = action.isOpened;
      break;

    case SET_APP_INITIAL_LOADING:
      const { appIsInitialLoading } = action;
      draft["appIsInitialLoading"] = appIsInitialLoading;
      break;

    case SET_APP_LOADING_DATA:
      const { isLoadingData } = action;
      draft["appLoadingData"] = isLoadingData;
      break;

    case SET_MODAL_STATE:
      const { state, modalName } = action;
      draft.modalStates[modalName] = {
        ...draft.modalStates[modalName],
        ...state,
      };
      break;

    case UPDATE_APP_MODULE_STATE:
      const { moduleState, moduleName } = action;
      draft.moduleStates[moduleName] = {
        ...draft.moduleStates[moduleName],
        ...moduleState,
      };
      break;

    case SET_SELECTED_ORDER:
      const { selectedOrderId } = action;
      draft.selectedOrderId = selectedOrderId;
      break;

    case SET_SELECTED_COMPANY_ID:
      const { selectedCompanyId } = action;
      draft["selectedCompanyId"] = selectedCompanyId;
      break;

    case SET_SELECTED_ITEM_VERSION:
      const { selectedItemVersion, selectedItemId } = action;
      draft.selectedItem = {};
      draft.selectedVersionId = selectedItemVersion;
      draft.selectedItemId = selectedItemId;
      break;

    case UPDATE_PRODUCT_FILTER:
      const { filterKey, filterValue } = action;
      draft.productFilters[filterKey].value = filterValue;
      break;

    case "@@router/LOCATION_CHANGE":
      const {
        payload: {
          location: { pathname },
        },
      } = action;
      let { companyIdInUrl } = getParamsFromPathname(pathname);
      // if (previousLocationChange !== companyIdInUrl) {
      // }

      previousLocationChange = companyIdInUrl;
      break;

    default:
      break;
  }

  return draft;
});
