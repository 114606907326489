import produce from "immer";

const INITIAL_STATE = {
  isLoadingAllShowrooms: false,
  showroomsInfo: [],
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case "IS_LOADING_ALL_SHOWROOMS":
      draft.isLoadingAllShowrooms = action.isLoadingAllShowrooms;
      break;
    case "SET_SHOWROOMS":
      draft.showroomsInfo = action.showroomsInfo;
      break;
    default:
      break;
  }

  return draft;
});
