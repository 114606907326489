import { downloadAndUnzip } from "modules/product/admin/helperFunctions";
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { Shell, ShellEntity } from "services/types";
interface SpaceContextValue {
  space: Shell | null;
  setSpace: (space: React.SetStateAction<Shell | null>) => void;
  spaceVersion: ShellEntity.Version | null;
  spaceVersionId: string | null;
  setSpaceVersionId: (
    spaceVersionId: React.SetStateAction<string | null>
  ) => void;
  isLoadingSpace: boolean;
  setIsLoadingSpace: (value: boolean) => void;
  spaceUrl: string;
  setSpaceUrl: (value: string) => void;
}

const SpaceContext = createContext<SpaceContextValue>({
  space: null,
  setSpace: () => {},
  spaceVersion: null,
  spaceVersionId: null,
  setSpaceVersionId: () => {},
  isLoadingSpace: false,
  setIsLoadingSpace: () => {},
  spaceUrl: "",
  setSpaceUrl: () => {},
});

export const SpaceContextProvider = (props: any) => {
  const [space, setSpace] = useState<Shell | null>(null);
  const [spaceVersionId, setSpaceVersionId] = useState<string | null>(null);
  const [isLoadingSpace, setIsLoadingSpace] = useState<boolean>(false);
  const [spaceUrl, setSpaceUrl] = useState<string>("");

  const spaceVersion = useMemo(() => {
    if (space && spaceVersionId) {
      return space.versions[spaceVersionId] || null;
    }
    return null;
  }, [space, spaceVersionId]);

  useEffect(() => {
    if (spaceVersion) {
      downloadAndUnzip(spaceVersion.fbx_urls.fbx_url).then((files) => {
        try {
          const glbFileName = Object.keys(files).find((name) =>
            name.endsWith(".glb")
          );
          if (glbFileName === undefined)
            throw new Error("glb not found in zip file");
          const url = URL.createObjectURL(files[glbFileName]);
          setSpaceUrl(url);
        } catch (error) {
          console.error("error glb not found in zip file");
        }
      });
    }
  }, [spaceVersion]);

  const value = {
    space,
    setSpace,
    spaceVersion,
    spaceVersionId,
    setSpaceVersionId,
    isLoadingSpace,
    setIsLoadingSpace,
    spaceUrl,
    setSpaceUrl,
  };

  return <SpaceContext.Provider value={value} {...props} />;
};

export const useSpaceContext = () => {
  return useContext<SpaceContextValue>(SpaceContext);
};
