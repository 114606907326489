import React from "react";
import { Card, Button, Layout, Divider } from "antd";
import { SelectedShellArguments, SpacesMenu } from "./spacesMenu";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "hooks";
const { Sider } = Layout;

export const ShowroomsSiderMenu = () => {
  const profile = useAppSelector((state: any) => state.profile);
  const { companyId, spaceId, spaceVersionId } = useParams();
  const navigate = useNavigate();

  /************* Actions **************/
  const handleOnSelectSpace = ({
    showroom,
    shell,
    shell_version,
  }: SelectedShellArguments) => {
    navigate(
      `/${companyId}/showrooms/${showroom.id}/spaces/${shell.id}/version/${shell_version.id}/overview`
    );
  };
  const handleOnUpgradePlan = () => {
    console.log("UPGRADE PLAN clicked");
  };
  /************************************/

  return (
    <Sider width={350} className="siderMenu siderMenu--showrooms">
      <Card bordered={false} className="cardButtonsMenu">
        <h2>Welcome</h2>
        {profile?.username && <h4>{profile?.username}</h4>}
        <Divider />
        <h4>
          <b>Your plan:</b> Free trial account
        </h4>

        <Button
          shape="round"
          className="upgrade-button"
          block
          onClick={handleOnUpgradePlan}
        >
          UPGRADE PLAN
        </Button>
        <Divider />
      </Card>

      <SpacesMenu
        selectedValue={spaceVersionId}
        onSelect={handleOnSelectSpace}
      />
    </Sider>
  );
};
