import React, { useCallback } from "react";
import { UndoOutlined, RedoOutlined } from "@ant-design/icons";
import _ from "lodash";
import { ItemType } from "antd/es/menu/hooks/useItems";
import {
  Axis,
  IPointerEvent,
  PickingInfo,
  Quaternion,
  Ray,
  RayHelper,
  Tools,
  Vector3,
} from "@babylonjs/core";
import { MenuActionType, ShowroomProduct } from "../types";
import { useMeshFocus } from "./useMeshFocus";
import { useEngineContext } from "../contexts/EngineContext";
import { useProductsContext } from "../contexts/ProductsContext";

export const useProductsEventsHandler = () => {
  const { scene } = useEngineContext();
  const {
    showroomProducts,
    focusedShowroomProduct,
    setFocusedShowroomProductById,
    updateProductQueueDebounced,
    deleteProduct,
    cutProduct,
    pasteProduct,
    clipboardProduct,
    enableBackward,
    enableForward,
    goBackwardAction,
    goForwardAction,
  } = useProductsContext();
  const { focusMesh, refreshFocusedMesh } = useMeshFocus();

  /************* Main Handler *************/
  const handleProductOnChange = useCallback(
    async (_product: ShowroomProduct) => {
      await updateProductQueueDebounced(_product);
    },
    [updateProductQueueDebounced]
  );
  const onCircleMenuToggleHandler = useCallback(() => {
    setFocusedShowroomProductById(null);
    refreshFocusedMesh(null);
  }, [refreshFocusedMesh, setFocusedShowroomProductById]);
  /***************************************/

  /*************** Actions ***************/
  const removeSelectedProduct = useCallback(
    async (placementId: string) => {
      await deleteProduct(showroomProducts[placementId] as ShowroomProduct);
    },
    [deleteProduct, showroomProducts]
  );
  const cutSelectedProduct = useCallback(
    (placementId: string) => {
      cutProduct(placementId);
    },
    [cutProduct]
  );
  const pasteSelectedProduct = useCallback(
    async ({ x, y, z }: Vector3) => {
      if (clipboardProduct) {
        const copyOfClipboard = await pasteProduct({ x, y, z });
        if (copyOfClipboard)
          setFocusedShowroomProductById({
            id: copyOfClipboard.placement.id,
            mode: "IDLE",
          });
      }
    },
    [clipboardProduct, pasteProduct, setFocusedShowroomProductById]
  );
  const rotateProduct = useCallback(
    ({
      showroomProduct,
      angle,
    }: {
      showroomProduct: ShowroomProduct;
      angle: number;
    }) => {
      const showroomProductCopy = {
        ...showroomProduct,
        placement: JSON.parse(JSON.stringify(showroomProduct.placement)),
      } as ShowroomProduct;
      // Actions
      const { placement } = showroomProductCopy;
      // placement.rotation.y = NormalizeRadians(
      //   placement.rotation.y + Tools.ToRadians(angle)
      // );
      placement.rotation.y += angle;
      return handleProductOnChange(showroomProductCopy);
    },
    [handleProductOnChange]
  );

  const snapProductIntoTheFloor = useCallback(
    ({ showroomProduct }: { showroomProduct: ShowroomProduct }) => {
      if (scene) {
        const prodcutMesh = scene.getMeshById(
          `product-placement-${showroomProduct.placement.id}`
        );
        if (prodcutMesh) {
          const origin = prodcutMesh.position;
          const bottomVector = Vector3.TransformCoordinates(
            new Vector3(0, -1, 0),
            prodcutMesh.getWorldMatrix()
          );
          let direction = bottomVector.subtract(origin);
          direction = Vector3.Normalize(direction);
          const ray = new Ray(
            new Vector3(origin.x, origin.y - 0.01, origin.z),
            direction,
            10
          );
          const hit = scene.pickWithRay(ray);
          if (hit && hit.pickedPoint) {
            const showroomProductCopy = {
              ...showroomProduct,
              placement: JSON.parse(JSON.stringify(showroomProduct.placement)),
            } as ShowroomProduct;
            const { placement } = showroomProductCopy;
            placement.position.y = hit.pickedPoint.y;
            onCircleMenuToggleHandler();
            return handleProductOnChange(showroomProductCopy);
          }
        }
      }

      onCircleMenuToggleHandler();
    },
    [handleProductOnChange, onCircleMenuToggleHandler, scene]
  );

  const freeMoveProduct = useCallback(
    ({
      showroomProduct,
      vector3: { x, y, z },
    }: {
      showroomProduct: ShowroomProduct;
      vector3: Vector3;
    }) => {
      const showroomProductCopy = {
        ...showroomProduct,
        placement: JSON.parse(JSON.stringify(showroomProduct.placement)),
      } as ShowroomProduct;
      const { placement } = showroomProductCopy;
      placement.position = { x, y, z };
      return handleProductOnChange(showroomProductCopy);
    },
    [handleProductOnChange]
  );
  const smartRotateProduct = useCallback(
    ({
      showroomProduct,
      pickResult,
    }: {
      showroomProduct: ShowroomProduct;
      pickResult: PickingInfo;
    }) => {
      const normal = pickResult.getNormal();
      if (normal && normal.z !== 0) return; // Do nothing if is floor
      const showroomProductCopy = {
        ...showroomProduct,
        placement: JSON.parse(JSON.stringify(showroomProduct.placement)),
      } as ShowroomProduct;
      const { placement } = showroomProductCopy;
      const norm = pickResult.getNormal(true, true);
      if (pickResult.ray && norm) {
        const dd = Vector3.Dot(norm, pickResult.ray.direction);
        if (dd > 0) {
          norm.negateInPlace();
        }
        const result = Quaternion.Zero();
        Quaternion.FromUnitVectorsToRef(Axis.Z, norm, result);
        const rotation = result.toEulerAngles();
        placement.rotation = {
          x: Tools.ToDegrees(rotation.x),
          y: Tools.ToDegrees(rotation.y),
          z: Tools.ToDegrees(rotation.z),
        };
        onCircleMenuToggleHandler(); // Close menu on success
        return handleProductOnChange(showroomProductCopy);
      }
    },
    [handleProductOnChange, onCircleMenuToggleHandler]
  );
  /***************************************/

  /************ Event Handlers ***********/
  const onLongPressClick = useCallback(
    (evt: IPointerEvent, pickResult: PickingInfo) => {
      if (
        (!focusedShowroomProduct ||
          ["IDLE", "GIZMO_MENU"].includes(focusedShowroomProduct.mode)) &&
        evt.button === 0
      ) {
        if (pickResult?.pickedMesh?.metadata?.type === "product-item") {
          setFocusedShowroomProductById({
            id: pickResult.pickedMesh.metadata.id,
            mode: "CIRCLE_MENU",
          });
        }
      }
    },
    [focusedShowroomProduct, setFocusedShowroomProductById]
  );

  const onLeftClickHandler = useCallback(
    async (
      evt: IPointerEvent,
      pickResult: PickingInfo,
      isLongPress: boolean
    ) => {
      if (isLongPress) return false;
      if (focusedShowroomProduct && pickResult.pickedPoint) {
        const { showroomProduct, mode } = focusedShowroomProduct;
        if (["FREE_MOVE", "SMART_ROTATE"].includes(mode)) {
          // Update product position
          switch (mode) {
            case "FREE_MOVE":
              // Update position
              onCircleMenuToggleHandler();
              freeMoveProduct({
                showroomProduct,
                vector3: pickResult.pickedPoint,
              });
              break;
            // case "SMART_MOVE":
            //   // Update position
            //   snapProductIntoTheFloor({ showroomProduct, pickResult });
            //   break;
            case "SMART_ROTATE":
              // Update rotation
              smartRotateProduct({
                showroomProduct,
                pickResult,
              });
              break;
          }
          return;
        }
      }
      if (
        focusedShowroomProduct &&
        focusedShowroomProduct.showroomProduct.product.rootMesh
      ) {
        focusedShowroomProduct.showroomProduct.product.rootMesh.showBoundingBox =
          false;
      }
      if (pickResult?.pickedMesh?.metadata?.type !== "product-item") {
        setFocusedShowroomProductById(null);
      } else {
        pickResult.pickedMesh.metadata.rootMesh.showBoundingBox = true;
        setFocusedShowroomProductById({
          id: pickResult.pickedMesh.metadata.id,
          mode: "GIZMO_MENU",
        });
      }
    },
    [
      focusedShowroomProduct,
      freeMoveProduct,
      onCircleMenuToggleHandler,
      setFocusedShowroomProductById,
      smartRotateProduct,
    ]
  );

  const onRightClickHandler = useCallback(
    (evt: IPointerEvent, pickResult: PickingInfo) => {
      if (focusedShowroomProduct) {
        if (focusedShowroomProduct.mode === "GIZMO_MENU") {
          setFocusedShowroomProductById(null);
        } else {
          return false;
        }
      }

      const options: ItemType[] = [];
      const historyOptions: ItemType[] = [];

      if (clipboardProduct) {
        options.push({
          key: "paste-product",
          label: <span>Paste Product</span>,
          onClick: () => {
            if (pickResult.pickedPoint)
              pasteSelectedProduct(pickResult.pickedPoint);
          },
        });
      }

      if (pickResult?.pickedMesh?.metadata?.type === "product-item") {
        options.push({
          key: "cut-product",
          label: <span>Cut Product</span>,
          onClick: () => {
            if (pickResult.pickedMesh?.metadata)
              cutSelectedProduct(pickResult.pickedMesh.metadata.id);
          },
        });

        options.push({
          key: "remove-product",
          label: <span>Remove Product</span>,
          onClick: () => {
            if (pickResult.pickedMesh?.metadata)
              removeSelectedProduct(pickResult.pickedMesh.metadata.id);
          },
        });
      }

      if (enableBackward) {
        historyOptions.push({
          key: "undo-product",
          icon: <UndoOutlined />,
          label: <span>Undo</span>,
          onClick: () => {
            goBackwardAction();
          },
        });
      }

      if (enableForward) {
        historyOptions.push({
          key: "redo-product",
          icon: <RedoOutlined />,
          label: <span>Redo</span>,
          onClick: () => {
            goForwardAction();
          },
        });
      }

      return (_options: ItemType[]) => [
        ...options,
        ..._options,
        ...historyOptions,
      ];
    },
    [
      clipboardProduct,
      cutSelectedProduct,
      enableBackward,
      enableForward,
      focusedShowroomProduct,
      goBackwardAction,
      goForwardAction,
      pasteSelectedProduct,
      removeSelectedProduct,
      setFocusedShowroomProductById,
    ]
  );
  const onCircleMenuClickHandler = useCallback(
    (action: MenuActionType) => {
      if (focusedShowroomProduct) {
        const { showroomProduct } = focusedShowroomProduct;
        if (action === "ROTATE_FORWARD") {
          rotateProduct({ showroomProduct, angle: 45 });
        } else if (action === "ROTATE_BACKWARD") {
          rotateProduct({ showroomProduct, angle: -45 });
        } else if (action === "SMART_MOVE") {
          snapProductIntoTheFloor({ showroomProduct });
        } else if (action && ["FREE_MOVE", "SMART_ROTATE"].includes(action)) {
          setFocusedShowroomProductById({
            id: focusedShowroomProduct.showroomProduct.placement.id,
            mode: action,
          });
        }
      }
    },
    [
      focusedShowroomProduct,
      rotateProduct,
      setFocusedShowroomProductById,
      snapProductIntoTheFloor,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPointerMoveHandler = useCallback(
    _.throttle((evt: IPointerEvent, pickResult: PickingInfo) => {
      if (
        scene &&
        focusedShowroomProduct &&
        ["SMART_ROTATE", "FREE_MOVE"].includes(focusedShowroomProduct.mode)
      ) {
        const picked = scene.pick(scene.pointerX, scene.pointerY);
        if (picked.pickedMesh) {
          let isDisabled = false;
          const normal = picked.getNormal(true, true);
          if (focusedShowroomProduct.mode === "SMART_ROTATE") {
            /// normal Y smaller than 0.01 means the normal is on X AND Z axis, which means the mesh is wall or paralle to wall)
            // can potentially increase the threshold depending on the how sensitive what want to assume it's wall
            if (normal && Math.abs(normal.y) > 0.01) {
              isDisabled = true;
            }
          }
          focusMesh(picked.pickedMesh, isDisabled);
          const norm = picked.getNormal(true);
          if (picked.ray && norm) {
            const dd = Vector3.Dot(norm, picked.ray.direction);
            if (dd > 0) {
              norm.negateInPlace();
            }
            const result = Quaternion.Zero();
            Quaternion.FromUnitVectorsToRef(Axis.Y, norm, result);
            const stickbase = scene.getMeshByName("stick-base");
            if (stickbase && picked.pickedPoint) {
              stickbase.rotationQuaternion = result;
              stickbase.position = picked.pickedPoint;
            }
          }
        }
        refreshFocusedMesh(picked.pickedMesh);
      }
    }, 50),
    [scene, focusedShowroomProduct]
  );
  /***************************************/

  return {
    onLeftClickHandler,
    onRightClickHandler,
    onCircleMenuClickHandler,
    onPointerMoveHandler,
    onLongPressClick,
    handleProductOnChange,
    onCircleMenuToggleHandler,
  };
};
