export type ItemType =
  | "collection"
  | "item"
  | "layout"
  | "partGroup"
  | "part"
  | "material";

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export enum ItemTypeLabel {
  collection = "Collection",
  item = "Item",
  layout = "Layout",
  partGroup = "Part Group",
  part = "Part",
  material = "Material",
}

export type SelectedItem = {
  uuid: string;
  _uuid: string;
  type: ItemType;
  readonly id: number;
  name: string;
  item: MixType;
  data: {
    collection?: CollectionType;
    item?: ItemRemoveType;
    layout?: LayoutType;
    partGroup?: PartGroupType;
    part?: PartType;
    material?: MaterialType;
  };
};

export interface CollectionType {
  uuid: string;
  _uuid: string;
  name: string;
  apiItemId: number;
  status: string;
  description: string | null;
  layouts: LayoutType[];
  versions: LayoutType[];
  items: ItemRemoveType[];
  id: number;
  apiId: number;
  price: number;
  dimensions: string | null;
  files: {
    product_image?: any[];
    reference_file?: any[];
  };
}
export interface ItemRemoveType {
  uuid: string;
  _uuid: string;
  name: string;
  apiId: number;
  apiItemId: number;
  status: string;
  description: string | null;
  id: number;
  price: number;
  dimensions: string | null;
  layouts: LayoutType[];
  versions: LayoutType[];
  files: {
    product_image?: any[];
    reference_file?: any[];
  };
}
export interface LayoutType {
  uuid: string;
  _uuid: string;
  id: number;
  name: string;
  basePrice: number | null;
  apiItemId: number;
  apiVersionId: number;
  items: ItemRemoveType[];
  apiId: number;
  partGroups: PartGroupType[];
  files: {
    product_image?: any[];
    reference_file?: any[];
  };
  external_url: string;
  thumbnail_post_url: PostURL;
  layouts: LayoutType[];
  versions: LayoutType[];
  status: string;
  description: string | null;
  price: number;
  dimensions: string | null;
}

export interface PartGroupType {
  uuid: string;
  _uuid: string;
  id: number;
  name: string;
  optional: boolean;
  apiId: number;
  parts: PartType[];
  status: string;
  apiItemId: number;
  description: string | null;
  price: number;
  dimensions: string | null;
  layouts: LayoutType[];
  versions: LayoutType[];
  files: {
    product_image?: any[];
    reference_file?: any[];
  };
}

export interface PartType {
  uuid: string;
  _uuid: string;
  id: number;
  name: string;
  priceMod: number;
  apiId: number;
  materials: MaterialType[];
  thumbnail_post_url: PostURL;
  external_url: string;
  status: string;
  apiItemId: number;
  description: string | null;
  price: number;
  dimensions: string | null;
  layouts: LayoutType[];
  versions: LayoutType[];
  files: {
    product_image?: any[];
    reference_file?: any[];
  };
}

export interface MaterialType {
  uuid: string;
  _uuid: string;
  id: number;
  name: string;
  apiId: number;
  priceMod: number;
  materialPriceModOverrideDefault: number;
  imageUrl: string;
  apiItemId: number;
  status: string;
  description: string | null;
  price: number;
  dimensions: string | null;
  layouts: LayoutType[];
  versions: LayoutType[];
  files: {
    product_image?: any[];
    reference_file?: any[];
  };
}

export interface PostURL {
  fields: Fields;
  url: string;
}

export interface Fields {
  AWSAccessKeyId: string;
  key: string;
  policy: string;
  signature: string;
  [key: string]: any;
}

export type MixType =
  | CollectionType
  | ItemRemoveType
  | LayoutType
  | PartGroupType
  | PartType
  | MaterialType;
