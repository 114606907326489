import React, { useMemo } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { Showroom } from "services/types";
import { ShowroomContextProvider } from "./contexts/ShowroomContext";
import { SpaceContextProvider } from "./contexts/SpaceContext";
import {
  EngineContextProvider,
  useEngineContext,
} from "./contexts/EngineContext";
import { CommentContextProvider } from "./contexts/CommentContext";
import { ProductsHistoryContextProvider } from "./contexts/ProductsHistoryContext";
import { ProductContextProvider } from "./contexts/ProductsContext";
import { ShowroomLayout } from "./layout/ShowroomLayout";
import { ShowroomEmpty } from "./showroomEmpty";
import { ShowroomFinalReview } from "./showroomFinalReview";
import { ShowroomFloorPlan } from "./showroomFloorPlan";
import { ShowroomMerchandising } from "./showroomMerchandising";
import { ShowroomOverview } from "./showroomOverview";
import { ShowroomSettings } from "./showroomSettings";
import { ShowroomLookAndFeel } from "./showroomLookAndFeel";
import { useAppSelector } from "hooks";

const BabylonContextProvider = ({
  children = null,
}: {
  children: React.ReactElement | null;
}) => {
  return (
    <CommentContextProvider>
      <EngineContextProvider>
        <BabylonPageWrapper>{children}</BabylonPageWrapper>
      </EngineContextProvider>
    </CommentContextProvider>
  );
};

const BabylonPageWrapper = ({
  children = null,
}: {
  children: React.ReactElement | null;
}) => {
  const { isFocusedEngine } = useEngineContext();
  return (
    <div className={`${isFocusedEngine ? "engine-focused" : ""}`}>
      {children}
    </div>
  );
};

export const ShowroomModule = () => {
  const { companyId } = useParams();
  const showrooms: any[] = useAppSelector((state: any) =>
    state.showrooms.showroomsInfo.filter(
      (showroom: Showroom) => `${showroom.company_id}` === `${companyId}`
    )
  );
  const defaultShowroomPath = useMemo(() => {
    try {
      const defaultShowroom = showrooms[0];
      const defaultShell: any = Object.values(defaultShowroom.shells)[0];
      const defaultShellVersion: any = Object.values(
        defaultShell.shell_versions
      )[0];
      return `/${companyId}/showrooms/${defaultShowroom.id}/spaces/${defaultShell.id}/version/${defaultShellVersion.id}/overview`;
    } catch (err) {
      return `/${companyId}/showrooms/empty`;
    }
  }, [companyId, showrooms]);

  return (
    <ShowroomContextProvider>
      <SpaceContextProvider>
        <Routes>
          <Route path={`/empty`} element={<ShowroomEmpty />} />
          <Route
            path="/:showroomId/spaces/:spaceId/version/:spaceVersionId"
            element={<ShowroomLayout />}
          >
            <Route element={<ShowroomOverview />} path={`overview`} />
            <Route element={<ShowroomSettings />} path={`settings`} />
            <Route element={<ShowroomLookAndFeel />} path={`look-and-feel`} />
            <Route
              element={
                <BabylonContextProvider>
                  <ShowroomFloorPlan />
                </BabylonContextProvider>
              }
              path={`floor-plan`}
            />
            <Route
              element={
                <BabylonContextProvider>
                  <ProductsHistoryContextProvider>
                    <ProductContextProvider>
                      <ShowroomMerchandising />
                    </ProductContextProvider>
                  </ProductsHistoryContextProvider>
                </BabylonContextProvider>
              }
              path={`merchandising`}
            />
            <Route
              element={
                <BabylonContextProvider>
                  <ShowroomFinalReview />
                </BabylonContextProvider>
              }
              path={`final-review`}
            />
          </Route>

          <Route
            element={<Navigate to={defaultShowroomPath} replace />}
            path={`*`}
          />
        </Routes>
      </SpaceContextProvider>
    </ShowroomContextProvider>
  );
};

export default ShowroomModule;
