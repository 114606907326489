import React, { Component } from "react";
import { Col, Row, Table } from "antd";
import { connect } from "react-redux";
import "./commentSection.scss";
import _ from "lodash";
import { getParamsFromPathname } from "../../utils/params";
import { ReactHeight } from "react-height";

const columns = (props, selectedCommentId) => [
  {
    title: "Commenter",
    dataIndex: "user",
    key: "user",
    render: (i) => {
      return <p>{i}</p>;
    },
  },
  {
    title: "Version",
    dataIndex: "version_number",
    key: "version_number",
    render: (i) => "Version" + i,
  },
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    render: (i) => "in progress",
  },
  { title: "Created", dataIndex: "created", key: "created" },
];

class CommentSection extends Component {
  state = {
    selectedCommentId: null,
    commentHeight: 200,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.selectedCommentId &&
      !this.props.loading &&
      this.props.itemDetails.notes.length
    ) {
      if (this.props.itemDetails.notes[0].annotations.length) {
        if (this.props.renderViewerRef.current) {
          this.props.setClickedCommentHotspots(
            this.props.itemDetails.notes[0].annotations
          );
        } else {
          this.setState({
            pendingAnnotationToLoad:
              this.props.itemDetails.notes[0].annotations,
          });
        }
      }
      this.setState({
        selectedCommentId: this.props.itemDetails.notes[0].id,
      });
    }
    if (
      this.state.pendingAnnotationToLoad &&
      !prevProps.renderViewerRef.current &&
      this.props.renderViewerRef.current
    ) {
      this.props.setClickedCommentHotspots(this.state.pendingAnnotationToLoad);
      this.setState({
        pendingAnnotationToLoad: undefined,
      });
    }
    if (prevProps.versionId !== this.props.versionId) {
      this.setState({
        selectedCommentId: null,
      });
    }
    if (
      prevProps.clickedCommentHotspots !== this.props.clickedCommentHotspots
    ) {
      if (this.props.renderViewerRef && this.props.renderViewerRef.current) {
        this.showHotspotOnClick(this.props.clickedCommentHotspots);
      }
    }
  }

  renderCommentImageThumbs = () => {
    return <div>No Images For This Comment</div>;
  };

  renderCommentDetails = () => {
    if (!this.state.selectedCommentId) {
      return (
        <div className="comment-details-container">No Comment Selected</div>
      );
    }
    let note = this.props.itemDetails.notes.find(
      (n) => n.id === this.state.selectedCommentId
    );
    return (
      <div className="comment-details-container">
        <div className="comment-details-header">
          <p>{note.user}</p>
          <p>{`Version ${note.version_number}`}</p>
          <p>{`in progress`}</p>
          <p>{note.created}</p>
        </div>
        <div className="comment-area">
          <h3>Comment:</h3>
          <p>{note.note}</p>
          <div
            className="view-hotspot-cta"
            onClick={() => {
              const headerOffset = 45;
              const elementPosition =
                this.props.renderViewerRef.current.getBoundingClientRect().top;
              const offsetPosition = elementPosition - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
              });
            }}
          >
            {note.annotations.length ? <p>Show Hotspot(s)</p> : ""}
          </div>
        </div>
      </div>
    );
  };

  showHotspotOnClick = (annotations) => {
    //moving camera to the top to watch the viewer with the hotspots
    if (!_.isEmpty(this.props.newHotspots)) {
      this.props.renderViewerRef.current.setAttribute("hotspot", "deleteAll");
    }

    //changing array object from [{ pX, pY, pZ, nX, nY, nZ }] to [{ x, y, z }] for the render viewer
    const hotspots = annotations.map(
      ({ pX: x, pY: y, pZ: z, nX, nY, nZ, ...rest }) => ({
        x,
        y,
        z,
        ...rest,
      })
    );

    //setting the hotspots
    this.props.renderViewerRef.current.hotspots = hotspots;
  };

  render() {
    if (this.props.loading) {
      return null;
    }

    if (!this.props.itemDetails.notes.length) {
      return (
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>No Comments Yet</h2>
        </Col>
      );
    }

    return (
      <Row className="comment-section-container">
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "35px",
          }}
        >
          <h3>All Comments({this.props.itemDetails.notes.length})</h3>
        </Col>
        <Col span={12}>
          <ReactHeight
            onHeightReady={(height) =>
              this.setState({
                commentHeight: height,
              })
            }
          >
            <Table
              pagination={{
                pageSize: 4,
              }}
              onRow={(i) => ({
                onClick: () => {
                  this.props.setClickedCommentHotspots(i.annotations);
                  this.setState({ selectedCommentId: i.id });
                },
              })}
              rowKey={(i) => i.id}
              rowClassName={(record) =>
                record.id === this.state.selectedCommentId ? "selected" : ""
              }
              expandable={{
                expandedRowRender: (record) => (
                  <p
                    style={{
                      margin: 0,
                      background:
                        record.id === this.state.selectedCommentId
                          ? "#F5F5F5"
                          : "#FFF",
                    }}
                  >
                    {record.note}
                  </p>
                ),
                expandedRowClassName: (record) =>
                  record.id === this.state.selectedCommentId ? "selected" : "",
              }}
              columns={columns(this.props)}
              dataSource={_.map(this.props.itemDetails.notes, (i) => i)}
              // expandedRowKeys={this.props.itemDetails.notes.map(n => n.id)}
            />
          </ReactHeight>
        </Col>
        <Col span={12}>
          <div
            className="comment-details-container"
            style={{ height: `${this.state.commentHeight}px` }}
          >
            {this.renderCommentDetails()}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ items, variants }) => {
  let {
    orderIdInUrl,
    companyIdInUrl,
    itemCollectionIdInUrl,
    versionIdInUrl,
    variantIdInUrl,
    itemIdInUrl,
  } = getParamsFromPathname(window.location.pathname);

  let loading =
    !companyIdInUrl ||
    !orderIdInUrl ||
    !itemCollectionIdInUrl ||
    !versionIdInUrl ||
    !variantIdInUrl ||
    !items[itemIdInUrl] ||
    !variants[variantIdInUrl];

  if (!loading) {
    return {
      loading,
      itemDetails: items[itemIdInUrl],
      versionId: versionIdInUrl,
      itemId: itemIdInUrl,
      versionDetails: items[itemIdInUrl].versions[versionIdInUrl],
    };
  } else {
    return {
      loading,
    };
  }
};

export default connect(mapStateToProps, {})(CommentSection);
