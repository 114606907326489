import { AnnotationType, Comment } from "../types";

// Get comment from /shell_notes -> shell -> notes
export const getComment = (
  noteApi: { [key: string]: any },
  annotationsApiMap: { [key: string]: any }
): Comment => {
  const annotations: { [key: string]: AnnotationType } = {};
  if (annotationsApiMap[noteApi.id]) {
    annotationsApiMap[noteApi.id].forEach((anotationApi: any) => {
      const annotation = getAnnotation(anotationApi);
      annotations[annotation.id] = annotation;
    });
  }

  return {
    id: noteApi.id,
    email: noteApi.user_email,
    description: noteApi.note,
    annotations,
    version: noteApi.shell_version.version_number,
    status: noteApi.is_resolved ? "resolved" : "created",
    createdOn: new Date(noteApi.created),
    updatedOn: new Date(noteApi.updated),
    images: noteApi.files.product_image,
    // images: [],
    isDirty: false,
  };
};

export const getAnnotation = (annotationApi: any): AnnotationType => ({
  id: annotationApi.id,
  type: "simple",
  x: annotationApi.pX,
  y: annotationApi.pY,
  z: annotationApi.pZ,
});
