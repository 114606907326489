import * as React from "react";

function GenericFile(props) {
  return (
    <svg
      width={31}
      height={35}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 33h20a1 1 0 001-1V11h-7V2.045A1 1 0 0021.704 2H8a1 1 0 00-1 1v29a1 1 0 001 1zM24 9h4.724L24 3.98V9zm6.185-1.366L24 1.062V1h-.059l-.053-.056A3 3 0 0021.704 0H8a3 3 0 00-3 3v29a3 3 0 003 3h20a3 3 0 003-3V9.69a3 3 0 00-.815-2.056z"
        fill="#545454"
      />
      <path
        d="M0 17a1 1 0 011-1h21a1 1 0 011 1v8a1 1 0 01-1 1H1a1 1 0 01-1-1v-8z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 18v6h19v-6H2zm-1-2a1 1 0 00-1 1v8a1 1 0 001 1h21a1 1 0 001-1v-8a1 1 0 00-1-1H1z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 22H4v-2h15v2z"
        fill="#545454"
      />
    </svg>
  );
}

export default GenericFile;
