import React from "react";
import { Layout, Typography } from "antd";
const { Content } = Layout;
const { Title } = Typography;

export const ShowroomFinalReview = () => {
  return (
    <Layout className="layout layout-showroom layout-showroom-3d layout-showroom--final-review">
      <Content>
        <Title level={2}>Showroom Final Review</Title>
      </Content>
    </Layout>
  );
};
