import axios from "axios";
import { BACKEND_URL, GET_METHOD, POST_METHOD } from "sharedConstants";

const getOrder = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/orders/${id}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const getAllOrders = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/orders`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const postOrder = async function (orderData) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/orders`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: orderData,
  });
  return response;
};

export { getOrder, getAllOrders, postOrder };
