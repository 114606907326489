import React, { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { getAndSetProfile } from "store/actions/profile";
import { getItemStatuses } from "store/actions/statuses";
import { setAppInitialLoadingState } from "store/actions/app";
import { getAllShowrooms } from "store/actions/showrooms";
import { getAndSetBehaviors } from "store/actions/behaviors";
import { getAllCompanies } from "store/actions/company";
import { getParamsFromPathname } from "utils";
import ApiService from "services/ApiService";
import { useAppDispatchWrapper, useAppSelector } from "hooks";
import FullScreenSpinner from "components/Loaders/FullScreenSpinner";
import Navigation from "modules/global-nav";
import { Company } from "services/types";
import { useCompanyContext } from "contexts/CompanyContext";

export const PortalLayout = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { search } = useLocation();
  const { setCompany, setIsLoadingCompany } = useCompanyContext();
  const { token } = useAppSelector((state) => state.auth);
  const appDispatchWrapper = useAppDispatchWrapper();
  const appIsInitialLoading: boolean = useAppSelector(
    (state) => state.app.appIsInitialLoading
  );
  const company: Company | null = useAppSelector(
    (state: any) => state?.companies[`${companyId}`] || null
  );

  const handleMouseClick = async () => {
    Auth.currentSession();
  };

  const init = async () => {
    await appDispatchWrapper(getAndSetProfile());
    await appDispatchWrapper(getAllCompanies());
    await appDispatchWrapper(getItemStatuses());
    await appDispatchWrapper(getAllShowrooms());
    await appDispatchWrapper(getAndSetBehaviors());

    const { companyIdInUrl } = getParamsFromPathname(window.location.pathname);
    if (!companyIdInUrl) {
      try {
        const companies = await ApiService.getCompanies();
        navigate(`/${companies.data[0].id}/products`);
      } catch (e) {
        // this should mean they are unauthenticated and see login
        // now no matter what the url
        navigate(`/`);
      }
    }

    appDispatchWrapper(setAppInitialLoadingState(false));

    window.addEventListener(
      "click",
      AwesomeDebouncePromise(handleMouseClick, 10000)
    );
  };

  // Redirect if not logged in
  useEffect(() => {
    if (!token) {
      navigate(`/login${search}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  // Load once only when logged in
  useEffect(() => {
    if (token) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update company context when {companyId} in url changes
  useEffect(() => {
    if (companyId) {
      setCompany(company || null);
    } else {
      setCompany(null);
    }
  }, [company, companyId, setCompany]);

  // Update company loading
  useEffect(() => {
    setIsLoadingCompany(appIsInitialLoading);
  }, [appIsInitialLoading, setIsLoadingCompany]);

  return appIsInitialLoading ? (
    <FullScreenSpinner />
  ) : (
    <>
      <Navigation />
      <div className="portal-layout-container">
        <Outlet />
      </div>
    </>
  );
};
