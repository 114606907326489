import React, { Component } from "react";
import "./dimensions.scss";

class DimensionsInput extends Component {
  state = {};

  handleChange = (key, newValue) => {
    let data = { ...this.props.value };
    data[key] = newValue;
    this.props.setParentValue(data);
  };
  render() {
    return (
      <div className="dimensions-input">
        <label>Width</label>
        <input
          onChange={(ev) => {
            this.handleChange("width_en", ev.target.value);
          }}
          defaultValue={this.props.value.width_en}
        />
        <label>Depth</label>
        <input
          onChange={(ev) => {
            this.handleChange("depth_en", ev.target.value);
          }}
          defaultValue={this.props.value.depth_en}
        />
        <label>Height</label>
        <input
          onChange={(ev) => {
            this.handleChange("height_en", ev.target.value);
          }}
          defaultValue={this.props.value.height_en}
        />
        <label>Diameter</label>
        <input
          onChange={(ev) => {
            this.handleChange("diameter_en", ev.target.value);
          }}
          defaultValue={this.props.value.diameter_en}
        />
      </div>
    );
  }
}

export default DimensionsInput;
