import { message, Upload } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { patchVariant } from "store/actions/variant";
import { uploadAsset } from "../../../../../services/assets";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import FullScreenSpinner from "components/Loaders/FullScreenSpinner";

class ThumbnailDisplay extends Component {
  state = {
    loading: false,
  };
  render() {
    // debugger
    return (
      <>
        <h3 className="section-title">Thumbnail</h3>
        {this.props.value.url && <span className="editLink">edit</span>}
        {this.props.value.url ? (
          <div className="thumbnail-container">
            <img
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              style={{
                width: "100%",
                maxWidth: "200px",
              }}
              src={this.props.value.url + "512.png?plzlord=" + Date.now()}
            />
          </div>
        ) : (
          <div className="empty-container">
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {this.state.loading ? (
                <FullAreaSpinner />
              ) : (
                <Upload
                  showUploadList={false}
                  beforeUpload={async (file) => {
                    message.loading("Uploading Thumnail... ", 1);
                    let assetId = await uploadAsset(
                      this.props.value.assetId,
                      file,
                      true,
                      "thumbnail",
                      "thumbnail.png"
                    );
                    if (assetId) {
                      this.setState({ loading: true });
                      setTimeout(() => {
                        this.props.patchVariant(this.props.value.variantId, {
                          thumbnail_id: assetId,
                        });
                        this.setState({ loading: false });
                      }, 5000);
                    }
                    return false;
                  }}
                >
                  No Thumbnail - Click here to upload.
                </Upload>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  patchVariant,
})(ThumbnailDisplay);
