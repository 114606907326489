import {
  Button,
  Card,
  Col,
  Collapse,
  Popconfirm,
  Row,
  Dropdown,
  Menu,
  Modal,
} from "antd";
import _, { iteratee } from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  patchItem,
  patchItemVersion,
  setETLdisabled,
  updateItemCollectionArchived,
  moveItemCollectionToOrder,
} from "store/actions/items";
import { getParamsFromPathname, replaceInPath } from "../../utils/params";
import { getStatusColor } from "../../utils/status";
import FullAreaSpinner from "../Loaders/FullAreaSpinner";
import { Link } from "react-router-dom";
import { setModalState } from "store/actions/app";
import { addOrder } from "store/actions/app";
import AdminActionsMenu from "../../modules/product/components/adminActionsMenu";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";

class ItemExpanded extends PureComponent {
  renderPublishSection = (itemData) => {
    return (
      <Popconfirm
        key={`confirm-${itemData.id}`}
        onConfirm={async () => {
          const payload = {
            itemCollectionId: this.props.itemCollectionId.toString(),
            itemVersionId: itemData.current_version_id.toString(),
          };

          try {
            // Call the existing function
            await this.props.patchItemVersion(
              itemData.id,
              itemData.current_version_id,
              {
                status_id: 4,
                item_id: itemData.id,
                update_showroom_shells: true,
                force: true,
              }
            );

            // Make the POST request to your Lambda function URL
            const response = await axios.post(
              "https://ot7k6dy5eofivxyb4dvakmdowm0fibgj.lambda-url.us-east-1.on.aws/",
              payload,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            console.log("Lambda function response:", response.data);

            // Display a success message
            Modal.success({
              title: "Success",
              content: "Item published and JSON file uploaded to S3.",
            });
          } catch (error) {
            console.error("Error:", error);

            // Display an error message
            Modal.error({
              title: "Error",
              content: "An error occurred during the publishing process.",
            });
          }
        }}
        title="This will update all occurances of Variants for this Item in your showrooms."
        cancelText="Cancel"
        okText="Publish Item"
      >
        <Button type="primary" style={{ width: "200px", display: "block" }}>
          Publish Current Version
        </Button>
      </Popconfirm>
    );
  };

  renderAdminItemInfo = (item) => {
    return (
      <div key={`admin-item-${item.id}`}>
        <Collapse ghost>
          <Collapse.Panel header="Admin Info" key="1">
            <ul>
              <li>{`Order Id: ${this.props.orderIdInUrl}`}</li>
              <li>{`Collection Id: ${this.props.itemCollectionId}`}</li>
              <li>{`Item Id: ${item.id}`}</li>
              <li>{`Current Version Id: ${item.current_version_id}`}</li>
            </ul>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  };

  renderAdminVariantInfo = (variantData) => {
    return (
      <div key={`variant-item-${variantData.id}`}>
        <Collapse ghost>
          <Collapse.Panel header="Admin Info" key="2">
            <ul>
              <li>{`Variant Id: ${variantData.id}`}</li>
            </ul>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  };

  renderVariantCard = (item, variantData, itemVersion) => {
    let statusData = this.props.itemStatuses.find(
      (s) => s.id === variantData.status_id
    );
    let newUrl = replaceInPath(
      "itemCollectionIdInUrl",
      this.props.itemCollectionId,
      window.location.pathname
    );
    newUrl = replaceInPath("itemIdInUrl", item.id, newUrl);
    newUrl = replaceInPath("versionIdInUrl", itemVersion.id, newUrl);
    newUrl = replaceInPath("variantIdInUrl", variantData.id, newUrl);
    return (
      <Col span={12} key={`variant-card-${item.id}-${variantData.id}`}>
        <Card
          title={
            <div>
              <h3>{variantData.name}</h3>
              <div className="card-field-header">
                {variantData.sub_sku ? (
                  <>
                    <label>Sub Sku: </label>
                    <span>{variantData.sub_sku}</span>
                  </>
                ) : null}
                <br />
                <label>Status: </label>
                <span
                  style={{
                    color:
                      variantData.api_item_status === "D" ? "red" : "green",
                  }}
                >
                  {variantData.api_item_status === "D"
                    ? "Discontinued"
                    : "Active"}
                </span>
              </div>
            </div>
          }
          bordered={true}
        >
          {this.props.profile.admin && this.renderAdminVariantInfo(variantData)}
          <div className="card-field">
            <div style={{ paddingBottom: "5px" }}>
              <label>ETL Status: </label>
              <span style={{ color: item.disable_etl ? "red" : "green" }}>
                {item.disable_etl ? "Disabled" : "Enabled"}
              </span>
            </div>
            <div>
              <label>Status: </label>
              <span style={{ color: getStatusColor(variantData.status_id) }}>
                {statusData.name}
              </span>
            </div>
          </div>
          <Link to={newUrl}>
            <Button type="default" style={{ width: "100", display: "block" }}>
              Manage Variant
            </Button>
          </Link>
        </Card>
      </Col>
    );
  };

  renderVariants = (item, itemVersion) => {
    let variants = _.map(itemVersion.variants, (variantId) => {
      let variantData = this.props.versionVariants[variantId];
      return this.renderVariantCard(item, variantData, itemVersion);
    });
    return (
      <Row gutter={16} key={`variant-row-${item.id}`}>
        {variants}
      </Row>
    );
  };

  renderItemHeader = (item) => {
    let split = item.name.split(" ");
    split.splice(split.length - 1, 1);
    split = split.join(" ");
    return (
      <div key={`header-${item.id}`}>
        <h3>{item.name}</h3>
      </div>
    );
  };

  handleApproveVersion = (value) => {
    this.props.patchVariant(
      this.props.variantIdInUrl,
      {
        status_id: value,
      },
      false
    );
  };

  renderItems = () => {
    return _.map(this.props.collectionItems, (i) => {
      const compressString = i.versions[i.current_version_id].bake_ids.compress;
      const parts = compressString.split("/");
      const secondLastId = parts[parts.length - 2];
      const partGroupID = Number(secondLastId);

      let statusData = this.props.itemStatuses.find(
        (s) => s.id === i.current_status_id
      );
      let etlDisabled =
        i.versions[i.current_version_id] &&
        i.versions[i.current_version_id].disable_etl
          ? true
          : false;
      return (
        <Row
          key={`item-collection-id-${i.id}-${this.props.collectionItems.id}`}
        >
          <Col span={24} style={{ marginBottom: "15px" }}>
            <Card title={this.renderItemHeader(i)} className="item-card">
              {this.props.profile.admin && this.renderAdminItemInfo(i)}
              <section>
                <h3 className="section-title">Actions</h3>
                <div>
                  <Link
                    to={`/${this.props.companyIdInUrl}/products-new/${this.props.orderIdInUrl}/advanced-product-creator-new/edit/${this.props.itemCollectionId}`}
                  >
                    <Button
                      className="new-advance-product-creator"
                      size="small"
                    >
                      Edit Product
                    </Button>
                  </Link>
                </div>
              </section>
              <section>
                <h3 className="section-title">Preview Item</h3>
                <div>
                  <Link
                    to={`/${this.props.companyIdInUrl}/products-new/previewProduct/${this.props.itemCollectionId}`}
                  >
                    <Button
                      className="new-advance-product-creator"
                      size="small"
                    >
                      View Product
                    </Button>
                  </Link>
                </div>
              </section>
              <section>
                <h3 className="section-title">ETL Controls</h3>
                <div className="card-field">
                  <Button
                    onClick={() => {
                      this.props.setETLdisabled({
                        itemId: i.id,
                        itemVersionId: i.current_version_id,
                        disableETL: !etlDisabled,
                      });
                    }}
                  >
                    {etlDisabled ? "Enable ETL" : "Disable ETL"}
                  </Button>
                </div>
                <h3 className="section-title">Publish</h3>
                <div className="card-field">
                  <label>Status: </label>
                  <span
                    style={{
                      color: getStatusColor(statusData.id === 4 ? 4 : 2),
                    }}
                  >
                    {statusData.id === 4
                      ? "Ready To Publish"
                      : "In Progress (All Variants Must Be Finalized)"}
                  </span>
                </div>
                {this.renderPublishSection(i)}
              </section>
              <section>
                {partGroupID && (
                  <div>
                    <Button
                      onClick={() => {
                        this.props.setModalState("createVariant", {
                          visible: true,
                          partGroupId: partGroupID,
                          itemCollectionId: this.props.itemCollectionId,
                        });
                      }}
                    >
                      Add Variant
                    </Button>
                  </div>
                )}
                {
                  <div>
                    <AdminActionsMenu
                      itemVersion={i.versions[i.current_version_id].id}
                      itemId={i.id}
                      itemCollectionId={this.props.itemCollectionId}
                    />
                  </div>
                }
                <h3 className="section-title">Variants</h3>
                {this.renderVariants(i, i.versions[i.current_version_id])}
              </section>
            </Card>
          </Col>
        </Row>
      );
    });
  };

  renderOrderDropdown = () => {
    const { companies, companyIdInUrl, orderIdInUrl } = this.props;
    const orders = companies[companyIdInUrl].orders;

    const menuItems = Object.values(orders).map((order) => ({
      key: order.id,
      label: order.name,
      disabled: order.id === parseInt(orderIdInUrl),
    }));

    return (
      <Dropdown menu={{ items: menuItems, onClick: this.handleOrderSelect }}>
        <Button>
          Move to another order <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  handleOrderSelect = ({ key }) => {
    const { itemCollection, companyIdInUrl, orderIdInUrl } = this.props;
    Modal.confirm({
      title: "Move Item Collection",
      content: `Are you sure you want to move this item collection to order ${key}?`,
      onOk: () => {
        this.props.moveItemCollectionToOrder({
          companyId: companyIdInUrl,
          itemCollectionId: itemCollection.id,
          newOrderId: key,
          oldOrderId: orderIdInUrl,
        });
        // refresh order to update item collection list when moved
        this.props.addOrder(orderIdInUrl);
        console.log(
          `Moving item collection ${itemCollection.id} to order ${key}`
        );
      },
      onCancel() {},
    });
  };

  render() {
    if (this.props.loading) {
      return <FullAreaSpinner />;
    } else {
      return (
        <>
          <div className="item-collection-actions">
            <h4>Item Collection Actions</h4>
            <Button
              onClick={() => {
                this.props.updateItemCollectionArchived({
                  companyId: this.props.companyIdInUrl,
                  orderId: this.props.orderIdInUrl,
                  itemCollectionId: this.props.itemCollection.id,
                  archived: !this.props.itemCollection.archived,
                });
                let { orderIdInUrl } = getParamsFromPathname(
                  window.location.pathname
                );
                // refresh order to update item collection list when archived or reactivated
                this.props.addOrder(orderIdInUrl);
              }}
              type="primary"
            >
              {this.props.itemCollection.archived ? "Reactivate" : "Archive"}
            </Button>
            {this.renderOrderDropdown()}
          </div>
          <div>{this.renderItems()}</div>
        </>
      );
    }
  }
}

const mapStateToProps = (
  { companies, items, variants, statuses: { itemStatuses }, profile },
  { itemCollectionId }
) => {
  let loading = true;
  let itemCollection;
  let collectionItems = {};
  let itemVersions = {};
  let versionVariants = {};

  let { orderIdInUrl, companyIdInUrl, versionIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );

  let detailsFetched =
    companies[companyIdInUrl] &&
    companies[companyIdInUrl].orders[orderIdInUrl] &&
    companies[companyIdInUrl].orders[orderIdInUrl].item_collections[
      itemCollectionId
    ]._detailsFetched;

  if (detailsFetched) {
    itemCollection =
      companies[companyIdInUrl].orders[orderIdInUrl].item_collections[
        itemCollectionId
      ];
    let itemsFetched = true;
    itemCollection.items.forEach((i) => {
      if (!items[i.id]) {
        itemsFetched = false;
      } else {
        collectionItems[i.id] = items[i.id];
      }
    });
    if (itemsFetched) {
      let versionsFetched = true;
      _.forEach(collectionItems, (i) => {
        if (!i.versions[i.current_version_id]) {
          versionsFetched = false;
        } else {
          itemVersions[i.current_version_id] = i.versions[i.current_version_id];
        }
      });
      let variantsFetched = true;
      if (versionsFetched) {
        _.forEach(itemVersions, (v) => {
          v.variants.forEach((variantId) => {
            if (!variants[variantId]) {
              variantsFetched = false;
            } else {
              versionVariants[variantId] = variants[variantId];
            }
          });
        });
        if (variantsFetched) {
          loading = false;
        }
      }
    }
  }

  if (loading) {
    return {
      loading,
      itemCollectionId,
    };
  } else {
    return {
      itemCollection,
      loading,
      collectionItems,
      versionVariants,
      itemStatuses,
      itemCollectionId,
      profile,
      companyIdInUrl,
      orderIdInUrl,
      versionIdInUrl,
      companies,
    };
  }
};

export default connect(mapStateToProps, {
  patchItem,
  patchItemVersion,
  setModalState,
  updateItemCollectionArchived,
  moveItemCollectionToOrder,
  setETLdisabled,
  addOrder,
})(ItemExpanded);
