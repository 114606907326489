import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import {
  SelectedItem,
  MixType,
  MixTypePartial,
  CollectionModelType,
  ItemVersionModelType,
  PartGroup,
  MeshMaterial,
} from "../types";
import { ProductsService } from "servicesNew/api";
import { ItemVersion } from "servicesNew/api/models/ItemVersion";
import {
  getPartGroupModel,
  getPartModel,
  getMeshMaterialModel,
  getItemVersionModel,
} from "../utils/dataModel";

const collectionWalker = (
  iterable: any[],
  callback: (item: MixType, parent: MixType | null) => void | boolean,
  parent: MixType | null = null
) => {
  for (const i of iterable) {
    const response = callback(i as MixType, parent);
    if (response) return;
    const newIterable: any[] = i.childrens || [];
    if (newIterable.length) {
      collectionWalker(newIterable, callback, i);
    }
  }
};

interface CollectionContextValue {
  companyId: number;
  orderId: number;
  itemCollectionId: number | null;
  collection: CollectionModelType | null;
  setCollection: (value: React.SetStateAction<CollectionModelType>) => void;
  selectedKey: string;
  setSelectedKey: (value: React.SetStateAction<string>) => void;
  selectedItem: SelectedItem | null;
  getItemByUUID: (uuid: string) => SelectedItem | null;
  updateItemByUUID: (
    uuid: string,
    callback: (item: MixType, parent: MixType | null) => MixTypePartial
  ) => void;
  moveItemByUUID: (uuid: string, direction: number) => void;
  updateCollectionData: (versionId: string, partGroupsData: any) => void;
}

const CollectionContext = createContext<CollectionContextValue>({
  companyId: 0,
  orderId: 0,
  itemCollectionId: null,
  collection: null,
  setCollection: () => {},
  selectedKey: "",
  setSelectedKey: () => {},
  selectedItem: null,
  getItemByUUID: () => null,
  updateItemByUUID: () => {},
  moveItemByUUID: () => {},
  updateCollectionData: () => {},
});

export const CollectionContextProvider = (props: any) => {
  const params = useParams();
  const [collection, setCollection] = useState<CollectionModelType | null>(
    null
  );
  const [selectedKey, setSelectedKey] = useState<string>("");
  const { companyId, orderId, itemCollectionId } = useMemo(
    () => ({
      companyId: Number(params.companyId),
      orderId: Number(params.orderId),
      itemCollectionId: Number(params.itemCollectionId) || null,
    }),
    [params.companyId, params.itemCollectionId, params.orderId]
  );
  const reverseMapByUUID = useMemo(() => {
    const mapped: { [key: string]: SelectedItem } = {};
    if (collection) {
      mapped[collection._uuid] = {
        _uuid: collection._uuid,
        id: collection.id,
        type: "collection",
        name: collection.name,
        item: collection,
        data: { collection },
      };
      collection.childrens.forEach((item) => {
        mapped[item._uuid] = {
          _uuid: item._uuid,
          id: item.id,
          type: "item",
          name: item.name,
          item: item,
          data: { collection, item },
        };
        item.childrens.forEach((itemVersion) => {
          mapped[itemVersion._uuid] = {
            _uuid: itemVersion._uuid,
            id: itemVersion.id,
            type: "itemVersion",
            name: itemVersion.name,
            item: itemVersion,
            data: { collection, item, itemVersion },
          };
          itemVersion.childrens.forEach((partGroup) => {
            mapped[partGroup._uuid] = {
              _uuid: partGroup._uuid,
              id: partGroup.id,
              type: "partGroup",
              name: partGroup.name,
              item: partGroup,
              data: { collection, item, itemVersion, partGroup },
            };
            partGroup.childrens.forEach((part) => {
              mapped[part._uuid] = {
                _uuid: part._uuid,
                id: part.id,
                type: "part",
                name: part.name,
                item: part,
                data: { collection, item, itemVersion, partGroup, part },
              };
              part.childrens.forEach((meshMaterial) => {
                mapped[meshMaterial._uuid] = {
                  _uuid: meshMaterial._uuid,
                  id: meshMaterial.id,
                  type: "meshMaterial",
                  name: meshMaterial.name,
                  item: meshMaterial,
                  data: {
                    collection,
                    item,
                    itemVersion,
                    partGroup,
                    part,
                    meshMaterial,
                  },
                };
              });
            });
          });
        });
      });
    }
    return mapped;
  }, [collection]);
  const selectedItem = useMemo<SelectedItem | null>(
    () => reverseMapByUUID[selectedKey] || null,
    [reverseMapByUUID, selectedKey]
  );

  const getItemByUUID = useCallback(
    (uuid: string) => reverseMapByUUID[uuid],
    [reverseMapByUUID]
  );
  const updateItemByUUID = useCallback(
    (
      uuid: string,
      callback: (item: MixType, parent: MixType | null) => MixTypePartial
    ): void => {
      const item = getItemByUUID(uuid);
      if (item) {
        const copyOfCollection: [CollectionModelType] = [
          JSON.parse(JSON.stringify(collection)),
        ];
        collectionWalker(copyOfCollection, (_item, _parent) => {
          if (_item._uuid === uuid) {
            Object.assign(_item, callback(_item, _parent));
            return true;
          }
        });
        setCollection(copyOfCollection[0]);
      }
    },
    [collection, getItemByUUID]
  );

  function hasChildrens(item: any): item is MixType & { childrens: MixType[] } {
    return Array.isArray(item.childrens);
  }

  const moveItemByUUID = useCallback(
    (uuid: string, direction: number): void => {
      console.log("Move action on item:", selectedItem);
      if (!selectedItem || !collection) return;
      let targetIndex = -1;
      let parentArray: (MixType & { childrens: MixType[] })[] | undefined =
        undefined;
      const copyOfCollection: [CollectionModelType] = [
        JSON.parse(JSON.stringify(collection)),
      ];
      collectionWalker(copyOfCollection, (item, parent) => {
        if (parent && hasChildrens(parent)) {
          const index = parent.childrens.findIndex(
            (child) => child._uuid === uuid
          );
          if (index !== -1) {
            targetIndex = index;
            parentArray = parent.childrens as (MixType & {
              childrens: MixType[];
            })[];
            return;
          }
        }
      });
      if (parentArray && targetIndex >= 0) {
        const siblingIndex = targetIndex + direction;
        const confirmedParentArray = parentArray as (MixType & {
          childrens: MixType[];
        })[];
        if (siblingIndex >= 0 && siblingIndex < confirmedParentArray.length) {
          const [reorderedItem] = confirmedParentArray.splice(targetIndex, 1);
          confirmedParentArray.splice(siblingIndex, 0, reorderedItem);
          setCollection(copyOfCollection[0]);
          const updateOrder = (item: MixType, newOrder: number) => {
            if (selectedItem.type) {
              console.log(`Updating ${selectedItem.type} order to ${newOrder}`);
              switch (selectedItem.type) {
                case "meshMaterial":
                  ProductsService.productsMeshMaterialsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                case "item":
                  ProductsService.productsItemsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                case "itemVersion":
                  ProductsService.productsItemVersionsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                case "partGroup":
                  ProductsService.productsPartGroupsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                case "part":
                  ProductsService.productsPartsUpdate({
                    id: item.id,
                    data: { list_order: newOrder },
                  }).catch(console.error);
                  break;
                default:
                  console.error(
                    `No update logic implemented for type: ${selectedItem.type}`
                  );
              }
            }
          };
          if (selectedItem.type) {
            updateOrder(reorderedItem, siblingIndex);
            let adjacentItemIndex;
            if (direction > 0) {
              adjacentItemIndex = siblingIndex - 1;
            } else {
              adjacentItemIndex = siblingIndex + 1;
            }
            const adjacentItem = confirmedParentArray[adjacentItemIndex];
            if (adjacentItem) {
              updateOrder(
                adjacentItem,
                direction > 0 ? targetIndex : adjacentItemIndex
              );
            }
          }
        }
      }
    },
    [collection, setCollection, selectedItem]
  );

  const updateCollectionData = useCallback(
    async (
      itemUUID: string,
      //data: {
      //  partGroups: {
      //    results?: Array<any>;
      //  };
      //  itemVersion: ItemVersion;
      //}
      data: {
        partGroups: Array<any>;
        itemVersion: ItemVersion;
      }
    ): Promise<void> => {
      setCollection((prevCollection) => {
        if (!prevCollection) return prevCollection;

        const newCollection = JSON.parse(JSON.stringify(prevCollection));
        let found = false;

        for (const item of newCollection.childrens) {
          if (item._uuid === itemUUID) {
            if (!item.childrens) {
              item.childrens = [];
            }

            // Create/update itemVersion
            let itemVersion = item.childrens[0] as ItemVersionModelType;
            if (!itemVersion) {
              itemVersion = getItemVersionModel(data.itemVersion);
              item.childrens.push(itemVersion);
            }

            const extra_price_modifier =
              data.itemVersion.extra_price_modifier || [];

            // Clear existing childrens before adding new ones
            itemVersion.childrens = [];

            // Get partGroups array from the response
            //const partGroupsArray = data.partGroups?.results || [];
            //console.log("PartGroups to process:", data.partGroups);
            const partGroupsArray = Array.isArray(data.partGroups)
              ? data.partGroups
              : [];
            //console.log("PartGroups to process:", partGroupsArray);

            // Sort and process partGroups
            //const sortedPartGroups = partGroupsArray.sort(
            //  (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
            //);
            const sortedPartGroups = [...partGroupsArray].sort(
              (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
            );

            // Process each partGroup
            sortedPartGroups.forEach((partGroupData: any) => {
              // Create partGroup using the correct data
              const partGroup = getPartGroupModel(partGroupData);
              //const partGroup = getPartGroupModel({
              //  id: partGroupData.id,
              //  name: partGroupData.name,
              //  item_version_id: itemVersion.id,
              //  list_order: partGroupData.list_order || 0,
              //  is_active: partGroupData.is_active,
              //  parts: partGroupData.parts || [],
              //  Add other necessary partGroup fields
              //});

              //console.log("Created partGroup:", partGroup);
              // Add partGroup to itemVersion
              itemVersion.childrens.push(partGroup);

              // Process parts if they exist
              const sortedParts = (partGroupData.parts || []).sort(
                (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
              );

              sortedParts.forEach((partData: any) => {
                const part = getPartModel(partData);
                partGroup.childrens.push(part);

                // Process materials
                const sortedMeshMaterials = (partData.materials || []).sort(
                  (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
                );

                sortedMeshMaterials.forEach((meshMaterial: any) => {
                  let price_mod = 0;
                  if (
                    part.id !== undefined &&
                    meshMaterial.id !== undefined &&
                    extra_price_modifier[part.id]?.[meshMaterial.id]
                  ) {
                    price_mod = extra_price_modifier[part.id][meshMaterial.id];
                  }

                  const newMeshMaterial = getMeshMaterialModel(
                    meshMaterial,
                    price_mod
                  );
                  part.childrens.push(newMeshMaterial);
                });
              });
            });

            found = true;
            break;
          }
        }

        if (!found) {
          console.warn("Item not found in collection:", itemUUID);
        }

        return newCollection;
      });
    },
    []
  );

  /*const updateCollectionData = useCallback(
    async (itemUUID: string, partGroupsData: any) => {
      console.log("updateCollectionData called with:", {
        itemUUID,
        partGroupsData,
      });
      setCollection((prevCollection) => {
        if (!prevCollection) {
          console.log("No previous collection found");
          return prevCollection;
        }
        const newCollection = JSON.parse(JSON.stringify(prevCollection));
        let found = false;

        for (const item of newCollection.childrens) {
          if (item._uuid === itemUUID) {
            if (!item.childrens) {
              item.childrens = [];
            }
            const versionNumber = item.version_number || 1;
            const versionId = item.current_version_id;

            let itemVersion = item.childrens[0] as ItemVersionModelType;
            if (!itemVersion) {
              itemVersion = {
                _uuid: `version-${versionId}`,
                id: versionId,
                name: `Version ${versionNumber}`,
                childrens: [],
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                is_active: true,
                version_number: versionNumber,
                item: item.id,
                extra_price_modifier: [],
                custom_request_message: null,
                external_url: null,
                list_order: 0,
                is_deleted: false,
                deleted_at: null,
                old_id: null,
                is_published: false,
                base_price: 0,
                is_master: false,
                status: ItemVersion.status.CREATED,
                thumbnail: null,
                reference_file: [],
                images: [],
                files: [],
                thumbnail_post_url: null,
                destination_item_version_id: null,
                origin_item_version_id: null,
                part_groups: partGroupsData,
              };
              const results: any = [];
              results.push(partGroupsData?.partGroups);

              itemVersion = getItemVersionModel({
                id: versionId,
                version_number: versionNumber,
                part_groups: results[0],
              } as ItemVersion);
              item.childrens.push(itemVersion);
            }

            // Process part groups with sorting
            const sortedPartGroups = (partGroupsData?.results || []).sort(
              (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
            );

            itemVersion.childrens = sortedPartGroups.map((partGroup: any) => {
              // Sort parts within each part group
              const sortedParts = (partGroup.parts || []).sort(
                (a: any, b: any) => (a.list_order || 0) - (b.list_order || 0)
              );

              return {
                ...getPartGroupModel(partGroup),
                childrens: sortedParts.map((part: any) => {
                  // Sort materials within each part
                  const sortedMaterials = (part.materials || []).sort(
                    (a: any, b: any) =>
                      (a.list_order || 0) - (b.list_order || 0)
                  );

                  return {
                    ...getPartModel(part),
                    childrens: sortedMaterials.map((material: any) => {
                      // Calculate price modifier if needed
                      let price_mod = 0;
                      if (
                        part.id !== undefined &&
                        material.id !== undefined &&
                        itemVersion.extra_price_modifier?.[part.id]?.[
                          material.id
                        ]
                      ) {
                        price_mod =
                          itemVersion.extra_price_modifier[part.id][
                            material.id
                          ];
                      }

                      return getMeshMaterialModel(material, price_mod);
                    }),
                  };
                }),
              };
            });

            found = true;
            break;
          }
        }

        if (!found) {
          console.warn("Item not found in collection:", itemUUID);
        }
        return newCollection;
      });
    },
    []
  );*/

  /*const updateCollectionData = useCallback(
    async (itemUUID: string, partGroupsData: any) => {
      console.log("updateCollectionData called with:", {
        itemUUID,
        partGroupsData,
      });
      setCollection((prevCollection) => {
        if (!prevCollection) {
          console.log("No previous collection found");
          return prevCollection;
        }
        const newCollection = JSON.parse(JSON.stringify(prevCollection));
        let found = false;
        for (const item of newCollection.childrens) {
          if (item._uuid === itemUUID) {
            if (!item.childrens) {
              item.childrens = [];
            }
            const versionNumber = item.version_number || 1;
            const versionId = item.current_version_id;

            let itemVersion = item.childrens[0] as ItemVersionModelType;
            if (!itemVersion) {
              itemVersion = {
                _uuid: `version-${versionId}`,
                id: versionId,
                name: `Version ${versionNumber}`,
                childrens: [],
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                is_active: true,
                version_number: versionNumber,
                item: item.id,
                extra_price_modifier: [],
                custom_request_message: null,
                external_url: null,
                list_order: 0,
                is_deleted: false,
                deleted_at: null,
                old_id: null,
                is_published: false,
                base_price: 0,
                is_master: false,
                status: ItemVersion.status.CREATED,
                thumbnail: null,
                reference_file: [],
                images: [],
                files: [],
                thumbnail_post_url: null,
                destination_item_version_id: null,
                origin_item_version_id: null,
                part_groups: partGroupsData,
              };
              item.childrens.push(itemVersion);
            }

            const processedPartGroups = Array.isArray(partGroupsData)
              ? partGroupsData.map((group: any) => ({
                  ...group,
                  childrens: Array.isArray(group.parts)
                    ? group.parts.map((part: any) => ({
                        ...part,
                        childrens: Array.isArray(part.materials)
                          ? part.materials
                          : [],
                      }))
                    : [],
                }))
              : [];

            itemVersion.childrens = processedPartGroups;
            found = true;
            break;
          }
        }
        if (!found) {
          console.warn("Item not found in collection:", itemUUID);
        }
        return newCollection;
      });
    },
    []
  );*/

  const value = {
    companyId,
    orderId,
    itemCollectionId,
    collection,
    setCollection,
    selectedKey,
    setSelectedKey,
    selectedItem,
    getItemByUUID,
    updateItemByUUID,
    moveItemByUUID,
    updateCollectionData,
  };

  return <CollectionContext.Provider value={value} {...props} />;
};

export const useCollectionContext = () => {
  return useContext<CollectionContextValue>(CollectionContext);
};
