import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Col, Modal, Row, Input, Checkbox, Pagination, Radio } from "antd";
import _ from "lodash";
import { SearchOutlined } from "@ant-design/icons";
import { CheckboxValueType, CheckboxOptionType } from "antd/es/checkbox/Group";
import { ProductModalItem } from "./productModalItem";
import { Product } from "../../../types";
import { useSpaceContext } from "modules/showrooms/contexts/SpaceContext";
import { searchProducts } from "modules/showrooms/utils/dataMap";
import type { RadioChangeEvent } from "antd";

interface ProductsModalProps {
  open?: boolean;
  onAddProduct?: (product: Product) => void;
  onCancel?: () => void;
}

export const ProductsModal = ({
  open = false,
  onAddProduct = () => {},
  onCancel = () => {},
}: ProductsModalProps) => {
  const { space } = useSpaceContext();
  const [products, setProducts] = useState<Product[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [pageSize, setPageSize] = useState(12);
  const [searchTextDebounced, setSearchTextDebounced] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [checkedFilters, setCheckedFilters] = useState<CheckboxValueType[]>([]);
  const [productsType, setProductsType] = useState("products");

  const debounceSearchText = useMemo(
    () =>
      _.debounce((value: string) => {
        setSearchTextDebounced(value);
      }, 1000),
    []
  );

  const onChange = (e: RadioChangeEvent) => {
    setProductsType(e.target.value);
  };

  const fetchProducts = useCallback(
    async (payload: any): Promise<Product[]> => {
      const order_id = productsType === "products" ? space?.order_id : "161"; //hardcoded props order_id, target to the showroom_props orders
      return searchProducts(order_id, payload, searchTextDebounced); // getProductsMochdata();
    },
    [searchTextDebounced, space?.order_id, productsType]
  );

  useEffect(() => {
    debounceSearchText(searchText);
  }, [debounceSearchText, searchText]);

  useEffect(() => {
    const payload = {
      searchText: searchTextDebounced,
      page: currentPage,
    };
    fetchProducts(payload).then((_products) => {
      setProducts(_products);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextDebounced, fetchProducts]);
  const productsToDisplay = products.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
      className="products-modal"
    >
      <Row>
        <Col className="products-list-container">
          <div className="header">
            <Row>
              <Input
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                className="search-input"
                placeholder="Search Library (name,sku)"
                prefix={<SearchOutlined />}
                allowClear
              />
            </Row>
            <Row>
              <Radio.Group onChange={onChange} value={productsType}>
                <Radio value={"products"}>Products</Radio>
                <Radio value={"props"}>Props</Radio>
              </Radio.Group>
            </Row>
          </div>
          <div className="content">
            {productsToDisplay.map((product, index) => (
              <ProductModalItem
                key={`product-id-${product.id}-${index}`}
                name={product.name}
                sku={product.sku}
                status={product.status}
                thumbnail={product.thumbnail}
                variants={product.variants}
                onClick={() => {
                  onAddProduct(product);
                }}
                disabled={!product?.data?.is_published || false}
              />
            ))}
          </div>
          <div className="footer">
            <Pagination
              current={currentPage}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setPageSize(pageSize);
              }}
              pageSize={pageSize}
              total={products.length}
              pageSizeOptions={[12, 24, 36, 48]}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
