import { UploadFile } from "antd";

export type ItemType = "space" | "room" | "environment";

export enum ItemTypeLabel {
  space = "Space",
  room = "Room",
  environment = "Environment",
}
export interface SpaceItemType {
  uuid: string;
  type: "space";
  name: string;
  sq: number | null;
  rooms: RoomItemType[];
  description: string;
  environment: EnvironmentItemType;
  files: UploadFile<any>[];
  files_generated: string[];
}

export interface RoomItemType {
  uuid: string;
  type: "room";
  name: string;
  sq: number | null;
  description: string;
  files: UploadFile<any>[];
  files_generated: string[];
}

export interface EnvironmentItemType {
  uuid: string;
  type: "environment";
  name: string;
  description: string;
  files: UploadFile<any>[];
  files_generated: string[];
}

export type SelectedItem = {
  uuid: string;
  type: ItemType;
  name: string;
  item: MixItemType;
  data: {
    spaceItem?: SpaceItemType;
    roomItem?: RoomItemType;
    environmentItem?: EnvironmentItemType;
  };
};

export type MixItemType = SpaceItemType | RoomItemType | EnvironmentItemType;
