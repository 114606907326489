import React, { createContext, useState, useContext } from "react";
import { Scene } from "@babylonjs/core/scene.js";

type HotspotModeType = "add" | null;

interface EngineContextValue {
  isFocusedEngine: boolean;
  setIsFocusedEngine: (value: React.SetStateAction<boolean>) => void;
  isLoadingEngine: boolean;
  setIsLoadingEngine: (value: React.SetStateAction<boolean>) => void;
  hotspotMode: HotspotModeType;
  setHotspotMode: (value: React.SetStateAction<HotspotModeType>) => void;
  showHotspots: boolean;
  setShowHotspots: (value: React.SetStateAction<boolean>) => void;
  scene: Scene | null;
  setScene: (value: React.SetStateAction<Scene | null>) => void;
  canvas: HTMLCanvasElement | null;
  setCanvas: (value: React.SetStateAction<HTMLCanvasElement | null>) => void;
}

const EngineContext = createContext<EngineContextValue>({
  isFocusedEngine: false,
  setIsFocusedEngine: () => {},
  isLoadingEngine: false,
  setIsLoadingEngine: () => {},
  hotspotMode: null,
  setHotspotMode: () => {},
  showHotspots: true,
  setShowHotspots: () => {},
  scene: null,
  setScene: () => {},
  canvas: null,
  setCanvas: () => {},
});

export const EngineContextProvider = (props: any) => {
  const [isFocusedEngine, setIsFocusedEngine] = useState<boolean>(false);
  const [isLoadingEngine, setIsLoadingEngine] = useState<boolean>(false);
  const [hotspotMode, setHotspotMode] = useState<HotspotModeType>(null);
  const [showHotspots, setShowHotspots] = useState<boolean>(true);
  const [scene, setScene] = useState<Scene | null>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

  const value = {
    isFocusedEngine,
    setIsFocusedEngine,
    isLoadingEngine,
    setIsLoadingEngine,
    hotspotMode,
    setHotspotMode,
    showHotspots,
    setShowHotspots,
    scene,
    setScene,
    canvas,
    setCanvas,
  };

  return <EngineContext.Provider value={value} {...props} />;
};

export const useEngineContext = () => {
  return useContext<EngineContextValue>(EngineContext);
};
