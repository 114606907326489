/* eslint-disable react/no-unknown-property */
import { EventState, Vector3 } from "@babylonjs/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AnnotationType } from "../../types";
import trashIcon from "assets/images/trash.png";
import imageIcon from "assets/images/image.png";
import { Control, Vector2WithInfo } from "@babylonjs/gui";
import _ from "lodash";

export type OnAnnotationClickType = {
  annotation: AnnotationType;
  pickResult: Vector2WithInfo;
  evt: EventState;
};
interface ButtonBasicProps {
  isActive?: boolean;
  onClick?: (pickResult: Vector2WithInfo, evt: EventState) => void;
  imageUrl?: string;
  isVisible?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const AnnotationButton = React.forwardRef(
  (
    {
      isActive = false,
      imageUrl,
      onClick = () => {},
      onMouseEnter = () => {},
      onMouseLeave = () => {},
    }: ButtonBasicProps,
    _ref: any
  ) => {
    const background = useMemo(() => {
      if (imageUrl) {
        return isActive
          ? "rgba(255, 239, 97, 0.5)"
          : "rgba(252, 252, 252, 0.5)";
      }
      return isActive ? "#FFEF61" : "#1890FF";
    }, [imageUrl, isActive]);
    return (
      <babylon-button
        width="20px"
        height="20px"
        color="white"
        background={background}
        hoverCursor="pointer"
        thickness={1}
        cornerRadius={20}
        onPointerEnterObservable={(eventData: Control) => {
          eventData.color = "orange";
          onMouseEnter();
        }}
        onPointerOutObservable={(eventData: Control) => {
          eventData.color = "white";
          onMouseLeave();
        }}
        onPointerClickObservable={onClick}
        zIndex={10}
        ref={_ref}
      >
        {imageUrl && <babylon-image source={imageIcon} setPadding="3px" />}
      </babylon-button>
    );
  }
);

const DeleteButton = React.forwardRef(
  (
    {
      onClick = () => {},
      isVisible = false,
      onMouseEnter = () => {},
      onMouseLeave = () => {},
    }: ButtonBasicProps,
    _ref: any
  ) => (
    <babylon-button
      width="20px"
      height="20px"
      linkOffsetX={-17}
      linkOffsetY={-17}
      background="white"
      hoverCursor="pointer"
      thickness={1}
      isPointerBlocker
      cornerRadius={20}
      isVisible={isVisible}
      onPointerClickObservable={onClick}
      onPointerEnterObservable={(eventData: Control) => {
        onMouseEnter();
      }}
      onPointerOutObservable={(eventData: Control) => {
        onMouseLeave();
      }}
      ref={_ref}
    >
      <babylon-image source={trashIcon} setPadding="3px" />
    </babylon-button>
  )
);
interface AnnotationProps {
  annotation: AnnotationType;
  isActive?: boolean;
  onClick?: ({ annotation, evt, pickResult }: OnAnnotationClickType) => void;
  onDelete?: ({ annotation, evt, pickResult }: OnAnnotationClickType) => void;
}

export const Annotation = ({
  annotation,
  isActive = false,
  onClick = () => {},
  onDelete = () => {},
}: AnnotationProps) => {
  const { id, x, y, z, imageUrl } = annotation;
  const btnAnnotationRef = useRef<any>(null);
  const btnDeleteRef = useRef<any>(null);
  const sphereRef = useRef<any>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const setIsVisibleDebounce = useMemo(
    () =>
      _.debounce((visible: boolean) => {
        if (isActive) {
          setIsVisible(visible);
        }
      }, 300),
    [isActive]
  );

  useEffect(() => {
    btnAnnotationRef.current.linkWithMesh(sphereRef.current);
    btnDeleteRef.current.linkWithMesh(sphereRef.current);
  }, []);

  useEffect(() => {
    if (!isActive) {
      setIsVisible(false);
    }
  }, [isActive]);

  return (
    <>
      <sphere
        id={`annotation-${id}`}
        name="annotation"
        position={new Vector3(x, y, z)}
        ref={sphereRef}
        segments={1}
        diameter={0.01}
        isVisible={false}
      ></sphere>
      <AnnotationButton
        ref={btnAnnotationRef}
        isActive={isActive}
        imageUrl={imageUrl}
        onClick={(pickResult, evt) => {
          onClick({ annotation, evt, pickResult });
          setIsVisible(true);
        }}
        onMouseEnter={() => {
          setIsVisibleDebounce(true);
        }}
        onMouseLeave={() => {
          setIsVisibleDebounce(false);
        }}
      />
      <DeleteButton
        ref={btnDeleteRef}
        isVisible={isVisible}
        onClick={(pickResult, evt) => {
          onDelete({ annotation, evt, pickResult });
          setIsVisibleDebounce(false);
        }}
        onMouseEnter={() => {
          setIsVisibleDebounce(true);
        }}
        onMouseLeave={() => {
          setIsVisibleDebounce(false);
        }}
      />
    </>
  );
};
