import { history } from "services/history";
import ApiService from "services/ApiService";
import { ADD_OR_UPDATE_VARIANT } from "sharedConstants";
import { replaceInPath } from "utils";
import { getItemCollectionDetailsForOrder } from "./company";
import { addOrReplaceItemVersion } from "./items";

export const addOrUpdateVariant = (variantId, variantData) => {
  return {
    type: ADD_OR_UPDATE_VARIANT,
    variantData,
    variantId,
  };
};

export const getAndSetVariant = (variantId) => async (dispatch) => {
  try {
    const response = await ApiService.getVariant(variantId);
    const { data } = response;
    dispatch(addOrUpdateVariant(variantId, data));
    // dispatch(
    //   replace(
    //     replaceInPath("variantIdInUrl", data.id, window.location.pathname)
    //   )
    // );
    history.replace(
      replaceInPath("variantIdInUrl", data.id, window.location.pathname)
    );
  } catch (error) {
    console.warn(error);
  } finally {
    /* empty */
  }
};

export const patchVariant = (variantId, data) => async (dispatch) => {
  try {
    let response;
    if (data.status_id !== undefined) {
      response = await ApiService.updateVariantStatus(variantId, data);
      let versionRes = await ApiService.getItemVersion(
        response.data.item_version_id
      );
      await dispatch(
        addOrReplaceItemVersion(response.data.item_id, versionRes.data)
      );
    } else {
      response = await ApiService.updateVariant(variantId, data);
    }

    dispatch(addOrUpdateVariant(variantId, response.data));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createVariant =
  (partGroupId, variantName, companyId, orderId, itemCollectionId) =>
  async (dispatch) => {
    try {
      let newVariantRes = await ApiService.createSimpleVariant(
        partGroupId,
        variantName
      );

      await dispatch(
        addOrUpdateVariant(newVariantRes.data.id, newVariantRes.data)
      );
      dispatch(
        getItemCollectionDetailsForOrder(companyId, orderId, itemCollectionId)
      );
      return newVariantRes.data;
    } catch (error) {
      console.error(error);
    }
  };
