import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, message, Upload } from "antd";
import { RocketTwoTone } from "@ant-design/icons";
import {
  generateFbx,
  downloadCompletedFbx,
  uploadFbxZip,
  downloadAllRefFiles,
} from "../../admin/adminFunctions";
import { getParamsFromPathname, createMenuItem } from "utils";
import { getItemVersion } from "store/actions/items";
import "./adminActionsMenu.scss";
import { setModalState } from "store/actions/app";

class ProductAdminActionsMenu extends Component {
  renderMenuItems = () => [
    createMenuItem({
      label: "Admin Functions",
      key: "sub1",
      icon: <RocketTwoTone size={25} />,
      children: [
        createMenuItem({
          label: "Generate Template Master FBX Zip",
          key: "generate-fbx-menu-item",
          onClick: () =>
            generateFbx({
              companyId: parseInt(this.props.companyIdInUrl),
              orderId: parseInt(this.props.orderIdInUrl),
              itemId: parseInt(this.props.itemIdInUrl),
              versionNumber:
                this.props.item.versions[this.props.versionIdInUrl]
                  .version_number,
              varId:
                this.props.item.versions[this.props.versionIdInUrl].variants[0],
              versionId: this.props.versionIdInUrl,
              getItemVersion: this.props.getItemVersion,
            }),
        }),
        createMenuItem({
          label: "Download Template Master FBX Zip",
          key: "download-completed-fbx-menu-item",
          onClick: () =>
            downloadCompletedFbx(
              this.props.getItemVersion,
              this.props.itemIdInUrl,
              this.props.versionIdInUrl
            ),
        }),
        createMenuItem({
          label: "Download Reference Files Zip",
          key: "download-reference-files-zip",
          onClick: () =>
            downloadAllRefFiles(
              this.props.item.file_locker.reference_file,
              this.props.item
            ),
        }),
        createMenuItem({
          label: "Show Behaviors Modal",
          key: "show-behaviors-modal",
          onClick: () =>
            this.props.setModalState("editVariantBehaviors", {
              visible: true,
            }),
        }),
        createMenuItem({
          label: (
            <Upload
              // disabled={this.props.variantData.status_id === 4}
              accept=".zip"
              showUploadList={false}
              beforeUpload={async (file) => {
                message.loading("Uploading Completed FBX..", 1);
                let version = await this.props.getItemVersion(
                  this.props.itemIdInUrl,
                  this.props.versionIdInUrl
                );
                uploadFbxZip(file, version.fbx_urls.fbx_post_url);
                return false;
              }}
            >
              Upload Completed Master FBX Zip
            </Upload>
          ),
          key: "upload-completed-fbx-menu-item",
        }),
      ],
    }),
  ];

  render() {
    if (this.props.loading || !this.props.profile.admin) {
      return null;
    }
    return (
      <Menu
        className="bitreel-admin-functions-menu"
        // defaultSelectedKeys={[`${this.props.selectedOrderId}`]}
        // selectedKeys={[`${this.props.selectedOrderId}`]}
        mode="inline"
        inlineCollapsed={true}
        items={this.renderMenuItems()}
      />
    );
  }
}

const mapStateToProps = (
  { items, auth, profile },
  { itemVersion, itemId, itemCollectionId }
) => {
  let { orderIdInUrl, companyIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  let loading = !itemVersion || !itemId || !itemCollectionId;

  if (loading) {
    return {
      loading,
      token: auth.token,
      profile,
    };
  } else {
    // debugger
    return {
      loading,
      item: items[itemId],
      orderIdInUrl,
      companyIdInUrl,
      itemCollectionIdInUrl: itemCollectionId,
      versionIdInUrl: itemVersion,
      // variantIdInUrl,
      itemIdInUrl: itemId,
      token: auth.token,
      profile,
      // variantData: variants[variantIdInUrl]
    };
  }
};

export default connect(mapStateToProps, {
  getItemVersion,
  setModalState,
})(ProductAdminActionsMenu);
