import _ from "lodash";
import React, { createContext, useState, useContext } from "react";
import { Comment, AnnotationType } from "../types";

const generateNewEmptyComment = (): Comment => ({
  id: "",
  email: "",
  description: "",
  annotations: {},
  status: "created",
  version: 0,
  createdOn: null,
  updatedOn: null,
  images: [],
  isDirty: false,
});

interface CommentContextValue {
  comment: Comment;
  setComment: (value: React.SetStateAction<Comment>) => void;
  updateComment: (value: React.SetStateAction<Partial<Comment>>) => void;
  createNewEmptyComment: () => void;
  selectedAnnotationId: string | null;
  setSelectedAnnotationId: (value: React.SetStateAction<string | null>) => void;
  selectedAnnotation: AnnotationType | null;
  upsetAnnotation: (annotation: AnnotationType) => void;
  removeAnnotation: (annotationId: string) => void;
  removeAllAnnotations: () => void;
}

const CommentContext = createContext<CommentContextValue>({
  comment: generateNewEmptyComment(),
  setComment: () => {},
  updateComment: () => {},
  createNewEmptyComment: () => {},
  selectedAnnotationId: null,
  setSelectedAnnotationId: () => {},
  selectedAnnotation: null,
  upsetAnnotation: () => {},
  removeAnnotation: () => {},
  removeAllAnnotations: () => {},
});

export const CommentContextProvider = (props: any) => {
  const [comment, setComment] = useState<Comment>(generateNewEmptyComment());
  const [selectedAnnotationId, setSelectedAnnotationId] = useState<
    string | null
  >(null);

  const selectedAnnotation =
    selectedAnnotationId && comment
      ? comment.annotations[selectedAnnotationId]
      : null;

  const setCommentHandler = (value: React.SetStateAction<Comment>) => {
    setComment(value);
    setSelectedAnnotationId(null);
  };

  const updateComment = (value: React.SetStateAction<Partial<Comment>>) => {
    setComment((_comment) => ({
      ..._comment,
      isDirty: true,
      ...(typeof value === "function" ? value(_comment) : value),
    }));
  };

  const upsetAnnotation = (annotation: AnnotationType) => {
    updateComment((_comment) => ({
      annotations: {
        ..._comment.annotations,
        [annotation.id]: {
          ...annotation,
        },
      },
    }));
  };

  const removeAnnotation = (id: string) => {
    updateComment((_comment) => ({
      annotations: _.omit(_comment.annotations, id),
    }));
  };

  const removeAllAnnotations = () => {
    updateComment(() => ({
      annotations: {},
    }));
  };

  const createNewEmptyComment = () => {
    setComment(generateNewEmptyComment());
  };

  const value = {
    selectedAnnotationId,
    setSelectedAnnotationId,
    selectedAnnotation,
    comment,
    setComment: setCommentHandler,
    updateComment,
    createNewEmptyComment,
    upsetAnnotation,
    removeAnnotation,
    removeAllAnnotations,
  };

  return <CommentContext.Provider value={value} {...props} />;
};

export const useCommentContext = () => {
  return useContext<CommentContextValue>(CommentContext);
};
