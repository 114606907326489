import { Select } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "services/history";
import { getParamsFromPathname, replaceInPath } from "../../../utils/params";
import FullAreaSpinner from "../../Loaders/FullAreaSpinner";
import "./versionSelect.scss";
const Option = Select.Option;
class VersionSelect extends Component {
  render() {
    if (this.props.loading) {
      return (
        <FullAreaSpinner
          textColor="black"
          loadingMessage="Checking Versions.."
        />
      );
    }
    return (
      <div className="version-select-section">
        <h2>{this.props.itemDetails.name}</h2>
        <h3>{this.props.versionDetails.sub_sku}</h3>
        <h3>
          Status:{" "}
          <span
            style={{
              color:
                this.props.versionDetails.api_item_status === "D"
                  ? "red"
                  : "green",
            }}
          >
            {this.props.versionDetails.api_item_status === "D"
              ? "Discontinued"
              : "Active"}
          </span>
        </h3>
        <div>
          <p>
            {"Status:   "}
            <span>
              {
                this.props.itemStatuses.find(
                  (s) => s.id === this.props.versionDetails.status_id
                ).name
              }
            </span>
          </p>
        </div>
        <Select
          onChange={(value) => {
            history.push(
              replaceInPath(
                "versionIdInUrl",
                value,
                window.location.pathname,
                false
              )
            );
          }}
          value={this.props.versionId}
          style={{ width: 200 }}
          defaultValue={this.props.versionId}
        >
          {_.map(this.props.itemDetails.versions, (v) => {
            return (
              <Option
                key={v.id}
                value={v.id}
              >{`Version ${v.version_number}`}</Option>
            );
          })}
        </Select>
        {/* <div className='bottom-options'>
                    <a href=''>View Model in AR </a>
                    <div className="spacer" />
                    <a href={this.props.versionDetails.part_groups[0].parts[0].internal_url.glb}>Download Model</a>
                </div> */}
      </div>
    );
  }
}

const mapStateToProps = ({ items, variants, statuses: { itemStatuses } }) => {
  let {
    orderIdInUrl,
    companyIdInUrl,
    itemCollectionIdInUrl,
    versionIdInUrl,
    variantIdInUrl,
    itemIdInUrl,
  } = getParamsFromPathname(window.location.pathname);

  let loading =
    !companyIdInUrl ||
    !orderIdInUrl ||
    !itemCollectionIdInUrl ||
    !versionIdInUrl ||
    !variantIdInUrl ||
    !items[itemIdInUrl] ||
    !variants[variantIdInUrl] ||
    !items[itemIdInUrl].versions[versionIdInUrl] ||
    !items[itemIdInUrl].versions[versionIdInUrl]._detailsFetched;

  if (!loading) {
    return {
      loading,
      itemDetails: items[itemIdInUrl],
      versionId: Number(versionIdInUrl),
      itemId: itemIdInUrl,
      // versionDetails: items[itemIdInUrl].versions[versionIdInUrl],
      versionDetails: variants[variantIdInUrl],
      itemStatuses,
    };
  } else {
    return {
      loading,
    };
  }
};

export default connect(mapStateToProps, {})(VersionSelect);
