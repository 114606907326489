import React, { Key, useCallback, useMemo } from "react";
import {
  AppstoreAddOutlined,
  BgColorsOutlined,
  PlusOutlined,
  LoadingOutlined,
  HomeOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Divider, Dropdown, MenuProps, Spin, Tree } from "antd";
import type { DataNode } from "antd/es/tree";
import { useCollectionContext } from "../contexts/CollectionContext";
import { CollectionModelType, PartModelType, MixType } from "../types";
import { useMeshMaterials } from "../hooks";
import { useUIContext } from "../contexts/UIContext";
import { fetchItemVersionPartGroups } from "../utils/api";
import { ItemVersion } from "../../../../servicesNew/api/models/ItemVersion";
import { PartGroup } from "../../../../servicesNew/api/models/PartGroup";
import { getItemVersionModel } from "../utils/dataModel";

const LabelContextMenu = ({
  item,
  part,
  moveItem,
  children,
}: {
  item: MixType;
  part?: PartModelType;
  moveItem: (direction: number, item: MixType) => void;
  children: React.ReactElement;
}) => {
  const { getItemByUUID } = useCollectionContext();
  const { clipboardItem, setClipboardItem } = useUIContext();
  const { cloneMeshMaterials } = useMeshMaterials();
  const menuItems = useMemo(() => {
    const items: MenuProps["items"] = [];
    if (part) {
      items.push({
        label: <span>Copy all materials</span>,
        key: "copy-materials",
        onClick: (e) => {
          e.domEvent.preventDefault();
          e.domEvent.stopPropagation();
          const { _uuid } = part;
          const item = getItemByUUID(_uuid);
          setTimeout(() => {
            if (item) setClipboardItem(item);
          }, 500);
        },
      });
      if (clipboardItem && clipboardItem.data.part) {
        items.push({
          label: (
            <span>
              Paste materials from <i>{clipboardItem.data.part?.name}"</i>
            </span>
          ),
          key: "paste-materials",
          onClick: async (e) => {
            e.domEvent.stopPropagation();
            const { part: originPart } = clipboardItem.data;
            if (originPart) {
              await cloneMeshMaterials({
                destinationPart: part,
                meshMaterials: originPart.childrens,
                selectedItem: clipboardItem,
              });
              setClipboardItem(null);
            }
          },
        });
      }
    }
    items.push({
      label: "Move Up",
      key: "move-up",
      icon: <UpOutlined />,
      onClick: () => moveItem(-1, item),
    });

    items.push({
      label: "Move Down",
      key: "move-down",
      icon: <DownOutlined />,
      onClick: () => moveItem(1, item),
    });

    return items;
  }, [
    clipboardItem,
    cloneMeshMaterials,
    getItemByUUID,
    part,
    item,
    setClipboardItem,
    moveItem,
  ]);

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["contextMenu"]}
      overlayClassName="layouts-explorer-context-menu"
    >
      {children}
    </Dropdown>
  );
};

interface CollectionExplorerProps {
  collection: CollectionModelType | null;
  onSelect?: (selectedKey: string) => void;
  selectedKey?: string;
  onCreateAssociation?: () => void;
  disableCreateAssociation?: boolean;
}

const findItemVersionByUUID = (
  collection: CollectionModelType | null,
  key: string
) => {
  if (!collection) return null;

  // Look for the item by _uuid
  for (const item of collection.childrens) {
    if (item._uuid === key) {
      return item.current_version_id;
    }

    // Also check if it's a version UUID
    const itemVersion = item.childrens.find((version) => version._uuid === key);
    if (itemVersion) {
      return itemVersion;
    }
  }

  return null;
};

export const CollectionExplorer = ({
  collection,
  onSelect = () => {},
  selectedKey,
  onCreateAssociation = () => {},
  disableCreateAssociation = false,
}: CollectionExplorerProps) => {
  const { moveItemByUUID, updateCollectionData } = useCollectionContext();
  const { isLoading, setIsLoading } = useUIContext();

  const handleVersionExpand = async (itemVersion: any, nodeKey: any) => {
    if (!itemVersion) return;

    setIsLoading(true);
    try {
      const response = await fetchItemVersionPartGroups(itemVersion);
      //console.log("Raw API response:", response);

      // Process the response similar to fetchAll
      const itemVersionData = {
        id: itemVersion,
        ...response.partGroups, // This should include all itemVersion fields from the API
        part_groups: response.partGroups.part_groups || [],
      };

      // Create a properly structured itemVersion model
      const processedItemVersion = getItemVersionModel(itemVersionData);

      await updateCollectionData(nodeKey, {
        partGroups: response.partGroups.part_groups,
        itemVersion: processedItemVersion,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /*const handleVersionExpand = async (itemVersion: any, nodeKey: any) => {
    console.log("handleVersionExpand starting with itemVersion:", itemVersion);
    if (!itemVersion) {
      console.log("No itemVersion provided to handleVersionExpand");
      return;
    }

    setIsLoading(true);
    try {
      console.log("Fetching part groups for version:", itemVersion);
      const fullItemVersion = await fetchItemVersionPartGroups(itemVersion);

      const response = await fetchItemVersionPartGroups(itemVersion);

      //interface PartGroupsResponse {
      //  partGroups: PartGroup;
      //  results?: PartGroup[];
      //}

      //const response: PartGroupsResponse = await fetchItemVersionPartGroups(
      //  itemVersion
      //);
      //console.log("Raw API response:", response);

      //const itemVersions = response as PartGroupsResponse;
      //const partGroupsData =
      //  itemVersions?.partGroups || itemVersions?.results || [];
      const partGroupsData = [response.partGroups];
      console.log("Extracted partGroupsData:", partGroupsData);

      //if (Array.isArray(partGroupsData)) {
      const processedPartGroups = partGroupsData.map((partGroup: any) => ({
        ...partGroup,
        childrens:
          partGroup.parts?.map((part: any) => ({
            ...part,
            childrens: part.materials || [],
          })) || [],
      }));

      console.log("Processed part groups:", processedPartGroups);
      await updateCollectionData(nodeKey, processedPartGroups);
      //} else {
      //console.error("partGroupsData is not an array:", partGroupsData);
      //}
    } catch (error) {
      console.error("Error fetching part groups:", error);
    } finally {
      setIsLoading(false);
    }
  };*/

  const moveItem = useCallback(
    (direction: number, item: MixType) => moveItemByUUID(item._uuid, direction),
    [moveItemByUUID]
  );

  const treeData: DataNode[] = useMemo(() => {
    if (collection) {
      return [collection].map((collectionItem) => ({
        key: collectionItem._uuid,
        icon: <HomeOutlined />,
        title: (
          <LabelContextMenu item={collectionItem} moveItem={moveItem}>
            <span>
              <b>Collection: </b>
              {collectionItem.name}
            </span>
          </LabelContextMenu>
        ),
        children: collectionItem.childrens.map((item) => ({
          key: item._uuid,
          title: (
            <div>
              <LabelContextMenu item={item} moveItem={moveItem}>
                <span>
                  <b>Item: </b>
                  {item.name}
                </span>
              </LabelContextMenu>
              <Button
                size="small"
                icon={<UpOutlined />}
                onClick={() => moveItem(-1, item)}
              />
              <Button
                size="small"
                icon={<DownOutlined />}
                onClick={() => moveItem(1, item)}
              />
            </div>
          ),
          children: item.childrens.map((itemVersion) => ({
            key: itemVersion._uuid,
            title: (
              <div>
                <LabelContextMenu item={itemVersion} moveItem={moveItem}>
                  <span>
                    <b>Version: </b>
                    {itemVersion.name}
                  </span>
                </LabelContextMenu>
                <Button
                  size="small"
                  icon={<UpOutlined />}
                  onClick={() => moveItem(-1, itemVersion)}
                />
                <Button
                  size="small"
                  icon={<DownOutlined />}
                  onClick={() => moveItem(1, itemVersion)}
                />
              </div>
            ),
            children:
              itemVersion.childrens.map((partGroup) => ({
                key: partGroup._uuid,
                title: (
                  <div>
                    <LabelContextMenu item={partGroup} moveItem={moveItem}>
                      <span>
                        <b>Part Group: </b>
                        {partGroup.name}
                      </span>
                    </LabelContextMenu>
                    <Button
                      size="small"
                      icon={<UpOutlined />}
                      onClick={() => moveItem(-1, partGroup)}
                    />
                    <Button
                      size="small"
                      icon={<DownOutlined />}
                      onClick={() => moveItem(1, partGroup)}
                    />
                  </div>
                ),
                icon: <AppstoreAddOutlined />,
                children: partGroup.childrens.map((part) => ({
                  key: part._uuid,
                  title: (
                    <div>
                      <LabelContextMenu
                        item={part}
                        part={part}
                        moveItem={moveItem}
                      >
                        <span>
                          <b>Part: </b>
                          {part.name}
                        </span>
                      </LabelContextMenu>
                      <Button
                        size="small"
                        icon={<UpOutlined />}
                        onClick={() => moveItem(-1, part)}
                      />
                      <Button
                        size="small"
                        icon={<DownOutlined />}
                        onClick={() => moveItem(1, part)}
                      />
                    </div>
                  ),
                  children: part.childrens.map((meshMaterial) => ({
                    key: meshMaterial._uuid,
                    title: (
                      <div>
                        <LabelContextMenu
                          item={meshMaterial}
                          moveItem={moveItem}
                        >
                          <span>
                            <b>Material: </b>
                            {meshMaterial.name}
                          </span>
                        </LabelContextMenu>
                        <Button
                          size="small"
                          icon={<UpOutlined />}
                          onClick={() => moveItem(-1, meshMaterial)}
                        />
                        <Button
                          size="small"
                          icon={<DownOutlined />}
                          onClick={() => moveItem(1, meshMaterial)}
                        />
                      </div>
                    ),
                    icon: <BgColorsOutlined />,
                    isLeaf: true,
                  })),
                })),
              })) || [],
            isLeaf: false,
          })),
        })),
      }));
    }
    return [];
  }, [collection, moveItem]);

  const handleOnSelect = useCallback(
    (keys: Key[], info: any) => {
      onSelect((keys[0] as string) || "");
    },
    [onSelect]
  );

  const isItemNode = (nodeKey: string): boolean => {
    if (!collection) return false;
    return collection.childrens.some((item) => item._uuid === nodeKey);
  };

  return (
    <div className="explorer">
      <Spin spinning={isLoading} indicator={<LoadingOutlined spin />}>
        <h3 className="title">
          <span>ITEMS COLLECTION</span>
        </h3>
      </Spin>
      {!!treeData.length && (
        <Tree
          showLine={true}
          defaultExpandedKeys={[treeData[0].key]}
          selectedKeys={selectedKey ? [selectedKey] : undefined}
          showIcon={true}
          onSelect={handleOnSelect}
          loadData={async (node) => {
            // This ensures the expand icon appears and handles the data loading
            //console.log("loadData called with node:", node);
            if (node.children?.length === 0) {
              const itemVersion = findItemVersionByUUID(
                collection,
                node.key as string
              );
              //console.log("loadData found itemVersion:", itemVersion);
              //if (itemVersion) {
              //  await handleVersionExpand(itemVersion);
              //}
            }
            return Promise.resolve();
          }}
          onExpand={(expandedKeys, { node }) => {
            const nodeKey = node.key as string;
            // Only trigger handleVersionExpand if it's an item node
            if (isItemNode(nodeKey)) {
              const itemVersion = findItemVersionByUUID(collection, nodeKey);
              if (itemVersion) {
                handleVersionExpand(itemVersion, nodeKey);
              }
            }
          }}
          treeData={treeData}
        />
      )}
      {!disableCreateAssociation && (
        <div className="footer">
          <Divider />
          <Button
            onClick={() => {
              onCreateAssociation();
            }}
          >
            <PlusOutlined /> View/Edit Associations
          </Button>
        </div>
      )}
    </div>
  );
};
