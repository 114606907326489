import React, { Component } from "react";
import { connect } from "react-redux";
import Screen from "components/Screen";
import LeftMenu from "components/LeftMenu";
import VersionSelect from "components/LeftMenu/sections/VersionSelect";
import { Col, Divider, notification, Row } from "antd";
import RenderViewer from "../components/modelView";
import CommentViewer from "components/CommentViewer";
import ProductDetails from "../components/productDetails";
import CommentSection from "components/CommentSection";
import { getParamsFromPathname } from "utils";
import { getAndSetItemCollectionWithAll } from "store/actions/items";
import ApproveItem from "../components/approveItem";
import FileLocker from "../components/fileLocker";
import "./detail.scss";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import EditVariantBehaviorsModal from "../../behaviors/modals/EditVariantBehaviorsModal";
class ProductDetail extends Component {
  state = {
    initialDataFetched: false,
    renderViewerRef: null,
    newHotspots: [],
    clickedCommentHotspots: [],
  };

  setRenderViewerRef = (ref) => {
    window.viewerRef = ref;
    this.setState({ renderViewerRef: ref });
  };

  setNewHotspots = (hotSpots) => {
    this.setState({ newHotspots: hotSpots });
  };

  setClickedCommentHotspots = (clickedCommentHotspots) => {
    this.setState({
      clickedCommentHotspots,
    });
  };

  componentDidMount() {
    if (this.props.itemCollectionIdInUrl) {
      this.setState(
        {
          initialDataFetched: true,
        },
        () => {
          this.props.getAndSetItemCollectionWithAll(
            this.props.itemCollectionIdInUrl,
            this.props.orderIdInUrl
          );
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.versionIdInUrl !== prevProps.versionIdInUrl) {
      this.props.getAndSetItemCollectionWithAll(
        this.props.itemCollectionIdInUrl,
        this.props.orderIdInUrl
      );
    }
  }

  handleAddHotSpot = () => {
    if (this.state.renderViewerRef) {
      notification.success({
        message:
          "Click the model where you would like hotspot to appear with comment.",
      });

      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.renderViewerRef.current.hotspots = [];
      this.state.renderViewerRef.current.setAttribute("hotspot", "enabled");
    }
  };

  render() {
    if (this.props.loading) {
      return (
        <Screen>
          <FullAreaSpinner />
        </Screen>
      );
    }
    return (
      <Screen className="product-detail">
        <div className="bitreel-admin-functions-container">
          {/* <ProductAdminActionsMenu /> */}
        </div>
        <Row>
          <Col span={7}>
            <LeftMenu
              sections={[
                <VersionSelect key="version-select" />,
                <CommentViewer
                  key="comment-viewer"
                  newHotspots={this.state.newHotspots}
                  renderViewerRef={this.state.renderViewerRef}
                  handleAddHotSpotFxn={this.handleAddHotSpot}
                />,
                <ApproveItem key="approve-item" />,
              ]}
            />
          </Col>
          <Col span={17}>
            <RenderViewer
              setNewHotspots={this.setNewHotspots}
              setRenderViewerRefFxn={this.setRenderViewerRef}
              variantId={Number(this.props.variantIdInUrl)}
              itemCollectionId={this.props.itemCollectionIdInUrl}
              clickedCommentHotspots={this.state.clickedCommentHotspots}
              setClickedCommentHotspots={this.setClickedCommentHotspots}
            />
          </Col>
          <Divider />
          <Col span={24}>
            <ProductDetails />
          </Col>
          <Divider />
          <Col span={24}>
            <CommentSection
              renderViewerRef={this.state.renderViewerRef}
              newHotspots={this.state.newHotspots}
              clickedCommentHotspots={this.state.clickedCommentHotspots}
              setClickedCommentHotspots={this.setClickedCommentHotspots}
            />
          </Col>
          <Col span="24">
            <FileLocker />
          </Col>
        </Row>
        <EditVariantBehaviorsModal variantData={this.props.variantData} />
      </Screen>
    );
  }
}

const mapStateToProps = ({ items, auth, variants, profile }) => {
  let {
    orderIdInUrl,
    companyIdInUrl,
    itemCollectionIdInUrl,
    versionIdInUrl,
    variantIdInUrl,
  } = getParamsFromPathname(window.location.pathname);
  let loading = false;

  if (!loading) {
    return {
      loading,
      itemCollection: items[itemCollectionIdInUrl],
      orderIdInUrl,
      companyIdInUrl,
      itemCollectionIdInUrl,
      versionIdInUrl,
      variantIdInUrl,
      token: auth.token,
      variantData: variants[variantIdInUrl],
      profile,
    };
  } else {
    return {
      loading,
    };
  }
};

export default connect(mapStateToProps, {
  getAndSetItemCollectionWithAll,
})(ProductDetail);
