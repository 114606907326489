//--------- GET ALL ------------- (only called once on main)
import ApiService from "services/ApiService";

export const setShowrooms = (showroomsInfo) => {
  return {
    type: "SET_SHOWROOMS",
    showroomsInfo,
  };
};

export const getAllShowrooms = (token) => async (dispatch) => {
  try {
    dispatch(isLoadingAllShowrooms(true));
    const response = await ApiService.getAllShowrooms(token);
    const { data } = response;
    const showrooms = [];
    data.forEach((showroom) => {
      // Dirty reverse
      const { showroom_shells } = showroom;
      delete showroom.showroom_shells;
      const shells = {};

      showroom_shells.forEach((showroom_shell) => {
        // Reveal
        const { shell_version } = showroom_shell;
        if (!shells[shell_version.shell.id])
          shells[shell_version.shell.id] = { ...shell_version.shell };
        const shell = shells[shell_version.shell.id];
        // Mutate
        if (!shell.shell_versions) shell.shell_versions = {};
        shell.shell_versions[shell_version.id] = shell_version;
        shell_version.showroom_shell = showroom_shell;
        // Delete
        delete shell_version.shell;
        delete showroom_shell.shell_version;
      });
      showroom.shells = shells;
      showrooms.push(showroom);
    });

    // const showrooms = data.map((showroom) => ({
    //   uuid: showroom.uuid,
    //   active: showroom.active,
    //   id: showroom.id,
    //   company_id: showroom.company_id,
    //   name: showroom.name,
    //   description: showroom.description,
    //   showroom_shells: showroom.showroom_shells.map((showroom_shell) => ({
    //     shell: showroom_shell.shell_version.shell,
    //   })),
    // }));
    dispatch(setShowrooms(showrooms));
    return showrooms;
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(isLoadingAllShowrooms(false));
  }
};

//---------- LOADING -----------
export const isLoadingAllShowrooms = (isLoadingAllShowrooms) => {
  return {
    type: "IS_LOADING_ALL_SHOWROOMS",
    isLoadingAllShowrooms,
  };
};
