import React, { useCallback, useMemo } from "react";
import { Breadcrumb } from "antd";
import { SelectedItem } from "../types";
import { HomeOutlined } from "@ant-design/icons";

interface CollectionBreadcrumbProps {
  selectedItem: SelectedItem;
  onSelect?: (key: string) => void;
}

export const CollectionBreadcrumb = ({
  selectedItem,
  onSelect = () => {},
}: CollectionBreadcrumbProps) => {
  const {
    data: { collection, item, itemVersion, partGroup, part, meshMaterial },
  } = selectedItem;

  const breadcrumbItems = useMemo(() => {
    return [collection, item, itemVersion, partGroup, part, meshMaterial]
      .filter((i) => !!i)
      .map((item: any, index) => ({
        uuid: item._uuid,
        title: item.name,
        index,
      }));
  }, [collection, item, itemVersion, meshMaterial, part, partGroup]);

  const breadcrumbItemRender = useCallback(
    (item: any) => {
      return (
        <span
          onClick={() => {
            onSelect(item.uuid);
          }}
        >
          {item.index === 0 ? (
            <HomeOutlined />
          ) : item.index === 2 ? (
            "Version"
          ) : null}{" "}
          {item.title}
        </span>
      );
    },
    [onSelect]
  );

  return (
    <Breadcrumb
      items={breadcrumbItems}
      itemRender={breadcrumbItemRender}
      className="layout-breadcrumb"
    />
  );
};
