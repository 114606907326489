import React, { useCallback, useState } from "react";
import { notification } from "antd";
import { useParams } from "react-router-dom";
import { useUIContext } from "../contexts/UIContext";
import { useCollectionContext } from "../contexts/CollectionContext";
import { ProductsService } from "servicesNew/api";
import {
  AtLeast,
  ItemVersionModelType,
  PartGroupModelType,
  PartModelType,
  PartGroupExtractedData,
} from "../types";
import { createPartGroupTree } from "../utils/api";
import {
  getPartGroupModel,
  getPartModel,
  getMeshMaterialModel,
} from "../utils/dataModel";
import { format } from "date-fns";

export const usePartGroups = () => {
  const { updateItemByUUID } = useCollectionContext();
  const { taskWrapper } = useUIContext();
  const [partCloneData, setPartCloneData] = useState({});
  const [materialClone, setMaterialClone] = useState<any>();
  const [partIds, setPartIds] = useState([]);

  const { companyId, orderId, itemCollectionId } = useParams();
  const companyIdExtracted = companyId !== undefined ? companyId : "";

  // updated code logic
  const addPartGroup = useCallback(
    async ({
      // itemVersion,
      name,
      item,
    }: {
      item: any;
      name: string;
    }) => {
      const list_order = item?.childrens[0]?.childrens.length;
      const partGroupTree = await createPartGroupTree({
        itemVersionId: item?.childrens[0]?.id,
        name,
        list_order,
      });
      updateItemByUUID(item?.childrens[0]?._uuid, (_itemVersion) => {
        (_itemVersion as ItemVersionModelType).childrens.push(partGroupTree);
        return _itemVersion;
      });
      notification.success({
        message: "Part Group successfully created!",
      });
    },
    [updateItemByUUID]
  );
  // old code logic
  // const addPartGroup = useCallback(
  //   async ({
  //     itemVersion,
  //     name,
  //   }: {
  //     itemVersion: ItemVersionModelType;
  //     name: string;
  //   }) => {
  //     const list_order = itemVersion.childrens.length;
  //     const partGroupTree = await createPartGroupTree({
  //       itemVersionId: itemVersion.id,
  //       name,
  //       list_order,
  //     });
  //     updateItemByUUID(itemVersion._uuid, (_itemVersion) => {
  //       (_itemVersion as ItemVersionModelType).childrens.push(partGroupTree);
  //       return _itemVersion;
  //     });
  //     notification.success({
  //       message: "Part Group successfully created!",
  //     });
  //   },
  //   [updateItemByUUID]
  // );

  const updatePartGroup = useCallback(
    async (
      partGroup: AtLeast<
        PartGroupModelType,
        /*"_uuid" | "id" |*/ "name" | "optional" | "custom_request_message"
      >
    ) => {
      if (
        typeof partGroup.id === "number" &&
        typeof partGroup._uuid === "string"
      ) {
        const payload: any = {
          name: partGroup.name,
          optional: partGroup.optional,
          // Only include custom_request_message if it's not null
          ...(partGroup.custom_request_message !== null && {
            custom_request_message: partGroup.custom_request_message,
          }),
        };
        await ProductsService.productsPartGroupsUpdate({
          id: partGroup.id,
          data: payload,
        });

        updateItemByUUID(partGroup._uuid, () => ({
          ...partGroup,
          name: partGroup.name,
        }));
        notification.success({
          message: "PartGroup successfully updated!",
        });
      } else {
        console.error("Missing partGroup id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  /*const updatePartGroup = useCallback(
    async (partGroup: AtLeast<PartGroupModelType, "_uuid" | "id">) => {
      await ProductsService.productsPartGroupsUpdate({
        id: partGroup.id,
        data: partGroup,
      });

      updateItemByUUID(partGroup._uuid, () => partGroup);
      notification.success({
        message: "Part Group successfully updated!",
      });
    },
    [updateItemByUUID]
  );*/

  const clonePartGroup = useCallback(
    async ({
      partGroup,
      selectedItem,
    }: {
      partGroup: PartGroupModelType;
      selectedItem: any;
    }) => {
      const selectedItemData = selectedItem?.data?.itemVersion;

      //Function to extract the last list_order of last partGroup
      const extractLastPartGroupListOrder = (data: any) => {
        if (
          !data ||
          !Array.isArray(data.childrens) ||
          data.childrens.length === 0
        ) {
          return null;
        }

        const lastPartGroup = data.childrens[data.childrens.length - 1];

        if (!lastPartGroup || typeof lastPartGroup.list_order !== "number") {
          return null;
        }

        return lastPartGroup.list_order;
      };
      const listOrder = extractLastPartGroupListOrder(
        selectedItem?.data?.itemVersion
      );
      //Incrementing the list order by 1 to send to the api for partGroup clone
      const clonedListOrder = listOrder != null ? listOrder + 1 : null;
      const suffix = format(new Date(), "yyyy-MM-dd HH-mm:ss");

      //Part Group Clone
      const partGroupClone = await ProductsService.productsPartGroupsCreate({
        data: {
          name: `${partGroup.name} -- clone ${suffix}`,
          item_version_id: partGroup?.item_version_id,
          list_order: clonedListOrder,
          optional: partGroup?.optional,
          custom_request_message:
            partGroup?.custom_request_message != null
              ? partGroup?.custom_request_message
              : "",
        },
      });
      const partGroupModel = getPartGroupModel(partGroupClone);

      const copyPartGroupThumbnails =
        await ProductsService.productsCopyPartGroupThumbnails({
          data: {
            destination_part_group_id: partGroupClone.id,
            origin_part_group_id: partGroup.id,
          },
        });

      updateItemByUUID(partGroup._uuid, (_partGroup, _itemVersion) => {
        (_itemVersion as ItemVersionModelType).childrens.push(partGroupModel);
        return {};
      });
      notification.success({
        message: "Part Group successfully cloned!",
      });

      //Part Clone
      const prepareChildrensForAPI = (data: any) => {
        if (
          !data ||
          !Array.isArray(data.childrens) ||
          data.childrens.length === 0
        ) {
          return []; // Return empty array if childrens array is empty or data is invalid
        }

        const apiPayloads = data.childrens.map((child: any) => {
          const { id, name, price_mod, visible, list_order } = child;

          // Construct the payload for API call
          const payload = {
            part_group_id: data.id, // Use the part_group_id from the parent data
            name,
            price_mod,
            visible,
            list_order,
            id,
          };

          return payload;
        });

        return apiPayloads;
      };
      const apiPayloads = prepareChildrensForAPI(partGroup);

      const partCloneResponses = await Promise.all(
        apiPayloads.map(async (payload: any) => {
          const partClone = await ProductsService.productsPartsClone({
            data: {
              part_group_id: partGroupClone?.id,
              name: `${payload?.name}`,
              price_mod: payload?.price_mod,
              visible: payload?.visible,
              list_order: payload?.list_order,
            },
          });

          setPartCloneData(partClone);

          const partGroupModel = getPartModel(partClone);

          const copyPartThumbnails =
            await ProductsService.productsCopyPartThumbnails({
              data: {
                destination_part_id: partClone.id,
                origin_part_id: payload.id,
              },
            });
          updateItemByUUID(partGroup._uuid, (_partGroup) => {
            (_partGroup as PartGroupModelType).childrens.push(partGroupModel);
            return {};
          });

          notification.success({
            message: "Part successfully cloned!",
          });

          return partClone; // Return the partClone response for Promise.all
        })
      );

      //Part Association Clone

      async function extractMatchingPartAndGroupIds(
        partData: any,
        itemVersionData: any
      ) {
        // Check if partData has childrens (partGroups)
        if (
          !partData.childrens ||
          !Array.isArray(partData.childrens) ||
          partData.childrens.length === 0
        ) {
          console.log("No part groups (childrens) to process.");
          return;
        }

        // Extract the IDs of part association groups from partData childrens
        const partAssociationGroupIds: number[] = [];
        // partData.childrens.forEach((partGroup: any) => {
        partData.childrens.forEach((part: any) => {
          if (
            part.part_association_groups &&
            Array.isArray(part.part_association_groups)
          ) {
            part.part_association_groups.forEach((group: any) => {
              partAssociationGroupIds.push(group.id);
            });
          }
        });
        // });

        // Traverse through itemVersionData to find matching part groups and associations
        itemVersionData.childrens.forEach(async (partGroup: any) => {
          partGroup.childrens.forEach(async (part: any) => {
            // Check if the part has association groups
            if (
              part.part_association_groups &&
              Array.isArray(part.part_association_groups) &&
              part.part_association_groups.length > 0
            ) {
              // Extract the IDs of association groups for filtering
              const partGroupIds = part.part_association_groups.map(
                (group: any) => group.id
              );

              // Find intersection of partGroupIds and partAssociationGroupIds
              const matchingGroups = partGroupIds.filter((groupId: any) =>
                partAssociationGroupIds.includes(groupId)
              );

              // If there are matching groups, send each association to the API
              if (matchingGroups.length > 0) {
                for (const groupId of matchingGroups) {
                  console.log("matchingPartIds", groupId);
                  await sendAssociationToApi(
                    part.id,
                    groupId,
                    part?.list_order
                  );
                }
              }
            }
          });
        });
      }

      // Define the function to send a part association to the API
      async function sendAssociationToApi(
        partId: number,
        groupId: number,
        list_order: any
      ) {
        const partIdToMatch = partId;
        const groupIdToMatch = groupId;
        const listOrderToMatch = list_order;
        const matchingPart = partGroup.childrens.find(
          (child) => child.id === partIdToMatch
        );

        let associatedPartId;
        if (matchingPart) {
          // If partId matches, now check list order
          const matchingListOrder = partCloneResponses.find(
            (item) => item.list_order === listOrderToMatch
          );

          if (matchingListOrder) {
            associatedPartId = matchingListOrder.id;
          }
        }

        try {
          await ProductsService.productsPartAssociationCreate({
            partId: associatedPartId ? associatedPartId : partId,
            partAssociationGroupId: groupId,
            data: {
              // Additional data can be included here as needed
              // For example:
              // ui_message: "Your UI message",
              // type: "Your type",
            },
          });
          console.log(
            `Association sent for partId: ${partId}, groupId: ${groupId},list_order:${list_order}`
          );
          // setPartCloneData({});
        } catch (error) {
          console.error(
            `Error sending association for partId: ${partId}, groupId: ${groupId}`,
            error
          );
        }
        //   }
        // }
        // return;
        // try {
        //   await ProductsService.productsPartAssociationCreate({
        //     partId:associatedPartId ?associatedPartId :,
        //     partAssociationGroupId: groupId,
        //     data: {
        //       // Additional data can be included here as needed
        //       // For example:
        //       // ui_message: "Your UI message",
        //       // type: "Your type",
        //     },
        //   });
        //   console.log(
        //     `Association sent for partId: ${partId}, groupId: ${groupId},list_order:${list_order}`
        //   );
        //   // setPartCloneData({});
        // } catch (error) {
        //   console.error(
        //     `Error sending association for partId: ${partId}, groupId: ${groupId}`,
        //     error
        //   );
        // }
      }

      const matchingPartIds = extractMatchingPartAndGroupIds(
        partGroup,
        selectedItemData
      );

      // Material clone

      // Function for cloning the materials
      async function materialCloning(
        partCloneResponses: any[],
        companyIdExtracted: any
      ): Promise<void> {
        const addedMeshMaterials: any = [];
        try {
          for (const part of partGroup.childrens) {
            const findMatchingId = (part: any, newArray: any) => {
              // Extract the list_order from the part
              const partListOrder = part.list_order;

              // Search for a matching list_order in the provided array
              const matchingItem = newArray.find(
                (item: any) => item.list_order === partListOrder
              );

              // If a matching item is found, return its id
              return matchingItem ? matchingItem.id : null;
            };
            const matchingId = findMatchingId(part, partCloneResponses);

            for (const _meshMaterial of part.childrens) {
              if (matchingId !== null) {
                const price_mod = 0;

                const response =
                  await ProductsService.productsMeshMaterialsCopy({
                    data: {
                      destination_part_id: matchingId, // Use a specific partCloneResponse ID
                      company_id: Number(companyIdExtracted),
                      origin_material_ids: [_meshMaterial.id],
                      categories: _meshMaterial.material_category_associations,
                    },
                  });

                const newMeshMaterialId = response[0].id;

                const updatedMeshMaterial =
                  await ProductsService.productsMeshMaterialsUpdate({
                    id: newMeshMaterialId,
                    data: {
                      list_order: _meshMaterial.list_order,
                      // Include more properties here as needed
                    },
                  });

                if (price_mod > 0) {
                  await ProductsService.productsPartMaterialModifierUpdate({
                    partId: matchingId, // Use a specific partCloneResponse ID
                    materialId: updatedMeshMaterial.id,
                    data: {
                      name: _meshMaterial.name,
                      price_mod: _meshMaterial.price_mod,
                      // Include more properties here as needed
                    },
                  });
                }

                await ProductsService.productsMeshMaterialsUpdate({
                  id: updatedMeshMaterial.id,
                  data: {
                    visible: _meshMaterial.visible,
                  },
                });

                updateItemByUUID(_meshMaterial._uuid, () => ({
                  ..._meshMaterial,
                  name: _meshMaterial.name,
                  price_mod: _meshMaterial.price_mod,
                  visible: _meshMaterial.visible,
                }));

                notification.success({
                  message: "Material successfully updated!",
                });

                console.log("Updated Mesh Material:", updatedMeshMaterial);

                addedMeshMaterials.push(updatedMeshMaterial);
              }
            }
          }

          if (addedMeshMaterials.length > 0) {
            return addedMeshMaterials;
          }
          // return getMeshMaterialModel(updatedMeshMaterial, price_mod);
          console.log("All parts and materials processed successfully!");
        } catch (error) {
          console.error("Error processing parts and materials:", error);
          // Handle error cases if needed
        }
      }

      // function for calling the materialCloning and returning the data
      async function materialCloneFunction() {
        const Returning = await materialCloning(
          partCloneResponses,
          companyIdExtracted
        );
        console.log("Added Mesh Materials:", Returning);

        return Returning;
      }

      const returning2 = await materialCloneFunction();

      // Function to check if any material_association IDs exist in the specified data structure
      const extractMaterialAssociationIds = (part: any): any[] => {
        const ids: any[] = [];

        if (part.childrens) {
          // Loop through each child part in the childrens array
          part.childrens.forEach((child: any) => {
            if (child.childrens) {
              // Loop through each grandchild (nested part) in the child's childrens array
              child.childrens.forEach((grandchild: any) => {
                if (grandchild.material_associations) {
                  // Loop through material associations of the grandchild
                  grandchild.material_associations.forEach(
                    (association: any) => {
                      ids.push(association.id);
                    }
                  );
                }
              });
            }
          });
        }

        return ids;
      };
      const extracedMaterialIds = extractMaterialAssociationIds(partGroup);
      console.log("extracedMaterialIds", extracedMaterialIds);
      // return;

      const extractMaterialAssociations = (
        data: any,
        targetIds: number[]
      ): Array<{
        materialId: number;
        associationId: number;
        list_order: any;
      }> => {
        const results: Array<{
          materialId: number;
          associationId: number;
          list_order: any;
        }> = [];

        const traverse = (node: any): void => {
          if (node && typeof node === "object") {
            if (Array.isArray(node)) {
              node.forEach((item) => traverse(item));
            } else {
              // Extract material associations from the current node
              if (
                node.material_associations &&
                Array.isArray(node.material_associations)
              ) {
                node.material_associations.forEach((association: any) => {
                  if (targetIds.includes(association.id)) {
                    results.push({
                      materialId: node.id,
                      associationId: association.id,
                      list_order: node.list_order,
                    });
                  }
                });
              }

              // Traverse into childrens recursively
              if (node.childrens && Array.isArray(node.childrens)) {
                node.childrens.forEach((child: any) => traverse(child));
              }
            }
          }
        };

        traverse(data);
        return results;
      };
      const resultData = extractMaterialAssociations(
        selectedItemData,
        extracedMaterialIds
      );
      console.log("resultData", resultData);
      const checkMaterialIdsExist = (
        data: any,
        targetMaterialIds: number[]
      ): boolean => {
        let materialIdsExist = false;

        const traverse = (node: any): void => {
          if (node && typeof node === "object") {
            if (Array.isArray(node)) {
              // If node is an array, traverse each item in the array
              node.forEach((item) => traverse(item));
            } else {
              // Check for origin_material_ids and traverse childrens
              if (
                node.origin_material_ids &&
                Array.isArray(node.origin_material_ids)
              ) {
                const existingMaterialIds = node.origin_material_ids.map(
                  (id: number) => id
                );

                // Check if any target material ID exists in the current node's origin_material_ids
                if (
                  existingMaterialIds.some((id: number) =>
                    targetMaterialIds.includes(id)
                  )
                ) {
                  materialIdsExist = true;
                  return; // Exit early if we found a match
                }
              }

              // Traverse childrens recursively
              if (node.childrens && Array.isArray(node.childrens)) {
                node.childrens.forEach((child: any) => {
                  if (!materialIdsExist) {
                    traverse(child); // Only traverse if materialIdsExist is false
                  }
                });
              }
            }
          }
        };

        traverse(data);
        return materialIdsExist;
      };
      for (const result of resultData) {
        const { materialId, associationId } = result;

        // Check if the materialId exists in the specified data structure
        const materialExists = checkMaterialIdsExist(partGroup, [materialId]);
        console.log("materialExists", materialExists);

        //  api calls for cloning the  accociations of materials
        try {
          // Await the resolution of the returning2 promise to get the mesh materials array
          const resolvedMeshMaterials: any = returning2;
          console.log("resolvedMeshMaterials", resolvedMeshMaterials);

          if (resolvedMeshMaterials && resolvedMeshMaterials.length > 0) {
            const matchingMeshMaterial = resolvedMeshMaterials.find(
              (meshMaterial: any) =>
                meshMaterial.list_order === result.list_order
            );
            console.log("matchingMeshMaterial", matchingMeshMaterial);

            if (materialExists && matchingMeshMaterial) {
              // Use matchingMeshMaterial to call the API
              const { id } = matchingMeshMaterial; // Assuming 'id' is the property you need
              console.log("matchingMeshMaterial", matchingMeshMaterial);
              // Call the API using matchingMeshMaterial's id
              await ProductsService.productsMeshMaterialAssociationCreate({
                materialId: id,
                materialAssociationGroupId: associationId,
                data: {
                  // Pass any additional data needed for the API call
                },
              });

              console.log(`Sent '1' to the API for existing material ID ${id}`);
            } else {
              // If matchingMeshMaterial is not found, use the provided materialId
              await ProductsService.productsMeshMaterialAssociationCreate({
                materialId,
                materialAssociationGroupId: associationId,
                data: {
                  // Pass any additional data needed for the API call
                },
              });

              console.log(`Sent material ID ${materialId} to the API`);
            }
          } else {
            console.log("Resolved array is empty.");
          }
        } catch (error) {
          console.error(
            `Error sending material ID ${materialId} to the API:`,
            error
          );
        }
      }
      window.location.reload();
    },
    [updateItemByUUID]
  );

  // updated code
  const removePartGroup = useCallback(
    async ({ partGroup }: { partGroup: PartGroupModelType }) => {
      // Function to extract the IDs and UUIDs from the partGroup
      function extractIdsAndUuids(data: any): PartGroupExtractedData {
        const extractedData: PartGroupExtractedData = {
          level0: [],
          level1: [],
          level2: [],
        };

        const traverse = function (children: any, level: number): void {
          if (level < 3) {
            const levelKey = `level${level}` as keyof PartGroupExtractedData;

            for (const child of children) {
              const id = child.id || null;
              const _uuid = child._uuid || null;
              extractedData[levelKey].push({ id, _uuid });

              if (child.childrens && child.childrens.length > 0) {
                traverse(child.childrens, level + 1);
              }
            }
          }
        };

        traverse([data], 0);

        return extractedData;
      }
      const extractedData = extractIdsAndUuids(partGroup);

      // Remove mesh materials

      // Function for extracting the ids fpr material association and price mode
      function extractMeshMaterialAssociations(data: any): {
        associationResults: Array<{
          meshMaterialId: number;
          associationIds: number[];
        }>;
        priceModResults: Array<{ materialId: number; partId: number }>;
        associationCategory: any;
      } {
        const associationResults: Array<{
          meshMaterialId: number;
          associationIds: number[];
        }> = [];
        const priceModResults: Array<{ materialId: number; partId: number }> =
          [];
        const associationCategory: any = [];

        data.childrens.forEach((childPart: any) => {
          childPart.childrens?.forEach((meshMaterial: any) => {
            // Logic for material_category_association delete
            if (
              meshMaterial.material_category_associations &&
              meshMaterial.material_category_associations.length > 0
            ) {
              meshMaterial.material_category_associations.forEach(
                (category: any) => {
                  if (category.id !== undefined) {
                    associationCategory.push({
                      meshMaterialId: meshMaterial.id,
                      categoryIds: category.id,
                    });
                  }
                }
              );
              console.log(
                "material_category_associations in part",
                associationCategory
              );
            }
            const associationIds: number[] = [];

            if (
              meshMaterial.material_associations &&
              meshMaterial.material_associations.length > 0
            ) {
              meshMaterial.material_associations.forEach((association: any) => {
                if (association.id !== undefined) {
                  associationIds.push(association.id);
                }
              });

              associationResults.push({
                meshMaterialId: meshMaterial.id,
                associationIds,
              });
            }

            if (meshMaterial.price_mod > 0) {
              priceModResults.push({
                materialId: meshMaterial.id,
                partId: childPart.id,
              });
            }
          });
        });

        return {
          associationResults,
          priceModResults,
          associationCategory,
        };
      }

      const results = extractMeshMaterialAssociations(partGroup);

      // Api call for association category check and deleting
      for (const {
        meshMaterialId,
        categoryIds,
      } of results.associationCategory) {
        if (meshMaterialId !== undefined && categoryIds !== undefined) {
          await ProductsService.productsMaterialCategoryAssociationsDelete({
            categoryId: categoryIds,
            materialId: meshMaterialId,
          });
        }
      }
      // Api call for price Mod check and deleting
      for (const { materialId, partId } of results.priceModResults) {
        if (materialId !== undefined && partId !== undefined) {
          await ProductsService.productsPartMaterialModifierDelete({
            partId,
            materialId,
          });
        }
      }

      // Api call for material association check and deleting
      for (const {
        meshMaterialId,
        associationIds,
      } of results.associationResults) {
        // Check if associationIds array contains any values
        if (associationIds && associationIds.length > 0) {
          for (const id of associationIds) {
            await ProductsService.productsMeshMaterialAssociationDelete({
              material_id: meshMaterialId,
              material_association_group_id: id,
            });
          }
        }
      }

      // Api call for deleting the meshmaterial

      for (const { id, _uuid } of extractedData.level2) {
        if (id !== undefined && _uuid !== undefined) {
          await ProductsService.productsMeshMaterialsDelete({ id });

          updateItemByUUID(_uuid, (_meshMaterial, _part) => {
            (_part as PartModelType).childrens = (
              _part as PartModelType
            ).childrens.filter(
              ({ _uuid: existingUuid }) => existingUuid !== _uuid
            );
            return {};
          });
        }
      }

      // Remove parts
      // Function for deleting any part association if present
      async function deletePartAssociationGroups(data: any) {
        for (const part of data.childrens) {
          if (
            Array.isArray(part.part_association_groups) &&
            part.part_association_groups.length > 0
          ) {
            // Iterate through each part_association_group and call the API to delete
            for (const associationGroup of part.part_association_groups) {
              if (associationGroup.id !== undefined) {
                await ProductsService.productsPartAssociationDelete({
                  part_id: part.id,
                  part_association_group_id: associationGroup.id,
                });
              }
            }
          }
        }
      }

      await deletePartAssociationGroups(partGroup);

      for (const child of extractedData.level1) {
        if (child.id !== undefined && child._uuid !== undefined) {
          await ProductsService.productsPartsDelete({ id: child.id });

          updateItemByUUID(child._uuid, (_part, _partGroup) => {
            (_partGroup as ItemVersionModelType).childrens = (
              _partGroup as ItemVersionModelType
            ).childrens.filter(({ _uuid }) => _uuid !== child._uuid);
            return {};
          });
        }
      }

      // Remove part groups
      if (partGroup.id !== undefined && partGroup._uuid !== undefined) {
        await ProductsService.productsPartGroupsDelete({ id: partGroup.id });

        updateItemByUUID(partGroup._uuid, (_partGroup, _itemVersion) => {
          (_itemVersion as ItemVersionModelType).childrens = (
            _itemVersion as ItemVersionModelType
          ).childrens.filter(({ _uuid }) => _uuid !== partGroup._uuid);
          return {};
        });
      }

      notification.success({
        message: "Part Group successfully removed!",
      });
    },
    [updateItemByUUID]
  );

  // old code
  // const removePartGroup = useCallback(
  //   async ({ partGroup }: { partGroup: PartGroupModelType }) => {
  //     console.log("hello23", partGroup)
  //     return;
  //     await ProductsService.productsPartGroupsDelete({
  //       id: partGroup.id,
  //     });
  //     updateItemByUUID(partGroup._uuid, (_partGroup, _itemVersion) => {
  //       (_itemVersion as ItemVersionModelType).childrens = (
  //         _itemVersion as ItemVersionModelType
  //       ).childrens.filter(({ _uuid }) => _uuid !== partGroup._uuid);
  //       return {};
  //     });
  //     notification.success({
  //       message: "Part Group successfully removed!",
  //     });
  //   },
  //   [updateItemByUUID]
  // );

  return {
    addPartGroup: taskWrapper(addPartGroup),
    updatePartGroup: taskWrapper(updatePartGroup),
    clonePartGroup: taskWrapper(clonePartGroup),
    removePartGroup: taskWrapper(removePartGroup),
  };
};
