import React, { FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const withRouter = (Component: FC) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ComponentWithRouterProp = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  };

  return ComponentWithRouterProp;
};
