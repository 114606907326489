import React, { useCallback, useMemo } from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { DetailSection } from "modules/showrooms/components/DetailSection";
import { MediaDropZone } from "components/Media";
import { AIGeneratedImages } from "../components/AIGeneratedImages";
import { EnvironmentItemType, SelectedItem } from "../types";
import { useUIContext } from "../contexts/UIContext";
import { useItemsContext } from "../contexts/ItemsContext";
import { FormViewWrapper } from "../components/FormViewWrapper";
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

interface EnvironmentViewProps {
  selectedItem: SelectedItem;
}

export const EnvironmentView = ({ selectedItem }: EnvironmentViewProps) => {
  const [form] = Form.useForm();
  const { setIsDirty } = useUIContext();
  const { updateItemByUUID } = useItemsContext();
  const environmentItem = selectedItem.item as EnvironmentItemType;
  const onFinish = useCallback(
    async (values: any) => {
      updateItemByUUID(selectedItem.uuid, (_environmentItem) => values);
      setIsDirty(false);
    },
    [selectedItem.uuid, setIsDirty, updateItemByUUID]
  );
  const validateFileTypes = useMemo(
    () => ({
      "image/png": { maxSize: 5000000 },
      "image/jpg": { maxSize: 5000000 },
      "image/jpeg": { maxSize: 5000000 },
      "video/mp4": { maxSize: 100000000 },
    }),
    []
  );
  return (
    <Form
      form={form}
      name="environment-form"
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        name: environmentItem.name,
        description: environmentItem.description,
        files: environmentItem.files,
      }}
      onValuesChange={() => {
        setIsDirty(true);
      }}
    >
      <FormViewWrapper selectedItem={selectedItem} enableFooter>
        <div className="content-view content-view--environment">
          <div className="page-header">
            <div className="page-header--content">
              <Title level={2}>Environment Look & Feel</Title>
              <Paragraph>Generate environment view</Paragraph>
            </div>
          </div>
          <div className="page-body">
            {/* Name */}
            <DetailSection
              title="Environment name"
              description={
                <>
                  The environment name appears on your space view.{" "}
                  <i>(Ex: City view)</i>
                </>
              }
            >
              <Col md={24} lg={12} xl={10} xxl={6}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the environment name!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </DetailSection>

            {/* Description */}
            <DetailSection
              title="Description"
              description={
                <>
                  Environment description. <i>(Ex: City space view.)</i>
                </>
              }
            >
              <Form.Item
                name="description"
                rules={[
                  {
                    max: 150,
                  },
                ]}
              >
                <TextArea
                  maxLength={100}
                  autoSize={{ minRows: 2, maxRows: 2 }}
                />
              </Form.Item>
            </DetailSection>

            {/* Uploader */}
            <Row wrap>
              <Col md={11}>
                <DetailSection
                  title="Uploads Images & Videos"
                  description={
                    <>
                      Upload Images and Videos that describes best the
                      environment.
                      <i>
                        (.jpg or .png per images 5 MB max and .mp4 for videos
                        max 100 MB)
                      </i>
                    </>
                  }
                  divider={false}
                >
                  <Form.Item
                    getValueFromEvent={({ urlFileList }) => urlFileList}
                    name="files"
                  >
                    <MediaDropZone
                      multiple
                      accept=".jpg,.jpeg,.png,.mp4"
                      validateFileTypes={validateFileTypes}
                      maxCount={7}
                    />
                  </Form.Item>
                </DetailSection>
              </Col>
              <Col md={11} offset={2}>
                <AIGeneratedImages />
              </Col>
            </Row>
          </div>
        </div>
      </FormViewWrapper>
    </Form>
  );
};
