import { format } from "date-fns";
import ApiService from "services/ApiService";
import { mapSeries } from "utils";
import {
  ItemRemoveType,
  LayoutType,
  PartGroupType,
  PartType,
  MaterialType,
  CollectionType,
} from "../types";
import {
  getItem,
  getLayout,
  getPartGroup,
  getPart,
  getMaterial,
  getCollection,
} from "./dataModel";

export const fetchCollectionItems = async (collectionId: number) => {
  // add collection here
  const items: ItemRemoveType[] = [];
  const versions: LayoutType[] = [];
  const itemCollectionRes = await ApiService.getItemCollection(collectionId);
  const collection: CollectionType = getCollection(itemCollectionRes.data);
  items.push(collection);
  console.log(itemCollectionRes.data);
  // add item here
  await mapSeries(itemCollectionRes.data.items, async (apiItem: any) => {
    const itemRes = await ApiService.getItemCopy(apiItem.id);
    const item: ItemRemoveType = getItem(itemRes.data);
    items.push(item);
    console.log(itemRes.data);
    // Add layout here
    await mapSeries(itemRes.data.versions, async (apiLayout: any) => {
      const versionRes = await ApiService.getVersion(apiLayout.id);
      //const { extra_price_modifier } = versionRes.data;
      const layout: LayoutType = getLayout(versionRes.data);
      item.versions.push(layout);
      console.log(versionRes.data);
      // add partGroup here
      await mapSeries(
        versionRes.data.part_groups,
        async (apiPartGroup: any) => {
          const partGroupRes = await ApiService.getPartGroupCopy(
            apiPartGroup.id
          );
          const partGroup: PartGroupType = getPartGroup(partGroupRes.data);
          layout.partGroups.push(partGroup);
          console.log(partGroupRes.data);
          // add part here
          await mapSeries(partGroupRes.data.parts, async (apiPart: any) => {
            const partRes = await ApiService.getPartCopy(apiPart.id);
            const part: PartType = getPart(partRes.data);
            partGroup.parts.push(part);
            console.log(partRes.data);
            // add material here
            await mapSeries(
              partRes.data.materials,
              async (apiMaterial: any) => {
                /*let priceMod = 0;
                if (
                  extra_price_modifier[apiPart.id] &&
                  extra_price_modifier[apiPart.id][apiMaterial.id]
                ) {
                  priceMod = extra_price_modifier[apiPart.id][apiMaterial.id];
                }*/
                const materialRes = await ApiService.getMaterialCopy(
                  apiMaterial.id
                );
                const material: MaterialType = getMaterial(materialRes.data);
                part.materials.push(material);
                console.log(materialRes.data);
              }
            );
          });
        }
      );
    });
  });

  return { collection /*, items, versions */ };
};

export const postLayout = async (
  name: string,
  { orderId, itemCollectionId }: { orderId: number; itemCollectionId: number }
) => {
  const itemApiResponse = await ApiService.postItem({
    name,
    order_id: orderId,
    item_collection_id: itemCollectionId,
  });
  if (itemApiResponse) {
    const version = itemApiResponse.data.versions[0];
    const layout = getLayout(version /*, itemApiResponse.data*/);
    //const item = getItem(itemApiResponse.data);
    return layout;
  }
};

export const postLayoutTree = async (
  name: string,
  { orderId, itemCollectionId }: { orderId: number; itemCollectionId: number },
  uniqueSuffix?: string
) => {
  const suffix = uniqueSuffix
    ? uniqueSuffix
    : format(new Date(), "yyyy-MM-dd HH-mm:ss");
  const itemApiResponse = await ApiService.postItem({
    name: `${name} -- ${suffix}`,
    order_id: orderId,
    item_collection_id: itemCollectionId,
  });
  if (itemApiResponse) {
    const version = itemApiResponse.data.versions[0];
    // @ts-ignore
    const partGroupApiResponse = await ApiService.postPartGroup(
      "Base",
      version.id
    );
    if (partGroupApiResponse) {
      // @ts-ignore
      const partApiResponse = await ApiService.postPart(
        "Standard Base",
        partGroupApiResponse.data.id,
        0
      );
      if (partApiResponse) {
        const item = getItem(itemApiResponse.data);
        console.log("Data passed to getItem:", itemApiResponse.data);
        const layout = getLayout(version /*, itemApiResponse.data*/);
        console.log("Data passed to getLayout:", version);
        const partGroup = getPartGroup(partGroupApiResponse.data);
        const part = getPart(partApiResponse.data);
        partGroup.parts = [part];
        layout.partGroups = [partGroup];
        item.layouts = [layout];
        return { item, layout };
      }
    }
  }
};

export const deleteItem = async (apiItemId: number) => {
  // @ts-ignore
  const itemApiResponse = await ApiService.deleteItem(apiItemId);
  return itemApiResponse;
};

export const deleteLayout = async (apiVersionId: number) => {
  // @ts-ignore
  const layoutApiResponse = await ApiService.deleteLayout(apiVersionId);
  return layoutApiResponse;
};

export const postPartGroup = async (
  name: string,
  apiVersionId: number,
  optional?: boolean
) => {
  // @ts-ignore
  const partGroupApiResponse = await ApiService.postPartGroup(
    name,
    apiVersionId,
    optional
  );
  if (partGroupApiResponse) {
    return getPartGroup(partGroupApiResponse.data);
  }
};

export const postPart = async (
  name: string,
  apiId: number,
  priceMod?: number
) => {
  // @ts-ignore
  const partApiResponse = await ApiService.postPart(name, apiId, priceMod || 0);
  if (partApiResponse) {
    return getPart(partApiResponse.data);
  }
};

export const postMaterials = async (
  part_id: number,
  materials: { name: string; image_url: string }[]
) => {
  const postSingleMaterial = async (
    part_id: number,
    { name, image_url }: { name: string; image_url: string }
  ) => {
    // @ts-ignore
    const materialApiResponse = await ApiService.postMaterial({
      name,
      part_id,
    });
    const res = await fetch(image_url);
    const blobImage = await res.blob();
    const { fields, url } = materialApiResponse.data.post_url;
    await uploadFileToS3(blobImage, url, fields);
    return materialApiResponse;
  };
  const postedMaterialsPromises = materials.map((materialData) =>
    postSingleMaterial(part_id, materialData)
  );
  return Promise.all(postedMaterialsPromises);
};

export const uploadFileToS3 = async (
  fileData: Blob,
  url: string,
  fields: { [key: string]: any }
) => {
  try {
    const uploadFormData = new FormData();
    Object.keys(fields).forEach((key) => {
      uploadFormData.append(key, fields[key]);
    });
    uploadFormData.append("file", fileData);
    // @ts-ignore
    const s3UploadResponse = await ApiService.postToS3(url, uploadFormData);
    return s3UploadResponse;
  } catch (error) {
    console.warn(error);
  }
};

export const patchItem = async (
  item: Pick<ItemRemoveType, "apiItemId" | "name">
) => {
  // @ts-ignore
  await ApiService.patchItem(item.apiItemId, {
    name: item.name,
  });
};

export const patchItemVersion = async (
  layout: Pick<LayoutType, "apiVersionId" | "basePrice">
) => {
  // @ts-ignore
  await ApiService.patchItemVersion(layout.apiVersionId, {
    base_price: layout.basePrice,
  });
};

export const patchPartGroup = async (
  partGroup: Pick<PartGroupType, "apiId" | "name" | "optional">
) => {
  // @ts-ignore
  await ApiService.patchPartGroup(partGroup.apiId, {
    name: partGroup.name,
    optional: partGroup.optional,
  });
};

export const patchPart = async (
  part: Pick<PartType, "apiId" | "name" | "priceMod">
) => {
  // @ts-ignore
  await ApiService.patchPart(part.apiId, {
    name: part.name,
    price_mod: part.priceMod,
  });
};

export const patchMaterial = async (
  part: PartType,
  material: Pick<MaterialType, "apiId" | "name" | "priceMod">
) => {
  // @ts-ignore
  await ApiService.patchMaterial(material.apiId, {
    name: material.name,
  });
  // @ts-ignore
  await ApiService.patchPartMaterialAccociation({
    partId: part.apiId,
    materialId: material.apiId,
    priceMod: material.priceMod,
  });
};

export const deletePartGroup = async (apiId: number) => {
  // @ts-ignore
  const partGroupApiResponse = await ApiService.deletePartGroup(apiId);
  return partGroupApiResponse;
};

export const deletePart = async (apiId: number) => {
  // @ts-ignore
  const partApiResponse = await ApiService.deletePart(apiId);
  return partApiResponse;
};

export const deleteMaterial = async (apiId: number) => {
  // @ts-ignore
  const materialApiResponse = await ApiService.deleteMaterial(apiId);
  return materialApiResponse;
};

export const bulkCopyMaterials = async ({
  company_id,
  origin_part_id,
  destination_part_id,
  origin_material_ids,
}: {
  company_id: number;
  origin_part_id?: number;
  destination_part_id: number;
  origin_material_ids: number[];
}) => {
  // @ts-ignore
  const partApiResponse = await ApiService.bulkCopyMaterials({
    company_id,
    destination_part_id,
    origin_material_ids,
    origin_part_id,
  });
  return partApiResponse;
};

export const postReferenceFiles = async ({
  id,
  key,
  file_name,
  fileData,
}: {
  id: number;
  key: string;
  file_name: string;
  fileData: Blob;
}) => {
  const s3UrlResponse = await ApiService.postFileLocker(id, {
    key,
    file_name,
  });
  const s3UrlData = s3UrlResponse.data;
  const { url, fields } = s3UrlData.url;
  await uploadFileToS3(fileData, url, fields);
  return s3UrlResponse;
};

export const patchCollection = async (
  collectionId: number,
  collection: Partial<CollectionType>
) => {
  // @ts-ignore
  await ApiService.patchItemCollection(collectionId, collection);
};
