import React, { Component } from "react";

class InlineTextInput extends Component {
  state = {};
  render() {
    return (
      <>
        {this.props.title && (
          <h3 className="product-title">{this.props.title}</h3>
        )}
        {this.props.showEdit && <span className="editLink">edit</span>}
        <input
          onChange={(ev) => {
            this.props.setParentValue(ev.target.value);
          }}
          defaultValue={this.props.value}
        />
      </>
    );
  }
}

export default InlineTextInput;
