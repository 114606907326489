/* eslint-disable react/no-unknown-property */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Layout, Row } from "antd";
import { SiderMenu } from "./siderMenu";
import { CommenterSection } from "../components/commenter";
import { BabylonView, BabylonViewWrapper } from "../components/babylon";
import { useSpaceContext } from "../contexts/SpaceContext";
import { Comment, ShowroomProduct } from "../types";
import { useCommentContext } from "../contexts/CommentContext";
import {
  KeyboardEventTypes,
  KeyboardInfo,
  Nullable,
  Observer,
  Vector3,
} from "@babylonjs/core";
import { useProductsContext } from "../contexts/ProductsContext";
import { CircleMenu } from "./products/circleMenu";
import { useProductsEventsHandler } from "../hooks/useProductsEventsHandler";
import { StickMesh } from "./products/StickMesh";
import { useShowroomContext } from "../contexts/ShowroomContext";
import { useCommentsApi } from "../hooks";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { useEngineContext } from "../contexts/EngineContext";
import { ProductModelWrapperList } from "./ProductsModel/ProductModelWrapperList";

const { Content } = Layout;

export const ShowroomMerchandising = () => {
  const { space, spaceUrl, spaceVersionId } = useSpaceContext();
  const { setComment, updateComment } = useCommentContext();
  // const { email } = useAppSelector((state: any) => state.profile);
  const [comments, setComments] = useState<Comment[]>([]);
  const {
    apiFetchComments,
    apiFetchComment,
    apiCreateComment,
    apiUpdateComment,
    apiRemoveCommentFiles,
    apiAddCommentFile,
  } = useCommentsApi();
  const {
    // isLoading,
    updateShowroomProductState,
    loadProductsDynamic,
    showroomProductsArray,
    focusedShowroomProduct,
  } = useProductsContext();
  const {
    onLeftClickHandler,
    onRightClickHandler,
    onCircleMenuClickHandler,
    onPointerMoveHandler,
    onLongPressClick,
    onCircleMenuToggleHandler,
    handleProductOnChange,
  } = useProductsEventsHandler();
  const { showroomGizmoMode, setShowroomGizmoMode } = useShowroomContext();
  const { scene } = useEngineContext();
  // const [keyBoardEvents, setkeyBoardEvents] =
  //   useState<Nullable<Observer<KeyboardInfo>>>(null);

  /***************** Memo ****************/
  const showSticker = useMemo(
    () =>
      (focusedShowroomProduct &&
        ["FREE_MOVE", "SMART_MOVE", "SMART_ROTATE"].includes(
          focusedShowroomProduct.mode
        )) ||
      false,
    [focusedShowroomProduct]
  );
  const keyBoardEventsRef = useRef<Nullable<Observer<KeyboardInfo>>>(null);

  useEffect(() => {
    if (scene) {
      if (keyBoardEventsRef.current) {
        scene.onKeyboardObservable.remove(keyBoardEventsRef.current);
      }
      const observable = scene.onKeyboardObservable.add(
        (kbInfo: KeyboardInfo) => {
          switch (kbInfo.type) {
            case KeyboardEventTypes.KEYDOWN:
              switch (kbInfo.event.key) {
                case "W":
                case "w":
                  setShowroomGizmoMode("POSITION");
                  break;
                case "E":
                case "e":
                  setShowroomGizmoMode("ROTATION");
                  break;
                case "Q":
                case "q":
                  setShowroomGizmoMode("SELECT");
                  break;
                case "F":
                case "f":
                  setShowroomGizmoMode("FOCUS");
                  break;
              }
              break;
          }
        }
      );
      keyBoardEventsRef.current = observable;
    }
  }, [scene, setShowroomGizmoMode]);
  useEffect(() => {
    //clean up the keyboard events when the component unmounts
    return function cleanup() {
      if (keyBoardEventsRef.current && scene) {
        scene.onKeyboardObservable.remove(keyBoardEventsRef.current);
      }
    };
  }, [scene]);

  useEffect(() => {
    if (showroomGizmoMode === "FOCUS" && scene) {
      const targetPosition = new Vector3(
        focusedShowroomProduct?.showroomProduct.placement.position.x,
        focusedShowroomProduct?.showroomProduct.placement.position.y,
        focusedShowroomProduct?.showroomProduct.placement.position.z
      );
      // @ts-ignore that
      scene.cameras[0].setTarget(targetPosition);
    }
  }, [showroomGizmoMode, scene, focusedShowroomProduct]);
  /***************************************/

  /*************** Handlers **************/
  const onSubmitCommentHandler = useCallback(
    async (commentPayload: Comment) => {
      if (!spaceVersionId || !space?.id) return;
      const _comment: Comment = { ...commentPayload, isDirty: false };
      if (_comment.id) {
        // Update comment
        const commentIndex = comments.findIndex((c) => c.id === _comment.id);
        if (commentIndex > -1) {
          const _commentResponse = await apiUpdateComment({
            spaceVersionId,
            noteTypeId: 1,
            spaceId: space.id,
            oldComment: comments[commentIndex],
            newComment: _comment,
          });

          const newComments = [...comments];
          newComments[commentIndex] = _commentResponse;
          setComment(_commentResponse);
          setComments(newComments);
        }
      } else {
        // Add new comment
        const _commentResponse = await apiCreateComment({
          spaceVersionId,
          noteTypeId: 1,
          spaceId: space.id,
          comment: _comment,
        });
        setComments((comments) => {
          return [...comments, _commentResponse];
        });
        setComment(_commentResponse);
      }
    },
    [
      apiCreateComment,
      apiUpdateComment,
      comments,
      setComment,
      space?.id,
      spaceVersionId,
    ]
  );

  const onUploadCommentFiles = useCallback(
    async (commentId: string, imagesUrl: string[]) => {
      if (imagesUrl.length) {
        const commentIndex = comments.findIndex((c) => c.id === commentId);
        if (commentIndex > -1) {
          const comment = { ...comments[commentIndex] };
          const uploadImage = async (imageUrl: string) => {
            const res = await fetch(imageUrl);
            const blobImage = (await res.blob()) as File;
            await apiAddCommentFile(
              commentId,
              "product_image",
              blobImage.name,
              blobImage
            );
          };
          await Promise.all(imagesUrl.map((url) => uploadImage(url)));
          const _commentWithoutAnnotation = await apiFetchComment(commentId);
          comment.images = _commentWithoutAnnotation.images;
          setComment(comment);
          const newComments = [...comments];
          newComments[commentIndex] = comment;
          setComments(newComments);
        }
      }
    },
    [apiAddCommentFile, apiFetchComment, comments, setComment]
  );

  const onRemoveCommentFiles = useCallback(
    async (commentId: string | number, fileIds: number[]) => {
      const commentIndex = comments.findIndex((c) => c.id === commentId);
      if (commentIndex > -1) {
        const fileIdsMap: { [key: number]: number } = {};
        fileIds.forEach((id) => {
          fileIdsMap[id] = id;
        });
        await apiRemoveCommentFiles(commentId, fileIds);
        const comment = { ...comments[commentIndex] };
        comment.images = comment.images?.filter(({ id }) => !fileIdsMap[id]);
        const newComments = [...comments];
        newComments[commentIndex] = comment;
        setComments(newComments);
        updateComment((_comment) => ({
          images: comment.images,
          isDirty: _comment.isDirty,
        }));
      }
    },
    [apiRemoveCommentFiles, comments, updateComment]
  );
  /***************************************/

  // Load comments
  useEffect(() => {
    if (spaceVersionId) {
      apiFetchComments(spaceVersionId, 2).then((result) => {
        setComments(result);
      });
    }
  }, [apiFetchComments, spaceVersionId]);

  // Load products
  useEffect(() => {
    if (scene) {
      loadProductsDynamic(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene]);

  return (
    <Layout className="layout layout-showroom layout-showroom-3d layout-showroom--merchandising">
      <Content>
        <Row className="page-first-section" wrap={false}>
          <Col className="page-first-section--left">
            <SiderMenu
              onSubmitComment={onSubmitCommentHandler}
              onChangeSelectedProduct={handleProductOnChange}
              selectedProduct={focusedShowroomProduct?.showroomProduct || null}
            />
          </Col>
          <Col flex="auto" className="babylon-col">
            {spaceUrl ? (
              <BabylonViewWrapper>
                <BabylonView
                  url={spaceUrl ? spaceUrl : ""}
                  onLeftClick={onLeftClickHandler}
                  onRightClick={onRightClickHandler}
                  onLongPressClick={onLongPressClick}
                  onPointerMove={onPointerMoveHandler}
                  onAddCommentImages={onUploadCommentFiles}
                >
                  <utilityLayerRenderer>
                    <ProductModelWrapperList
                      onChange={(_product: ShowroomProduct) =>
                        handleProductOnChange(_product)
                      }
                      focusedShowroomProduct={focusedShowroomProduct}
                      value={showroomProductsArray}
                      updateShowroomProductState={(
                        _product: ShowroomProduct
                      ) => {
                        updateShowroomProductState(_product);
                      }}
                      showroomGizmoMode={showroomGizmoMode}
                    />
                  </utilityLayerRenderer>
                  {showSticker && <StickMesh />}
                </BabylonView>
                <CircleMenu
                  value={focusedShowroomProduct}
                  onToggle={onCircleMenuToggleHandler}
                  onClick={onCircleMenuClickHandler}
                />
              </BabylonViewWrapper>
            ) : (
              <FullAreaSpinner />
            )}
          </Col>
        </Row>
        <CommenterSection
          comments={comments}
          onSubmitComment={onSubmitCommentHandler}
          onRemoveImages={onRemoveCommentFiles}
        />
      </Content>
    </Layout>
  );
};
