/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ItemVersion = {
  readonly id?: number;
  readonly created_at?: string;
  readonly updated_at?: string;
  is_active?: boolean;
  is_deleted?: boolean;
  deleted_at?: string | null;
  old_id?: number | null;
  version_number?: number;
  is_published?: boolean;
  base_price?: number;
  is_master?: boolean;
  status?: ItemVersion.status;
  item?: number | null;
  thumbnail?: string | null;
  reference_file?: any[];
  images?: Array<string>;
  files?: Array<string>;
  external_url?: string | null;
  thumbnail_post_url?: any | null;
  extra_price_modifier?: any[];
  list_order?: number | null;
  custom_request_message?: string | null;
  destination_item_version_id?: number | null;
  origin_item_version_id?: number | null;
  part_groups?: any;

  // itemResponse?: any;
  //files?: {
  //    product_image?: any[];
  //    reference_file?: any[];
  //  };
};

export namespace ItemVersion {
  export enum status {
    CREATED = "Created",
    CLIENT_REVIEW = "Client Review",
    BITREEL_REVIEW = "Bitreel Review",
    FINALIZED = "Finalized",
  }
}
