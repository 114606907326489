import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import {
  Col,
  Modal,
  Row,
  Spin,
  Upload,
  Form,
  message,
  Input,
  Button,
} from "antd";
import { cloneDeep, forEach } from "lodash";

import CloudUploadSvg from "assets/svg/CloudUpload";
import { setModalState } from "store/actions/app";
import { getParamsFromPathname } from "../../../../utils/params";
import { createItemSimple } from "store/actions/company";
import { addToItemFilelocker } from "store/actions/items";
import { patchVariant } from "store/actions/variant";
import ApiService from "../../../../services/ApiService";

const { Dragger } = Upload;

class CreateProductModal extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.uploadRef = React.createRef();
    this.submit = this.submit.bind(this);
    this.createProduct = this.createProduct.bind(this);
  }

  state = {
    isLoading: false,
  };

  createProduct(uploadData, detailValues) {
    const itemData = cloneDeep(detailValues);

    const fieldDataToPatch = {
      description: itemData.description,
      price: itemData.price,
      product_url: itemData.product_url,
    };

    itemData.order_id = this.props.orderIdInUrl;

    this.setState({ isLoading: true });

    this.props
      .createItemSimple(
        this.props.orderIdInUrl,
        itemData.name,
        this.props.companyIdInUrl
      )
      .then((itemCreated) => {
        let promises = [];
        console.log("ITEM CREATED", itemCreated);

        const reader = new FileReader();
        reader.onload = (e) => {
          const thumbnailData = e.target.result;
          promises.push(
            ApiService.postThumbnail({
              item_id: itemCreated.id,
              thumbnail: thumbnailData,
            }),
            ApiService.postItemCollectionThumbnail({
              item_collection_id: itemCreated.item_collection_id,
              thumbnail: thumbnailData,
              order_id: itemCreated.order_id,
            })
          );
        };

        if (uploadData.thumbnail) {
          reader.readAsDataURL(uploadData.thumbnail.file);
        }

        if (uploadData.files) {
          forEach(uploadData.files.fileList, (image) => {
            promises.push(
              this.props.addToItemFilelocker(
                itemCreated.id,
                image.originFileObj,
                "reference_file",
                image.name
              )
            );
          });
        }

        promises.push(
          this.props.patchVariant(
            itemCreated.versions[0].variants[0],
            fieldDataToPatch
          )
        );

        Promise.all(promises).then(() => {
          this.props.setModalState("createItemSimple", { visible: false });
          //set the loading state back to false when loading finished
          this.setState({ isLoading: false });
        });
      });
  }

  async submit() {
    try {
      const uploadData = this.uploadRef.current.getFieldsValue();
      let uploadError = false;
      let errorStr = "";

      if (uploadError) {
        message.error(errorStr);
      }
      const detailValues = await this.formRef.current.validateFields();

      if (!uploadError) {
        this.createProduct(uploadData, detailValues);
      }
    } catch (error) {
      console.warn(error);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <Modal
        style={{ top: 10 }}
        title={"Product Details"}
        open={this.props.modalState.visible}
        onOk={() => this.submit()}
        onCancel={() =>
          this.props.setModalState("createItemSimple", { visible: false })
        }
        destroyOnClose={true}
        cancelText="CANCEL"
        okText="CREATE"
        okButtonProps={{
          className: "bitreel-button-primary",
        }}
        cancelButtonProps={{
          disabled: this.state.isLoading,
          className: "bitreel-button",
        }}
        closable={!this.state.isLoading}
        width={1000}
      >
        <Spin spinning={this.state.isLoading}>
          <Button
            onClick={() => {
              this.props.setModalState("createItemSimple", { visible: false });
              this.props.setModalState("createItemAdvanced", { visible: true });
            }}
          >
            Advanced
          </Button>
          <Row align="middle" justify="space-around">
            <Col span={10}>
              <Form layout={"vertical"} ref={this.formRef}>
                <Form.Item
                  label="Product Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please type the product name!",
                    },
                  ]}
                  initialValue={this.props.itemData.name}
                >
                  <Input placeholder="Please type the product name!" />
                </Form.Item>
                <Form.Item
                  label="Product Price"
                  name="price"
                  rules={[
                    {
                      required: false,
                      message: "Please type the product price!",
                    },
                  ]}
                  initialValue={this.props.itemData.price}
                >
                  <Input placeholder="Please type the product price!" />
                </Form.Item>
                <Form.Item
                  label="Product Description"
                  name="description"
                  rules={[
                    {
                      required: false,
                      message: "Please type the product description!",
                    },
                  ]}
                  initialValue={this.props.itemData.description}
                >
                  <Input.TextArea
                    rows={
                      this.props.descriptionHeight
                        ? this.props.descriptionHeight
                        : 8
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Product URL"
                  name="product_url"
                  rules={[{ required: false }]}
                  initialValue={this.props.itemData.product_url}
                >
                  <Input.TextArea
                    rows={this.props.urlHeight ? this.props.urlHeight : 3}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col span={10}>
              <Form layout={"vertical"} ref={this.uploadRef}>
                <Form.Item
                  label="Product Thumbnail"
                  name="thumbnail"
                  rules={[{ required: true, message: "Please add Thumbnail" }]}
                  valuePropName="file"
                >
                  <Dragger
                    style={{ backgroundColor: "white" }}
                    name="thumbnail"
                    multiple={false}
                    accept=".png,.jpg"
                    beforeUpload={(file) => {
                      return false;
                    }}
                    maxCount={1}
                  >
                    <p className="ant-upload-drag-icon">
                      <CloudUploadSvg />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag thumbnail file to this area to upload
                    </p>
                  </Dragger>
                </Form.Item>
                <Form.Item
                  label="Reference Files"
                  name="files"
                  rules={[{ required: false, message: "Please add files" }]}
                  valuePropName="file"
                >
                  <Dragger
                    style={{ backgroundColor: "white" }}
                    multiple={true}
                    name="files"
                    beforeUpload={(file) => {
                      return false;
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <CloudUploadSvg />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag reference files to this area to upload
                    </p>
                  </Dragger>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (
  {
    app: {
      modalStates: { createItemSimple: modalState },
    },
  },
  ownProps
) => {
  let { orderIdInUrl, companyIdInUrl } = getParamsFromPathname(
    window.location.pathname
  );
  return {
    itemData: {},
    modalState,
    orderIdInUrl,
    companyIdInUrl,
  };
};

export default connect(mapStateToProps, {
  createItemSimple,
  patchVariant,
  setModalState,
  addToItemFilelocker,
})(withRouter(CreateProductModal));
