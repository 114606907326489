import React from "react";
import { Button, Modal, ModalProps } from "antd";

interface CommenterConfirmModalProps extends ModalProps {
  onDiscard?: () => void;
  disableSubmit?: boolean;
}

export const CommenterConfirmModal = ({
  onCancel,
  onOk,
  onDiscard,
  disableSubmit = false,
  ...rest
}: CommenterConfirmModalProps) => {
  return (
    <Modal
      {...rest}
      centered
      className="commenter-confirm-modal"
      title="Current comment has changes"
      onCancel={onCancel}
      footer={[
        <Button
          key="back"
          onClick={(e) => {
            if (onCancel) onCancel(e as any);
          }}
        >
          Cancel
        </Button>,
        <Button key="discard" danger onClick={onDiscard}>
          Discard
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={disableSubmit}
          onClick={(e) => {
            if (onOk) onOk(e as any);
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <p>What do you want to do with current comment?</p>
    </Modal>
  );
};
