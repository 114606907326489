import React, { useCallback, useEffect, useRef } from "react";
import { AbstractMesh, Color3, Nullable } from "@babylonjs/core";
import { useShowroomContext } from "../contexts/ShowroomContext";
import { useEngineContext } from "../contexts/EngineContext";

export const useMeshFocus = () => {
  const { isFocusedEngine } = useEngineContext();
  const { setShowroomModeParams } = useShowroomContext();
  const tempFocusedMeshes = useRef<{ [key: string]: AbstractMesh }>({});

  const focusMesh = useCallback(
    (pickedMesh: AbstractMesh, disabled = false) => {
      const { id } = pickedMesh;
      if (!tempFocusedMeshes.current[id]) {
        tempFocusedMeshes.current[id] = pickedMesh;
        pickedMesh.renderOverlay = true;
        pickedMesh.overlayColor = disabled ? Color3.Red() : Color3.Green();
        setShowroomModeParams((prev) => ({
          ...prev,
          hoverProduct: `hoverProduct${disabled ? "--disabled" : ""}`,
        }));
      }
    },
    [setShowroomModeParams]
  );

  const refreshFocusedMesh = useCallback(
    (pickedMesh: Nullable<AbstractMesh>) => {
      if (pickedMesh) {
        Object.values(tempFocusedMeshes.current).forEach((mesh) => {
          if (mesh.id !== pickedMesh.id) {
            mesh.renderOverlay = false;
            delete tempFocusedMeshes.current[mesh.id];
          }
        });
        // console.log(pickedMesh);
      } else {
        const meshes = Object.values(tempFocusedMeshes.current);
        if (meshes.length) {
          meshes.forEach((mesh) => {
            mesh.renderOverlay = false;
          });
          tempFocusedMeshes.current = {};
        }
      }
      if (!Object.keys(tempFocusedMeshes.current).length) {
        setShowroomModeParams((prev) => {
          const params = { ...prev };
          delete params["hoverProduct"];
          return params;
        });
      }
    },
    [setShowroomModeParams]
  );

  // Remove selected meshes on focus out
  useEffect(() => {
    if (!isFocusedEngine) {
      refreshFocusedMesh(null);
    }
  }, [isFocusedEngine, refreshFocusedMesh]);

  return { focusMesh, refreshFocusedMesh };
};
