import ApiService from "services/ApiService";
import { SET_ITEM_STATUSES } from "sharedConstants";

export const setItemStatuses = (itemStatuses) => {
  return {
    type: SET_ITEM_STATUSES,
    itemStatuses,
  };
};

export const getItemStatuses = () => async (dispatch) => {
  try {
    let res = await ApiService.getItemStatuses();
    dispatch(setItemStatuses(res.data));
  } catch (e) {
    console.warn(e);
  }
};
