import React, { Key, useCallback, useMemo } from "react";
import {
  EnvironmentOutlined,
  PlusOutlined,
  HomeOutlined,
  PictureOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Divider, Tree } from "antd";
import { ItemDropdownActions } from "./ItemDropdownActions";
import type { DataNode } from "antd/es/tree";
import { useItemsContext } from "../contexts/ItemsContext";
import { SpaceItemType } from "../types";
interface ItemsExplorerProps {
  spaceItem: SpaceItemType;
  onSelect?: (selectedKey: string) => void;
  selectedKey?: string;
}

export const ItemsExplorer = ({
  spaceItem,
  onSelect = () => {},
  selectedKey,
}: ItemsExplorerProps) => {
  const { addNewRoom } = useItemsContext();
  const treeData: DataNode[] = useMemo(
    () => [
      {
        key: spaceItem.environment.uuid,
        title: (
          <div className="item-wrapper">
            <b>Environment: </b>
            {spaceItem.environment.name}
          </div>
        ),
        icon: <PictureOutlined />,
        className: "environment-item",
      },
      ...[spaceItem].map((_spaceItem) => ({
        key: _spaceItem.uuid,
        title: (
          <div className="item-wrapper">
            <b>Space: </b>
            {_spaceItem.name}
            <ItemDropdownActions item={_spaceItem}>
              <Button size="small" icon={<MoreOutlined />} type="link" />
            </ItemDropdownActions>
          </div>
        ),
        icon: <HomeOutlined />,
        children: [
          ..._spaceItem.rooms.map((_room) => ({
            key: _room.uuid,
            title: (
              <div className="item-wrapper">
                <b>Room: </b> {_room.name}
                <ItemDropdownActions item={_room}>
                  <Button size="small" icon={<MoreOutlined />} type="link" />
                </ItemDropdownActions>
              </div>
            ),
            icon: <EnvironmentOutlined />,
          })),
          {
            key: "add-room",
            selectable: false,
            isLeaf: true,
            className: "add-room-item",
            title: (
              <Button
                type="link"
                size="small"
                onClick={() => {
                  addNewRoom();
                }}
              >
                <PlusOutlined />
                Add room
              </Button>
            ),
          },
        ],
      })),
    ],
    [addNewRoom, spaceItem]
  );

  const handleOnSelect = useCallback(
    (keys: Key[], info: any) => {
      if (keys[0]) onSelect((keys[0] as string) || "");
    },
    [onSelect]
  );

  return (
    <div className="explorer-tree">
      <h3 className="title">
        <span>SPACE ROOMS</span>
      </h3>
      {!!treeData.length && (
        <Tree
          showLine={true}
          defaultExpandAll
          selectedKeys={selectedKey ? [selectedKey] : undefined}
          showIcon={true}
          onSelect={handleOnSelect}
          treeData={treeData}
        />
      )}
    </div>
  );
};
