import React, { Component } from "react";

class AudioDisplay extends Component {
  state = {};
  render() {
    return (
      <>
        <h3 className="section-title">Audio Description</h3>
        <span className="editLink">edit</span>
      </>
    );
  }
}

export default AudioDisplay;
