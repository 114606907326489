import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import { Col, Divider, Row, Input, Button, Switch } from "antd";
import { getParamsFromPathname } from "../../../../utils/params";
import { updateCompanyInfo } from "store/actions/company";

const { TextArea } = Input;

class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.saveCompanyInfo = this.saveCompanyInfo.bind(this);
    this.handlePriceSettingChange = this.handlePriceSettingChange.bind(this);
  }

  state = {
    copyright:
      this.props.companies[
        getParamsFromPathname(window.location.pathname).companyIdInUrl
      ].copyright,
    terms:
      this.props.companies[
        getParamsFromPathname(window.location.pathname).companyIdInUrl
      ].terms_and_conditions,
    price: {
      en: this.props.companies[
        getParamsFromPathname(window.location.pathname).companyIdInUrl
      ].disable_ecomm_en,
      ca: this.props.companies[
        getParamsFromPathname(window.location.pathname).companyIdInUrl
      ].disable_ecomm_ca,
    },
  };

  componentDidMount() {
    //console.log(this.props.profile);
    // if (!auth.token && !NO_AUTH_URLS.includes(window.location.pathname)) {
    //     window.location.href = window.location.origin;
    // }
    // if (auth.token && !NO_AUTH_URLS.includes(window.location.pathname)) {
    // }
  }

  handlePriceSettingChange(type) {
    switch (type) {
      case "en":
        this.setState((prevState) => ({
          price: {
            ...prevState.price,
            en: !prevState.price.en,
          },
        }));
        break;
      case "ca":
        this.setState((prevState) => ({
          price: {
            ...prevState.price,
            ca: !prevState.price.ca,
          },
        }));
        break;
      default:
        return;
    }
  }

  saveCompanyInfo() {
    const tmpcompany = {};
    tmpcompany.copyright = this.state.copyright;
    tmpcompany.terms_and_conditions = this.state.terms;
    tmpcompany.disable_ecomm_en = this.state.price.en;
    tmpcompany.disable_ecomm_ca = this.state.price.ca;

    this.props.updateCompanyInfo(
      getParamsFromPathname(window.location.pathname).companyIdInUrl,
      tmpcompany
    );
  }

  render() {
    return (
      <div className={"screen-container"}>
        <Row justify="center" align="middle">
          <h1>Company Profile</h1>
        </Row>
        <Divider />
        <Row justify={"start"} align={"middle"}>
          <h3>Copyright Text</h3>
        </Row>
        <Row justify={"start"} align={"middle"}>
          <TextArea
            onChange={(e) =>
              this.setState({ ...this.state, copyright: e.target.value })
            }
            style={{ width: "100%" }}
            autoSize={{ minRows: 4, maxRows: 20 }}
            value={this.state.copyright}
          />
        </Row>
        <Divider />
        {this.props.profile.super_admin && (
          <div>
            <Row justify={"start"} align={"middle"}>
              <h3>Price Disabled</h3>
            </Row>
            <Row justify={"start"} align={"middle"}>
              <h4>En:</h4>
              <Switch
                checked={this.state.price.en}
                onChange={() => {
                  this.handlePriceSettingChange("en");
                }}
              />
              <h4>Ca:</h4>
              <Switch
                checked={this.state.price.ca}
                onChange={() => {
                  this.handlePriceSettingChange("ca");
                }}
              />
            </Row>
            <Divider />
          </div>
        )}
        <Row justify={"start"} align={"middle"}>
          <h3>Terms & Conditions</h3>
        </Row>
        <Row justify={"start"} align={"middle"}>
          <TextArea
            onChange={(e) =>
              this.setState({ ...this.state, terms: e.target.value })
            }
            style={{ width: "100%" }}
            autoSize={{ minRows: 8, maxRows: 20 }}
            value={this.state.terms}
          />
        </Row>
        <Row
          justify={"end"}
          align={"middle"}
          gutter={[16, 16]}
          style={{ paddingTop: 10 }}
        >
          <Col>
            <Button
              className="bitreel-button"
              onClick={() =>
                this.setState({
                  copyright:
                    this.props.companies[
                      getParamsFromPathname(window.location.pathname)
                        .companyIdInUrl
                    ].copyright,
                  terms:
                    this.props.companies[
                      getParamsFromPathname(window.location.pathname)
                        .companyIdInUrl
                    ].terms_and_conditions,
                  price: {
                    en: this.props.companies[
                      getParamsFromPathname(window.location.pathname)
                        .companyIdInUrl
                    ].disable_ecomm_en,
                    ca: this.props.companies[
                      getParamsFromPathname(window.location.pathname)
                        .companyIdInUrl
                    ].disable_ecomm_ca,
                  },
                })
              }
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              className="bitreel-button-primary"
              onClick={() => {
                this.saveCompanyInfo();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ companies, profile }) => {
  return {
    companies,
    profile,
  };
};

export default connect(mapStateToProps, {
  updateCompanyInfo,
})(withRouter(CompanyProfile));
