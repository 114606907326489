import React, { useMemo } from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import {
  DownOutlined,
  LayoutOutlined,
  AppstoreAddOutlined,
  BuildOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import { ItemType, ItemTypeLabel, SelectedItem } from "../types";

interface ActionsMenuProps {
  selectedItem: SelectedItem;
  onAdd?: (type: ItemType) => void;
  onClone?: (type: ItemType) => void;
  onDelete?: (type: ItemType) => void;
}

export const ActionsMenu = ({
  selectedItem,
  onAdd = () => {},
  onClone = () => {},
  onDelete = () => {},
}: ActionsMenuProps) => {
  const {
    type,
    data: { item, layout, partGroup, part },
  } = selectedItem;
  const { addItems, cloneItems } = useMemo(() => {
    const addItems: MenuProps["items"] = [];
    const cloneItems: MenuProps["items"] = [];

    if (type === "item") {
      addItems.push({
        label: "Add New Layout",
        key: "add-layout",
        icon: <AppstoreAddOutlined />,
        onClick: () => {
          onAdd("layout");
        },
      });
      cloneItems.push({
        label: (
          <span>
            Clone Item: (<i>{item?.name}</i>)
          </span>
        ),
        key: "clone-item",
        icon: <LayoutOutlined />,
        onClick: () => {
          onClone("item");
        },
      });
    } else if (type === "layout") {
      addItems.push({
        label: "Add New Part Group",
        key: "add-part-group",
        icon: <AppstoreAddOutlined />,
        onClick: () => {
          onAdd("partGroup");
        },
      });
      cloneItems.push({
        label: (
          <span>
            Clone Layout: (<i>{layout?.name}</i>)
          </span>
        ),
        key: "clone-layout",
        icon: <LayoutOutlined />,
        onClick: () => {
          onClone("layout");
        },
      });
    } else if (type === "partGroup") {
      addItems.push({
        label: "Add New Part",
        key: "add-part",
        icon: <BuildOutlined />,
        onClick: () => {
          onAdd("part");
        },
      });
      cloneItems.push({
        label: (
          <span>
            Clone Part Group: (<i>{partGroup?.name}</i>)
          </span>
        ),
        key: "clone-part-group",
        icon: <AppstoreAddOutlined />,
        onClick: () => {
          onClone("partGroup");
        },
      });
    } else if (type === "part") {
      addItems.push({
        label: "Add New Material",
        key: "add-material",
        icon: <BgColorsOutlined />,
        onClick: () => {
          onAdd("material");
        },
      });
      cloneItems.push({
        label: (
          <span>
            Clone Part: (<i>{part?.name}</i>)
          </span>
        ),
        key: "clone-part",
        icon: <BuildOutlined />,
        onClick: () => {
          onClone("part");
        },
      });
    }
    return { addItems, cloneItems };
  }, [
    item?.name,
    layout?.name,
    onAdd,
    onClone,
    part?.name,
    partGroup?.name,
    type,
  ]);

  return (
    <div className="actions-menu">
      {!!addItems.length && (
        <Dropdown menu={{ items: addItems }}>
          <Button size="small">
            <Space>
              Add
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )}
      {type !== "material" && type !== "layout" && (
        <Dropdown menu={{ items: cloneItems }}>
          <Button size="small">
            <Space>
              Clone
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )}

      <Button
        danger
        size="small"
        onClick={() => {
          onDelete(type);
        }}
      >
        Delete {ItemTypeLabel[type]}
      </Button>
    </div>
  );
};
