export const validateMatch = (str, strcp) => {
  if (!strcp || strcp === str) {
    return Promise.resolve();
  }

  return Promise.reject(
    new Error("The two passwords that you entered do not match!")
  );
};

export const validateMinimumLength = (value) => {
  if (!value || value.length >= 8) {
    return Promise.resolve();
  }

  return Promise.reject(
    new Error("The password must has a minimum length of 8 characters!")
  );
};

export const validateContainNumber = (value) => {
  if (!value || /\d/.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject(new Error("The password must contain a number!"));
};

export const validateSpecialCharacter = (value) => {
  const special = /[^A-Za-z0-9]/;
  if (!value || special.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject(
    new Error("The password must contain a special character!")
  );
};

export const validateUpperCase = (value) => {
  if (!value || /[A-Z]/.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject(
    new Error("The password must contain an upper case character!")
  );
};

export const validateLowerCase = (value) => {
  if (!value || /[a-z]/.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject(
    new Error("The password must contain a lower case character!")
  );
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase())) return Promise.resolve();
  return Promise.reject(
    new Error("Email addresses not valid, please check your format!")
  );
};
