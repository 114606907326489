import React from "react";
import { Checkbox } from "antd";
import { ColumnType } from "antd/es/table/interface";
import { CheckboxOptionType } from "antd/es/checkbox/Group";

export const useCommenterTableMultiSelect = <DataType,>() => {
  type DataIndex = keyof DataType;

  const getColumnMultiselectProps = (
    dataIndex: DataIndex,
    options: CheckboxOptionType[]
  ): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Checkbox.Group
          className="multiselect-filter"
          options={options}
          value={selectedKeys}
          onChange={(values) => {
            setSelectedKeys(values as string[]);
            confirm({ closeDropdown: false });
          }}
        />
      </div>
    ),
    onFilter: (value: any, record) => {
      return record[dataIndex] === value;
    },
  });

  return {
    getColumnMultiselectProps,
  };
};
