import { Children, useCallback } from "react";
import { notification } from "antd";
import { useCollectionContext } from "../contexts/CollectionContext";
import { useUIContext } from "../contexts/UIContext";
import { ProductsService } from "servicesNew/api";
import { postReferenceFiles } from "../../../productNew/advancedProductCreatorNew/utils/api";
import {
  AtLeast,
  ItemModelType,
  ItemVersionModelType,
  PartModelType,
  PartGroup,
} from "../types";

// import { createItemVersionTree } from "../utils/api";

export const useItemVersions = () => {
  const { updateItemByUUID } = useCollectionContext();
  const { taskWrapper } = useUIContext();

  const updateItemVersion = useCallback(
    async (
      itemVersion: AtLeast<
        ItemVersionModelType,
        /*"_uuid" | "id" |*/ "base_price" | "custom_request_message"
      >
    ) => {
      if (
        typeof itemVersion.id === "number" &&
        typeof itemVersion._uuid === "string"
      ) {
        const payload1: any = {
          base_price: itemVersion.base_price,
        };
        const payload2: any = {
          // Only add custom_request_message if it is not null
          ...(itemVersion.custom_request_message !== null && {
            custom_request_message: itemVersion.custom_request_message,
          }),
        };

        // Function to check if an object is empty
        const isEmpty = (obj: any) => Object.keys(obj).length === 0;
        // Only send API requests if the payload objects are not empty
        if (!isEmpty(payload1)) {
          await ProductsService.productsItemVersionsUpdate({
            id: itemVersion.id,
            data: payload1,
          });
        }

        if (!isEmpty(payload2)) {
          await ProductsService.productsItemVersionsUpdate({
            id: itemVersion.id,
            data: payload2,
          });
        }
        updateItemByUUID(itemVersion._uuid, () => ({
          ...itemVersion,
          base_price: itemVersion.base_price,
        }));
        notification.success({
          message: "ItemVersion successfully updated!",
        });
      } else {
        console.error("Missing itemVersion id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  /*
  const addItemVersion = useCallback(
    async (item: ItemModelType) => {
      const itemVersionTree = await createItemVersionTree({ itemId: item.id });
      updateItemByUUID(item._uuid, (_item) => {
        (_item as ItemModelType).childrens.push(itemVersionTree);
        return _item;
      });
      notification.success({
        message: "Item Version successfully created!",
      });
    },
    [updateItemByUUID]
  );
  */

  const removeItemVersion = useCallback(
    async ({ itemVersion }: { itemVersion: ItemVersionModelType }) => {
      console.log("itemVersion", itemVersion);

      function extractIdsAndUuids(partGroup: PartGroup): {
        firstLevel: { id: number; _uuid: string }[];
        secondLevel: { id: number; _uuid: string }[];
        thirdLevel: { id: number; _uuid: string }[];
      } {
        // Initialize the result object with empty arrays
        const result = {
          firstLevel: [] as { id: number; _uuid: string }[],
          secondLevel: [] as { id: number; _uuid: string }[],
          thirdLevel: [] as { id: number; _uuid: string }[],
        };

        // Helper function to traverse the part group hierarchy
        const traverse = (children: any[], level: number): void => {
          if (level >= 3) return; // Stop at the third level

          // Define the appropriate result array based on the level
          const levelKey =
            level === 0
              ? "firstLevel"
              : level === 1
              ? "secondLevel"
              : "thirdLevel";

          // Iterate over children at the current level
          for (const child of children) {
            // Add ID and UUID to the appropriate result array
            result[levelKey].push({ id: child.id, _uuid: child._uuid });

            // Recursively traverse child children
            if (child.childrens) {
              traverse(child.childrens, level + 1);
            }
          }
        };

        // Start traversal from the part group children at level 0
        traverse(partGroup.childrens, 0);

        return result;
      }

      const extractedData = extractIdsAndUuids(itemVersion);

      // remove mesh material

      // Function to extract the ids for partAssociation and price mod
      const getMeshMaterialData = (itemVersion: any) => {
        const materialAssociationsData: {
          part_id: number;
          meshMaterial_id: number;
          material_association_ids: number[];
        }[] = [];
        const priceModData: {
          part_id: number;
          meshMaterial_id: number;
          price_mod: number;
        }[] = [];

        itemVersion.childrens?.forEach((child: any) => {
          child.childrens?.forEach((part: any) => {
            part.childrens?.forEach((meshMaterial: any) => {
              const meshMaterialId = meshMaterial.id;

              if (
                meshMaterial.material_associations &&
                meshMaterial.material_associations.length > 0
              ) {
                const materialAssociationIds =
                  meshMaterial.material_associations.map(
                    (assoc: any) => assoc.id
                  );

                materialAssociationsData.push({
                  part_id: part.id,
                  meshMaterial_id: meshMaterialId,
                  material_association_ids: materialAssociationIds,
                });
              }

              if (meshMaterial.price_mod > 0) {
                priceModData.push({
                  part_id: part.id,
                  meshMaterial_id: meshMaterialId,
                  price_mod: meshMaterial.price_mod,
                });
              }
            });
          });
        });

        return {
          materialAssociationsData,
          priceModData,
        };
      };

      const { materialAssociationsData, priceModData } =
        getMeshMaterialData(itemVersion);

      // Api call for deleting price modifier
      for (const { part_id, meshMaterial_id } of priceModData) {
        if (part_id !== undefined && meshMaterial_id !== undefined) {
          await ProductsService.productsPartMaterialModifierDelete({
            partId: part_id,
            materialId: meshMaterial_id,
          });
        }
      }
      // Api call for deleting material associaiton
      for (const {
        meshMaterial_id: meshMaterialId,
        material_association_ids: materialAssociationIds,
      } of materialAssociationsData) {
        if (materialAssociationIds.length > 0) {
          for (const id of materialAssociationIds) {
            await ProductsService.productsMeshMaterialAssociationDelete({
              material_id: meshMaterialId,
              material_association_group_id: id,
            });
          }
        }
      }
      // Api call for deleting materials
      for (const { id, _uuid } of extractedData.thirdLevel) {
        if (id !== undefined && _uuid !== undefined) {
          await ProductsService.productsMeshMaterialsDelete({ id });

          updateItemByUUID(_uuid, (_meshMaterial, _part) => {
            (_part as PartModelType).childrens = (
              _part as PartModelType
            ).childrens.filter(
              ({ _uuid: existingUuid }) => existingUuid !== _uuid
            );
            return {};
          });
        }
      }

      // Remove parts

      // const getPartAndAssociationGroupIds = (itemVersion: any) => {
      //   const partsWithAssociations: {
      //     partId: number;
      //     partAssociationGroupIds: number[];
      //   }[] = [];

      //   // Iterate over the children of the itemVersion object
      //   itemVersion.childrens?.forEach((child: any) => {
      //     // Iterate over the children of each child
      //     child.childrens?.forEach((part: any) => {
      //       // Check if the part has any part_association_groups
      //       if (
      //         part.part_association_groups &&
      //         part.part_association_groups.length > 0
      //       ) {
      //         // Get the ids from the part_association_groups array
      //         const partAssociationGroupIds = part.part_association_groups.map(
      //           (group: any) => group.id
      //         );

      //         // Add the part's id and part_association_group ids to the array
      //         partsWithAssociations.push({
      //           partId: part.id,
      //           partAssociationGroupIds: partAssociationGroupIds,
      //         });
      //       }
      //     });
      //   });

      //   return partsWithAssociations;
      // };

      // // Usage example:
      // const partsWithAssociations = getPartAndAssociationGroupIds(itemVersion);
      // console.log("Parts with association groups:", partsWithAssociations);
      const deletePartAssociations = async (itemVersion: any) => {
        // Iterate over the children of the itemVersion object
        for (const child of itemVersion.childrens || []) {
          // Iterate over the children of each child
          for (const part of child.childrens || []) {
            // Check if the part has any part_association_groups
            if (
              part.part_association_groups &&
              part.part_association_groups.length > 0
            ) {
              // Iterate over each part_association_group in part_association_groups
              for (const associationGroup of part.part_association_groups) {
                // Call the API to delete the part association
                await ProductsService.productsPartAssociationDelete({
                  part_id: part.id,
                  part_association_group_id: associationGroup.id,
                });
              }
            }
          }
        }
      };

      // Usage example:
      await deletePartAssociations(itemVersion);

      // return;

      for (const child of extractedData.secondLevel) {
        if (child.id !== undefined && child._uuid !== undefined) {
          await ProductsService.productsPartsDelete({ id: child.id });

          updateItemByUUID(child._uuid, (_part, _partGroup) => {
            (_partGroup as ItemVersionModelType).childrens = (
              _partGroup as ItemVersionModelType
            ).childrens.filter(({ _uuid }) => _uuid !== child._uuid);
            return {};
          });
        }
      }

      // Remove part group

      for (const child of extractedData.firstLevel) {
        if (child.id !== undefined && child._uuid !== undefined) {
          await ProductsService.productsPartGroupsDelete({ id: child.id });

          updateItemByUUID(child._uuid, (_partGroup, _itemVersion) => {
            (_itemVersion as ItemVersionModelType).childrens = (
              _itemVersion as ItemVersionModelType
            ).childrens.filter(({ _uuid }) => _uuid !== child._uuid);
            return {};
          });
        }
      }

      await ProductsService.productsItemVersionsDelete({
        id: itemVersion.id,
      });
      updateItemByUUID(itemVersion._uuid, (_itemVersion, _item) => {
        (_item as ItemModelType).childrens = (
          _item as ItemModelType
        ).childrens.filter(({ _uuid }) => _uuid !== itemVersion._uuid);
        return {};
      });
      notification.success({
        message: "Item Version successfully removed!",
      });
    },
    [updateItemByUUID]
  );

  // const addLayoutTree = useCallback(
  //   async (
  //     name: string,
  //     {
  //       orderId,
  //       itemCollectionId,
  //     }: { orderId: number; itemCollectionId: number }
  //   ) => {
  //     const layout = await postLayoutTree(name, {
  //       orderId,
  //       itemCollectionId,
  //     });
  //     if (layout) {
  //       setLayouts((prevLayouts) => [...prevLayouts, layout]);
  //       setSelectedKey(layout.uuid);
  //       notification.success({
  //         message: "Layout successfully created!",
  //       });
  //     }
  //   },
  //   [setLayouts, setSelectedKey]
  // );

  const addReferenceFiles = useCallback(
    async ({
      itemVersion,
      fileData,
      fileName,
    }: {
      itemVersion: ItemVersionModelType;
      fileData: Blob;
      fileName: string;
    }) => {
      const response = await postReferenceFiles({
        id: itemVersion.id,
        fileData,
        key: "reference_file",
        file_name: fileName,
      });
      const {
        id,
        url: {
          url: baseUrl,
          fields: { key, AWSAccessKeyId, signature },
        },
      } = response.data;
      // const url = `${baseUrl}${key}?AWSAccessKeyId=${AWSAccessKeyId}&Signature=${signature}`;
      const filenameSplit = key.split("/");
      const file_name = filenameSplit[filenameSplit.length - 1];
      const referenceFile = { id, url: "", file_name };
      updateItemByUUID(itemVersion._uuid, (_itemVersion) => {
        (_itemVersion as ItemVersionModelType).reference_file.push(
          referenceFile
        );
        return _itemVersion;
      });
      return referenceFile;
    },
    [updateItemByUUID]
  );

  // return {
  //   // addLayoutTree: taskWrapper(addLayoutTree),
  //   // updateLayout: taskWrapper(updateLayout),
  //   // addReferenceFiles: taskWrapper(addReferenceFiles),
  // };
  return {
    updateItemVersion: taskWrapper(updateItemVersion),
    //addItemVersion: taskWrapper(addItemVersion),
    removeItemVersion: taskWrapper(removeItemVersion),
    //addReferenceFiles: (arg1?: any, arg2?: any) => {},
    addReferenceFiles: taskWrapper(addReferenceFiles),
  };
};
