import {
  SET_LIBRARY_MATERIALS,
  UPDATE_LIBRARY_MATERIAL,
  SET_LIBRARY_MATERIAL_CATEGORIES,
  UPDATE_LIBRARY_MATERIAL_CATEGORY,
} from "sharedConstants";
import produce from "immer";

const INITIAL_STATE = {
  materials: [],
  materialCategories: [],
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_LIBRARY_MATERIALS:
      // Update the materials object within the draft state
      draft.materials = action.materials;
      //draft.materials = { ...draft.materials, ...action.materials };
      break;

    case UPDATE_LIBRARY_MATERIAL:
      // Update a specific material within the materials object
      draft.materials[action.materialData.id] = action.materialData;
      break;

    case SET_LIBRARY_MATERIAL_CATEGORIES:
      // Update the entire materialCategories object within the draft state
      draft.materialCategories = action.materialCategory;
      break;

    case UPDATE_LIBRARY_MATERIAL_CATEGORY:
      // Update a specific material category within the materialCategories object
      draft.materialCategories[action.materialCategoryData.id] =
        action.materialCategoryData;
      break;
    //if (draft.materialCategories && action.materialCategoryData.id) {
    //  draft.materialCategories[action.materialCategoryData.id] =
    //    action.materialCategoryData;
    //}
    //break;

    // No default case needed; if an action type doesn't match, nothing happens
  }

  // No need to return anything; Immer automatically takes care of updating the state
}, INITIAL_STATE);
