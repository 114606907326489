import React, { Component } from "react";
import "./textArea.scss";

class URLDisplay extends Component {
  state = {};
  render() {
    return (
      <div className={`${this.props.bold ? "bold-text" : ""}`}>
        <h3 className="section-title">Product URL</h3>
        <span className="editLink">edit</span>
        <div>
          <a href={this.props.value}>{this.props.value}</a>
        </div>
      </div>
    );
  }
}

export default URLDisplay;
