import axios from "axios";
import { BACKEND_URL, POST_METHOD, AWS_SQS } from "sharedConstants";

const postShellFileLocker = async function (fileLockerData) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/shell_note_file_locker/${fileLockerData.shellNoteId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      key: fileLockerData.key,
      file_name: fileLockerData.fileName,
    },
  });
  return response;
};

const postFileLocker = async function (itemId, fileLockerData) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/file_locker/${itemId}`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      key: fileLockerData.key,
      file_name: fileLockerData.file_name,
    },
  });
  return response;
};

const postItemVersionQWERTY = async function (itemId) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/item_versions`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      item_id: itemId,
    },
  });
  return response;
};

const postToS3 = async function (url, formData) {
  const response = await axios({
    method: POST_METHOD,
    url: url,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

const generateFbx = async function (data) {
  const response = await axios({
    method: POST_METHOD,
    url: AWS_SQS,
    data: data,
  });
  return response;
};

const checkForFbx = async (data) => {
  const response = await axios({
    method: POST_METHOD,
    url: AWS_SQS,
    data: data,
  });
  return response;
};

export {
  postFileLocker,
  postShellFileLocker,
  postItemVersionQWERTY,
  postToS3,
  generateFbx,
  checkForFbx,
};
