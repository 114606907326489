import React, { useState, useMemo, useCallback, useEffect } from "react";
import _ from "lodash";
import ApiService from "services/ApiService";
// import sleep from "sleep-promise";
import {
  PromiseQueue,
  Tasks,
  concurrencySeries,
  concurrencySeriesDynamic,
} from "utils";
import { Product, ProductPlacement, ShowroomProduct } from "../types";
import {
  getApiProductPlacement,
  getProductsPlacement,
  getProduct,
  getProductPlacement,
} from "../utils/dataMap";
import { useSpaceContext } from "../contexts/SpaceContext";
import { ItemCollectionConfigurationPublic } from "services/types";
import { useShowroomContext } from "../contexts/ShowroomContext";
import sleep from "sleep-promise";

export const useProductsApi = () => {
  /************* Constants ***************/
  // Keep all requests in queue, initialise once
  const promiseQueue = useMemo(() => new PromiseQueue(), []);
  const TasksManager = useMemo(() => new Tasks(), []);
  /***************************************/

  /*************** Hooks *****************/
  const { space, spaceVersionId } = useSpaceContext();
  const { showroom } = useShowroomContext();
  const showroomShell = useMemo(() => {
    if (showroom && space?.id && spaceVersionId) {
      return showroom.shells[space.id].shell_versions[spaceVersionId]
        .showroom_shell;
    }
  }, [showroom, space?.id, spaceVersionId]);
  /***************************************/

  /*************** State *****************/
  const [loading, setLoading] = useState<boolean>(false);
  /***************************************/

  /***************** API *****************/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiAddProduct = useCallback(
    TasksManager.taskWrapper(
      async (
        _product: Product,
        _placementApi: { [key: string]: number | undefined }
      ) => {
        // @ts-ignore
        const { data } = await ApiService.addShowroomShellVariantSingle(
          showroomShell?.id,
          _product.id,
          _placementApi
        );
        if (showroom && space && data?.variant?.default_parts.length) {
          // if (!data.variant?.clickable) {
          //   // @ts-ignore
          //   await ApiService.updateVariant(_product.id, { clickable: true });
          // }

          const { showroom_shell_variants } = data.showroom_shell;
          const variantData = showroom_shell_variants.find(
            (variant: any) => data.id === variant.id
          );
          data.variant.default_variant = {
            default_parts: data.variant.default_parts,
          };
          variantData.item_collection_id = data.variant.item_collection_id;
          const showroomProduct: ShowroomProduct = {
            product: getProduct(data.variant, {
              companyId: showroom.company_id,
              orderId: space.order_id,
            }),
            placement: getProductPlacement(variantData),
          };
          return showroomProduct;
        }
        return null;
      }
    ),
    [showroomShell?.id]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiUpdateProduct = useCallback(
    TasksManager.taskWrapper(async (_product: ShowroomProduct) => {
      // @ts-ignore
      const { data } = await ApiService.updateShowroomShellVariantSingle(
        _product.placement.id,
        getApiProductPlacement(_product.placement)
      );
      return data;
    }),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiDeleteProduct = useCallback(
    TasksManager.taskWrapper(async (_placement: ProductPlacement) => {
      // @ts-ignore
      const { data } = await ApiService.deleteShowroomShellVariantSingle(
        _placement.id
      );
      return data;
    }),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiFetchProductsPositions = useCallback(
    TasksManager.taskWrapper(async (_showroomShellId: string | number) => {
      // @ts-ignore
      const { data } = await ApiService.getShowroomShellVariantBulk(
        _showroomShellId
      );
      return data;
    }),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiFetchProduct = useCallback(
    TasksManager.taskWrapper(async (_placement: ProductPlacement) => {
      // @ts-ignore
      const { data } = await ApiService.getItemCollectionConfigurationsPublic(
        _placement.itemCollectionId,
        { defaultVariant: _placement.variantId }
      );
      return data as ItemCollectionConfigurationPublic;
    }),
    []
  );

  const apiFetchAllProductsDynamic = useCallback(
    async ({
      callbackSortPositions,
      callbackBulk,
      concurrency = 3,
    }: {
      callbackSortPositions: (
        placements: ProductPlacement[],
        index: number
      ) => ProductPlacement[] | void;
      callbackBulk: (
        showroomProducts: ShowroomProduct[],
        index: number
      ) => void;
      concurrency?: number;
    }) => {
      if (showroom && space && showroomShell?.id) {
        const data = await apiFetchProductsPositions(showroomShell.id);
        const placements = getProductsPlacement(data);
        let index = -1;
        const _showroomProducts: { [key: string]: ShowroomProduct } = {};
        let iterationsLeft = [...placements];
        await concurrencySeriesDynamic<ProductPlacement, ShowroomProduct>({
          iterable: () => {
            // Order iterations here
            index++;
            const iterations =
              callbackSortPositions(iterationsLeft, index) || [];
            iterationsLeft = iterations.slice(concurrency, iterations.length);
            return iterations.slice(0, concurrency);
          },
          cb: async (placement: ProductPlacement) => {
            const _productApi = await apiFetchProduct(placement);
            const _product = _productApi
              ? getProduct(_productApi, {
                  companyId: showroom.company_id,
                  orderId: space.order_id,
                })
              : null;
            if (_product) {
              _showroomProducts[placement.id] = {
                product: _product,
                placement,
              };
            }
            return _showroomProducts[placement.id];
          },
          cbBatch: async (_showroomProductsArray) => {
            if (callbackBulk) {
              callbackBulk(_showroomProductsArray, index);
            }
            await sleep(1000);
          },
        });
        return _showroomProducts;
      }
    },
    [
      apiFetchProduct,
      apiFetchProductsPositions,
      showroom,
      showroomShell?.id,
      space,
    ]
  );
  const apiFetchAllProducts = useCallback(
    async ({
      cbAfterFetchPositions,
      cbAfterConcurrencyFetch,
      concurrency = 3,
    }: {
      cbAfterFetchPositions?: (
        placements: ProductPlacement[]
      ) => ProductPlacement[] | void;
      cbAfterConcurrencyFetch?: (showroomProducts: ShowroomProduct[]) => void;
      concurrency?: number;
    }) => {
      if (space && showroom && showroomShell?.id) {
        const data = await apiFetchProductsPositions(showroomShell.id);
        let placements = getProductsPlacement(data);
        if (cbAfterFetchPositions) {
          // Order product purpose, so they be loaded by order
          const newPlacements = cbAfterFetchPositions(placements);
          if (newPlacements) placements = newPlacements;
        }
        const _showroomProducts: { [key: string]: ShowroomProduct } = {};
        await concurrencySeries<ProductPlacement, ShowroomProduct>(
          placements,
          {
            cb: async (placement: ProductPlacement) => {
              const _productApi = await apiFetchProduct(placement);
              const _product = _productApi
                ? getProduct(_productApi, {
                    companyId: showroom?.company_id,
                    orderId: space?.order_id,
                  })
                : null;
              if (_product) {
                _showroomProducts[placement.id] = {
                  product: _product,
                  placement,
                };
              }
              return _showroomProducts[placement.id];
            },
            cbBatch: async (_showroomProductsArray) => {
              if (cbAfterConcurrencyFetch) {
                cbAfterConcurrencyFetch(_showroomProductsArray);
              }
              // await sleep(1000);
            },
          },
          concurrency
        );
        return _showroomProducts;
      }
    },
    [
      apiFetchProduct,
      apiFetchProductsPositions,
      showroom,
      showroomShell?.id,
      space,
    ]
  );
  const apiUpdateProductPositionQueue = useCallback(
    async (_product: ShowroomProduct, fnCallback?: (data: any) => void) => {
      return new Promise((resolve) => {
        promiseQueue.createCancellablePromise(
          async () => {
            const data = await apiUpdateProduct(_product);
            return data;
          },
          (data: any) => {
            resolve(data);
            if (fnCallback) fnCallback(data);
          }
        );
      });
    },
    [apiUpdateProduct, promiseQueue]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const __apiUpdateProductPositionQueueDebounced = useCallback(
    _.debounce(apiUpdateProductPositionQueue, 1000),
    [promiseQueue]
  );
  const apiUpdateProductPositionQueueDebounced = useCallback(
    async (_product: ShowroomProduct) => {
      return new Promise((resolve) => {
        __apiUpdateProductPositionQueueDebounced(_product, (data) => {
          resolve(data);
        });
      });
    },
    [__apiUpdateProductPositionQueueDebounced]
  );
  /***************************************/

  // Global loading
  useEffect(() => {
    TasksManager.on("start-task", (length) => {
      setLoading(!!length);
    });
    TasksManager.on("end-task", (length) => {
      setLoading(!!length);
    });
  }, [TasksManager, setLoading]);

  return {
    loading,
    apiAddProduct,
    apiUpdateProduct,
    apiDeleteProduct,
    apiFetchProductsPositions,
    apiFetchProduct,
    apiFetchAllProducts,
    apiFetchAllProductsDynamic,
    apiUpdateProductPositionQueue,
    apiUpdateProductPositionQueueDebounced,
  };
};
