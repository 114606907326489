/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from "react";
import { Vector3, Camera, ArcRotateCamera } from "@babylonjs/core";
import { useCanvas, useScene } from "react-babylonjs";
import { CameraActionsType } from "./types";

interface BabylonCamera2DProps {
  cameraRef?: React.MutableRefObject<CameraActionsType | null>;
}

export const BabylonCamera2D = ({
  cameraRef = { current: null },
}: BabylonCamera2DProps) => {
  const arcCameraRef = useRef<ArcRotateCamera | null>(null);
  const canvas = useCanvas();
  const scene = useScene();
  const [oldRadius, setOldRadius] = useState<number>(0);
  const setOrthoCameraTopBottom = (camera: ArcRotateCamera, ratio: number) => {
    camera.orthoTop = (camera.orthoRight || 0) * ratio;
    camera.orthoBottom = (camera.orthoLeft || 0) * ratio;
  };

  useEffect(() => {
    let observerHandler: any = null;
    const camera = arcCameraRef.current;
    if (camera && scene) {
      observerHandler = scene?.onBeforeRenderObservable.add(() => {
        if (oldRadius !== camera.radius) {
          const radiusChangeRatio = oldRadius ? camera.radius / oldRadius : 1;
          camera.orthoLeft = (camera.orthoLeft || 0) * radiusChangeRatio;
          camera.orthoRight = (camera.orthoRight || 0) * radiusChangeRatio;
          setOldRadius(camera.radius);
          const ratio =
            (canvas as HTMLCanvasElement).height /
            (canvas as HTMLCanvasElement).width;
          setOrthoCameraTopBottom(camera, ratio);
        }
      });
    }
    return () => {
      if (observerHandler) {
        scene?.onBeforeRenderObservable.remove(observerHandler);
      }
    };
  }, [canvas, oldRadius, scene]);

  useEffect(() => {
    cameraRef.current = {
      zoomIn: () => {
        if (arcCameraRef.current) {
          arcCameraRef.current.radius -= 1;
        }
      },
      zoomOut: () => {
        if (arcCameraRef.current) {
          arcCameraRef.current.radius += 1;
        }
      },
      zoomReset: () => {
        if (arcCameraRef.current) {
          arcCameraRef.current.radius = 60;
        }
      },
    };
  }, [cameraRef]);

  return (
    <arcRotateCamera
      name="camera-2d"
      target={Vector3.Zero()}
      alpha={Math.PI / 2}
      beta={Math.PI / 4}
      radius={60}
      orthoTop={50}
      orthoRight={50}
      orthoBottom={-50}
      orthoLeft={-50}
      minZ={0.01}
      wheelDeltaPercentage={0.01}
      upperBetaLimit={0}
      lowerBetaLimit={0}
      mode={Camera.ORTHOGRAPHIC_CAMERA}
      ref={arcCameraRef}
    />
  );
};
