import { SET_REFRESH, SET_TOKEN } from "sharedConstants";

import ApiService from "services/ApiService";
import AmplifyService from "services/AmplifyService";
import { message } from "antd";
import { setAppIsLoadingData } from "./app";

export const setToken = (token = null) => {
  return {
    type: SET_TOKEN,
    token,
  };
};

export const setRefresh = (refresh) => {
  return {
    type: SET_REFRESH,
    refresh,
  };
};

export const userLogin = (user) => async (dispatch) => {
  try {
    await dispatch(setAppIsLoadingData(true));
    const getUserToken = await AmplifyService.userSignIn(user);
    ApiService.setToken(getUserToken.signInUserSession.accessToken.jwtToken);
    await dispatch(
      setToken(getUserToken.signInUserSession.accessToken.jwtToken)
    );
    await dispatch(
      setRefresh(getUserToken.signInUserSession.refreshToken.token)
    );
    return getUserToken;
  } catch (error) {
    message.error(`Login failed: ${error.message}`);
    console.log(error);
  } finally {
    dispatch(setAppIsLoadingData(false));
  }
};

export const refreshToken = (token, refresh) => async (dispatch) => {
  try {
    const getNewToken = await ApiService.postRefresh(token, refresh);
    dispatch(setToken(getNewToken.data));
    return getNewToken.data;
  } catch (error) {
    console.warn(error);
  }
};
