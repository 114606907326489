import React, { useState } from "react";
import { notification } from "antd";
import { Modal, Card, Checkbox, Spin, Form, Input, Select } from "antd";
import {
  PartGroupModel,
  PartModel,
  ProductsService,
  MeshMaterialModel,
} from "servicesNew/api";
import {
  PartModelType,
  ItemVersionModelType,
  PartGroupModelType,
  SelectedItem,
  MeshMaterialModelType,
} from "../../../productNew/advancedProductCreatorNew/types";
import { useParts } from "../hooks";
import { useMeshMaterials } from "../hooks";

interface CreateMeshMaterialAssociationModalProps {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: () => any;
  itemVersionData?: any; // Replace 'any' with your actual itemVersionData type
  selectedPartId?: number;
  selectedPartsData: any;
  setSelectedPartsData: any;
  //materialData?: MeshMaterialModelType;
}
interface Part {
  id: number;
  name: string;
  imageUrl: string;
  // Add other properties as needed for the Part data structure
}
interface MeshMaterial {
  id: number;
  name: string;
  imageUrl: string;
  // Add other properties from meshMaterial as needed
}
export const PartMaterialAssociation: React.FC<
  CreateMeshMaterialAssociationModalProps
> = ({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
  itemVersionData,
  selectedPartsData,
  setSelectedPartsData,
  // selectedPartGroupId,
}) => {
  const [selectedParts, setSelectedParts] = useState<Record<string, boolean>>(
    {}
  );
  // const [selectedPartsData, setSelectedPartsData] = useState<Part[]>([]);
  const { createMeshMaterialAssociationGroup, createMeshMaterialAssociation } =
    useMeshMaterials();
  const [form] = Form.useForm();
  const { createPartAssociationGroup, createPartAssociation } = useParts();
  const part = itemVersionData as PartModelType;
  const partData = part as PartModelType;
  const meshMaterial = itemVersionData as MeshMaterialModelType;
  const materialData = meshMaterial as MeshMaterialModelType;

  const itemVersionId = itemVersionData?.data?.itemVersion?.id;
  const [selectedMeshMaterials, setSelectedMeshMaterials] = useState<
    Record<string, boolean>
  >({});

  const handleOnSelectPart = (
    partId: number,
    isSelected: boolean,
    part: any
  ) => {
    console.log(`Selecting part: ${partId}, Selected state: ${isSelected}`);
    setSelectedParts((prevSelected) => ({
      ...prevSelected,
      [partId]: isSelected,
    }));
    if (isSelected) {
      // Add selected part to selectedPartsData if it's not already included
      if (
        !selectedPartsData.some(
          (selectedPart: any) => selectedPart.id === partId
        )
      ) {
        setSelectedPartsData((prevSelected: any) => [...prevSelected, part]);
      }
    } else {
      // Remove selected part from selectedPartsData
      setSelectedPartsData((prevSelected: any) =>
        prevSelected.filter((selectedPart: any) => selectedPart.id !== partId)
      );
    }
  };

  // Function to match meshMaterial name to parts and extract corresponding meshMaterial objects

  const extractMeshMaterials = (parts: any[]): MeshMaterial[][] => {
    const meshMaterialsMap: Map<string, MeshMaterial[]> = new Map();

    // Populate meshMaterialsMap with meshMaterials grouped by name
    parts.forEach((part) => {
      if (part.childrens && Array.isArray(part.childrens)) {
        part.childrens.forEach((meshMaterial: any) => {
          const { name, id, imageUrl } = meshMaterial;

          if (!meshMaterialsMap.has(name)) {
            meshMaterialsMap.set(name, []);
          }

          meshMaterialsMap.get(name)?.push(meshMaterial);
        });
      }
    });

    // Filter and group meshMaterials that have the same name across parts
    const groupedMeshMaterials: MeshMaterial[][] = [];

    meshMaterialsMap.forEach((meshMaterialArray) => {
      if (meshMaterialArray.length > 1) {
        // If there are multiple meshMaterials with the same name
        groupedMeshMaterials.push(meshMaterialArray);
      }
    });

    return groupedMeshMaterials;
  };

  const handleSubmit = async () => {
    const matchedMeshMaterials = extractMeshMaterials(selectedPartsData);

    let selectedMeshMaterialModels: Required<MeshMaterialModel>[]; // Declare selectedMeshMaterialModels here
    // return;
    if (matchedMeshMaterials.length === 0) {
      form.resetFields();
      notification.error({
        message: "Bad Input",
        description: "No matched material found!",
      });
      onSubmit();

      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }

    try {
      const values = await form.validateFields();
      console.log("Form values:", values);

      const extractedIds = matchedMeshMaterials.map((group) =>
        group.map((material) => material.id)
      );

      console.log("extractedIds", extractedIds);

      // API CALL FOR CREATING MATERIALASSOCIATION GROUP

      if (Object.keys(values).length > 0) {
        // values object is not empty

        console.log("Form values in api:", values);
        //let counter = 1;

        for (const ids of extractedIds) {
          const response =
            await ProductsService.productsMeshMaterialAssociationGroupCreate({
              data: {
                ui_message: `${values.ui_message}`,
                type: values.type,
                item_version_id: itemVersionId,
              },
            });
          if (response?.id) {
            console.log(
              "Material Association Group created successfully.",
              response
            );
            //counter++;
            notification.success({
              message: "Material Association Group successfully created!",
            });
            // API CALL FOR CREATING MATERIAL ASSOCIATION
            for (const id of ids) {
              const materialAssociationGroupId = response.id;

              try {
                const assocResponse =
                  await ProductsService.productsMeshMaterialAssociationCreate({
                    materialId: id,
                    materialAssociationGroupId: materialAssociationGroupId,
                    data: {
                      // ui_message: values.ui_message,
                      // type: values.type,
                    },
                  });
                console.log(
                  `Association created for material ID ${id}`,
                  assocResponse
                );
                notification.success({
                  message: "Material Association successfully created!",
                });
              } catch (assocError) {
                console.error(
                  `Error creating association for material ID ${id}`,
                  assocError
                );
              }
            }
          } else {
            console.log("No response from createMaterialAssociationGroup");
          }
        }
        setSelectedPartsData([]);
        onSubmit();
        window.location.reload();
      } else {
        // values object is empty
        console.log("No form values");
      }
    } catch (error) {
      console.log("Validation Failed:", error);
      // selectedMeshMaterialModels = []; // Handle the error case by initializing to an empty array or appropriate error handling
    }
  };

  return (
    <Modal
      title="Add Matching Material Associations"
      open={open}
      onCancel={onCancel}
      onOk={handleSubmit}
      afterClose={() => {
        setSelectedParts({});
      }}
      okText="Create"
      className="add-associations-modal"
      cancelButtonProps={{ className: "cancel-btn" }}
      okButtonProps={{
        className: "upload-btn",
        disabled: Object.values(selectedParts).filter(Boolean).length < 2,
      }}
    >
      <Spin spinning={!itemVersionData} size="large">
        <div className="associations-content">
          {itemVersionData?.data?.itemVersion?.childrens?.map(
            (partGroup: PartGroupModelType) => (
              //(itemVersion: ItemVersionModelType) =>
              //itemVersion.childrens.map((partGroup) => (
              <div key={partGroup._uuid}>
                <h3>{partGroup.name}</h3>
                {partGroup.childrens
                  .filter((part) => {
                    const isSelectedPart =
                      part.id === Number(itemVersionData?.id);
                    const isDifferentPartGroup =
                      partGroup._uuid.toString() !==
                      itemVersionData?.data?.partGroup?._uuid?.toString();
                    return isSelectedPart || isDifferentPartGroup;
                  })
                  .map((part) => (
                    <Card key={part.id} hoverable className="associations-card">
                      <Checkbox
                        checked={!!selectedParts[part.id]}
                        onChange={(e) =>
                          handleOnSelectPart(part.id, e.target.checked, part)
                        }
                      />
                      <div className="associations-card-content">
                        <img
                          src={
                            part.external_url +
                            "/thumbnail_compressed/256.png?rerender=" +
                            Date.now()
                          }
                          alt={part.name || ""}
                        />
                        <p>{part.name}</p>
                      </div>
                    </Card>
                  ))}
              </div>
            )
          )}
        </div>
      </Spin>
      <Form
        form={form}
        layout="vertical"
        name="partAssociationForm"
        initialValues={{
          type: "dictate",
        }}
      >
        <Form.Item
          name="ui_message"
          label="Association UI Message"
          rules={[
            {
              required: true,
              message: "Please input the association UI message!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Association Type"
          rules={[
            { required: true, message: "Please select the association type!" },
          ]}
        >
          <Select>
            {/* <Select.Option value="invalid">Invalid</Select.Option> */}
            <Select.Option value="dictate">Dictate</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
