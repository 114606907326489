import React, { useCallback } from "react";
import { notification } from "antd";
import { patchPart, postPart, deletePart } from "../utils/api";
import { useLayoutsContext } from "../contexts/LayoutsContext";
import { PartGroupType, PartType } from "../types";
import { useUIContext } from "../contexts/UIContext";

export const useParts = () => {
  const { setSelectedKey, updateItemByUUID, getItemByUUID } =
    useLayoutsContext();
  const { taskWrapper } = useUIContext();

  const addPart = useCallback(
    async (
      { name, priceMod }: { name: string; priceMod?: number },
      { partGroup }: { partGroup: PartGroupType }
    ) => {
      if (partGroup) {
        const part = await postPart(name, partGroup.apiId, priceMod);
        if (part) {
          updateItemByUUID(partGroup.uuid, (_partGroup) => {
            (_partGroup as PartGroupType).parts.push(part);
            return _partGroup;
          });
          setSelectedKey(part.uuid);
          notification.success({
            message: "Part successfully created!",
          });
          return part;
        }
      }
    },
    [setSelectedKey, updateItemByUUID]
  );

  const updatePart = useCallback(
    async (part: Pick<PartType, "uuid" | "apiId" | "name" | "priceMod">) => {
      await patchPart(part);
      updateItemByUUID(part.uuid, (_part) => ({
        ..._part,
        ...part,
      }));
      notification.success({
        message: "Part successfully updated!",
      });
    },
    [updateItemByUUID]
  );

  const clonePart = useCallback(
    async ({ part }: { part: PartType }) => {
      const partItem = getItemByUUID(part.uuid);
      if (partItem) {
        const partGroup = partItem.data.partGroup as PartGroupType;
        const clonedPart = await addPart(
          { name: `${part.name} (Clone)`, priceMod: part.priceMod },
          { partGroup }
        );
        return clonedPart;
      }
    },
    [addPart, getItemByUUID]
  );

  const removePart = useCallback(
    async ({
      partGroup,
      part,
    }: {
      partGroup: PartGroupType;
      part: PartType;
    }) => {
      if (partGroup && part) {
        // @ts-ignore
        const partApiResponse = await deletePart(part.apiId);
        if (partApiResponse) {
          updateItemByUUID(partGroup.uuid, (_partGroup) => {
            (_partGroup as PartGroupType).parts = (
              _partGroup as PartGroupType
            ).parts.filter((p) => part.uuid !== p.uuid);
            return _partGroup;
          });
          setSelectedKey(partGroup.uuid);
          notification.success({
            message: "Part successfully deleted!",
          });
        }
      }
    },
    [setSelectedKey, updateItemByUUID]
  );

  return {
    addPart: taskWrapper(addPart),
    updatePart: taskWrapper(updatePart),
    clonePart: taskWrapper(clonePart),
    removePart: taskWrapper(removePart),
  };
};
