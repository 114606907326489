import React from "react";
import { Upload, UploadProps } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
const { Dragger } = Upload;

export interface FilesDropZoneProps<T = RcFile>
  extends Omit<UploadProps, "onChange"> {
  onChange?: (
    info: UploadChangeParam<T> & {
      urls: string[];
      urlFileList: UploadFile<any>[];
    }
  ) => void;
}

export const FilesDropZone = ({
  onChange,
  beforeUpload = () => false,
  ...rest
}: FilesDropZoneProps) => {
  return (
    <div className="files-dropzone">
      <Dragger
        // name="file"
        beforeUpload={beforeUpload}
        onChange={(info) => {
          if (onChange) {
            const urls: string[] = [];
            const urlFileList: UploadFile<any>[] = [];
            info.fileList.forEach((file) => {
              const url =
                file.url || URL.createObjectURL(file.originFileObj as File);
              urls.push(url);
              urlFileList.push({
                uid: file.uid,
                name: file.name,
                url,
              });
            });
            onChange({ ...info, urls, urlFileList } as any);
          }
        }}
        {...rest}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">Click Or drag and drop file(s) here.</p>
      </Dragger>
    </div>
  );
};
