import React, { useMemo } from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import {
  DownOutlined,
  LayoutOutlined,
  AppstoreAddOutlined,
  BuildOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import { ModelType, ModelTypeLabel, SelectedItem } from "../types";

interface ActionsMenuProps {
  selectedItem: SelectedItem;
  onAdd?: (type: ModelType) => void;
  onClone?: (type: ModelType) => void;
  onDelete?: (type: ModelType) => void;
  onCreate?: (type: ModelType) => void;
}

export const ActionsMenu = ({
  selectedItem,
  onAdd = () => {},
  onClone = () => {},
  onDelete = () => {},
  onCreate = () => {},
}: ActionsMenuProps) => {
  const {
    type,
    data: { itemVersion, partGroup, part, item },
  } = selectedItem;
  const { addItems, cloneItems } = useMemo(() => {
    const addItems: MenuProps["items"] = [];
    const cloneItems: MenuProps["items"] = [];
    if (type === "collection") {
      addItems.push({
        label: "Add New Item",
        key: "add-item",
        onClick: () => {
          onAdd("item");
        },
      });
    } else if (type === "item") {
      // addItems.push({
      //   label: "Add New Item Version",
      //   key: "add-item-version",
      //   onClick: () => {
      //     onAdd("itemVersion");
      //   },
      // });
      addItems.push({
        label: "Add New Part Group",
        key: "add-part-group",
        icon: <AppstoreAddOutlined />,
        onClick: () => {
          onAdd("partGroup");
        },
      });
      cloneItems.push({
        label: (
          <span>
            Clone Item (<i>{item?.name}</i>)
          </span>
        ),
        key: "clone-part-group",
        icon: <AppstoreAddOutlined />,
        onClick: () => {
          onClone("item");
        },
      });
    } else if (type === "itemVersion") {
      addItems.push({
        label: "Add New Part Group",
        key: "add-part-group",
        icon: <AppstoreAddOutlined />,
        onClick: () => {
          onAdd("partGroup");
        },
      });
      cloneItems.push({
        label: (
          <span>
            Clone Item Version: (<i>{itemVersion?.name}</i>)
          </span>
        ),
        key: "clone-item-version",
        icon: <LayoutOutlined />,
        onClick: () => {
          onClone("itemVersion");
        },
      });
    } else if (type === "partGroup") {
      addItems.push({
        label: "Add New Part",
        key: "add-part",
        icon: <BuildOutlined />,
        onClick: () => {
          onAdd("part");
        },
      });
      cloneItems.push({
        label: (
          <span>
            Clone Part Group: (<i>{partGroup?.name}</i>)
          </span>
        ),
        key: "clone-part-group",
        icon: <AppstoreAddOutlined />,
        onClick: () => {
          onClone("partGroup");
        },
      });
    } else if (type === "part") {
      addItems.push({
        label: "Add New Material",
        key: "add-material",
        icon: <BgColorsOutlined />,
        onClick: () => {
          onAdd("meshMaterial");
        },
      });
      cloneItems.push({
        label: (
          <span>
            Clone Part: (<i>{part?.name}</i>)
          </span>
        ),
        key: "clone-part",
        icon: <BuildOutlined />,
        onClick: () => {
          onClone("part");
        },
      });
    }
    return { addItems, cloneItems };
  }, [itemVersion?.name, onAdd, onClone, part?.name, partGroup?.name, type]);

  return (
    <div className="actions-menu">
      {!!addItems.length && type !== "itemVersion" && (
        <Dropdown menu={{ items: addItems }}>
          <Button size="small">
            <Space>
              Add
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      )}
      {type !== "meshMaterial" &&
        type !== "itemVersion" &&
        !!cloneItems.length && (
          <Dropdown menu={{ items: cloneItems }}>
            <Button size="small">
              <Space>
                Clone
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}

      {type !== "collection" && type !== "itemVersion" && (
        <Button
          danger
          size="small"
          onClick={() => {
            onDelete(type);
          }}
        >
          Delete {ModelTypeLabel[type]}
        </Button>
      )}
      {type !== "collection" &&
        type !== "item" &&
        type !== "itemVersion" &&
        type !== "partGroup" && (
          <Button
            size="small"
            onClick={() => {
              onCreate(type);
            }}
          >
            Create {ModelTypeLabel[type]} Association
          </Button>
        )}
      {type !== "collection" &&
        type !== "item" &&
        type !== "itemVersion" &&
        type !== "partGroup" &&
        type !== "meshMaterial" && (
          <Button
            size="small"
            onClick={() => {
              onCreate("partMaterial");
            }}
          >
            Create Mesh Material Association
          </Button>
        )}
      {/* {type == "part" && (
        <Button
          size="small"
          onClick={() => {
            onCreate("meshMaterial");
          }}
        >
          Create Material Association
        </Button>
      )} */}
    </div>
  );
};
