import { ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import { initStore } from "./store";
import authReducer from "./reducers/auth";
import appReducer from "./reducers/app";
import variantReducer from "./reducers/variant";
import statusesReducer from "./reducers/statuses";
import companyReducer from "./reducers/company";
import profileReducer from "./reducers/profile";
import itemsReducer from "./reducers/items";
import itemCollectionsReducer from "./reducers/itemCollections";
import showroomsReducer from "./reducers/showrooms";
import shellsReducer from "./reducers/shells";
import user from "./reducers/user";
import materials from "./reducers/materials";
import materialCategories from "./reducers/materials";
import behaviors from "./reducers/behaviors";

export const rootReducers = combineReducers({
  auth: authReducer,
  app: appReducer,
  variants: variantReducer,
  statuses: statusesReducer,
  companies: companyReducer,
  profile: profileReducer,
  items: itemsReducer,
  itemCollections: itemCollectionsReducer,
  showrooms: showroomsReducer,
  shells: shellsReducer,
  user: user,
  materials: materials,
  materialCategories: materialCategories,
  behaviors,
});

export const store = initStore(rootReducers);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
