import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, notification } from "antd";
import ApiService from "services/ApiService";
import { format } from "date-fns";

interface AddCollectionViewProps {
  companyId: number;
  orderId: number;
}

export const AddCollectionView = ({
  companyId,
  orderId,
}: AddCollectionViewProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [highestListOrder, setHighestListOrder] = useState<number>(-1);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        // @ts-ignore
        const orderResponse = await ApiService.getOrder(orderId);
        const itemCollections = orderResponse.data.item_collections;

        const maxListOrder = itemCollections.reduce(
          (max: number, collection: any) =>
            Math.max(max, collection.list_order || 0),
          -1
        );
        setHighestListOrder(maxListOrder);
      } catch (error) {
        console.error("Failed to fetch order details:", error);
        notification.error({
          message: "Failed to fetch order details!",
        });
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const dateTimeNow = format(new Date(), "yyyy-MM-dd HH-mm:ss");
    try {
      // @ts-ignore
      const itemCollectionApiResponse = await ApiService.postItemCollection(
        orderId,
        `${values.collectionName}`,
        highestListOrder + 1
      );
      // Create Layout for this collection
      if (itemCollectionApiResponse) {
        const { id } = itemCollectionApiResponse.data;
        const itemApiResponse = await ApiService.postItem({
          name: `${values.layoutName}`,
          order_id: orderId,
          item_collection_id: id,
          list_order: 0,
        });
        // Add Default Part Group
        const versionId = itemApiResponse.data.versions[0].id;

        // @ts-ignore
        const partGroupApiResponse = await ApiService.postPartGroup(
          "Base",
          versionId,
          0
        );

        // Add Default Part
        if (partGroupApiResponse) {
          // @ts-ignore
          const partApiResponse = await ApiService.postPart(
            "Standard Base",
            partGroupApiResponse.data.id,
            0,
            0
          );
          // Navigate to edit collection
          if (partApiResponse) {
            navigate(
              `/${companyId}/products-new/${orderId}/advanced-product-creator-new/edit/${id}`
            );
            notification.success({
              message: "Layout successfully created!",
            });
          }
        }
      }
      // if (itemCollectionApiResponse) {
      //   const { id } = itemCollectionApiResponse.data;
      //   const itemApiResponse = await ApiService.postItem({
      //     name: `${values.layoutName} -- ${dateTimeNow}`,
      //     order_id: orderId,
      //     item_collection_id: id,
      //   });
      //   // Add Default Part Group
      //   const versionId = itemApiResponse.data.versions[0].id;

      //   // @ts-ignore
      //   const partGroupApiResponse = await ApiService.postPartGroup(
      //     "Base",
      //     versionId
      //   );
      //   console.log("i am here");
      //   // Add Default Part
      //   if (partGroupApiResponse) {
      //     // @ts-ignore
      //     const partApiResponse = await ApiService.postPart(
      //       "Standard Base",
      //       partGroupApiResponse.data.id,
      //       0
      //     );
      //     // Navigate to edit collection
      //     if (partApiResponse) {
      //       navigate(
      //         `/${companyId}/products-new/${orderId}/advanced-product-creator-new/edit/${id}`
      //       );
      //       notification.success({
      //         message: "Layout successfully created!",
      //       });
      //     }
      //   }
      // }
    } catch (error) {
      notification.error({
        message: "Failed to create layout!",
      });
    }
    setLoading(false);
  };

  return (
    <div className="add-collection-view">
      <Form
        form={form}
        name="add-collection-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          collectionName: "",
          layoutName: "",
        }}
      >
        <Form.Item
          label="Collection Name"
          name="collectionName"
          rules={[
            { required: true, message: "Please input the collection name!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Layout Name"
          name="layoutName"
          rules={[{ required: true, message: "Please input the layout name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <div className="commit-buttons">
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                form.resetFields();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
