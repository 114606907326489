import React, { useEffect, useMemo, useState } from "react";
import { Card, Modal, Image, Checkbox } from "antd";
import { useCompanyContext } from "contexts/CompanyContext";
import ApiService from "services/ApiService";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";

interface AddMaterialsModalProps {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (materials: any[]) => void;
}

export const AddMaterialsModal = ({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
}: AddMaterialsModalProps) => {
  const { company } = useCompanyContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [materials, setMaterials] = useState<any[]>([]);
  const [selectedMaterials, setSelectedMaterials] = useState<{
    [key: string]: any;
  }>({});
  const selectedMaterialsArray = useMemo<any[]>(
    () => Object.values(selectedMaterials),
    [selectedMaterials]
  );

  const handleOnSelect = (material: any) => {
    setSelectedMaterials((prevSelectedMaterials) => {
      const copyMaterials = { ...prevSelectedMaterials };
      if (prevSelectedMaterials[material.id]) {
        delete copyMaterials[material.id];
      } else {
        copyMaterials[material.id] = material;
      }
      return copyMaterials;
    });
  };

  useEffect(() => {
    const fetchMaterials = async () => {
      setLoading(true);
      // @ts-ignore
      const materialsRes = await ApiService.getLibraryMaterials(company?.id);
      setMaterials(materialsRes.data);
      setLoading(false);
    };
    fetchMaterials();
  }, [company?.id]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={() => {
        onSubmit(selectedMaterialsArray);
      }}
      afterClose={() => {
        setSelectedMaterials({});
      }}
      title="Add Materials"
      okText="Add"
      centered
      className="add-materials-modal"
      cancelButtonProps={{ className: "cancel-btn" }}
      okButtonProps={{
        className: "upload-btn",
        disabled: !selectedMaterialsArray.length,
      }}
    >
      {loading ? (
        <FullAreaSpinner />
      ) : (
        <div className="matterials-content">
          {materials.map((material) => (
            <Card
              className={`${selectedMaterials[material.id] ? "selected" : ""}`}
              key={material.id}
              size="small"
              title={material.name}
              hoverable
              extra={<Checkbox checked={!!selectedMaterials[material.id]} />}
              style={{ width: 200 }}
              onClick={() => {
                handleOnSelect(material);
              }}
            >
              <Image
                preview={false}
                // onClick={(e) => {
                //   e.stopPropagation();
                // }}
                src={material.internal_url}
              />
            </Card>
          ))}
        </div>
      )}
    </Modal>
  );
};
