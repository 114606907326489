import { Button, Select } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import _, { debounce } from "lodash";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Layout, Modal, Skeleton } from "antd";
import { withRouter, getParamsFromPathname } from "utils";
import Screen from "components/Screen";
import { RiDeleteBin5Line } from "react-icons/ri";
import { setModalState } from "store/actions/app";
import {
  getLibraryMaterials,
  getLibraryMaterialCategories,
  patchLibraryMaterial,
  patchLibraryMaterialCategory,
  uploadMaterialThumbnail,
} from "store/actions/materials";
import NewMaterial from "../modals/NewMaterial";
import NewMaterialCategory from "../modals/NewMaterialCategory";
import EasyEdit from "react-easy-edit";
import "./materialsList.scss";
import ApiService from "services/ApiService";
import { ProductsService } from "servicesNew/api";

const { Option } = Select;

class MaterialsListScreen extends Component {
  constructor(props) {
    super(props);
    this.debouncedSave = debounce(this.onSave, 750);
    this.state = {
      categoryAdded: false, // Local state to track category addition
    };
    // super(props);
    this.handleOnDelete = this.handleOnDelete.bind(this);
  }

  componentDidMount() {
    this.props.getLibraryMaterials(this.props.companyIdInUrl);
    this.props.getLibraryMaterialCategories(this.props.companyIdInUrl);
    //this.fetchInitialData();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the categoryAdded flag has changed to true
    if (
      this.state.categoryAdded &&
      this.state.categoryAdded !== prevState.categoryAdded
    ) {
      this.props.getLibraryMaterialCategories(this.props.companyIdInUrl);
      this.setState({ categoryAdded: false }); // Reset the flag
    }
  }

  fetchInitialData = () => {
    this.props.getLibraryMaterials(this.props.companyIdInUrl);
    this.props.getLibraryMaterialCategories(this.props.companyIdInUrl);
  };

  handleCategoryAdded = () => {
    this.setState({ categoryAdded: true }); // Set flag to true when a category is added
  };

  onNameSave = async (mId, value) => {
    try {
      const { companyIdInUrl } = this.props;
      await ProductsService.productsPushLibraryMaterialChanges({
        data: {
          lm_id: mId, // Assuming mId is the lm_id
          company_id: parseInt(companyIdInUrl, 10), // Ensure company_id is an integer
          material_name: value, // Optional field
        },
      });
      await ApiService.patchMaterialName(mId, { name: value });
    } catch (error) {
      console.error("Failed to update category name", error);
    }
  };

  // onSavePrice = (mId, value) => {
  //     this.props.patchLibraryMaterial(mId, { price_mod: value })
  // }

  updateMaterialCategoryAssociation = async (
    material_id,
    material_category_id,
    materialCategoriesList
    //material_categories
  ) => {
    console.log(
      "Updating material category association:",
      material_id,
      material_category_id,
      materialCategoriesList
    );
    try {
      // Call the API to update the material category association
      await ApiService.patchMaterialCategoryAssociation(
        material_id,
        material_category_id
      );

      const updatedCategory = materialCategoriesList.find(
        (cat) => cat.id === material_category_id
      );

      if (!updatedCategory) {
        throw new Error("Updated category not found");
      }

      const { companyIdInUrl } = this.props;
      await ProductsService.productsPushLibraryMaterialChanges({
        data: {
          lm_id: material_id,
          company_id: parseInt(companyIdInUrl, 10),
          //categories: material_categories,
          categories: [updatedCategory],
        },
      });

      // Optionally, fetch and update your component's state or Redux store to reflect the change
      //this.props.getLibraryMaterials(this.props.companyIdInUrl); // Assuming you have such a method to refresh materials
    } catch (error) {
      console.error("Failed to update material category", error);
    } finally {
      window.location.reload();
    }
  };

  handleOnDelete = (materialId, categoryId) => {
    Modal.confirm({
      title: `Do you want to delete this Material `,
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk: async () => {
        try {
          await ApiService.deleteMaterials(materialId, categoryId);
        } catch (error) {
          console.error("Failed to delete category name", error);
        }
      },
    });
  };

  onSave = (mId, key, value, categoryId) => {
    this.props.patchLibraryMaterial(mId, { [key]: value });
    this.props.patchLibraryMaterialCategory(categoryId, {
      [key]: value,
    });
  };

  renderMaterialCards = (materials) => {
    return materials.map((material) => (
      <div className="material-card" key={material.id}>
        <div className="top">
          <div className="top-inner">
            <h4>Material Name</h4>
            <div
              onClick={() =>
                this.handleOnDelete(
                  material.id,
                  material.categories.length > 0
                    ? material.categories[0].id
                    : undefined
                )
              }
            >
              <DeleteOutlined className="delete-btn" />
            </div>
          </div>
          <EasyEdit
            value={material.name}
            type="text"
            onSave={(value) => {
              if (value) {
                this.onNameSave(material.id, value);
              }
            }}
            onCancel={this.onNameCancel}
            saveButtonLabel="Save"
            cancelButtonLabel="Cancel"
          />
        </div>
        <div className="bottom">
          <h4>Material Thumbnail</h4>
          <Dragger
            style={{ backgroundColor: "white" }}
            name="thumbnail"
            multiple={false}
            accept=".png,.jpg"
            beforeUpload={async (file) => {
              try {
                await this.props.uploadMaterialThumbnail(
                  file,
                  material.post_url.url,
                  material.post_url.fields
                );
                const { companyIdInUrl } = this.props;
                await ProductsService.productsPushLibraryMaterialChanges({
                  data: {
                    lm_id: material.id,
                    company_id: parseInt(companyIdInUrl, 10),
                    is_thumbnail_updated: true,
                  },
                });
                // Optionally, you can refresh the material data after the update
                //this.props.getLibraryMaterials(companyIdInUrl);
              } catch (error) {
                console.error("Failed to upload thumbnail", error);
              }
              return false;
            }}
            maxCount={1}
          >
            <div
              className="material-image"
              style={{ backgroundImage: `url(${material.internal_url})` }}
            />
          </Dragger>
          <h4>Default Material Price</h4>
          <input
            type="number"
            onChange={async (e) => {
              const newPrice = Number(e.target.value);
              const { companyIdInUrl } = this.props;
              try {
                await this.debouncedSave(material.id, "price_mod", newPrice);
                await ProductsService.productsPushLibraryMaterialChanges({
                  data: {
                    lm_id: material.id,
                    company_id: parseInt(companyIdInUrl, 10),
                    price_mod: newPrice,
                  },
                });
              } catch (error) {
                console.error("Failed to update material price", error);
              }
            }}
            defaultValue={material.price_mod}
          />
          <h4>Update Material Category</h4>
          <Select
            value={
              material.categories.length > 0
                ? String(material.categories[0].name)
                : undefined
            }
            style={{ width: "100%" }}
            onChange={(selectedCategoryId) =>
              this.updateMaterialCategoryAssociation(
                material.id,
                selectedCategoryId,
                this.props.materialCategories
              )
            }
          >
            {this.props.materialCategories.map((materialCategory) => (
              <Option key={materialCategory.id} value={materialCategory.id}>
                {materialCategory.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    ));
  };

  renderMaterialCategoryCards = () => {
    const { materialCategories, materials } = this.props;

    if (materialCategories.length === 0) {
      return <div>No categories found</div>;
    }

    const materialArray = Object.values(materials);

    return materialCategories.map((materialCategory) => {
      const filteredMaterialList = materialArray.filter(
        (material) =>
          material.library &&
          material.categories.some((cat) => cat.id === materialCategory.id)
      );

      return (
        <div className="material-category-card" key={materialCategory.id}>
          <h3>{materialCategory.name}</h3>
          {filteredMaterialList.length > 0 ? (
            <div className="materials-in-category">
              {this.renderMaterialCards(filteredMaterialList)}
            </div>
          ) : (
            <div className="no-materials-message">
              No materials in this category
            </div>
          )}
        </div>
      );
    });
  };

  render() {
    return (
      <>
        <Screen className="materials-list-screen">
          <Button
            className="add-material-button"
            onClick={() => {
              this.props.setModalState("createLibraryMaterial", {
                visible: true,
              });
            }}
          >
            Add Library Material
          </Button>
          <Button
            className="add-material-category-button"
            onClick={() => {
              this.props.setModalState("createLibraryMaterialCategory", {
                visible: true,
              });
            }}
          >
            Add Material Category
          </Button>
          <div className="cards-container">
            <h2>Material Library</h2>
            {this.renderMaterialCategoryCards()}
          </div>
        </Screen>
        <NewMaterialCategory />
        <NewMaterial />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let { companyIdInUrl } = getParamsFromPathname(window.location.pathname);
  return {
    companyIdInUrl,
    materials: state.materials.materials,
    materialCategories: state.materialCategories.materialCategories,
  };
};

/*const mapStateToProps = ({ materialCategory, materials }) => {
  let { companyIdInUrl } = getParamsFromPathname(window.location.pathname);
  return {
    companyIdInUrl,
    materialCategory,
    materials,
  };
};*/

export default connect(mapStateToProps, {
  setModalState,
  getLibraryMaterials,
  getLibraryMaterialCategories,
  uploadMaterialThumbnail,
  patchLibraryMaterial,
  patchLibraryMaterialCategory,
})(withRouter(MaterialsListScreen));
