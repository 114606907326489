import React, { Component } from "react";

class Assembler extends Component {
  constructor(props) {
    super(props);
    this.assemblerRef = React.createRef();
    this.eventAdded = false;
    this.state = {
      selectedParts: {},
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        some: "state",
      });
    }, 1000);
  }

  handleItemUpdate = (data) => {
    this.props.setSelectedPartsFxn(data.detail);
  };

  componentWillUnmount() {
    if (this.assemblerRef && this.assemblerRef.current && this.eventAdded) {
      this.assemblerRef.current.removeEventListener(
        "itemupdate",
        this.handleItemUpdate
      );
    }
  }

  componentDidUpdate() {
    if (this.assemblerRef && this.assemblerRef.current && !this.eventAdded) {
      this.assemblerRef.current.addEventListener(
        "itemupdate",
        this.handleItemUpdate
      );
      this.eventAdded = true;
    }
  }

  render() {
    return (
      <bitreel-variant-assembler
        id="bitreel-variant-assembler"
        item-collection-id={this.props.itemCollectionId}
        material-max-lod="0"
        token={this.props.token}
        ref={this.assemblerRef}
      />
    );
  }
}

export default Assembler;
