import React, { Component } from "react";
import "./textArea.scss";

class TextAreaDisplay extends Component {
  state = {};
  render() {
    return (
      <div className="product-description-display">
        <h3 className="section-title">Details</h3>
        <span className="editLink">edit</span>
        <p>{this.props.value}</p>
      </div>
    );
  }
}

export default TextAreaDisplay;
