import { Form, Input, message, Modal } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, getParamsFromPathname } from "utils";
import { setModalState } from "store/actions/app";
import ApiService from "services/ApiService";

class NewMaterialCategory extends Component {
  formRef = React.createRef();

  onSubmit = async () => {
    try {
      const detailValues = await this.formRef.current.validateFields();

      const postData = {
        ...detailValues,
        company_id: Number(this.props.companyIdInUrl),
      };

      let matCatRes = await ApiService.postMaterialCategory(postData);

      //await ApiService.patchMaterialCategory(matCatRes.data.id, {
      //  company_id: Number(this.props.companyIdInUrl),
      //});

      // Invoke the callback if defined
      if (this.props.onCategoryAdded) {
        this.props.onCategoryAdded();
      }

      // Close the modal after successful operation
      this.props.setModalState("createLibraryMaterialCategory", {
        visible: false,
      });

      message.success("Material category added successfully");
      window.location.reload();
    } catch (error) {
      console.warn(error);
      message.error("Failed to add material category");
    }
  };

  render() {
    return (
      <Modal
        title="Add Material Category"
        onCancel={() =>
          this.props.setModalState("createLibraryMaterialCategory", {
            visible: false,
          })
        }
        open={this.props.modalState.visible}
        onOk={this.onSubmit}
      >
        <Form ref={this.formRef}>
          <h4>Material Category</h4>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Please input the category name!" },
            ]}
          >
            <Input placeholder="Category Name" />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  app: {
    modalStates: { createLibraryMaterialCategory: modalState },
  },
}) => {
  let { companyIdInUrl } = getParamsFromPathname(window.location.pathname);
  return {
    modalState,
    companyIdInUrl,
  };
};

export default connect(mapStateToProps, { setModalState })(
  withRouter(NewMaterialCategory)
);
