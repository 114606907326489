/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo } from "react";
import {
  ArcRotateCamera,
  IPointerEvent,
  PickingInfo,
  Vector3,
  TransformNode,
} from "@babylonjs/core";
import { Engine, Scene } from "react-babylonjs";
import { usePrecisionClick } from "../../hooks/usePrecisionClick";
import { BabylonModel } from "./babylonModel";
import { BabylonCamera3D } from "./babylonCamera3D";
import { useEngineContext } from "modules/showrooms/contexts/EngineContext";

interface BabylonEngineProps {
  url: string;
  onLeftClick?: (
    evt: IPointerEvent,
    pickResult: PickingInfo,
    isLongPress: boolean
  ) => void;
  onLongPressClick?: (evt: IPointerEvent, pickResult: PickingInfo) => void;
  onMiddleClick?: (
    evt: IPointerEvent,
    pickResult: PickingInfo,
    isLongPress: boolean
  ) => void;
  onRightClick?: (
    evt: IPointerEvent,
    pickResult: PickingInfo,
    isLongPress: boolean
  ) => void;
  onPointerMove?: (evt: IPointerEvent, pickResult: PickingInfo) => void;
  onModelLoading?: (value: boolean) => void;
  camera?: React.ReactElement | null;
  children?: React.ReactElement | React.ReactElement[] | null;
  showroomShellOffset: {
    x: number | null;
    y: number | null;
    z: number | null;
  };
}

export const BabylonEngine = ({
  url,
  onLeftClick = () => {},
  onMiddleClick = () => {},
  onLongPressClick = () => {},
  onRightClick = () => {},
  onModelLoading = () => {},
  onPointerMove,
  camera,
  children,
  showroomShellOffset,
}: BabylonEngineProps) => {
  const { scene, canvas, setCanvas, setScene } = useEngineContext();
  const {
    resetPointerClick,
    setPointerDown,
    setPointerUp,
    setPointerDownLongPress,
    getPointerDownLongPress,
    validateClick,
    validateClickDistance,
    setIsPressed,
    getIsPressed,
  } = usePrecisionClick();

  const { rootUrl, sceneFilename, sceneName } = useMemo(() => {
    const rootUrl = url.slice(0, url.lastIndexOf("/") + 1);
    const sceneFilename = url.slice(url.lastIndexOf("/") + 1);
    const sceneName = sceneFilename.split("?")[0] || "sceneView";
    return { rootUrl, sceneFilename, sceneName };
  }, [url]);

  useEffect(() => {
    if (process.env.REACT_APP_STAGE === "beta") {
      (window as any).scene = scene;
      (window as any).canvas = canvas;
    }
    return () => {
      if (process.env.REACT_APP_STAGE === "beta") {
        delete (window as any).scene;
        delete (window as any).canvas;
      }
    };
  }, [canvas, scene]);

  useEffect(() => {
    // Resize the babylon engine on window resize event
    const resize = () => {
      scene?.getEngine().resize();
    };
    canvas?.addEventListener("resize", resize);
    return () => {
      // cleanup
      canvas?.removeEventListener("resize", resize);
    };
  }, [scene, canvas]);

  /**
   * The magic function to make the camera rotation center to be the picked point(without moving the camera)
   */
  const setCameraOffset = (
    camera: ArcRotateCamera | null,
    position: Vector3
  ) => {
    // If we have a mesh to set that hasn't already been set
    if (!camera) {
      return;
    }
    const node = new TransformNode("node", scene);
    node.position.copyFrom(position);

    /**
     * This is an important part.  The getPositionInCameraSpace function will give us the location of the mesh, as if we were to pan
     * the camera to it.  We then take this value and set our offsets to the relative x and y of that position and use the z as our
     * radius.  By copying the alpha and beta angles, we're effectively performing a 3D Pan and then immediately offsetting the
     * camera back into the original position.
     * */
    const relPos = node.getPositionInCameraSpace(camera);
    const alpha = camera.alpha;
    const beta = camera.beta;
    camera.target = node.position.clone();
    camera.targetScreenOffset.x = relPos.x;
    camera.targetScreenOffset.y = relPos.y;
    camera.radius = relPos.z;
    camera.alpha = alpha;
    camera.beta = beta;
    node.dispose();
  };

  return (
    <Engine
      antialias
      adaptToDeviceRatio
      onScroll={(e) => {
        e.preventDefault();
      }}
    >
      <Scene
        onSceneMount={(sceneEventArgs) => {
          setCanvas(sceneEventArgs.canvas);
          setScene(sceneEventArgs.scene);
        }}
        onPointerDown={(evt, pickResult) => {
          resetPointerClick();
          setIsPressed(true);
          if (pickResult.hit && pickResult.pickedPoint) {
            setPointerDown(pickResult.pickedPoint);
            // @ts-ignore that
            setCameraOffset(scene.cameras[0], pickResult.pickedPoint);
            // LongPress handler
            setTimeout(() => {
              if (scene && pickResult.pickedPoint) {
                const picked = scene.pick(scene.pointerX, scene.pointerY);
                if (picked.pickedPoint) {
                  setPointerDownLongPress(true);
                  const validDistance = validateClickDistance(
                    pickResult.pickedPoint,
                    picked.pickedPoint
                  );
                  if (validDistance && getIsPressed()) {
                    onLongPressClick(evt, pickResult);
                  }
                }
              }
            }, 200);
          }
        }}
        onPointerUp={(evt, pickResult) => {
          setIsPressed(false);
          if (pickResult.hit && pickResult.pickedPoint) {
            setPointerUp(pickResult.pickedPoint);
            if (validateClick()) {
              const isLongPress = getPointerDownLongPress();
              if (evt.button === 0) {
                onLeftClick(evt, pickResult, isLongPress);
              } else if (evt.button === 1) {
                onMiddleClick(evt, pickResult, isLongPress);
              } else if (evt.button === 2) {
                onRightClick(evt, pickResult, isLongPress);
              }
            }
          }
          setPointerDownLongPress(false);
        }}
        onPointerMove={onPointerMove}
      >
        <adtFullscreenUi name="2D-gui">
          <BabylonModel
            sceneName={sceneName}
            rootUrl={rootUrl}
            sceneFilename={sceneFilename}
            onModelLoading={onModelLoading}
            showroomShellOffset={showroomShellOffset}
          >
            <>
              {camera || <BabylonCamera3D />}
              <hemisphericLight
                name="light"
                intensity={1}
                direction={Vector3.Up()}
              />
              {children}
            </>
          </BabylonModel>
        </adtFullscreenUi>
      </Scene>
    </Engine>
  );
};
