/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, forwardRef, Suspense } from "react";
import { ILoadedModel, Model } from "react-babylonjs";
import {
  ActionEvent,
  ActionManager,
  ExecuteCodeAction,
} from "@babylonjs/core/Actions";
import { ModelProps } from "react-babylonjs/dist/customComponents/Model";
import { AbstractMesh, Nullable } from "@babylonjs/core";
import { ModelFallback } from "./ModelFallback";

interface ProductModelProps extends ModelProps {
  children?: any;
  onClick?: (event: ActionEvent) => void;
  onModelLoadFinished?: (model: ILoadedModel) => void;
}

export const ProductModel = forwardRef<
  Nullable<AbstractMesh>,
  ProductModelProps
>(
  (
    {
      children = null,
      onClick = () => {},
      metadata = {},
      onModelLoadFinished = () => {},
      ...rest
    }: ProductModelProps,
    ref: any
  ) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    useEffect(() => {
      if (isLoaded && ref) {
        if (rest.position) ref.current.position = rest.position;
        if (rest.rotation) ref.current.rotation = rest.rotation;
      }
    }, [isLoaded, ref, rest.position, rest.rotation]);

    return (
      <Suspense fallback={<ModelFallback position={rest.position} />}>
        {/* @ts-ignore */}
        <Model
          {...rest}
          onCreated={(rootMesh) => {
            if (ref) ref.current = rootMesh;
          }}
          onModelLoaded={(model) => {
            if (!model.meshes) {
              throw new Error("model.meshes not set");
            }
            onModelLoadFinished(model);
            // console.log(`Load product ${rest.sceneFilename}`);

            const scene = model.meshes[0]._scene;
            const actionManagerInstance = new ActionManager(scene);
            actionManagerInstance.registerAction(
              new ExecuteCodeAction(
                {
                  trigger: ActionManager.OnPickDownTrigger,
                },
                (event) => {
                  onClick(event);
                }
              )
            );

            model.meshes.forEach((mesh, index) => {
              mesh.actionManager = actionManagerInstance;
              mesh.metadata = { ...mesh.metadata, ...metadata };
            });
            setIsLoaded(true);
          }}
        >
          {children}
        </Model>
      </Suspense>
    );
  }
);
