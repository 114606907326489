import React, { useCallback, useState } from "react";
import { notification } from "antd";
import {
  AtLeast,
  CollectionModelType,
  ItemModelType,
  ItemVersionModelType,
  ItemExtractedData,
  PartModelType,
  PartGroupModelType,
} from "../types";
import { getPartGroupModel, getPartModel } from "../utils/dataModel";
import { useParams } from "react-router-dom";
import { useUIContext } from "../contexts/UIContext";
import { useCollectionContext } from "../contexts/CollectionContext";
import { ProductsService } from "servicesNew/api";
import { createItemTree, createItemVersionTree } from "../utils/api";
import { assert } from "console";
import { format } from "date-fns";
type MaterialGroup = {
  materialIds: Set<number>;
  partGroupListOrders: Set<number>;
  partGroupNames: { [key: number]: Set<string> };
  partListOrders: Set<number>;
  partNames: { [key: number]: Set<string> };
  materialListOrders: Set<number>;
  materialNames: { [key: number]: Set<string> };
};
export const useItem = () => {
  const { updateItemByUUID } = useCollectionContext();
  const { taskWrapper } = useUIContext();
  const [partsIds, setPartsIds] = useState([]);
  const [partCloneData, setPartCloneData]: any = useState([]);
  const [groupId, setGroupId] = useState(null);

  const { companyId, orderId, itemCollectionId } = useParams();
  const companyIdExtracted = companyId !== undefined ? companyId : "";
  const updateItem = useCallback(
    async (item: AtLeast<ItemModelType, /*"_uuid" | "id" |*/ "name">) => {
      if (typeof item.id === "number" && typeof item._uuid === "string") {
        const payload = {
          name: item.name,
          ...(item.dimensions !== null &&
            item.dimensions !== undefined && { dimensions: item.dimensions }),
          ...(item.description !== null &&
            item.description !== undefined && {
              description: item.description,
            }),
          ...(item.custom_request_message !== null &&
            item.custom_request_message !== undefined && {
              custom_request_message: item.custom_request_message,
            }),
        };
        await ProductsService.productsItemsUpdate({
          id: item.id,
          data: payload,
        });

        updateItemByUUID(item._uuid, () => ({ ...item, name: item.name }));
        notification.success({
          message: "Item successfully updated!",
        });
      } else {
        console.error("Missing item id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  const addItem = useCallback(
    async ({
      collection,
      name,
      order_id,
    }: {
      collection: CollectionModelType;
      name: string;
      order_id: number;
    }) => {
      const list_order = collection.childrens.length;
      const itemTree = await createItemTree({
        collectionId: collection.id,
        name,
        order_id,
        list_order,
      });
      updateItemByUUID(collection._uuid, (_item) => {
        (_item as CollectionModelType).childrens.push(itemTree);
        return _item;
      });
      notification.success({
        message: "Item successfully created!",
      });
    },
    [updateItemByUUID]
  );
  const cloneItem = useCallback(
    async ({
      item,
      collection,
      partGroup,
      selectedItem,
    }: // itemVersion,
    {
      item: ItemModelType;
      collection: CollectionModelType;
      partGroup: any;
      selectedItem: any;
    }) => {
      const list_order = collection.childrens.length;
      const itemVersion: any = item?.childrens[0];
      const partGroupsItem = item?.childrens[0]?.childrens;
      const suffix = format(new Date(), "yyyy-MM-dd HH-mm:ss");

      //ITEMS CLONE
      // API CALL TO CLONE ITEMS
      const itemTree = await ProductsService.productsItemsCreate({
        data: {
          item_collection_id: collection?.id,
          name: `${item.name} -- clone ${suffix}`,
          order_id: item?.order_id || 0,
          list_order,
          dimensions: item?.dimensions || "",
          description: item?.description || "",
          custom_request_message: item?.custom_request_message || "",
        },
      });
      // API CALL TO CLONE ITEMS VERSION
      const itemVersionTree = await ProductsService.productsItemVersionsRead({
        id: itemTree?.current_version_id,
      });
      if (
        typeof itemVersion.id === "number" &&
        typeof itemVersion._uuid === "string"
      ) {
        const payload1: any = {
          base_price: itemVersion.base_price,
        };

        await ProductsService.productsItemVersionsUpdate({
          id: itemTree?.current_version_id,
          data: payload1,
        });

        // Check if itemVersion.custom_request_message is not null or undefined
        if (
          itemVersion.custom_request_message !== null &&
          itemVersion.custom_request_message !== undefined
        ) {
          try {
            const payload = {
              custom_request_message: itemVersion.custom_request_message,
            };

            await ProductsService.productsItemVersionsUpdate({
              id: itemTree?.current_version_id,
              data: payload,
            });
          } catch (error) {
            console.error("Failed to update item version:", error);

            // throw error; // Throw the error to propagate it further
          }
        } else {
          console.log(
            "Custom request message is null or undefined. Skipping update."
          );
        }

        updateItemByUUID(itemVersion._uuid, () => ({
          ...itemVersion,
          base_price: itemVersion.base_price,
        }));
        notification.success({
          message: "ItemVersion successfully updated!",
        });
      } else {
        console.error("Missing itemVersion id or _uuid");
      }

      const copyItemVersionThumbnails =
        await ProductsService.productsCopyItemVersionThumbnails({
          data: {
            destination_item_version_id: itemVersionTree.id,
            origin_item_version_id: itemVersion.id,
          },
        });

      // updateItemByUUID(collection._uuid, (_item) => {
      //   (_item as CollectionModelType).childrens.push(itemTree);
      //   return _item;
      // });
      notification.success({
        message: "Item successfully created!",
      });

      // PART GROUP CLONE
      // const partGroupClone = await Promise.all(
      //   itemVersion.childrens.map(async (partGroup) => {
      //     // Create a part group clone using ProductsService
      //     const partGroupClones =
      //       await ProductsService.productsPartGroupsCreate({
      //         data: {
      //           name: partGroup.name,
      //           item_version_id: itemTree?.current_version_id,
      //           list_order: partGroup?.list_order,
      //           optional: partGroup?.optional,
      //           custom_request_message:
      //             partGroup?.custom_request_message != null
      //               ? partGroup?.custom_request_message
      //               : "",
      //         },
      //       });
      //     const partGroupModel = getPartGroupModel(partGroupClones);

      //     updateItemByUUID(partGroup._uuid, (_partGroup, _itemVersion) => {
      //       (_itemVersion as ItemVersionModelType).childrens.push(
      //         partGroupModel
      //       );
      //       return {};
      //     });
      //     notification.success({
      //       message: "Part Group successfully cloned!",
      //     });
      //   })
      // );
      const partGroupClonePromises = itemVersion.childrens.map(
        async (partGroup: any) => {
          // Create a part group clone using ProductsService
          const partGroupClone = await ProductsService.productsPartGroupsCreate(
            {
              data: {
                name: partGroup.name,
                item_version_id: itemTree?.current_version_id,
                list_order: partGroup?.list_order,
                optional: partGroup?.optional,
                custom_request_message:
                  partGroup?.custom_request_message != null
                    ? partGroup?.custom_request_message
                    : "",
              },
            }
          );

          const partGroupModel = getPartGroupModel(partGroupClone);
          const copyPartGroupThumbnails =
            await ProductsService.productsCopyPartGroupThumbnails({
              data: {
                destination_part_group_id: partGroupClone.id,
                origin_part_group_id: partGroup.id,
              },
            });

          // Update item by UUID with the cloned partGroupModel
          updateItemByUUID(partGroup._uuid, (_partGroup, _itemVersion) => {
            (_itemVersion as ItemVersionModelType).childrens.push(
              partGroupModel
            );
            return {};
          });

          // Return the partGroupClone to be collected by Promise.all
          return partGroupClone;
        }
      );

      const partGroupClones: any = await Promise.all(partGroupClonePromises);

      notification.success({
        message: "Part Groups successfully cloned!",
      });

      // PART  CLONE
      const extractPartData = (data: any): any => {
        const partDataList: any = [];

        // Check if data and data.childrens are valid arrays
        if (
          !data ||
          !Array.isArray(data.childrens) ||
          data.childrens.length === 0
        ) {
          return partDataList; // Return empty array if childrens array is empty or data is invalid
        }

        // Iterate over each partGroup in itemVersion.childrens
        data.childrens.forEach((partGroup: any) => {
          // Get part_group_id from the parent data
          const partGroupId = partGroup.id;

          // Check if partGroup.childrens is a valid array
          if (
            Array.isArray(partGroup.childrens) &&
            partGroup.childrens.length > 0
          ) {
            // Iterate over each part in partGroup.childrens
            partGroup.childrens.forEach((part: any) => {
              // Extract required properties from part and construct PartData object
              const partData: any = {
                part_group_id: partGroupId,
                id: part.id,
                name: part.name,
                price_mod: part.price_mod,
                visible: part.visible,
                list_order: part.list_order,
              };

              // Push the extracted PartData object to partDataList
              partDataList.push(partData);
            });
          }
        });

        return partDataList;
      };

      const apiPayloads = extractPartData(itemVersion);
      const partGroupID = partGroupClones[0]?.id;

      const updateApiPayloads = (
        apiPayloads: any,
        partGroupsItem: any,
        clonedPartGroups: any
      ) => {
        const updatedPayloads = partGroupsItem.map((payload: any) => {
          const matchingGroup = clonedPartGroups.find(
            (group: any) => group.name === payload.name
          );

          if (matchingGroup) {
            const matchingChildren = apiPayloads.filter(
              (child: any) => child.part_group_id === payload.id
            );

            if (matchingChildren.length > 0) {
              // Update part_group_id of each matching child with matchingGroup.id
              const updatedChildren = matchingChildren.map((child: any) => ({
                ...child,
                part_group_id: matchingGroup.id,
              }));

              // Replace matching children in the original payload with updated children
              const updatedPayload = {
                ...payload,
                childrens: updatedChildren,
              };

              return updatedPayload;
            }
          }
          return payload;
        });
        return updatedPayloads;
      };
      // const handleUpdateApiPayloads = () => {
      const updatedPayloads = updateApiPayloads(
        apiPayloads,
        partGroupsItem,
        partGroupClones
      );

      const extractedChildrens = updatedPayloads.flatMap(
        (partGroup: any) => partGroup.childrens
      );

      // You can use the updatedPayloads as needed in your component
      // };

      const partCloneResponses: any = extractedChildrens.map(
        async (payload: any) => {
          // Create a part group clone using ProductsService
          const partClone = await ProductsService.productsPartsClone({
            data: {
              part_group_id: payload.part_group_id,
              name: payload.name,
              price_mod: payload.price_mod,
              visible: payload.visible,
              list_order: payload.list_order,
            },
          });
          const copyPartThumbnails =
            await ProductsService.productsCopyPartThumbnails({
              data: {
                destination_part_id: partClone.id,
                origin_part_id: payload.id,
              },
            });

          return partClone;
        }
      );

      const arrayOfObjects: any[] = [];

      // Function to extract and store objects in arrayOfObjects
      async function extractAndStoreObjects(
        partCloneResponses: Promise<any>[]
      ) {
        try {
          const resolvedObjects = await Promise.all(partCloneResponses);

          arrayOfObjects.length = 0;

          resolvedObjects.forEach((obj) => {
            arrayOfObjects.push(obj);
          });

          return arrayOfObjects;
        } catch (error) {
          console.error("Error extracting and storing objects:", error);
          throw error;
        }
      }

      //Part Association Clone
      // FUNCITON TO EXTRACT THE PARTGROUP ,PART AND ACCOCIATION DATA AND SEND TO API FUCNITON
      async function extractMatchingPartAndGroupIds(
        partData: any,
        itemVersionData: any
      ) {
        if (
          !partData.childrens ||
          !Array.isArray(partData.childrens) ||
          partData.childrens.length === 0
        ) {
          console.log("No part groups (childrens) to process.");
          return [];
        }

        const extractedDataToSend: any[] = [];

        const allGroups = itemVersionData.childrens.reduce(
          (acc: any[], partGroup: any) => {
            if (partGroup.childrens && Array.isArray(partGroup.childrens)) {
              partGroup.childrens.forEach((part: any) => {
                if (
                  part.part_association_groups &&
                  Array.isArray(part.part_association_groups) &&
                  part.part_association_groups.length > 0
                ) {
                  acc.push(...part.part_association_groups);
                }
              });
            }
            return acc;
          },
          []
        );

        allGroups.forEach((group: any) => {
          const matchingGroups = allGroups.filter((g: any) => {
            return (
              g.id === group.id &&
              g.type === group.type &&
              g.ui_message === group.ui_message
            );
          });

          if (matchingGroups.length > 1) {
            // Extract part and partGroup data for each matching group
            matchingGroups.forEach((matchingGroup: any) => {
              itemVersionData.childrens.forEach((partGroup: any) => {
                partGroup.childrens.forEach((part: any) => {
                  if (
                    part.part_association_groups &&
                    Array.isArray(part.part_association_groups)
                  ) {
                    const associatedGroup = part.part_association_groups.find(
                      (ag: any) => ag.id === matchingGroup.id
                    );

                    if (associatedGroup) {
                      const matchingPartGroup = partData.childrens.find(
                        (pg: any) => pg.id === part.part_group_id
                      );

                      if (matchingPartGroup) {
                        const matchingPart = matchingPartGroup.childrens.find(
                          (p: any) => p.id === part.id
                        );

                        if (matchingPart) {
                          // Extract necessary data
                          const extractedData = {
                            part: {
                              id: matchingPart.id,
                              name: matchingPart.name,
                              list_order: matchingPart.list_order,
                            },
                            partGroup: {
                              id: matchingPartGroup.id,
                              name: matchingPartGroup.name,
                              list_order: matchingPartGroup.list_order,
                            },
                            associationGroup: {
                              id: matchingGroup.id,
                              type: matchingGroup.type,
                              ui_message: matchingGroup.ui_message,
                            },
                          };

                          extractedDataToSend.push(extractedData);
                        }
                      }
                    }
                  }
                });
              });
            });
          }
        });

        function extractDataWithMatchingAssociation(extractedData: any[]) {
          // Create a map to store extracted data based on associationGroup key
          const associationGroupMap = new Map<string, any>();

          // Iterate through the extractedData array
          extractedData.forEach(({ part, partGroup, associationGroup }) => {
            const key = `${associationGroup.id}_${associationGroup.type}_${associationGroup.ui_message}`;

            // Ensure the associationGroup exists in the map
            if (!associationGroupMap.has(key)) {
              // Initialize with an empty object and store associationGroup data
              associationGroupMap.set(key, {
                associationGroup: {
                  id: associationGroup.id,
                  type: associationGroup.type,
                  ui_message: associationGroup.ui_message,
                },
                parts: new Map<number, any>(), // Using Map to store parts with IDs as keys
                partGroups: new Map<number, any>(), // Using Map to store partGroups with IDs as keys
              });
            }

            // Add part data to the parts map using part ID as key
            associationGroupMap.get(key).parts.set(part.id, {
              id: part.id,
              name: part.name,
              list_order: part.list_order,
            });

            // Add partGroup data to the partGroups map using partGroup ID as key
            associationGroupMap.get(key).partGroups.set(partGroup.id, {
              id: partGroup.id,
              name: partGroup.name,
              list_order: partGroup.list_order,
            });
          });

          // Prepare final output array
          const result: any[] = [];

          // Iterate through the map to aggregate data
          associationGroupMap.forEach(
            ({ associationGroup, parts, partGroups }) => {
              // Convert parts map to an array of objects
              const uniqueParts = Array.from(parts.values());

              // Convert partGroups map to an array of objects
              const uniquePartGroups = Array.from(partGroups.values());

              // Push aggregated data with associationGroup info into the result array
              result.push({
                associationGroup,
                parts: uniqueParts,
                partGroups: uniquePartGroups,
              });
            }
          );

          return result;
        }

        const result = extractDataWithMatchingAssociation(extractedDataToSend);

        if (result.length > 0) {
          for (const data of result) {
            const groupsIds = await partAssociationGroup(
              data?.associationGroup,
              data?.partGroups,
              data?.parts
            );
            await sendAssociationToApi(
              data?.associationGroup,
              data?.partGroups,
              data?.parts,
              groupsIds
            );
          }
        }

        return extractedDataToSend;
      }
      async function partAssociationGroup(
        associationGroup: { id: number; type: string; ui_message: string },
        partGroups: { id: number; name: string; list_order: number }[],
        parts: { id: number; name: string; list_order: number }[]
      ) {
        //API CALL TO MAKE NEW PART GROUPS FOR ASSOCIATION
        if (associationGroup) {
          const response =
            await ProductsService.productsPartAssociationGroupCreate({
              data: {
                ui_message: associationGroup?.ui_message,
                type: associationGroup?.type,
                item_version_id: itemVersionTree?.id,
              },
            });
          const partGroupId: any = response?.id;
          setGroupId(partGroupId);

          // updateItemByUUID(part._uuid, () => ({
          //   ...part,
          //   ui_message: part.ui_message,
          //   type: part.type,
          // }));
          notification.success({
            message: "Part Association Group successfully created!",
          });
          return response;
        } else {
          console.error("Invalid Part Association Group");
        }
      }

      async function partAssociationApi(ids: any, groupsIds: any) {
        if (ids.length > 0 && groupsIds) {
          for (const partId of ids) {
            console.log(
              `Sending association for partId: ${partId}, groupId: ${groupId}`
            );

            try {
              const associationResponse =
                await ProductsService.productsPartAssociationCreate({
                  partId: partId,
                  partAssociationGroupId: groupsIds?.id,
                  data: {
                    // Additional data can be included here as needed
                    // For example:
                    // ui_message: "Your UI message",
                    // type: "Your type",
                  },
                });

              console.log(
                `Association sent for partId: ${partId}, groupId: ${groupId}`
              );

              // Optionally handle the response if needed
              console.log("Association Response:", associationResponse);
            } catch (error) {
              console.error(
                `Error sending association for partId: ${partId}, groupId: ${groupId}`,
                error
              );
            }
          }
        }
      }
      async function sendAssociationToApi(
        associationGroup: { id: number; type: string; ui_message: string },
        partGroups: { id: number; name: string; list_order: number }[],
        parts: { id: number; name: string; list_order: number }[],
        groupsIds: any
      ) {
        function extractMatchingPartGroupIds(
          partGroups: any[],
          searchCriteria: any
        ) {
          const matchingIds: number[] = [];

          for (const criteria of searchCriteria) {
            const { name, list_order } = criteria;

            for (const partGroup of partGroups) {
              if (
                partGroup.name === name &&
                partGroup.list_order === list_order
              ) {
                matchingIds.push(partGroup.id);
              }
            }
          }

          return matchingIds;
        }
        const partGroupId = extractMatchingPartGroupIds(
          partGroupClones,
          partGroups
        );

        extractAndStoreObjects(partCloneResponses)
          .then(async (arrayOfObjects) => {
            console.log("Array of extracted objects:", arrayOfObjects);
            setPartCloneData(arrayOfObjects);
            if (arrayOfObjects.length > 0) {
              const firstObject = arrayOfObjects[0];
            }
            function extractMatchingPartIds(
              partsData: any,
              partGroupIds: number[],
              criteria: { id: number; name: string; list_order: number }[]
            ): number[] {
              const matchingPartIds: number[] = [];
              // console.log("teh", partsData.length);

              for (const criterion of criteria) {
                const { name, list_order } = criterion;

                for (const part of arrayOfObjects) {
                  if (
                    partGroupIds.includes(part.part_group_id) &&
                    part.name === name &&
                    part.list_order === list_order
                  ) {
                    matchingPartIds.push(part.id);
                  }
                }
              }

              return matchingPartIds;
            }

            const extractedPartIds = extractMatchingPartIds(
              arrayOfObjects,
              partGroupId,
              parts
            );

            const uniquePartIds: any = Array.from(new Set(extractedPartIds));

            setPartsIds(uniquePartIds);
            const sendData = await partAssociationApi(uniquePartIds, groupsIds);

            // if (uniquePartIds.length > 0 && groupId) {
            //   for (const partId of uniquePartIds) {
            //     console.log(
            //       `Sending association for partId: ${partId}, groupId: ${groupId}`
            //     );

            //     try {
            //       const associationResponse =
            //         await ProductsService.productsPartAssociationCreate({
            //           partId: partId,
            //           partAssociationGroupId: groupId,
            //           data: {
            //             // Additional data can be included here as needed
            //             // For example:
            //             // ui_message: "Your UI message",
            //             // type: "Your type",
            //           },
            //         });

            //       console.log(
            //         `Association sent for partId: ${partId}, groupId: ${groupId}`
            //       );

            //       // Optionally handle the response if needed
            //       console.log("Association Response:", associationResponse);
            //     } catch (error) {
            //       console.error(
            //         `Error sending association for partId: ${partId}, groupId: ${groupId}`,
            //         error
            //       );
            //     }
            //   }
            // }

            return arrayOfObjects;
          })
          .then((returnedArray) => {
            console.log(
              "Length of arrayOfObjects outside `then` block:",
              returnedArray.length
            );
          })
          .catch((error) => {
            console.error("Error during extraction and storing:", error);
          });
      }

      const matchingPartIds = extractMatchingPartAndGroupIds(
        item?.childrens[0],
        item?.childrens[0]
      );

      // Material clone

      // Function for cloning the materials
      async function materialCloning(
        partCloneResponses: any[],
        companyIdExtracted: any
      ): Promise<void> {
        const addedMeshMaterials: any = [];
        try {
          const partClones = await extractAndStoreObjects(partCloneResponses);
          // .then((partClones) => {
          //   console.log("Extracted Part Clones:", partClones);
          //   setPartCloneData(partClones);
          // })
          // .catch((error) => {
          //   console.error("Error extracting part clones:", error);
          //   // Handle error if extraction fails
          // });

          for (const partGroup of itemVersion.childrens) {
            console.log(
              "Part Group Name:",
              partGroup.name,
              "List Order:",
              partGroup.list_order
            );

            const matchPartGroup = (
              partGroup: any,
              newPartGroups: any,
              partGroupData: any
            ) => {
              // Extract list_order and name from partGroup
              const { list_order, name } = partGroup;

              // Find the matched part group in the newPartGroups
              const matchedPartGroup = newPartGroups.find(
                (pg: any) => pg.list_order === list_order && pg.name === name
              );

              // Extract the id of the matched part group
              if (matchedPartGroup) {
                const { id } = matchedPartGroup;

                // Find the part group data that matches the part_group_id with the extracted id
                const matchedPartGroupData = partGroupData.filter(
                  (pgData: any) => pgData.part_group_id === id
                );

                // Return the matched array
                return matchedPartGroupData;
              } else {
                return [];
              }
            };
            const matchedPartGroupData = matchPartGroup(
              partGroup,
              partGroupClones,
              partClones
            );

            for (const part of partGroup.childrens) {
              const partListOrder = part.list_order;
              const partName = part.name;

              // Find the matched part in matchedPartGroupData based on partListOrder and partName
              const matchedPart = matchedPartGroupData.find(
                (mpg: any) =>
                  mpg.list_order === partListOrder && mpg.name === partName
              );

              if (matchedPart) {
                const matchedPartId = matchedPart.id;

                for (const _meshMaterial of part.childrens) {
                  const price_mod = 0;
                  if (matchedPartId !== undefined) {
                    const response =
                      await ProductsService.productsMeshMaterialsCopy({
                        data: {
                          destination_part_id: matchedPartId,
                          company_id: Number(companyIdExtracted),
                          origin_material_ids: [_meshMaterial.id],
                          categories:
                            _meshMaterial.material_category_associations,
                        },
                      });

                    const newMeshMaterialId = response[0].id;

                    const updatedMeshMaterial =
                      await ProductsService.productsMeshMaterialsUpdate({
                        id: newMeshMaterialId,
                        data: {
                          list_order: _meshMaterial.list_order,
                          // Include more properties here as needed
                        },
                      });

                    if (price_mod > 0) {
                      await ProductsService.productsPartMaterialModifierUpdate({
                        partId: matchedPartId,
                        materialId: updatedMeshMaterial.id,
                        data: {
                          name: _meshMaterial.name,
                          price_mod: _meshMaterial.price_mod,
                          // Include more properties here as needed
                        },
                      });
                    }

                    await ProductsService.productsMeshMaterialsUpdate({
                      id: updatedMeshMaterial.id,
                      data: {
                        visible: _meshMaterial.visible,
                      },
                    });

                    updateItemByUUID(_meshMaterial._uuid, () => ({
                      ..._meshMaterial,
                      name: _meshMaterial.name,
                      price_mod: _meshMaterial.price_mod,
                      visible: _meshMaterial.visible,
                    }));

                    notification.success({
                      message: "Material successfully updated!",
                    });

                    console.log("Updated Mesh Material:", updatedMeshMaterial);

                    // Push updatedMeshMaterial to the external array
                    addedMeshMaterials.push(updatedMeshMaterial);
                  }
                }
              }
            }
          }
          // }
          if (addedMeshMaterials.length > 0) {
            return addedMeshMaterials;
          }
          // return getMeshMaterialModel(updatedMeshMaterial, price_mod);
          console.log("All parts and materials processed successfully!");
        } catch (error) {
          console.error("Error processing parts and materials:", error);
          // Handle error cases if needed
        }
      }

      // function for calling the materialCloning and returning the data
      async function materialCloneFunction() {
        const Returning = await materialCloning(
          partCloneResponses,
          companyIdExtracted
        );
        console.log("Added Mesh Materials:", Returning);

        return Returning;
      }

      const returning2 = materialCloneFunction();

      //MATERIAL ASSOCIATION
      //MATERIAL ASSOCIATION GROUP API CALLING
      //FUNCTION FOR EXTRACTING THE DATA FROM MATERIAL TO CLONE
      function extractMaterialIds(itemVersion: any) {
        const materialIdGroups: { [key: string]: MaterialGroup } = {};

        itemVersion.childrens.forEach((partGroup: any) => {
          const partGroupListOrder = partGroup.list_order;
          const partGroupName = partGroup.name;

          partGroup.childrens.forEach((part: any) => {
            const partListOrder = part.list_order;
            const partName = part.name;

            part.childrens.forEach((meshMaterial: any) => {
              const materialListOrder = meshMaterial.list_order;
              const materialName = meshMaterial.name;

              meshMaterial.material_associations.forEach((association: any) => {
                const key = `${association.ui_message}_${association.type}_${association.id}`;
                if (!materialIdGroups[key]) {
                  materialIdGroups[key] = {
                    materialIds: new Set(),
                    partGroupListOrders: new Set(),
                    partGroupNames: {},
                    partListOrders: new Set(),
                    partNames: {},
                    materialListOrders: new Set(),
                    materialNames: {}, // Change to object to store names per ID
                  };
                }
                materialIdGroups[key].materialIds.add(meshMaterial.id);
                materialIdGroups[key].partGroupListOrders.add(
                  partGroupListOrder
                );
                if (!materialIdGroups[key].partGroupNames[meshMaterial.id]) {
                  materialIdGroups[key].partGroupNames[meshMaterial.id] =
                    new Set();
                }
                materialIdGroups[key].partGroupNames[meshMaterial.id].add(
                  partGroupName
                );

                materialIdGroups[key].partListOrders.add(partListOrder);
                if (!materialIdGroups[key].partNames[meshMaterial.id]) {
                  materialIdGroups[key].partNames[meshMaterial.id] = new Set();
                }
                materialIdGroups[key].partNames[meshMaterial.id].add(partName);

                materialIdGroups[key].materialListOrders.add(materialListOrder);
                if (!materialIdGroups[key].materialNames[meshMaterial.id]) {
                  materialIdGroups[key].materialNames[meshMaterial.id] =
                    new Set();
                }
                materialIdGroups[key].materialNames[meshMaterial.id].add(
                  materialName
                );
              });
            });
          });
        });

        const result = Object.entries(materialIdGroups).map(
          ([
            key,
            {
              materialIds,
              partGroupListOrders,
              partGroupNames,
              partListOrders,
              partNames,
              materialListOrders,
              materialNames,
            },
          ]) => {
            const [ui_message, type, id] = key.split("_");
            return {
              ids: Array.from(materialIds).map((materialId) => ({
                id: materialId,
                partGroupNames: Array.from(partGroupNames[materialId] || []),
                partNames: Array.from(partNames[materialId] || []),
                materialNames: Array.from(materialNames[materialId] || []),
              })),
              ui_message,
              type,
              id,
              partGroupListOrders: Array.from(partGroupListOrders),
              partListOrders: Array.from(partListOrders),
              materialListOrders: Array.from(materialListOrders),
            };
          }
        );

        return result;
      }

      const materialData = extractMaterialIds(itemVersion);

      //FUNCTION FOR EXTRACTING ID'S AND DATA TO SEND THE API FOR MATERIAL ASSOCIATION
      const processData = async (
        data: any[],
        partCloneResponses: any,
        partGroupClones: any[],
        materialClone: any
      ) => {
        // Extract parts and store their names, ids, and list_orders
        const partsClone = await extractAndStoreObjects(partCloneResponses);
        const materialClones = await materialClone;

        // Map partGroupClones by name for quick lookup
        const partGroupCloneMap = new Map<string, number>();
        partGroupClones.forEach((partGroup) => {
          partGroupCloneMap.set(partGroup.name, partGroup.id);
        });

        // Extract ids from partGroupClones based on data
        const extractedIds = data.flatMap((dataItem) => {
          return dataItem.ids.flatMap((item: any) => {
            const ids = item.partGroupNames.flatMap((partGroupName: string) => {
              const id = partGroupCloneMap.get(partGroupName);
              return id !== undefined
                ? [
                    {
                      partGroupId: id,
                      partName: item.partNames[0],
                      materialName: item.materialNames[0],
                      type: dataItem.type,
                      ui_message: dataItem.ui_message,
                      prevGroupId: dataItem.id,
                    },
                  ]
                : [];
            });

            return ids;
          });
        });

        // Extract part_id when part_group_id matches partGroupId and partName matches
        const matchedParts = extractedIds.flatMap((extractedId) => {
          return partsClone
            .filter(
              (part: any) =>
                part.part_group_id === extractedId.partGroupId &&
                part.name === extractedId.partName
            )
            .map((part: any) => ({
              partId: part.id,
              partGroupId: part.part_group_id,
              partName: part.name,
              materialName: extractedId.materialName,
              type: extractedId.type,
              ui_message: extractedId.ui_message,
              prevGroupId: extractedId.prevGroupId,
            }));
        });

        // Extract material_id from materialClones using partId and materialName extracted from matchedParts
        const extractedMaterials = matchedParts.flatMap((matchedPart) => {
          return materialClones
            .filter(
              (material: any) =>
                material.part_id === matchedPart.partId &&
                material.name === matchedPart.materialName
            )
            .map((material: any) => ({
              materialId: material.id,
              partId: material.part_id,
              materialName: material.name,
              type: matchedPart.type,
              ui_message: matchedPart.ui_message,
              prevGroupId: matchedPart.prevGroupId,
            }));
        });

        // Grouping materials by type and ui_message
        const groupedMaterials = extractedMaterials.reduce((acc, curr) => {
          const key = `${curr.type}-${curr.ui_message}-${curr.prevGroupId}`;
          if (!acc[key]) {
            acc[key] = {
              type: curr.type,
              ui_message: curr.ui_message,
              ids: [curr.materialId],
              prevGroupId: curr.prevGroupId,
            };
          } else {
            acc[key].ids.push(curr.materialId);
          }
          return acc;
        }, {});

        const groupedMaterialsArray = Object.values(groupedMaterials);

        return groupedMaterialsArray;
      };

      const processedData = await processData(
        materialData,
        partCloneResponses,
        partGroupClones,
        returning2
      );

      // return;
      // return;
      for (const data of processedData) {
        // Type assertion to inform TypeScript about the structure of 'data'
        const dataWithIds = data as {
          ids: number[];
          ui_message: string;
          type: string;
          id: string;
        };

        // Create a new material association group
        const response =
          await ProductsService.productsMeshMaterialAssociationGroupCreate({
            data: {
              ui_message: dataWithIds?.ui_message,
              type: dataWithIds?.type,
              item_version_id: itemVersionTree?.id,
            },
          });

        notification.success({
          message: "Material Association Group successfully created!",
        });

        // // Iterate over each ID in the 'ids' array and create a material association for each ID
        for (const id of dataWithIds.ids) {
          const assocResponse =
            await ProductsService.productsMeshMaterialAssociationCreate({
              materialId: id, // Use the current ID from the 'ids' array
              materialAssociationGroupId: response?.id, // Use the ID of the created material association group
              data: {
                // You can include additional data here if needed
              },
            });

          notification.success({
            message: "Material Association successfully created!",
          });
        }
      }
      window.location.reload();
    },
    [updateItemByUUID]
  );

  const removeItem = useCallback(
    async ({ item }: { item: ItemModelType }) => {
      function extractIdsAndUuids(data: any): ItemExtractedData {
        const extractedData: ItemExtractedData = {
          level0: [],
          level1: [],
          level2: [],
          level3: [],
          level4: [],
        };

        function traverse(childrens: any[], level: number) {
          childrens.forEach((child: any) => {
            const { id, _uuid, childrens } = child;

            // Depending on the level, push the data to the respective array
            if (level === 0) {
              extractedData.level0.push({
                id: id || null,
                _uuid: _uuid || null,
              });
            } else if (level === 1) {
              extractedData.level1.push({
                id: id || null,
                _uuid: _uuid || null,
              });
            } else if (level === 2) {
              extractedData.level2.push({
                id: id || null,
                _uuid: _uuid || null,
              });
            } else if (level === 3) {
              extractedData.level3.push({
                id: id || null,
                _uuid: _uuid || null,
              });
            } else if (level === 4) {
              extractedData.level4.push({
                id: id || null,
                _uuid: _uuid || null,
              });
            }

            if (childrens && childrens.length > 0) {
              traverse(childrens, level + 1);
            }
          });
        }

        traverse([data], 0);

        return extractedData;
      }

      const extractedData = extractIdsAndUuids(item);

      // remove mesh material

      function extractMeshMaterialAssociations(data: any): {
        associationResults: Array<{
          meshMaterialId: number;
          associationIds: number[];
        }>;
        priceModResults: Array<{ materialId: number; partId: number }>;
        associationCategory: any;
      } {
        const associationResults: Array<{
          meshMaterialId: number;
          associationIds: number[];
        }> = [];
        const priceModResults: Array<{ materialId: number; partId: number }> =
          [];
        const associationCategory: any = [];

        data.childrens.forEach((childPart: any) => {
          childPart.childrens?.forEach((partGroup: any) => {
            partGroup.childrens?.forEach((part: any) => {
              part.childrens?.forEach((meshMaterial: any) => {
                // Logic for material_category_association delete
                if (
                  meshMaterial.material_category_associations &&
                  meshMaterial.material_category_associations.length > 0
                ) {
                  meshMaterial.material_category_associations.forEach(
                    (category: any) => {
                      if (category.id !== undefined) {
                        associationCategory.push({
                          meshMaterialId: meshMaterial.id,
                          categoryIds: category.id,
                        });
                      }
                    }
                  );
                  console.log(
                    "material_category_associations in part",
                    associationCategory
                  );
                }
                const associationIds: number[] = [];

                if (
                  meshMaterial.material_associations &&
                  meshMaterial.material_associations.length > 0
                ) {
                  meshMaterial.material_associations.forEach(
                    (association: any) => {
                      if (association.id !== undefined) {
                        associationIds.push(association.id);
                      }
                    }
                  );

                  associationResults.push({
                    meshMaterialId: meshMaterial.id,
                    associationIds,
                  });
                }

                if (meshMaterial.price_mod > 0) {
                  priceModResults.push({
                    materialId: meshMaterial.id,
                    partId: part.id,
                  });
                }
              });
            });
          });
        });

        return {
          associationResults,
          priceModResults,
          associationCategory,
        };
      }
      const materialAssociationsAndModifier =
        extractMeshMaterialAssociations(item);
      console.log(
        "materialAssociationsAndModifier",
        materialAssociationsAndModifier
      );
      // Api call for association category check and deleting
      for (const {
        meshMaterialId,
        categoryIds,
      } of materialAssociationsAndModifier.associationCategory) {
        if (meshMaterialId !== undefined && categoryIds !== undefined) {
          await ProductsService.productsMaterialCategoryAssociationsDelete({
            categoryId: categoryIds,
            materialId: meshMaterialId,
          });
        }
      }
      // Api call for price Mod check and deleting
      for (const {
        materialId,
        partId,
      } of materialAssociationsAndModifier.priceModResults) {
        if (materialId !== undefined && partId !== undefined) {
          await ProductsService.productsPartMaterialModifierDelete({
            partId,
            materialId,
          });
        }
      }

      // Api call for material association check and deleting
      for (const {
        meshMaterialId,
        associationIds,
      } of materialAssociationsAndModifier.associationResults) {
        // Check if associationIds array contains any values
        if (associationIds && associationIds.length > 0) {
          for (const id of associationIds) {
            await ProductsService.productsMeshMaterialAssociationDelete({
              material_id: meshMaterialId,
              material_association_group_id: id,
            });
          }
        }
      }

      // Api request to delete the material association group
      const uniqueAssociationIds: any = new Set();
      // To remove the duplicate ids
      for (const {
        associationIds,
      } of materialAssociationsAndModifier.associationResults) {
        // Add associationIds to the set to ensure uniqueness
        if (associationIds && associationIds.length > 0) {
          associationIds.forEach((id) => {
            uniqueAssociationIds.add(id);
          });
        }
      }

      for (const id of uniqueAssociationIds) {
        await ProductsService.productsMeshMaterialAssociationGroupDelete({
          material_association_group_id: id,
        });
      }

      // Api call to delete the meshmaterial
      for (const { id, _uuid } of extractedData.level4) {
        if (id !== undefined && _uuid !== undefined) {
          await ProductsService.productsMeshMaterialsDelete({ id });

          updateItemByUUID(_uuid, (_meshMaterial, _part) => {
            (_part as PartModelType).childrens = (
              _part as PartModelType
            ).childrens.filter(
              ({ _uuid: existingUuid }) => existingUuid !== _uuid
            );
            return {};
          });
        }
      }

      // Remove parts
      function extractPartIds(
        data: any
      ): Array<{ partId: number; associationGroupIds: number[] }> {
        const partAssociationResults: Array<{
          partId: number;
          associationGroupIds: number[];
        }> = [];

        data.childrens.forEach((childPart: any) => {
          childPart.childrens?.forEach((partGroup: any) => {
            partGroup.childrens?.forEach((part: any) => {
              if (
                part.part_association_groups &&
                part.part_association_groups.length > 0
              ) {
                const associationGroupIds: number[] = [];
                part.part_association_groups.forEach(
                  (associationGroup: any) => {
                    if (associationGroup.id !== undefined) {
                      associationGroupIds.push(associationGroup.id);
                    }
                  }
                );

                partAssociationResults.push({
                  partId: part.id,
                  associationGroupIds,
                });
              }
            });
          });
        });

        return partAssociationResults;
      }
      const partAssociationIds = extractPartIds(item);

      // Api call for part association check and deleting
      for (const { partId, associationGroupIds } of partAssociationIds) {
        // Check if associationIds array contains any values
        if (associationGroupIds && associationGroupIds.length > 0) {
          for (const id of associationGroupIds) {
            await ProductsService.productsPartAssociationDelete({
              part_id: partId,
              part_association_group_id: id,
            });
          }
        }
      }

      // Api request to delete the part association group
      const uniquePartAssociationIds: any = new Set();
      // To remove the duplicate ids
      for (const { associationGroupIds } of partAssociationIds) {
        // Add associationIds to the set to ensure uniqueness
        if (associationGroupIds && associationGroupIds.length > 0) {
          associationGroupIds.forEach((id) => {
            uniquePartAssociationIds.add(id);
          });
        }
      }

      for (const id of uniquePartAssociationIds) {
        await ProductsService.productsPartAssociationGroupDelete({
          part_association_group_id: id,
        });
      }

      for (const child of extractedData.level3) {
        if (child.id !== undefined && child._uuid !== undefined) {
          await ProductsService.productsPartsDelete({ id: child.id });

          updateItemByUUID(child._uuid, (_part, _partGroup) => {
            (_partGroup as ItemVersionModelType).childrens = (
              _partGroup as ItemVersionModelType
            ).childrens.filter(({ _uuid }) => _uuid !== child._uuid);
            return {};
          });
        }
      }

      // Remove part group

      for (const child of extractedData.level2) {
        if (child.id !== undefined && child._uuid !== undefined) {
          await ProductsService.productsPartGroupsDelete({ id: child.id });

          updateItemByUUID(child._uuid, (_partGroup, _itemVersion) => {
            (_itemVersion as ItemVersionModelType).childrens = (
              _itemVersion as ItemVersionModelType
            ).childrens.filter(({ _uuid }) => _uuid !== child._uuid);
            return {};
          });
        }
      }
      // Remove item-version

      for (const child of extractedData.level1) {
        if (child.id !== undefined && child._uuid !== undefined) {
          await ProductsService.productsItemVersionsDelete({ id: child.id });

          updateItemByUUID(child._uuid, (_itemVersion, _item) => {
            (_item as ItemModelType).childrens = (
              _item as ItemModelType
            ).childrens.filter(({ _uuid }) => _uuid !== child._uuid);
            return {};
          });
        }
      }

      // Remove items

      await ProductsService.productsItemsDelete({
        id: item.id,
      });
      updateItemByUUID(item._uuid, (_item, _collection) => {
        (_collection as CollectionModelType).childrens = (
          _collection as CollectionModelType
        ).childrens.filter(({ _uuid }) => _uuid !== item._uuid);
        return {};
      });
      notification.success({
        message: "Item successfully removed!",
      });
    },
    [updateItemByUUID]
  );

  return {
    updateItem: taskWrapper(updateItem),
    addItem: taskWrapper(addItem),
    removeItem: taskWrapper(removeItem),
    cloneItem: taskWrapper(cloneItem),
  };
};
