import React, { Key, useCallback, useMemo } from "react";
import {
  AppstoreAddOutlined,
  BgColorsOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Divider, Dropdown, MenuProps, Spin, Tree } from "antd";
import type { DataNode } from "antd/es/tree";
import { useLayoutsContext } from "../contexts/LayoutsContext";
import { CollectionType, ItemRemoveType, LayoutType, PartType } from "../types";
import { useMaterials } from "../hooks";
import { useUIContext } from "../contexts/UIContext";

const LabelContextMenu = ({
  part,
  children,
}: {
  part: PartType;
  children: React.ReactElement;
}) => {
  const { getItemByUUID } = useLayoutsContext();
  const { clipboardItem, setClipboardItem } = useUIContext();
  const { cloneMaterials } = useMaterials();
  const menuItems = useMemo(() => {
    const items: MenuProps["items"] = [];
    items.push({
      label: <span>Copy all materials</span>,
      key: "copy-materials",
      onClick: (e) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
        const { uuid } = part;
        const item = getItemByUUID(uuid);
        setTimeout(() => {
          if (item) setClipboardItem(item);
        }, 500);
      },
    });
    if (clipboardItem) {
      items.push({
        label: (
          <span>
            Paste materials from <i>{clipboardItem.data.part?.name}"</i>
          </span>
        ),
        key: "paste-materials",
        onClick: async (e) => {
          e.domEvent.stopPropagation();
          const { part: originPart } = clipboardItem.data;
          if (originPart) {
            await cloneMaterials({
              originPart,
              destinationPart: part,
              materials: originPart.materials,
            });
            setClipboardItem(null);
          }
        },
      });
    }

    return items;
  }, [clipboardItem, cloneMaterials, getItemByUUID, part, setClipboardItem]);

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["contextMenu"]}
      overlayClassName="layouts-explorer-context-menu"
    >
      {children}
    </Dropdown>
  );
};

interface LayoutsExplorerProps {
  collection: CollectionType | null;
  //items: ItemRemoveType | null;
  //layouts: LayoutType[];
  onSelect?: (selectedKey: string) => void;
  selectedKey?: string;
  onAddLayout?: () => void;
  disableAddLayout?: boolean;
}

export const LayoutsExplorer = ({
  collection,
  //items,
  //layouts,
  onSelect = () => {},
  selectedKey,
  onAddLayout = () => {},
  disableAddLayout = false,
}: LayoutsExplorerProps) => {
  const { isLoading } = useUIContext();
  const treeData: DataNode[] = useMemo(() => {
    if (collection) {
      return [collection].map((collection) => ({
        key: collection.uuid,
        title: (
          <span
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          >
            <b>collection: </b>
            {collection.name}
          </span>
        ),
        children: collection.items.map((item) => ({
          key: item.uuid,
          title: (
            <span
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            >
              <b>item: </b>
              {item.name}
            </span>
          ),
          children: item.layouts.map((layout) => ({
            key: layout.uuid,
            title: (
              <span
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
              >
                <b>Layout: </b>
                {layout.name}
              </span>
            ),
            children: layout.partGroups.map((partGroup) => ({
              key: partGroup.uuid,
              title: (
                <span
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                >
                  {partGroup.name}
                </span>
              ),
              icon: <AppstoreAddOutlined />,
              children: partGroup.parts.map((part) => ({
                title: (
                  <LabelContextMenu part={part}>
                    <span>
                      <b>Part: </b>
                      {part.name}
                    </span>
                  </LabelContextMenu>
                ),
                key: part.uuid,
                children: part.materials.map((material) => ({
                  key: material.uuid,
                  title: (
                    <span
                      onContextMenu={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <b>Material: </b>
                      {material.name}
                    </span>
                  ),
                  icon: <BgColorsOutlined />,
                  isLeaf: true,
                })),
              })),
            })),
          })),
        })),
      }));
    }
    return [];
  }, [collection]);

  const handleOnSelect = useCallback(
    (keys: Key[], info: any) => {
      onSelect((keys[0] as string) || "");
    },
    [onSelect]
  );

  return (
    <div className="explorer">
      <Spin spinning={isLoading} indicator={<LoadingOutlined spin />}>
        <h3 className="title">
          <span>ITEMS COLLECTION</span>
        </h3>
      </Spin>
      {!!collection && (
        <Button
          title={collection.name}
          className={`collection ${selectedKey === "collection" && "active"}`}
          type="ghost"
          onClick={() => {
            onSelect("collection");
          }}
        >
          <span>
            <b>Collection: </b>
            {collection.name}
          </span>
        </Button>
      )}
      {!!treeData.length && (
        <Tree
          showLine={true}
          defaultExpandedKeys={[treeData[0].key]}
          selectedKeys={selectedKey ? [selectedKey] : undefined}
          showIcon={true}
          onSelect={handleOnSelect}
          treeData={treeData}
        />
      )}
      {!disableAddLayout && (
        <div className="footer">
          <Divider />
          <Button
            onClick={() => {
              onAddLayout();
            }}
          >
            <PlusOutlined /> Add New Layout
          </Button>
        </div>
      )}
    </div>
  );
};
