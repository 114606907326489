import { openCreateUserModal } from "./app";
import ApiService from "services/ApiService";

export const isCreatingUser = (isCreatingUser) => {
  return {
    type: "IS_CREATING_USER",
    isCreatingUser,
  };
};

export const createUserAndLinkToCurrentCompany =
  (user, companyId) => async (dispatch) => {
    try {
      dispatch(isCreatingUser(true));
      const response = await ApiService.postUser(user);
      const responseCompany = await ApiService.postUserCompany(
        response.data.id,
        companyId
      );
      dispatch(openCreateUserModal(false));
      return { response, responseCompany };
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(isCreatingUser(false));
    }
  };
