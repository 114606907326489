import React, { PureComponent } from "react";

import { Empty } from "antd";
import { connect } from "react-redux";
import "./renderViewer.scss";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";

class RenderViewer extends PureComponent {
  constructor(props) {
    super(props);
    this.renderViewerRef = React.createRef();
    props.setRenderViewerRefFxn(this.renderViewerRef);
  }

  componentWillUnmount() {
    if (this.renderViewerRef && this.renderViewerRef.current) {
      this.renderViewerRef.current.removeEventListener(
        "variantupdate",
        this.variantUpdateEvent
      );
      this.renderViewerRef.current.removeEventListener(
        "hotspotupdated",
        this.hotspotUpdatedEvent
      );
    }
  }

  componentDidUpdate() {
    if (this.renderViewerRef && this.renderViewerRef.current) {
      this.renderViewerRef.current.addEventListener(
        "variantupdate",
        this.variantUpdateEvent
      );
      this.renderViewerRef.current.addEventListener(
        "hotspotupdated",
        this.hotspotUpdatedEvent
      );
    }
  }

  variantUpdateEvent = (e) => {
    console.log(e.detail);
  };

  hotspotUpdatedEvent = (e) => {
    this.props.setNewHotspots(e.detail);
  };

  renderClearHotspots = () => {
    if (
      this.props.clickedCommentHotspots &&
      this.props.clickedCommentHotspots.length
    )
      return (
        <div className="clear-hotspots-container">
          <div onClick={() => this.props.setClickedCommentHotspots([])}>
            Clear Hotspots
          </div>
        </div>
      );
  };

  render() {
    if (this.props.loading) {
      return (
        <FullAreaSpinner
          textColor="black"
          loadingMessage="Checking Model Status.."
        />
      );
    }

    if (!this.props.variantData.default_parts_internal[0].internal_url.glb) {
      return (
        <div className="no-model-container">
          <Empty description={null} />
          <h2>No 3D Model</h2>
        </div>
      );
    }

    return (
      <div className="render-area-container">
        {this.renderClearHotspots()}
        <bitreel-model-viewer
          portalMode={true}
          key="bitreel-model-viewer"
          id="bitreel-model-viewer"
          // variant-id=""
          hotspot=""
          item-collection-id={
            this.props.itemCollectionId +
            `${
              this.props.variantId
                ? `?defaultVariant=${this.props.variantId}`
                : ""
            }`
          }
          //variant-id={this.props.variantId}
          // variant-id={2205}
          ref={this.renderViewerRef}
          token={this.props.token}
          hide-customize={true}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ variants, auth }, ownProps) => {
  let loading = !ownProps.variantId || !variants[Number(ownProps.variantId)];

  if (loading) {
    return {
      loading,
    };
  } else {
    return {
      variantId: ownProps.variantId,
      variantData: variants[ownProps.variantId],
      token: auth.token,
    };
  }
};

export default connect(mapStateToProps, {})(RenderViewer);
