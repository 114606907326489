import { message } from "antd";
import ApiService from "../../../services/ApiService";
import FileSaver from "file-saver";
import JsZip from "jszip";
import axios from "axios";
import Promise from "bluebird";

export const downloadCompletedFbx = async (
  getItemVersionFxn,
  itemId,
  versionId
) => {
  let version = await getItemVersionFxn(itemId, versionId);
  if (version.fbx_urls.fbx_completed_url) {
    window.location.href = version.fbx_urls.fbx_completed_url;
  } else {
    message.destroy();
    message.error("No Completed FBX Zip Found");
  }
};

export const generateFbx = async ({
  companyId,
  orderId,
  itemId,
  versionNumber,
  varId,
  versionId,
  getItemVersion: getItemVersionFxn,
}) => {
  await ApiService.generateFbx({
    itemVersionId: versionId,
    companyId,
    orderId,
    itemId,
    versionNumber,
  });
  setTimeout(async () => {
    let version = await getItemVersionFxn(itemId, versionId);
    if (version.fbx_urls.fbx_url) {
      window.location.href = version.fbx_urls.fbx_url;
    } else {
      message.destroy();
      message.error("No FBX Zip Generated");
    }
  }, 3000);
};

export const downloadBlankFbx = async (
  getItemVersionFxn,
  itemId,
  versionId
) => {
  let version = await getItemVersionFxn(itemId, versionId);
  if (version.fbx_urls.fbx_url) {
    window.location.href = version.fbx_urls.fbx_url;
  } else {
    message.destroy();
    message.error("No FBX Zip Template Found");
  }
};

export const uploadFbxZip = async (
  file,
  fbxPostUrl
  // itemId,
  // versionId,
  // getItemVersionFxn
) => {
  //let version = await getItemVersionFxn(itemId, versionId);
  let uploadS3Response;
  try {
    const { url, fields } = fbxPostUrl;
    const uploadFormData = new FormData();
    Object.keys(fields).forEach((key) => {
      uploadFormData.append(key, fields[key]);
    });
    uploadFormData.append("file", file);
    const uploadS3Response = await ApiService.postToS3(url, uploadFormData);
    return uploadS3Response;
  } catch (error) {
    console.warn(error);
  }

  if (uploadS3Response.status === 204) {
    message.destroy();
    message.success("Completed FBX Uploaded!");
  } else {
    message.destroy();
    message.error("Upload Failed");
  }
};

const download = async (url) => {
  let dl = await axios.get(url, {
    responseType: "arraybuffer",
    headers: {
      Accept:
        "image/jpeg,image/jpg,text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
    },
  });
  console.log(dl);
  let obj = {};
  let psurl = dl.config.url;
  let first = psurl.split("?");
  let second = first[0].split("/");
  let third = second[second.length - 1];
  console.log("filename ==> ", third);
  obj.data = dl.data;
  obj.name = third;
  obj.fileName = third.split(".")[0];
  obj.extension = third.split(".")[1];
  console.log(obj);
  return obj;
};

const downloadByGroup = (urls, files_per_group = 20) => {
  return Promise.map(
    urls,
    async (url) => {
      return await download(url);
    },
    { concurrency: files_per_group }
  );
};

const exportZip = (blobs, itemData) => {
  const zip = JsZip();
  blobs.forEach((blob) => {
    zip.file(`${blob.name}`, blob.data);
  });
  zip.generateAsync({ type: "blob" }).then((zipFile) => {
    const fileName = `${itemData.name}_ref_files.zip`;
    message.destroy();
    message.success("Reference Files Zipped!");
    return FileSaver.saveAs(zipFile, fileName);
  });
};

const downloadAndZip = (urls, itemData) => {
  message.loading("Generating Reference Files Zip....", 45);
  return downloadByGroup(urls, 20).then((blobs) => exportZip(blobs, itemData));
};

export const downloadAllRefFiles = async (referenceFiles, itemData) => {
  // console.log(props.item.file_locker.reference_file);
  let urls = referenceFiles.map((e) => e.url + "&pleaselord=1");
  // console.log(urls);
  downloadAndZip(urls, itemData);
};
