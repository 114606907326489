import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ProductsModal } from "./addProductsModal/productsModal";
import { useProductsContext } from "modules/showrooms/contexts/ProductsContext";

interface ProductsListProps {
  onClick?: () => void;
  onAction?: () => void;
}

export const ProductsList = ({
  onClick = () => {},
  onAction = () => {},
}: ProductsListProps) => {
  const { addProduct } = useProductsContext();
  const [isOpenProductsModal, setIsOpenProductsModal] =
    useState<boolean>(false);
  return (
    <>
      <div className="products-list">
        <Tooltip title="Add new product">
          <Button
            className="add-new-product"
            onClick={() => {
              setIsOpenProductsModal(true);
            }}
          >
            <PlusOutlined />
          </Button>
        </Tooltip>
      </div>
      <ProductsModal
        open={isOpenProductsModal}
        onCancel={() => {
          setIsOpenProductsModal(false);
        }}
        onAddProduct={(_product) => {
          addProduct(_product);
          setIsOpenProductsModal(false);
        }}
      />
    </>
  );
};
