import * as React from "react";

function CloudUpload(props) {
  return (
    <svg
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.155 26.94l-4.875-4.875a.972.972 0 00-1.377 0l-4.876 4.875a.972.972 0 00.69 1.663c.25 0 .5-.095.69-.285l3.21-3.21v11.05a.976.976 0 001.95 0v-11.05l3.21 3.21a.974.974 0 101.378-1.378z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M32.275 16.96c-.47-5.483-5.083-9.801-10.683-9.801-5.913 0-10.724 4.81-10.724 10.723 0 .489.036.98.106 1.47a7.54 7.54 0 00-.35-.008c-3.896 0-7.067 3.171-7.067 7.068s3.17 7.068 7.068 7.068h6.093a.975.975 0 100-1.95h-6.093a5.124 5.124 0 01-5.119-5.118 5.123 5.123 0 015.119-5.119c.439 0 .888.063 1.338.185a.976.976 0 001.193-1.208 8.758 8.758 0 01-.338-2.39c0-4.837 3.936-8.774 8.774-8.774 4.837 0 8.773 3.937 8.773 8.774 0 .54.438.975.975.975a6.345 6.345 0 016.337 6.337 6.344 6.344 0 01-6.337 6.337h-4.875a.975.975 0 100 1.95h4.875c4.569 0 8.287-3.718 8.287-8.287 0-4.252-3.22-7.767-7.352-8.233z"
        fill="url(#prefix__paint1_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={21.592}
          y1={21.779}
          x2={21.592}
          y2={37.133}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#34D2E4" />
          <stop offset={1} stopColor="#1890FF" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={21.592}
          y1={7.159}
          x2={21.592}
          y2={33.48}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#34D2E4" />
          <stop offset={1} stopColor="#1890FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CloudUpload;
