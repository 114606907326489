import axios from "axios";
import { BACKEND_URL, GET_METHOD } from "sharedConstants";

const getItemStatuses = async function () {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/item_statuses`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export { getItemStatuses };
