import axios from "axios";
import {
  BACKEND_URL,
  DELETE_METHOD,
  GET_METHOD,
  POST_METHOD,
} from "sharedConstants";

const getBehaviors = async function (id) {
  const response = await axios({
    method: GET_METHOD,
    url: `${BACKEND_URL}/behaviors`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const postBehavior = async function (name) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/behaviors`,
    headers: { Authorization: `Bearer ${this.token}` },
    data: {
      name,
    },
  });
  return response;
};

const addVariantBehavior = async function (variantId, behaviorId) {
  const response = await axios({
    method: POST_METHOD,
    url: `${BACKEND_URL}/variant_behaviors/${variantId}/${behaviorId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

const removeVariantBehavior = async function (variantId, behaviorId) {
  const response = await axios({
    method: DELETE_METHOD,
    url: `${BACKEND_URL}/variant_behaviors/${variantId}/${behaviorId}`,
    headers: { Authorization: `Bearer ${this.token}` },
  });
  return response;
};

export {
  getBehaviors,
  postBehavior,
  addVariantBehavior,
  removeVariantBehavior,
};
