import React from "react";
import { Layout, Typography, Input, Button, Col } from "antd";
import { ShowroomsSiderMenu } from "../components/showroomsSiderMenu";
import { DetailSection } from "../components/DetailSection";
import { ImageUploader, AudioUploader } from "components/Media";
const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

export const ShowroomSettings = () => {
  return (
    <Layout className="layout layout-showroom layout-showroom--settings">
      <ShowroomsSiderMenu />
      <Content>
        <div className="settings-header">
          <div className="settings-header--content">
            <Title level={2}>Showrooms Details</Title>
            <Paragraph>
              Add your brand touches to the showroom experience.
            </Paragraph>
          </div>
        </div>

        <div className="settings-body">
          <DetailSection
            title="Showroom name"
            description={
              <>
                The showroom name appears on your showroom loading page, ontop
                of the banner image. <i>(Ex: My Showroom)</i>
              </>
            }
          >
            <Col md={24} lg={12} xl={10} xxl={6}>
              <Input />
            </Col>
          </DetailSection>

          <DetailSection
            title="Showroom Logo"
            description={
              <>
                The logo appears at the top of the browser window while
                customers are exploring the showroom. A transparent background
                is highly recommended and should work well on both light and
                dark backgrounds. <i>(.svg or .png files, 1 MB max)</i>
              </>
            }
          >
            <ImageUploader maxSize={1} />
          </DetailSection>

          <DetailSection
            title="Background / Ambience Music"
            description={
              <>
                A 3-5 min audio loop is recommended.{" "}
                <i>(.mp3 files only, 2 MB max)</i>
              </>
            }
          >
            <AudioUploader maxSize={2} />
          </DetailSection>

          <DetailSection
            title="Audio Welcome Message"
            description={
              <>
                Ex: “Welcome to the Bitreel showroom...”{" "}
                <i>(mp3 file only, 1 MB max)</i>
              </>
            }
          >
            <AudioUploader maxSize={1} />
          </DetailSection>

          <DetailSection
            title="Banner Image"
            description={
              <>
                Your showroom image will be used in several places throughout
                the customer experience. Ex: showroom loading page, emails and
                social sharing.{" "}
                <i>(recomended image size: 1920 x 1080 pixels)</i>
              </>
            }
          >
            <ImageUploader maxSize={5} />
          </DetailSection>

          <DetailSection
            title="Banner Logo"
            description={
              <>
                The banner logo appears on your showroom loading page, ontop of
                the banner image (seen above). A transparent background is
                highly recommended and should work well on dark backgrounds.
                <i>(.svg or .png files, 1 MB max)</i>
              </>
            }
          >
            <ImageUploader maxSize={1} />
          </DetailSection>

          <DetailSection
            title="Copyright Text"
            description={
              <>
                This text appears at the bottom of the showroom hamburger menu
                and share emails. <i>(Ex: © 2021 MyCompany Inc.)</i>
              </>
            }
            divider={false}
          >
            <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
          </DetailSection>
        </div>

        <div className="settings-footer">
          <Button type="primary" shape="round" ghost>
            CANCEL
          </Button>
          <Button type="primary" shape="round">
            SAVE
          </Button>
        </div>
      </Content>
    </Layout>
  );
};
