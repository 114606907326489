/* eslint-disable no-async-promise-executor */
import ApiService from "services/ApiService";
import { v4 as uuidv4 } from "uuid";

export default function loadItemVersionForEdit(itemCollectionId) {
  let loadedLayoutsForEdit = [];

  const addLayout = (apiLayout, apiItem) => {
    let layout = {
      name: apiItem.name,
      //image,
      //imageFile,
      uuid: uuidv4().replace(/-/g, ""),
      partGroups: [],
      basePrice: apiLayout.base_price,
      apiItemId: apiItem.id,
      apiVersionId: apiLayout.id,
    };
    return layout;
  };

  const addPartGroup = (apiPartgroup) => {
    let partGroup = {
      uuid: uuidv4().replace(/-/g, ""),
      name: apiPartgroup.name,
      optional: apiPartgroup.optional,
      parts: [],
      apiId: apiPartgroup.id,
    };
    return partGroup;
  };

  const addPart = (apiPart) => {
    let part = {
      name: apiPart.name,
      priceMod: apiPart.price_mod,
      materials: [],
      uuid: uuidv4().replace(/-/g, ""),
      apiId: apiPart.id,
    };
    return part;
  };

  const addMaterial = (apiMaterial, partId, materialModDict) => {
    let priceMod = 0;
    if (materialModDict[partId] && materialModDict[partId][apiMaterial.id]) {
      priceMod = materialModDict[partId][apiMaterial.id];
    }
    let material = {
      name: apiMaterial.name,
      uuid: uuidv4().replace(/-/g, ""),
      priceMod, // TODO needs to lookup from price mod dict in item version :((
      materialPriceModOverrideDefault: 0, // sigh need to look up lib price too..
      apiId: apiMaterial.id,
    };
    return material;
  };

  return new Promise(async (resolve) => {
    let itemCollectionRes = await ApiService.getItemCollection(
      itemCollectionId
    );
    let versionRes;
    await mapSeries(itemCollectionRes.data.items, async (apiItem) => {
      versionRes = await ApiService.getVersion(apiItem.current_version_id);
      let newLayout = addLayout(versionRes.data, apiItem);
      await mapSeries(versionRes.data.part_groups, async (apiPartGroup) => {
        let partGroup = addPartGroup(apiPartGroup);
        newLayout.partGroups.push(partGroup);
        await mapSeries(apiPartGroup.parts, async (apiPart) => {
          let newPart = addPart(apiPart);
          partGroup.parts.push(newPart);
          await mapSeries(apiPart.materials, (apiMaterial) => {
            let newMaterial = addMaterial(
              apiMaterial,
              apiPart.id,
              versionRes.data.extra_price_modifier
            );
            newPart.materials.push(newMaterial);
          });
        });
      });
      loadedLayoutsForEdit.push(newLayout);
    });
    resolve({
      layoutsToLoad: loadedLayoutsForEdit,
      collectionName: itemCollectionRes.data.name,
      itemVersionData: versionRes.data,
    });
  });
}

const mapSeries = async (iterable, action) => {
  for (const x of iterable) {
    await action(x);
  }
};
