import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { Tasks, TaskWrapperType } from "utils";
import { SelectedItem } from "../types";

interface UIContextValue {
  clipboardItem: SelectedItem | null;
  setClipboardItem: (value: React.SetStateAction<SelectedItem | null>) => void;
  isDirty: boolean;
  setIsDirty: (value: React.SetStateAction<boolean>) => void;
  isLoading: boolean;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  taskWrapper: TaskWrapperType;
}

const UIContext = createContext<UIContextValue>({
  clipboardItem: null,
  setClipboardItem: () => {},
  isDirty: false,
  setIsDirty: () => {},
  isLoading: false,
  setIsLoading: () => {},
  taskWrapper: () => () => {},
});

export const UIContextProvider = (props: any) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clipboardItem, setClipboardItem] = useState<SelectedItem | null>(null);
  const TasksManager = useMemo(() => new Tasks(), []);

  useEffect(() => {
    TasksManager.on("start-task", (length) => {
      setIsLoading(!!length);
    });
    TasksManager.on("end-task", (length) => {
      setIsLoading(!!length);
    });
  }, [TasksManager, setIsLoading]);

  const value = {
    clipboardItem,
    setClipboardItem,
    isDirty,
    setIsDirty,
    isLoading,
    setIsLoading,
    taskWrapper: (fn: any) => TasksManager.taskWrapper(fn),
  };

  return <UIContext.Provider value={value} {...props} />;
};

export const useUIContext = () => {
  return useContext<UIContextValue>(UIContext);
};
