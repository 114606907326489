import { history } from "services/history";
import ApiService from "services/ApiService";
import {
  ADD_OR_REPLACE_ITEM_VERSION,
  ADD_OR_UPDATE_ITEM,
  ADD_COMMENT,
  ADD_TO_ITEM_FILE_LOCKER,
  imageExtensions,
  UPDATE_ITEM_COLLECTION_ARCHIVED,
  MOVE_ITEM_COLLECTION_TO_ORDER,
} from "sharedConstants";
import { replaceInPath, getParamsFromPathname } from "utils";
import { getAndSetVariant } from "./variant";
import { store } from "store";
import { notification } from "antd";
import { setItemCollectionDetailsForOrder } from "./company";

export const addOrUpdateItem = (itemId, itemData) => {
  return {
    type: ADD_OR_UPDATE_ITEM,
    itemId,
    itemData,
  };
};

export const addToItemFileLocker = (itemId, itemType, itemData) => {
  return {
    type: ADD_TO_ITEM_FILE_LOCKER,
    itemId,
    itemData,
    itemType,
  };
};

export const moveItemCollectionToOrder =
  ({ companyId, itemCollectionId, newOrderId, oldOrderId }) =>
  async (dispatch, getState) => {
    try {
      console.log("Moving item collection:", {
        companyId,
        itemCollectionId,
        newOrderId,
        oldOrderId,
      });

      await ApiService.moveItemCollectionToOrder(itemCollectionId, newOrderId);

      dispatch({
        type: MOVE_ITEM_COLLECTION_TO_ORDER,
        payload: {
          companyId,
          itemCollectionId,
          newOrderId,
          oldOrderId,
        },
      });

      // Refresh the new order details
      dispatch(setItemCollectionDetailsForOrder(companyId, newOrderId, {}));

      // Optionally, refresh the old order details if needed
      // dispatch(setItemCollectionDetailsForOrder(companyId, oldOrderId, {}));
    } catch (e) {
      console.error("Error moving item collection:", e);
    }
  };

export const updateItemCollectionArchived =
  ({ companyId, orderId, itemCollectionId, archived }) =>
  async (dispatch) => {
    try {
      await ApiService.archiveItemCollectionsOrVariants(archived, [
        itemCollectionId,
      ]);
      dispatch({
        type: UPDATE_ITEM_COLLECTION_ARCHIVED,
        payload: {
          companyId,
          orderId,
          itemCollectionId,
          archived,
        },
      });
      dispatch(setItemCollectionDetailsForOrder(companyId, orderId, {}));
    } catch (e) {
      /* empty */
    }
  };

export const addOrReplaceItemVersion = (itemId, versionData) => {
  return {
    type: ADD_OR_REPLACE_ITEM_VERSION,
    itemId,
    versionData,
  };
};

export const getItemVersion = (itemId, versionId) => async (dispatch) => {
  let versionRes = await ApiService.getItemVersion(versionId);
  await dispatch(addOrReplaceItemVersion(itemId, versionRes.data));
  return versionRes.data;
};

export const getAndSetItem =
  (itemId, updateUrl = true) =>
  async (dispatch) => {
    try {
      const response = await ApiService.getItemCopy(itemId);
      const { data } = response;
      dispatch(addOrUpdateItem(itemId, data));
      if (updateUrl) {
        // dispatch(
        //   replace(
        //     replaceInPath("itemIdInUrl", data.id, window.location.pathname)
        //   )
        // );
        history.replace(
          replaceInPath("itemIdInUrl", data.id, window.location.pathname)
        );
      }
      return response;
    } catch (error) {
      console.warn(error);
    } finally {
      /* empty */
    }
  };

export const patchItem = (itemId, itemData) => async (dispatch) => {
  try {
    let res = await ApiService.patchItem(itemId, itemData);
    dispatch(addOrUpdateItem(itemId, res.data));
    return res.data;
  } catch (e) {
    console.warn("error patching item", e.message);
  }
};

export const patchItemVersion =
  (itemId, versionId, versionData) => async (dispatch) => {
    try {
      let versionResponse = await ApiService.patchItemVersion(
        versionId,
        versionData
      );
      dispatch(addOrReplaceItemVersion(itemId, versionResponse.data));
      return versionResponse.data;
    } catch (e) {
      console.warn("error patching item", e.message);
    }
  };

export const getAndSetItemCollectionWithAll =
  (itemCollectionId) => async (dispatch) => {
    try {
      let { companyIdInUrl, versionIdInUrl, itemIdInUrl, variantIdInUrl } =
        getParamsFromPathname(window.location.pathname);
      let collectionResponse = await ApiService.getItemCollection(
        itemCollectionId
      );
      let item;
      if (itemIdInUrl) {
        let found = collectionResponse.data.items.find(
          (item) => item.id === Number(itemIdInUrl)
        );
        if (found) {
          item = found;
        } else {
          item = collectionResponse.data.items[0];
        }
      } else {
        item = collectionResponse.data.items[0];
      }
      if (item) {
        await dispatch(getAndSetItem(item.id));
        let versionReq = await ApiService.getItemVersion(
          versionIdInUrl || item.current_version_id
        );
        const versionData = versionReq.data;
        dispatch(addOrReplaceItemVersion(item.id, versionData));
        // dispatch(
        //   replace(
        //     replaceInPath(
        //       "versionIdInUrl",
        //       versionData.id,
        //       window.location.pathname
        //     )
        //   )
        // );
        history.replace(
          replaceInPath(
            "versionIdInUrl",
            versionData.id,
            window.location.pathname
          )
        );
        if (versionData.variants[0]) {
          dispatch(getAndSetVariant(variantIdInUrl || versionData.variants[0]));
        } else {
          window.alert("item has no variants..");
          // dispatch(push(`/${companyIdInUrl}/products`));
          history.push(`/${companyIdInUrl}/products`);
        }
      } else {
        window.alert("collection has no items...");
        // dispatch(push(`/${companyIdInUrl}/products`));
        history.push(`/${companyIdInUrl}/products`);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      /* empty */
    }
  };

export const setETLdisabled =
  ({ itemId, itemVersionId, disableETL }) =>
  async (dispatch) => {
    let disableETLResponse = await ApiService.bulkDisableETLForItem({
      itemVersionId,
      disableETL,
    });
    dispatch(
      addOrUpdateItem(itemId, {
        disable_etl: disableETL,
      })
    );
    return disableETLResponse.data;
  };

export const createNewItemVersion = (itemId) => async (dispatch) => {
  let newVersionResponse = await ApiService.postItemVersion({
    item_id: itemId,
  });
  return newVersionResponse.data;
};

const addComment = (itemId, itemVersionId, commentData) => {
  let { profile } = store.getState();
  return {
    type: ADD_COMMENT,
    itemVersionId,
    commentData,
    itemId,
    email: profile.email,
  };
};

export const addCommentForItemVersion =
  (itemId, itemVersionId, commentBody, hotspots) => async (dispatch) => {
    try {
      let res = await ApiService.postItemNote(commentBody, itemVersionId);
      dispatch(addComment(itemId, itemVersionId, res.data));
      let promises = [];
      hotspots.forEach((hotspot) => {
        promises.push(
          ApiService.postItemAnnotation({
            item_note_id: res.data.id,
            item_id: itemId,

            pX: hotspot._x,
            pY: hotspot._y,
            pZ: hotspot._z,

            nX: 1,
            nY: 1,
            nZ: 1,
          })
        );
      });
      await Promise.all(promises);
      notification.success({
        message: "Comment Added",
        duration: 1.5,
      });
      return true;
    } catch (e) {
      notification.error({
        message: "Bad Request",
        description: e.message,
        duration: 1.5,
      });
      return false;
    }
  };

export const addToItemFilelocker =
  (itemId, fileData, fileType, fileName) => async (dispatch) => {
    try {
      const s3UrlResponse = await ApiService.postFileLocker(itemId, {
        key: fileType,
        file_name: fileName,
      });
      const s3UrlData = s3UrlResponse.data;
      const { url, fields } = s3UrlData.url;
      const uploadFormData = new FormData();

      Object.keys(fields).forEach((key) => {
        uploadFormData.append(key, fields[key]);
      });

      uploadFormData.append("file", fileData);
      const s3UploadResponse = await ApiService.postToS3(url, uploadFormData);

      let ext = fileData.name.split(".").pop();
      dispatch(
        addToItemFileLocker(itemId, fileType, {
          id: s3UrlResponse.data.id,
          url: URL.createObjectURL(fileData),
          file_name: fileData.name,
          localImageUploadBlob: imageExtensions.includes(ext),
        })
      );

      return s3UploadResponse.data;
    } catch (error) {
      console.warn(error);
    }
  };
