import React, { useEffect, useMemo, useState } from "react";
import { Tooltip } from "antd";
import { PlayCircleFilled, PauseCircleFilled } from "@ant-design/icons";

export const AudioPlayer = ({ source }: { source: string }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audio = useMemo(() => {
    const audioObject = new Audio(source);
    audioObject.onended = () => {
      setIsPlaying(false);
    };
    return audioObject;
  }, [source]);

  useEffect(() => {
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [audio, isPlaying]);

  useEffect(() => () => {
    audio.pause();
    audio.currentTime = 0;
  });

  return (
    <div className="audio-player">
      <Tooltip
        placement="left"
        title={isPlaying ? "Stop" : "Play"}
        mouseEnterDelay={0.2}
        mouseLeaveDelay={0.2}
      >
        {isPlaying ? (
          <PauseCircleFilled
            className="pause-button"
            onClick={() => {
              setIsPlaying(false);
            }}
          />
        ) : (
          <PlayCircleFilled
            className="play-button"
            onClick={() => {
              setIsPlaying(true);
            }}
          />
        )}
      </Tooltip>
    </div>
  );
};
