import React, { useMemo, useRef, useState } from "react";
import { Input, InputRef } from "antd";
import { ColumnType } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import _ from "lodash";

export const useCommenterTableSearch = <DataType,>() => {
  type DataIndex = keyof DataType;

  const [searchText, setSearchText] = useState("");
  const searchInput = useRef<InputRef>(null);

  const setSearchTextDebounced = useMemo(
    () => _.debounce((fn: () => void) => fn(), 500),
    []
  );

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div
        style={{ padding: 8, maxWidth: "300px" }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search for ${dataIndex as string}`}
          value={selectedKeys[0] || ""}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedKeys(value ? [value] : []);
            setSearchTextDebounced(() => {
              setSearchText(value);
              confirm({ closeDropdown: false });
            });
          }}
          onPressEnter={() => {
            setSearchText((selectedKeys[0] as string) || "");
            confirm({ closeDropdown: false });
          }}
          allowClear
        />
      </div>
    ),
    onFilter: (value, record) =>
      (record[dataIndex] as string)
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  return {
    getColumnSearchProps,
  };
};
