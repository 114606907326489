/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Variant = {
    readonly id?: number;
    readonly created_at?: string;
    readonly updated_at?: string;
    is_active?: boolean;
    is_deleted?: boolean;
    deleted_at?: string | null;
    old_id?: number | null;
    name?: string | null;
    sub_sku?: string | null;
    description?: string | null;
    price?: string | null;
    dimensions?: string | null;
    product_url?: string | null;
    depth_en?: string | null;
    width_en?: string | null;
    height_en?: string | null;
    length_en?: string | null;
    diameter_en?: string | null;
    depth_ca?: string | null;
    width_ca?: string | null;
    height_ca?: string | null;
    length_ca?: string | null;
    diameter_ca?: string | null;
    dimension_alt?: string | null;
    starting_at?: boolean | null;
    product_url_ca?: string | null;
    flip_array?: any;
    disable_etl?: boolean;
    archived?: boolean;
    clickable?: boolean;
    in_inventory?: boolean;
    disable_add_to_cart?: boolean;
    metadata_external_id?: string | null;
    details_camera_start_position_x?: number | null;
    details_camera_start_position_y?: number | null;
    details_camera_start_position_z?: number | null;
    details_light_intensity?: number | null;
    is_external_asset?: boolean;
    status?: Variant.status;
    thumbnail?: string | null;
    images?: Array<string>;
    parts?: Array<number>;
    mesh_materials?: Array<number>;
};

export namespace Variant {

    export enum status {
        CREATED = 'Created',
        CLIENT_REVIEW = 'Client Review',
        BITREEL_REVIEW = 'Bitreel Review',
        FINALIZED = 'Finalized',
    }


}

