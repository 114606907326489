import React from "react";
import { Layout, Typography } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { ShowroomsSiderMenu } from "../components/showroomsSiderMenu";
import { ItemCard } from "./itemCard";
import FullAreaSpinner from "components/Loaders/FullAreaSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { useSpaceContext } from "../contexts/SpaceContext";
import { useAppSelector } from "hooks";
const { Content } = Layout;
const { Title, Paragraph } = Typography;

export const ShowroomOverview = () => {
  const { companyId, showroomId, spaceId, spaceVersionId } = useParams();
  const { isLoadingSpace, space } = useSpaceContext();
  const { admin } = useAppSelector((state: any) => state.profile);
  const navigate = useNavigate();

  /************* Actions **************/
  const handleOnLookAndFeelClick = () => {
    navigate(
      `/${companyId}/showrooms/${showroomId}/spaces/${spaceId}/version/${spaceVersionId}/look-and-feel`
    );
  };
  const handleOnFloorPlanClick = () => {
    navigate(
      `/${companyId}/showrooms/${showroomId}/spaces/${spaceId}/version/${spaceVersionId}/floor-plan`
    );
  };
  const handleOnMerchandisingClick = () => {
    navigate(
      `/${companyId}/showrooms/${showroomId}/spaces/${spaceId}/version/${spaceVersionId}/merchandising`
    );
  };
  const handleOnFinalReviewClick = () => {
    navigate(
      `/${companyId}/showrooms/${showroomId}/spaces/${spaceId}/version/${spaceVersionId}/final-review`
    );
  };
  const handleOnSettingsClick = () => {
    navigate(
      `/${companyId}/showrooms/${showroomId}/spaces/${spaceId}/version/${spaceVersionId}/settings`
    );
  };
  /************************************/

  return !space ? (
    <FullAreaSpinner />
  ) : (
    <Layout className="layout layout-showroom layout-showroom--overview">
      <ShowroomsSiderMenu />
      <Content>
        <div className="overview-header">
          <div className="overview-header--content">
            <Title level={2}>Let's get your showroom online.</Title>
            <Paragraph>
              We'll guide you step-by-step on how to build your virtual
              showroom, get your products ready and curated, then share it with
              the world.
            </Paragraph>
          </div>
        </div>

        <div className="overview-body">
          {!!admin && (
            <ItemCard
              title={
                <>
                  <b>
                    Look & Feel <DoubleRightOutlined />
                  </b>
                </>
              }
              description="Describe floorplan and rooms specs..."
              buttonText="GET STARTED"
              onClick={handleOnLookAndFeelClick}
            />
          )}

          <ItemCard
            title={
              <>
                <b>Step 1:</b> Showroom Floor Plan
              </>
            }
            description="Manage your floorplan and showroom specs..."
            progress={75}
            onClick={handleOnFloorPlanClick}
          />
          <ItemCard
            title={
              <>
                <b>Step 2:</b> Showroom Merchandising
              </>
            }
            description="Drag and drop products into your showroom..."
            // disabled={!space?.step_1_complete}
            // buttonText={
            //   !space?.step_1_complete ? "COMPLETE STEP 1" : "GET STARTED"
            // }
            buttonText="GET STARTED"
            onClick={handleOnMerchandisingClick}
          />
          <ItemCard
            title={
              <>
                <b>Step 3:</b> Showroom “Design Touches”
              </>
            }
            description="Manage final touches like lighting, decor, textures, exterior renderings..."
            disabled={!space?.step_2_complete}
            buttonText={
              !space?.step_2_complete ? "COMPLETE STEP 2" : "GET STARTED"
            }
            onClick={handleOnFinalReviewClick}
          />
          <ItemCard
            title="Showroom Details & Descriptions"
            description="Manage your showroom's finishing touches like banner images, logos, music, and welcome messages."
            progress={100}
            disabled={true}
            //onClick={handleOnSettingsClick}
          />
        </div>
      </Content>
    </Layout>
  );
};
