import React from "react";
import { Button, Form, Input, Image } from "antd";
import { useMaterials } from "../hooks";
import { MaterialType, SelectedItem } from "./../types";
import { useUIContext } from "../contexts/UIContext";

interface MaterialViewProps {
  selectedItem: SelectedItem;
}

export const MaterialView = ({ selectedItem }: MaterialViewProps) => {
  const { updateMaterial } = useMaterials();
  const { isDirty, setIsDirty } = useUIContext();
  const material = selectedItem.item as MaterialType;
  const {
    data: { part },
  } = selectedItem;

  const onFinish = async (values: any) => {
    if (part) {
      await updateMaterial(part, {
        uuid: material.uuid,
        name: values.name,
        apiId: material.apiId,
        priceMod: values.priceMod,
      });
      setIsDirty(false);
    }
  };

  return (
    <div className="material-view">
      <Form
        name="material-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: material.name,
          priceMod: material.priceMod || 0,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Material Price"
          tooltip={`Default Library Price $${
            material.materialPriceModOverrideDefault || 0
          }`}
          name="priceMod"
          rules={[
            { required: true, message: "Please input the material price!" },
          ]}
        >
          <Input addonBefore="$" type="number" />
        </Form.Item>

        <Form.Item label="Material Image">
          <Image src={material.imageUrl} />
        </Form.Item>

        <Form.Item>
          <div className="commit-buttons">
            <Button disabled={!isDirty} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              htmlType="reset"
              onClick={() => {
                setIsDirty(false);
              }}
            >
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
