import React, { Component } from "react";
import { withRouter } from "utils";
import { Card, Col, Row, Space, Statistic, Typography, message } from "antd";
import { Form, Input, Button } from "antd";
import { connect } from "react-redux";
import { selectLoginStage } from "store/actions/app";

import {
  validateContainNumber,
  validateEmail,
  validateLowerCase,
  validateMatch,
  validateMinimumLength,
  validateSpecialCharacter,
  validateUpperCase,
} from "utils";
import { SIGN_IN } from "sharedConstants";
import AmplifyService from "services/AmplifyService";
const { Text, Link } = Typography;
const { Countdown } = Statistic;

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.sendCode = this.sendCode.bind(this);
  }

  loginFormRef = React.createRef();

  onFinish = (values) => {
    this.setState({ ...this.state, submitLoading: true });
    AmplifyService.forgotPassword(
      values.email,
      values.verificationCode,
      values.newPassword
    )
      .then((data) => {
        message.success("Success! Please login in again!").then((r) => {
          this.setState({ ...this.state, submitLoading: false });
          this.props.selectLoginStage(SIGN_IN);
        });
      })
      .catch((err) => {
        message.error("Password reset failed!");
        console.log(err);
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  state = {
    countdown: false,
    countdownValue: 60,
    countdownLoading: false,
    submitLoading: false,
  };

  sendCode() {
    this.loginFormRef.current
      .validateFields(["email"])
      .then(() => {
        this.setState({ ...this.state, countdownLoading: true });
        AmplifyService.sendVerificationCode(
          this.loginFormRef.current.getFieldValue("email")
        )
          .then((data) => {
            this.setState({
              countdownValue: 60,
              countdown: true,
              countdownLoading: false,
            });
            this.interval = setInterval(() => {
              this.setState({
                countdownValue: this.state.countdownValue - 1,
                countdown: true,
              });
              if (this.state.countdownValue === 0) {
                this.setState({ countdownValue: 60, countdown: false });
                clearInterval(this.interval);
              }
            }, 1000);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <Col offset={4} span={16}>
        <Row className="content_row" justify="center" align="middle">
          <h1>Forgot your password?</h1>
        </Row>
        <Form
          name="basic"
          // labelCol={{ span: 14, offset: 5 }}
          // wrapperCol={{ span: 14, offset: 5 }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          autoComplete="off"
          layout="vertical"
          ref={this.loginFormRef}
        >
          <Form.Item label="Email" required>
            <Form.Item
              name="email"
              noStyle
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  validator: (_, value) => validateEmail(value),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Button
              block
              disabled={this.state.countdown}
              loading={this.state.countdownLoading}
              onClick={() => this.sendCode()}
            >
              {" "}
              {this.state.countdown
                ? this.state.countdownValue
                : "Send Verification Code"}
            </Button>
          </Form.Item>

          <Form.Item
            label="Verification code"
            name="verificationCode"
            rules={[
              {
                required: true,
                message: "Please input your verification code!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="New password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                validator: (_, value) => validateLowerCase(value),
              },
              {
                validator: (_, value) => validateUpperCase(value),
              },
              {
                validator: (_, value) => validateSpecialCharacter(value),
              },
              {
                validator: (_, value) => validateContainNumber(value),
              },
              {
                validator: (_, value) => validateMinimumLength(value),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item label="Confirm new password" required>
            <Form.Item
              name="confirmPassword"
              noStyle
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return validateMatch(getFieldValue("newPassword"), value);
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={this.state.submitLoading}
            >
              Submit
            </Button>
            <Form.Item>
              <Text>Already have an account? </Text>
              <Text underline>
                <a onClick={() => this.props.selectLoginStage(SIGN_IN)}>
                  Sign in
                </a>
              </Text>
            </Form.Item>
          </Form.Item>
        </Form>
      </Col>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  selectLoginStage,
})(withRouter(ForgotPasswordForm));
