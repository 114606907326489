import * as React from "react";

function PlusOutlinedYellow(props) {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={10.714} cy={10.032} r={9.5} fill="#FFD43A" />
      <path
        d="M9.973 10.758H6.714V9.322h3.26v-3.29h1.473v3.29h3.268v1.436h-3.268v3.274H9.973v-3.274z"
        fill="#000"
      />
    </svg>
  );
}

export default PlusOutlinedYellow;
