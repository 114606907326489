import { uniqueId } from "lodash";
import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { ShowroomProduct } from "../types";

export const enum HistoryActions {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

const limit = 50;

// Clone state to be immutable
const cloneState = (showroomProduct: ShowroomProduct) => ({
  ...showroomProduct,
  placement: JSON.parse(JSON.stringify(showroomProduct.placement)),
});

export type Transition = {
  action: HistoryActions;
  sessionId: string;
  data?: {
    position?: { x?: true; y?: true; z?: true };
    rotation?: { x?: true; y?: true; z?: true };
    [key: string]: any;
  };
};

export type TransitionDataCallback = ({
  startState,
  endState,
}: {
  startState: ShowroomProduct | null;
  endState: ShowroomProduct | null;
}) => Transition["data"] | void;

export type HistoryItem = {
  uuid: string;
  transition: Transition;
  stateBefore: ShowroomProduct | null;
  stateAfter: ShowroomProduct | null;
};

export interface ProductsHistoryContextValue {
  historyStake: HistoryItem[];
  stakePointerIndex: number;
  activeHistoryItem: HistoryItem | null;
  createHistorySession: (
    transition: Transition,
    transitionDataCallback?: TransitionDataCallback
  ) => {
    startState: (stateBefore: ShowroomProduct | null) => void;
    endState: (stateAfter: ShowroomProduct | null) => void;
    clearSession: () => void;
  };
  goBackward: (
    callback: (historyItem: HistoryItem) => Promise<void> | void
  ) => void;
  goForward: (
    callback: (historyItem: HistoryItem) => Promise<void> | void
  ) => void;
  enableBackward: boolean;
  enableForward: boolean;
}

const ProductsHistoryContext = createContext<ProductsHistoryContextValue>({
  historyStake: [],
  stakePointerIndex: -1,
  activeHistoryItem: null,
  createHistorySession: () => ({
    startState: () => {},
    endState: () => {},
    clearSession: () => {},
  }),
  goBackward: () => {},
  goForward: () => {},
  enableBackward: false,
  enableForward: false,
});

export const ProductsHistoryContextProvider = (props: any) => {
  /*************** State *****************/
  const [historyStake, setHistoryStake] = useState<HistoryItem[]>([]);
  const [stakePointerIndex, setStakePointerIndex] = useState<number>(
    historyStake.length - 1
  );
  const sessionRef = useRef<{ [key: string]: any }>({});
  const { enableBackward, enableForward } = useMemo(
    () => ({
      enableBackward: stakePointerIndex > -1,
      enableForward: stakePointerIndex < historyStake.length - 1,
    }),
    [historyStake.length, stakePointerIndex]
  );
  const activeHistoryItem = useMemo(
    () => historyStake[stakePointerIndex] || null,
    [historyStake, stakePointerIndex]
  );
  /***************************************/

  /************** Generics ***************/
  const generateNewHistoryStake = useCallback(() => {
    let historyStakeCopy = [...historyStake];
    const lastIndex = historyStakeCopy.length - 1;
    // If user has traveled into history and changes
    if (stakePointerIndex < lastIndex) {
      historyStakeCopy = historyStakeCopy.slice(0, stakePointerIndex + 1);
    }
    // If the history reach the limit
    if (historyStakeCopy.length >= limit - 1) {
      historyStakeCopy = historyStakeCopy.slice(
        -limit,
        historyStakeCopy.length
      );
    }
    return historyStakeCopy;
  }, [historyStake, stakePointerIndex]);
  /***************************************/

  /*************** Actions ***************/
  const createHistorySession = useCallback(
    (
      transition: Transition,
      transitionDataCallback?: TransitionDataCallback
    ) => {
      return {
        clearSession: () => delete sessionRef.current[transition.sessionId],
        startState: (stateBefore: ShowroomProduct | null) => {
          if (typeof sessionRef.current[transition.sessionId] === "undefined") {
            sessionRef.current[transition.sessionId] = stateBefore
              ? cloneState(stateBefore)
              : null;
          }
        },
        endState: (stateAfter: ShowroomProduct | null) => {
          if (typeof sessionRef.current[transition.sessionId] !== "undefined") {
            const historyItem: HistoryItem = {
              uuid: uniqueId("history-item-"),
              transition: { ...transition },
              stateBefore: sessionRef.current[transition.sessionId],
              stateAfter: stateAfter ? cloneState(stateAfter) : null,
            };
            const transitionData = transitionDataCallback
              ? transitionDataCallback({
                  startState: historyItem.stateBefore,
                  endState: historyItem.stateAfter,
                })
              : null;
            if (transitionData) historyItem.transition.data = transitionData;
            const newHistoryState = [...generateNewHistoryStake(), historyItem];
            setHistoryStake(newHistoryState);
            setStakePointerIndex(newHistoryState.length - 1);
            delete sessionRef.current[transition.sessionId];
          }
        },
      };
    },
    [generateNewHistoryStake]
  );
  const goBackward = useCallback(
    async (callback?: (historyItem: HistoryItem) => Promise<void> | void) => {
      if (enableBackward) {
        if (callback) await callback(historyStake[stakePointerIndex]);
        setStakePointerIndex((prevIndex) => prevIndex - 1);
      }
    },
    [enableBackward, historyStake, stakePointerIndex]
  );
  const goForward = useCallback(
    async (callback?: (historyItem: HistoryItem) => Promise<void> | void) => {
      if (enableForward) {
        if (callback) await callback(historyStake[stakePointerIndex + 1]);
        setStakePointerIndex((prevIndex) => prevIndex + 1);
      }
    },
    [enableForward, historyStake, stakePointerIndex]
  );
  /***************************************/

  const value = {
    historyStake,
    stakePointerIndex,
    activeHistoryItem,
    createHistorySession,
    goBackward,
    goForward,
    enableBackward,
    enableForward,
  };

  return <ProductsHistoryContext.Provider value={value} {...props} />;
};

export const useProductsHistoryContext = () => {
  return useContext<ProductsHistoryContextValue>(ProductsHistoryContext);
};
