import React, { Component } from "react";
import { withRouter } from "utils/withRouter";
import { connect } from "react-redux";
import _ from "lodash";

import { Spin, Modal, Form, Input, Select } from "antd";
import { setModalState } from "store/actions/app";
import { createOrder } from "store/actions/company";
import { getParamsFromPathname } from "../../../../utils/params";

let setOrder = _.noop;
let openCreateOrderModal = _.noop;

class CreateOrderModal extends Component {
  orderFormRef = React.createRef();

  onOrderFinish = async () => {
    const { setModalState, companyIdInUrl } = this.props;
    const data = await this.orderFormRef.current.validateFields();

    await this.props.createOrder(companyIdInUrl, data.name);
    setModalState("createOrder", { visible: false });
  };

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  render() {
    const { setModalState, isLoading, modalState } = this.props;

    return (
      <>
        <Modal
          title={"Create Order"}
          open={modalState.visible}
          onOk={this.onOrderFinish}
          onCancel={() => setModalState("createOrder", { visible: false })}
          destroyOnClose={true}
          cancelText="Cancel"
          okText="Create"
          okButtonProps={{
            disabled: isLoading,
            className: "bitreel-button-primary",
          }}
          cancelButtonProps={{
            disabled: isLoading,
            className: "bitreel-button",
          }}
          closable={!isLoading}
          maskClosable={!isLoading}
        >
          <Spin spinning={isLoading}>
            <Form
              layout={"vertical"}
              name="order"
              onFinish={this.onOrderFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.orderFormRef}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input new order's name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (
  {
    app: {
      modalStates: { createOrder: modalState },
    },
  },
  ownProps
) => {
  let { companyIdInUrl } = getParamsFromPathname(window.location.pathname);
  return {
    modalState,
    isLoading: false,
    companyIdInUrl,
  };
};

export default connect(mapStateToProps, {
  openCreateOrderModal,
  setOrder,
  setModalState,
  createOrder,
})(withRouter(CreateOrderModal));
