import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import {
  SelectedItem,
  LayoutType,
  MixType,
  ItemRemoveType,
  CollectionType,
} from "../types";

const collectionWalker = (
  iterable: any[],
  callback: (item: MixType, parent: MixType | null) => void | boolean,
  parent: MixType | null = null
) => {
  for (const i of iterable) {
    const response = callback(i as MixType, parent);
    if (response) return;
    const newIterable: any[] =
      i.items || i.layouts || i.partGroups || i.parts || i.materials || [];
    if (newIterable.length) {
      collectionWalker(newIterable, callback, i);
    }
  }
};

interface LayoutsContextValue {
  companyId: number;
  orderId: number;
  itemCollectionId: number | null;
  items: ItemRemoveType | null;
  setItems: (value: React.SetStateAction<ItemRemoveType[]>) => void;
  collection: CollectionType | null;
  setCollection: (value: React.SetStateAction<CollectionType>) => void;
  layouts: LayoutType[];
  setLayouts: (value: React.SetStateAction<LayoutType[]>) => void;
  selectedKey: string;
  setSelectedKey: (value: React.SetStateAction<string>) => void;
  selectedItem: SelectedItem | null;
  getItemByUUID: (uuid: string) => SelectedItem | null;
  updateItemByUUID: (
    uuid: string,
    callback: (item: MixType, parent: MixType | null) => Partial<MixType>
  ) => void;
}

const LayoutsContext = createContext<LayoutsContextValue>({
  companyId: 0,
  orderId: 0,
  itemCollectionId: null,
  items: null,
  setItems: () => {},
  collection: null,
  setCollection: () => {},
  layouts: [],
  setLayouts: () => {},
  selectedKey: "",
  setSelectedKey: () => {},
  selectedItem: null,
  getItemByUUID: () => null,
  updateItemByUUID: () => {},
});

export const LayoutsContextProvider = (props: any) => {
  const params = useParams();
  const [collection, setCollection] = useState<CollectionType | null>(null);
  const [items, setItems] = useState<ItemRemoveType[]>([]);
  const [layouts, setLayouts] = useState<LayoutType[]>([]);
  const [selectedKey, setSelectedKey] = useState<string>("");
  const { companyId, orderId, itemCollectionId } = useMemo(
    () => ({
      companyId: Number(params.companyId),
      orderId: Number(params.orderId),
      itemCollectionId: Number(params.itemCollectionId) || null,
    }),
    [params.companyId, params.itemCollectionId, params.orderId]
  );
  const reverseMapByUUID = useMemo(() => {
    const mapped: { [key: string]: SelectedItem } = {};
    if (collection) {
      mapped[collection.uuid] = {
        uuid: collection.uuid,
        _uuid: collection._uuid,
        id: collection.id,
        type: "collection",
        name: collection.name,
        item: collection,
        data: { collection },
      };
      collection.items.forEach((item) => {
        mapped[item.uuid] = {
          uuid: item.uuid,
          _uuid: item._uuid,
          id: item.id,
          type: "item",
          name: item.name,
          item: item,
          data: { collection, item },
        };
        item.layouts.forEach((layout) => {
          mapped[layout.uuid] = {
            uuid: layout.uuid,
            _uuid: layout._uuid,
            id: layout.id,
            type: "layout",
            name: layout.name,
            item: layout,
            data: { collection, item, layout },
          };
          layout.partGroups.forEach((partGroup) => {
            mapped[partGroup.uuid] = {
              uuid: partGroup.uuid,
              _uuid: partGroup._uuid,
              id: partGroup.id,
              type: "partGroup",
              name: partGroup.name,
              item: partGroup,
              data: { collection, item, layout, partGroup },
            };
            partGroup.parts.forEach((part) => {
              mapped[part.uuid] = {
                uuid: part.uuid,
                _uuid: part._uuid,
                id: part.id,
                type: "part",
                name: part.name,
                item: part,
                data: { collection, item, layout, partGroup, part },
              };
              part.materials.forEach((material) => {
                mapped[material.uuid] = {
                  uuid: material.uuid,
                  _uuid: material._uuid,
                  id: material.id,
                  type: "material",
                  name: material.name,
                  item: material,
                  data: { collection, item, layout, partGroup, part, material },
                };
              });
            });
          });
        });
      });
    }
    return mapped;
  }, [collection]);
  const selectedItem = useMemo<SelectedItem | null>(
    () => reverseMapByUUID[selectedKey] || null,
    [reverseMapByUUID, selectedKey]
  );

  const getItemByUUID = useCallback(
    (uuid: string) => reverseMapByUUID[uuid],
    [reverseMapByUUID]
  );
  const updateItemByUUID = useCallback(
    (
      uuid: string,
      callback: (item: MixType, parent: MixType | null) => Partial<MixType>
    ): void => {
      const item = getItemByUUID(uuid);
      if (item) {
        const copyOfLayouts: LayoutType[] = JSON.parse(JSON.stringify(layouts));
        collectionWalker(copyOfLayouts, (_item, _parent) => {
          if (_item.uuid === uuid) {
            Object.assign(_item, callback(_item, _parent));
            return true;
          }
        });
        setLayouts(copyOfLayouts);
      }
    },
    [getItemByUUID, layouts]
  );

  const value = {
    companyId,
    orderId,
    itemCollectionId,
    items,
    setItems,
    collection,
    setCollection,
    layouts,
    setLayouts,
    selectedKey,
    setSelectedKey,
    selectedItem,
    getItemByUUID,
    updateItemByUUID,
  };

  return <LayoutsContext.Provider value={value} {...props} />;
};

export const useLayoutsContext = () => {
  return useContext<LayoutsContextValue>(LayoutsContext);
};
