import React, { Component } from "react";
import "./leftMenu.scss";

class LeftMenu extends Component {
  state = {};

  renderSections = () => {
    return this.props.sections;
  };

  renderApproveSection = () => {
    return "approve ?";
  };

  render() {
    return (
      <div className="left-menu">
        {this.renderSections()}
        {this.props.showApprove && this.renderApproveSection()}
      </div>
    );
  }
}

export default LeftMenu;
