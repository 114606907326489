import React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
const { confirm } = Modal;

export const useCommenterConfirmPopup = () => {
  const showConfirm = (onOk: () => void) => {
    confirm({
      title: "Unsaved changes!",
      icon: <ExclamationCircleFilled />,
      content: "Do you want to discard this comment?",
      onOk,
      okText: "Discard",
      okType: "danger",
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return {
    showConfirm,
  };
};
