import React from "react";
import { Avatar, Dropdown, Layout, Menu, Image } from "antd";
import logo from "assets/images/logo.png";
import UserDropdown from "./UserDropdown";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setToken } from "store/actions/auth";
import { Select } from "antd";
import _ from "lodash";
import {
  getParamsFromPathname,
  capitalizeFirstLetter,
  withRouter,
  createMenuItem,
} from "utils";

const { Header } = Layout;

class Navigation extends React.Component {
  handleCompanyChanage = (value) => {
    this.props.router.navigate(`/${value}/products`);
  };

  renderCompaniesSelect = ({ selectedCompanyId }) => {
    let i = -1;
    return (
      <div key="select" className="companies-select">
        <Select
          defaultValue={selectedCompanyId}
          value={selectedCompanyId}
          style={{ width: "200px" }}
          onChange={async (value) => {
            this.handleCompanyChanage(value);
          }}
        >
          {_.map(this.props.companies, (c) => {
            i++;
            return (
              <Select.Option key={`nav-item-${i}`} value={c.id}>
                {c.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  };

  renderMenuItems = ({ selectedCompanyId, selectedOrderId }) => {
    const menuTabs = [
      createMenuItem({
        label: (
          <Link
            to={`/${selectedCompanyId}/products${
              selectedOrderId ? "/" + selectedOrderId : ""
            }`}
          >
            PRODUCTS
          </Link>
        ),
        Key: "products",
      }),
      createMenuItem({
        label: <Link to={`/${selectedCompanyId}/materials`}>MATERIALS</Link>,
        Key: "materials",
      }),
    ];

    if (this.props.profile.admin) {
      menuTabs.push(
        createMenuItem({
          label: <Link to={`/${selectedCompanyId}/showrooms`}>SHOWROOMS</Link>,
          key: "showrooms",
        })
      );
    }

    return menuTabs;
  };

  render() {
    const { companyIdInUrl, orderIdInUrl } = getParamsFromPathname(
      window.location.pathname
    );
    const urlParams = {
      selectedCompanyId: Number(companyIdInUrl),
      selectedOrderId: Number(orderIdInUrl),
    };
    return (
      <Header
        className="portalGlobalNavHeader"
        style={{
          position: "fixed",
          width: "100%",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          zIndex: 99,
        }}
      >
        <div className="logo">
          <Image
            preview={false}
            className="logo_img"
            alt="bitreel"
            src={logo}
            onClick={() => {
              this.props.router.navigate(
                `/${urlParams.selectedCompanyId}/products${
                  urlParams.selectedOrderId
                    ? "/" + urlParams.selectedOrderId
                    : ""
                }`
              );
            }}
          />
          <Menu
            className="portalGlobalNavHorizontalMenu"
            mode="horizontal"
            // style={{ height: "100%", minWidth: "100%" }}
            defaultSelectedKeys={[
              window.location.pathname.split("/")[1] || "home",
            ]}
            items={this.renderMenuItems({ ...urlParams })}
          />
        </div>
        <div className="user">
          {this.renderCompaniesSelect({
            selectedCompanyId: urlParams.selectedCompanyId,
          })}
          <Dropdown
            dropdownRender={() => (
              <UserDropdown
                profile={this.props.profile}
                selectedCompanyId={urlParams.selectedCompanyId}
                logoutFxn={this.props.setToken}
              />
            )}
            placement="bottomRight"
            arrow
          >
            <Avatar size="large" gap={2}>
              {Object.keys(this.props.profile).length
                ? capitalizeFirstLetter(this.props.profile.email)
                : ""}
            </Avatar>
          </Dropdown>
        </div>
      </Header>
    );
  }
}

const mapStateToProps = ({ companies, profile }) => {
  return {
    companies,
    profile,
  };
};

export default connect(mapStateToProps, {
  setToken,
})(withRouter(Navigation));
