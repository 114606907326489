import { Button, Modal } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "utils";
class AddMaterial extends Component {
  state = {
    name: "",
    priceMod: "",
  };

  renderMaterialCards = () => {
    return (
      <div className="material-cards-container">
        {_.map(this.props.materials, (m) => {
          return (
            <div className="material-card" key={`meterial-id-${m.id}`}>
              <div className="top">
                <h4>Material Name</h4>
                <div>{m.name}</div>
              </div>
              <div className="bottom">
                <div
                  className="material-image"
                  style={{ backgroundImage: `url(${m.internal_url})` }}
                />
              </div>
              <Button
                onClick={() => {
                  this.props.onOk(m);
                }}
              >
                Select
              </Button>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <Modal
        title="Add Material"
        open={this.props.visible}
        onOk={() => this.props.onOk(this.state.name, this.state.priceMod)}
        onCancel={this.props.onCancel}
      >
        {this.renderMaterialCards()}
        {/* <label>name</label>
                <input value={this.state.name} onChange={e => this.setState({ name: e.target.value})}></input> */}
      </Modal>
    );
  }
}

const mapStateToProps = (
  {
    materials,
    auth,
    app: {
      modalStates: { createVariantAdvanced: modalState },
    },
  },
  { itemCollectionId }
) => {
  return {
    materials,
  };
};

export default connect(mapStateToProps, {})(withRouter(AddMaterial));
