import React, { useMemo, useState } from "react";
import { Button, Form, Input, UploadFile } from "antd";
import { LayoutType, ItemRemoveType, SelectedItem } from "./../types";
import { FilesDropZone } from "components/Media";
import { useLayouts, useItem } from "../hooks";
import { imageExtensions } from "sharedConstants";
import { useUIContext } from "../contexts/UIContext";
import ApiService from "services/ApiService";

interface LayoutViewProps {
  selectedItem: SelectedItem;
}

export const LayoutView = ({ selectedItem }: LayoutViewProps) => {
  const [refreshKey, setRefreshKey] = useState(0);
  const { updateLayout, addReferenceFiles } = useLayouts();
  const { isDirty, setIsDirty } = useUIContext();
  const layout = selectedItem.item as LayoutType;
  const item = selectedItem.item as ItemRemoveType;

  const isImage = (filename: string) => {
    const filenameSplit = filename.split(".");
    return imageExtensions.includes(filenameSplit[filenameSplit.length - 1]);
  };

  const fileList = useMemo<UploadFile[]>(
    () =>
      item.files.reference_file?.map((file: any) => ({
        name: `${file.file_name}`,
        uid: file.id,
        thumbUrl: isImage(file.file_name) ? file.url : undefined,
        url: file.url,
      })) || [],
    [item.files.reference_file]
  );

  const onFinish = async (values: any) => {
    await updateLayout({
      uuid: layout.uuid,
      apiItemId: layout.apiItemId,
      apiVersionId: layout.apiVersionId,
      name: values.name,
      basePrice: values.basePrice,
    });
    setIsDirty(false);
  };
  // console.log(layout.external_url);
  // console.log(layout.thumbnail_post_url);
  return (
    <div className="layout-view">
      <Form
        name="layout-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: layout.name,
          basePrice: layout.basePrice || 0,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Base Price"
          name="basePrice"
          rules={[{ required: true, message: "Please input the base price!" }]}
        >
          <Input addonBefore="$" type="number" />
        </Form.Item>

        <Form.Item
          label="Reference Files"
          name="referenceFiles"
          valuePropName="fileList"
        >
          <>
            <FilesDropZone
              multiple
              beforeUpload={async (fileData) => {
                await addReferenceFiles({
                  item: item,
                  fileData,
                  fileName: fileData.name,
                });
                return false;
              }}
              fileList={fileList}
              listType="picture"
              showUploadList={{ showRemoveIcon: false }}
              // showUploadList={false}
            />
          </>
        </Form.Item>
        <p>Thumbnail</p>
        <div>
          <img
            src={
              layout.external_url +
              "/thumbnail_compressed/256.png?rerender=" +
              Date.now()
            }
          />
        </div>
        <>
          <FilesDropZone
            beforeUpload={async (fileData) => {
              console.log("fileDataInVersion", fileData);
              try {
                const uploadFormData = new FormData();
                Object.keys(layout.thumbnail_post_url.fields).forEach((key) => {
                  uploadFormData.append(
                    key,
                    layout.thumbnail_post_url.fields[key]
                  );
                });
                uploadFormData.append("file", fileData);
                // @ts-ignore
                const s3UploadResponse = await ApiService.postToS3(
                  layout.thumbnail_post_url?.url,
                  uploadFormData
                );
                setTimeout(() => {
                  setRefreshKey(refreshKey + 1);
                }, 10000);
                return false;
              } catch (e) {
                console.log(e);
              }
            }}
            fileList={[]}
            listType="picture"
            showUploadList={{ showRemoveIcon: false }}
          />
        </>

        <Form.Item>
          <div className="commit-buttons">
            <Button disabled={!isDirty} type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              htmlType="reset"
              onClick={() => {
                setIsDirty(false);
              }}
            >
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
