import { Button, Modal, Upload } from "antd";
import React, { Component } from "react";
import CloudUpload from "assets/svg/CloudUpload";

class AddLayout extends Component {
  state = {
    img: null,
    name: "",
  };
  render() {
    return (
      <Modal
        title="Add Product Layout"
        open={this.props.visible}
        onOk={() =>
          this.props.onOk(this.state.name, this.state.img, this.state.file)
        }
        onCancel={this.props.onCancel}
      >
        <label>Item/Layout Name:</label>
        <div style={{ marginBottom: "12px" }}>
          <input
            style={{ width: "350px" }}
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          ></input>
        </div>
        <div>
          <Upload
            showUploadList={false}
            beforeUpload={(file) => {
              // debugger
              const reader = new FileReader();
              reader.onload = () => {
                this.setState({
                  img: reader.result,
                  file,
                });
              };
              reader.readAsDataURL(file);
              return false;
            }}
          >
            <Button>
              <div>Add Product Image</div>
            </Button>
          </Upload>
        </div>
      </Modal>
    );
  }
}

export default AddLayout;
