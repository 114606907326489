import React, { useEffect, useMemo } from "react";
import { AimOutlined } from "@ant-design/icons";
import { useEngineContext } from "../../contexts/EngineContext";
import { useShowroomContext } from "modules/showrooms/contexts/ShowroomContext";

interface BabylonViewWrapperProps {
  children?: React.ReactElement | React.ReactElement[];
}

export const BabylonViewWrapper = ({ children }: BabylonViewWrapperProps) => {
  const { isFocusedEngine, setIsFocusedEngine } = useEngineContext();
  const { showroomMode, showroomModeParams } = useShowroomContext();
  const showroomParamsClassNames = useMemo(
    () =>
      Object.values(showroomModeParams)
        .map((param) => `MODE__PARAMETER--${param}`)
        .join(" "),
    [showroomModeParams]
  );

  useEffect(
    () => () => {
      setIsFocusedEngine(false);
    },
    [setIsFocusedEngine]
  );

  return (
    <div
      // onClick={() => {
      //   setIsFocusedEngine(true);
      // }}
      onMouseEnter={() => {
        setIsFocusedEngine(true);
      }}
      onMouseLeave={() => {
        setIsFocusedEngine(false);
      }}
      className={`scene-wrapper ${
        isFocusedEngine ? "is-focused" : "not-focused"
      } MODE__${showroomMode} ${showroomParamsClassNames}`}
      style={{ height: "100%" }}
    >
      {children}
      {/* <div
        className="scene-layover"
        // onClick={(e) => {
        //   setIsFocusedEngine(true);
        //   e.stopPropagation();
        //   e.preventDefault();
        // }}
      >
        <div className="scene-layover--title">
          <AimOutlined />
          <span>Click to Interact</span>
        </div>
      </div> */}
    </div>
  );
};
