import React, { useCallback, useMemo } from "react";
import { Breadcrumb } from "antd";
import { SelectedItem } from "../types";
import { LayoutOutlined } from "@ant-design/icons";

interface LayoutBreadcrumbProps {
  selectedItem: SelectedItem;
  onSelect?: (key: string) => void;
}

export const LayoutBreadcrumb = ({
  selectedItem,
  onSelect = () => {},
}: LayoutBreadcrumbProps) => {
  const {
    data: { item, layout, partGroup, part, material },
  } = selectedItem;

  const breadcrumbItems = useMemo(() => {
    return [layout, partGroup, part, material]
      .filter((i) => !!i)
      .map((item: any, index) => ({
        title: item.name,
        uuid: item.uuid,
        index,
      }));
  }, [item, layout, material, part, partGroup]);

  const breadcrumbItemRender = useCallback(
    (item: any) => {
      return (
        <span
          onClick={() => {
            onSelect(item.uuid);
          }}
        >
          {item.index === 0 && <LayoutOutlined />} {item.title}
        </span>
      );
    },
    [onSelect]
  );

  return (
    <Breadcrumb
      items={breadcrumbItems}
      itemRender={breadcrumbItemRender}
      className="layout-breadcrumb"
    />
  );
};
