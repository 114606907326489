import produce from "immer";
import { SET_ITEM_STATUSES } from "sharedConstants";

const INITIAL_STATE = {
  itemStatuses: [],
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ITEM_STATUSES:
      const { itemStatuses } = action;
      draft["itemStatuses"] = itemStatuses;
      break;

    default:
      break;
  }

  return draft;
});
