import React, { useMemo } from "react";
import { Button, Input } from "antd";
import _ from "lodash";
import {
  PlusCircleFilled,
  CloseCircleFilled,
  CloseCircleOutlined,
  PauseCircleFilled,
} from "@ant-design/icons";
import { Comment } from "../../types";
import { useEngineContext } from "../../contexts/EngineContext";
import { useCommentContext } from "../../contexts/CommentContext";
import { useCommenterConfirmPopup } from "./useCommenterConfirmPopup";

const { TextArea } = Input;

interface CommenterFormProps {
  onSubmit?: (payload: Comment) => void;
}

export const CommenterForm = ({ onSubmit = () => {} }: CommenterFormProps) => {
  const { hotspotMode, setHotspotMode, setIsFocusedEngine, setShowHotspots } =
    useEngineContext();
  const {
    comment,
    updateComment,
    createNewEmptyComment,
    removeAnnotation,
    removeAllAnnotations,
    selectedAnnotationId,
    setSelectedAnnotationId,
  } = useCommentContext();
  const { showConfirm } = useCommenterConfirmPopup();
  const annotationsArray = useMemo(
    () => _.values(comment.annotations),
    [comment.annotations]
  );

  const disableSubmit = useMemo(
    () => !comment.description || !comment.isDirty,
    [comment.description, comment.isDirty]
  );

  /*************** Actions ***************/
  const onEnableAddingAnnotationsHandler = () => {
    setHotspotMode("add");
    setIsFocusedEngine(true);
    setShowHotspots(true);
  };

  const onDisableAddingAnnotationsHandler = () => {
    setHotspotMode(null);
  };

  const onRemoveAllAnnotationsHandler = () => {
    removeAllAnnotations();
  };

  const onRemoveAnnotationHandler = (annotationId: string) => {
    removeAnnotation(annotationId);
    setSelectedAnnotationId(null);
  };

  const handleOnSubmit = () => {
    if (comment) {
      onSubmit(comment);
    }
  };
  /***************************************/

  return (
    <div className="commenter-form">
      <div className="comment-section--title">
        {comment.id ? "Edit" : "Add"} comment:
      </div>
      <div className="comment-section--actions">
        {hotspotMode === "add" ? (
          <Button
            className="stop-adding-hotspots"
            type="link"
            onClick={onDisableAddingAnnotationsHandler}
          >
            <PauseCircleFilled />
            Cancel Adding
          </Button>
        ) : (
          <Button
            className="start-adding-hotspots"
            type="link"
            onClick={onEnableAddingAnnotationsHandler}
          >
            <PlusCircleFilled />
            Add Hotspot
          </Button>
        )}
        <Button
          disabled={!selectedAnnotationId}
          onClick={() => {
            if (selectedAnnotationId)
              onRemoveAnnotationHandler(selectedAnnotationId);
          }}
          type="link"
        >
          <CloseCircleFilled /> Remove
        </Button>
        <Button
          disabled={!annotationsArray.length}
          onClick={() => {
            onRemoveAllAnnotationsHandler();
          }}
          type="link"
        >
          <CloseCircleOutlined /> Remove All
          {annotationsArray.length ? ` (${annotationsArray.length})` : null}
        </Button>
      </div>
      <div className="comment-section--textarea">
        <TextArea
          value={comment.description}
          onChange={(e) => {
            updateComment(() => ({ description: e.target.value }));
          }}
          autoSize={{ minRows: 6, maxRows: 6 }}
        />
      </div>
      <div className="comment-section--submit-actions">
        <Button
          type="primary"
          shape="round"
          className="submit-button"
          disabled={disableSubmit}
          onClick={handleOnSubmit}
        >
          {comment.id ? "SAVE" : "SUBMIT"}
        </Button>
        {!!comment.id && (
          <Button
            type="link"
            className="submit-button"
            onClick={() => {
              if (comment.isDirty) {
                showConfirm(() => {
                  createNewEmptyComment();
                  setShowHotspots(true);
                });
              } else {
                createNewEmptyComment();
                setShowHotspots(true);
              }
            }}
          >
            CREATE NEW COMMENT
          </Button>
        )}
      </div>
    </div>
  );
};
