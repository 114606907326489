import React, { useMemo, useRef, useState } from "react";
import { Button, message, Upload } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload/interface";
import { UploadFile } from "antd/lib/upload/interface";
import { AudioPlayer } from "components/Media";

type CustomRcFile = RcFile & { isValid?: boolean; tempUrl: string };
type AudioType = "mpeg" | "mp3";

interface AudioUploaderProps {
  allowedType?: AudioType[];
  maxSize?: number;
  accept?: string;
}

const UploadButton = ({ loading }: { loading?: boolean }) => (
  <div>
    {loading ? <UploadOutlined /> : <PlusOutlined />}
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);

export const AudioUploader = ({
  allowedType = ["mpeg"],
  maxSize = 10,
  accept = ".mp3",
}: AudioUploaderProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const file = useMemo(() => {
    const file = fileList[0] || null;
    if (file)
      (file as CustomRcFile).tempUrl = URL.createObjectURL(
        file.originFileObj as File
      );
    return file;
  }, [fileList]);
  const wrapperRef = useRef<any>(null);

  const handleBeforeUpload = (rcFile: RcFile) => {
    let isValid = true;
    if (isValid && allowedType) {
      if (!allowedType.find((ext) => `audio/${ext}` === rcFile.type)) {
        isValid = false;
        message.error(
          `You can only upload ${allowedType
            .map((ext) => ext.toUpperCase())
            .join("/")} file!`
        );
      }
    }

    if (isValid && maxSize) {
      if (rcFile.size / 1024 / 1024 > maxSize) {
        isValid = false;
        message.error(`Audio must be smaller than ${maxSize}MB!`);
      }
    }

    (rcFile as CustomRcFile).isValid = isValid;
    return false;
  };

  const handleOnReplace = () => {
    try {
      wrapperRef.current.querySelectorAll('input[type="file"]')[0].click();
    } catch {
      /* empty */
    }
  };

  const handleOnRemove = () => {
    try {
      wrapperRef.current.getElementsByClassName("anticon-delete")[0].click();
    } catch {
      /* empty */
    }
  };

  return (
    <div ref={wrapperRef} className="audio-uploader">
      <Upload
        className={`${file ? "inserted-file" : "no-file"}`}
        fileList={fileList}
        beforeUpload={handleBeforeUpload}
        onRemove={() => {
          setFileList([]);
        }}
        onChange={({ fileList }) => {
          if (fileList[0] && (fileList[0] as CustomRcFile).isValid) {
            setFileList(fileList);
          }
        }}
        maxCount={1}
        listType="picture-card"
        accept={accept}
      >
        {!file && <UploadButton />}
      </Upload>
      {file && (
        <div className="file-properties-wrapper">
          <AudioPlayer source={(file as CustomRcFile).tempUrl} />
          <div className="file-properties">
            <div className="file-name">{file.name}</div>
            <div className="file-actions">
              <Button type="link" onClick={handleOnRemove}>
                remove
              </Button>
              <span className="divider">|</span>
              <Button type="link" onClick={handleOnReplace}>
                replace
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
