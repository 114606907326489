import { Button, Upload } from "antd";
import React, { Component } from "react";

class LayoutDetails extends Component {
  state = {};

  renderReferenceFiles = () => {
    return <div></div>;
  };

  render() {
    return (
      <div className="layout-details">
        <div className="layout actions">
          <div>
            <div>
              <h3>Reference Files</h3>
              <Upload
                // accept=""
                showUploadList={false}
                beforeUpload={(file) => {
                  this.props.addReferenceFileFxn(file);
                  return false;
                }}
              >
                <Button>Attach File</Button>
              </Upload>
            </div>
          </div>
        </div>
        <div
          className="layout-image"
          style={{
            backgroundImage: this.props.layout
              ? `url('${this.props.layout.image}')`
              : "none",
          }}
        ></div>
      </div>
    );
  }
}

export default LayoutDetails;
